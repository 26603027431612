import Image from "next/image";

interface Props {
  mainText: any;
  secondaryTextLineOne: any;
  secondaryTextLineTwo: any;
  icon: any;
  SimpleBlockContent: any;
  urlFor: Function;
}
const LocalizedOneIconAndTwoText = (props: Props) => {
  const {
    mainText,
    secondaryTextLineOne,
    secondaryTextLineTwo,
    icon,
    urlFor,
    SimpleBlockContent,
  } = props;
  return (
    <div className="relative w-full bg-primary-4 text-primary-2 flex flex-col items-center justify-center p-4 sm:py-6 sm:px-0">
      <div className="flex md:hidden justify-center">
        <Image
          src={urlFor(icon.image, true).url()}
          height={100}
          width={100}
          objectFit="contain"
          alt={icon.image.alt || ""}
        />
      </div>
      <div className="hidden md:flex md:justify-center">
        <Image
          src={urlFor(icon.image, true).url()}
          height={100}
          width={160}
          objectFit="contain"
          alt={icon.image.alt || ""}
        />
      </div>
      {mainText && (
        <SimpleBlockContent
          blocks={mainText}
          className="font-p22 font-medium text-primary-2 text-center text-base sm:text-xl lg:text-3xl"
        />
      )}
      {secondaryTextLineOne && (
        <SimpleBlockContent
          blocks={secondaryTextLineOne}
          className="font-objektiv font-medium text-center text-base sm:text-lg my-0"
        />
      )}
      {secondaryTextLineTwo && (
        <SimpleBlockContent
          blocks={secondaryTextLineTwo}
          className="font-objektiv font-medium text-center text-base sm:text-lg my-0"
        />
      )}
    </div>
  );
};

export default LocalizedOneIconAndTwoText;
