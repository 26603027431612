const CheckMarkCircle = ({
  height,
  width,
  siteName,
}: {
  height: number;
  width: number;
  siteName: "lildog" | "lilcat";
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 55 56`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.653 23.5002L53.3506 26.6976C52.9392 27.786 52.9392 28.9425 53.3506 30.031L54.653 33.2283C55.4071 35.1331 54.7902 37.31 53.0764 38.5346L50.1972 40.5074C49.2374 41.1877 48.6205 42.2081 48.3463 43.2966L47.7293 46.698C47.318 48.7388 45.6042 50.2355 43.5476 50.3035L40.0515 50.4396C38.8861 50.5076 37.7893 50.9838 36.9666 51.8001L34.5673 54.3172C33.1277 55.8138 30.8655 56.1539 29.0832 55.1335L26.0669 53.3648C25.0386 52.7525 23.8732 52.6165 22.7078 52.8886L19.2802 53.7049C17.2922 54.1811 15.1671 53.2287 14.2074 51.3919L12.6307 48.2626C12.0823 47.2422 11.1911 46.4259 10.0943 46.0857L6.80378 44.9973C4.81577 44.317 3.58184 42.4122 3.78749 40.3713L4.13025 36.9019C4.26736 35.7454 3.9246 34.5889 3.17053 33.7045L0.976865 30.9834C-0.325622 29.3507 -0.325622 27.1058 0.976865 25.4731L3.17053 22.7519C3.9246 21.8676 4.26736 20.7111 4.13025 19.5546L3.78749 16.0851C3.58184 14.0443 4.81577 12.1395 6.80378 11.4592L10.0943 10.3707C11.1911 10.0306 12.0823 9.21426 12.6307 8.19383L14.2074 5.06452C15.1671 3.22775 17.2237 2.27535 19.2802 2.75155L22.7078 3.56789C23.8047 3.84001 25.0386 3.70395 26.0669 3.09169L29.0832 1.32295C30.8655 0.302523 33.1277 0.574637 34.5673 2.13929L36.9666 4.65635C37.7893 5.47269 38.8861 6.01692 40.0515 6.01692L43.5476 6.15298C45.6042 6.221 47.3865 7.71763 47.7293 9.75849L48.3463 13.1599C48.5519 14.3164 49.2374 15.3368 50.1972 15.9491L53.0764 17.9219C54.7902 19.4185 55.4757 21.5954 54.653 23.5002Z"
        fill={"#B5CF8F"}
      />
      <path
        d="M41.9709 50.1674C41.6282 50.1674 41.2854 50.2355 41.0112 50.3035L43.4791 50.1674C43.8218 50.1674 44.096 50.0994 44.4388 50.0314L41.9709 50.1674Z"
        fill="white"
      />
      <path
        d="M16.6067 24.0445L25.1757 33.7726L42.725 19.7587"
        fill={"#B5CF8F"}
      />
      <path
        d="M23.8645 41.3552C22.9048 41.3552 21.9451 40.947 21.2596 40.1987L12.6906 30.4706C11.4566 29.042 11.5937 26.9331 12.9648 25.6406C14.4044 24.4161 16.5295 24.5521 17.832 25.9127L24.2758 33.1918L39.2887 21.2188C40.7283 20.0623 42.922 20.2663 44.0874 21.763C45.2527 23.2596 45.0471 25.3685 43.5389 26.525L25.9896 40.6069C25.3727 41.0831 24.6186 41.3552 23.8645 41.3552Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckMarkCircle;
