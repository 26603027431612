import React from "react";

type Props = {
  boldText: string;
  smallText: string;
  siteName: "lildog" | "lilcat";
};

const AntiTwoAlignedTexts = ({ boldText, smallText }: Props) => {
  return (
    <div className="flex flex-col items-center justify-center font-objektiv text-primary-2 py-12">
      <div className="flex flex-col w-full rounded-bl-3xl pb-10 pt-12 lg:flex-row">
        <div className="">
          <h1 className=" font-extrabold text-h1 leading-h1  mx-6 lg:pr-0  pb-6 lg:text-h1-desktop lg:leading-h1-desktop ">
            {boldText}
          </h1>
        </div>
        <div className="mx-6 lg:pl-2">
          <h2 className="leading-h6-desktop text-h5 lg:leading-h5-desktop lg:text-h4 lg:pl-12 font-normal">
            {smallText}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default AntiTwoAlignedTexts;
