import { Image as ImageType } from "@lilbit/shared";
import Image from "next/image";
type Props = {
  title: string;
  imageArray: Array<ImageType & { _key: string }>;
  urlFor: Function;
};
const GallerySection = (props: Props) => {
  const { title, imageArray, urlFor } = props;
  return (
    <div>
      <div>
        <h1 className="text-red-500">{title}</h1>
        {imageArray.map((image) => (
          <Image
            key={image._key}
            src={urlFor(image).url()}
            width={250}
            height={250}
            alt={image.alt || ""}
          />
        ))}
      </div>
    </div>
  );
};

export default GallerySection;
