import { memo } from "react";
const ArrowRight = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17.5" cy="17.5" r="16.5" stroke="#1C3054" strokeWidth="2" />
      <path
        d="M14.1348 8.07812L23.5576 17.501L14.1348 26.9239"
        stroke="#21401a"
        strokeWidth="2"
      />
    </svg>
  );
};

export default memo(ArrowRight);