import React from "react";
import { useLocale } from "../../translations";
import CountdownTimer from "../CountdownTimer";

import { InformationCircleIcon } from "@heroicons/react/24/outline";
interface Props {
  textcenter: string;
  endDateTime: Date;
  mainProductPrices: {
    PRICE: any;
    ORIGINAL_PRICE: any;
  };
  productTaxRate: string;
  locale: {
    defaultCurrency: string;
  };
  header: boolean;
}

const LocalizedSectionCyberMonday = (props: Props) => {
  const {
    textcenter,
    endDateTime,
    mainProductPrices,
    productTaxRate,
    locale,
    header,
  } = props;
  const urlPrefix = useLocale().urlPrefix;
  const endDate = new Date(endDateTime.toString());

  const calculatePrice = (price: string, taxRate: number) => {
    const priceNumber = parseFloat(price);
    const priceWithTax = priceNumber * (1 + taxRate / 100);
    return priceWithTax.toFixed(0);
  };

  const originalPrice = calculatePrice(
    mainProductPrices?.ORIGINAL_PRICE?.[locale?.defaultCurrency] ?? null,
    parseFloat(productTaxRate)
  );
  const price = calculatePrice(
    mainProductPrices?.PRICE?.[locale?.defaultCurrency] ?? null,
    parseFloat(productTaxRate)
  );

  const discount = parseFloat(originalPrice) - parseFloat(price);

  const cyberMondayHeader = (
    <>
      {mainProductPrices?.ORIGINAL_PRICE && (
        <div className="flex flex-col bg-cyber-monday-gradient w-full h-52 mt-4 text-cyberMonday-cyberLight text-center justify-center">
          <p className="text-3xl md:text-4xl lg:text-5xl font-serif ">
            {textcenter ? textcenter : translations.cyberMonday.en}
          </p>
          <p className=" text-lg md:text-2xl lg:text-3xl font-extralight ">
            {translations.goodDeal[urlPrefix]
              ? translations.goodDeal[urlPrefix]
              : translations.goodDeal.en}
          </p>
        </div>
      )}
    </>
  );

  if (header) {
    return <>{cyberMondayHeader}</>;
  }
  return (
    <>
      {mainProductPrices?.ORIGINAL_PRICE ? (
        <div className="flex flex-col  bg-cyber-monday-gradient w-full h-fit mt-4 text-cyberMonday-cyberLight text-center">
          <div className="flex justify-center">
            <p className="mt-4  text-3xl md:text-5xl lg:text-6xl  font-serif ">
              {textcenter ? textcenter : translations.cyberMonday.en}
            </p>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 my-8 justify-center">
            <div className="flex flex-col justify-center text-center">
              {discount ? (
                <p className="text-2xl ml-4 sm:ml-8 md:ml-12 lg:ml-16 font-semibold">
                  {translations.save[urlPrefix]
                    ? translations.save[urlPrefix]
                    : translations.save.en}{" "}
                  {discount} {translations.curr[locale.defaultCurrency]}
                </p>
              ) : (
                <p className="text-2xl ml-4 sm:ml-8 md:ml-12 lg:ml-16 flex justify-center  font-semibold">
                  <></>
                </p>
              )}
            </div>
            <div className="hidden md:flex flex-col items-center justify-center">
              <p className="text-sm">
                {translations.timer[urlPrefix]
                  ? translations.timer[urlPrefix]
                  : translations.timer.en}
              </p>
              <CountdownTimer targetDate={endDate} />
            </div>
            <div className="flex flex-col items-center text-center mr-4 sm:mr-8 md:mr-12 lg:mr-16 justify-center">
              <p className="text-xs md:text:sm px-10 hidden sm:flex">
                <></>
              </p>
              <div className="w-4/5   bg-white rounded-2xl items-center text-center flex justify-center flex-col md:flex-row ">
                <InformationCircleIcon className="h-8 w-8 fill-black hidden md:flex " />
                <p className="text-xs lg:text-sm text-black md:ml-2 my-1 md:my-2">
                  {translations.lagerstatus[urlPrefix]
                    ? translations.lagerstatus[urlPrefix]
                    : translations.lagerstatus.en}
                  :
                </p>
                <p className="text-sm lg:text-lg text-red-800 md:mx-2">
                  {translations.varer[urlPrefix]
                    ? translations.varer[urlPrefix]
                    : translations.varer.en}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:hidden text-center items-center justify-center mb-4">
            <CountdownTimer targetDate={endDate} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const translations = {
  save: {
    no: "Kjøp nå og spar",
    en: "Buy now and save",
    se: "Köp nu och spara",
    dk: "Køb nu og spar",
    fi: "Osta nyt ja säästä",
    de: "Jetzt kaufen und sparen",
    fr: "Achetez maintenant et économisez",
    es: "Compre ahora y ahorre",
  },
  timer: {
    no: "Tilbudet utløper om",
    en: "Offer expires in",
    se: "Erbjudandet löper ut om",
    dk: "Tilbuddet udløber om",
    fi: "Tarjous päättyy",
    de: "Angebot endet in",
    fr: "L'offre expire dans",
    es: "La oferta expira en",
  },
  lagerstatus: {
    no: "Lagerstatus",
    en: "Stock status",
    se: "Lagerstatus",
    dk: "Lagerstatus",
    fi: "Varastotilanne",
    de: "Lagerstatus",
    fr: "Statut des stocks",
    es: "Estado de stock",
  },
  varer: {
    no: "Få varer igjen",
    en: "Few items left",
    se: "Få varor kvar",
    dk: "Få varer tilbage",
    fi: "Vain muutama tuote jäljellä",
    de: "Nur noch wenige Artikel übrig",
    fr: "Plus que quelques articles",
    es: "Quedan pocos artículos",
  },
  curr: {
    NOK: ",-",
    USD: "$",
    EUR: "€",
  },
  cyberMonday: {
    en: "Cyber Monday",
  },
  goodDeal: {
    no: "Gjør Et Kupp",
    en: "Make a Great Deal",
    se: "Gör ett bra köp",
    dk: "Gør et godt køb",
    fi: "Tee hyvä kauppa",
    de: "Machen Sie ein gutes Geschäft",
    fr: "Faites une bonne affaire",
    es: "Haz un buen negocio",
  },
};
export default LocalizedSectionCyberMonday;
