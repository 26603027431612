const countryList = [
  { countryCode: "NO", countryName: "Norge" },
  { countryCode: "DE", countryName: "Germany" },
  // { countryCode: "US", countryName: "USA" },
  { countryCode: "NL", countryName: "Netherlands" },
  { countryCode: "SE", countryName: "Sweden" },
  { countryCode: "DK", countryName: "Denmark" },
  { countryCode: "FI", countryName: "Finland" },
  { countryCode: "EE", countryName: "Estonia" },
  { countryCode: "FR", countryName: "France" },
  { countryCode: "IS", countryName: "Iceland" },
  { countryCode: "LU", countryName: "Luxembourg" },
  { countryCode: "ES", countryName: "Spain" },
  { countryCode: "GR", countryName: "Greece" },
  { countryCode: "CH", countryName: "Switzerland" },
];

export default countryList;
