import Link from "next/link";
import { Image as ImageType, getPositionFromHotspot } from "../../lib";
import Image from "next/image";
import { useLocale } from "../../translations";

interface Props {
  lilcatImage: ImageType;
  lilcatText: string;
  lilcatLogo: ImageType;
  lildogImage: ImageType;
  lildogText: string;
  lildogLogo: ImageType;
  urlFor: Function;
}
const LocalizedSectionfour = (props: Props) => {
  const {
    lilcatImage,
    lilcatLogo,
    lilcatText,
    lildogImage,
    lildogLogo,
    lildogText,
    urlFor,
  } = props;

  const urlPrefix = useLocale().urlPrefix;
  return (
    <div className="flex flex-col space-y-4 max-w-screen-2xl mx-auto px-4 mt-4 lg:flex-row 2xl:px-0 lg:space-x-4 lg:space-y-0">
      <Link href={`https://lildog.com/${urlPrefix}`}>
        <div className="relative w-full h-[500px] flex justify-center cursor-pointer">
          {lildogImage && (
            <Image
              className="rounded-tr-[50px] rounded-b-[50px]"
              src={urlFor(lildogImage).url()}
              layout="fill"
              objectFit="cover"
              objectPosition={
                lildogImage.hotspot &&
                getPositionFromHotspot(lildogImage.hotspot)
              }
            />
          )}
          <div className="absolute w-1/4 h-1/4">
            {lildogLogo && (
              <Image
                src={urlFor(lildogLogo).url()}
                layout="fill"
                objectFit="contain"
                objectPosition={
                  lildogLogo.hotspot &&
                  getPositionFromHotspot(lildogLogo.hotspot)
                }
              />
            )}
          </div>
          <div className="absolute bottom-10 transition-transform hover:scale-102">
            <a className="text-white text-2xl underline">{lildogText}</a>
          </div>
        </div>
      </Link>
      <Link href={`https://lilcat.com/${urlPrefix}`}>
        <div className="relative w-full h-[500px] flex justify-center cursor-pointer">
          {lilcatImage && (
            <Image
              className="rounded-tl-[50px] rounded-b-[50px]"
              src={urlFor(lilcatImage).url()}
              layout="fill"
              objectFit="cover"
              objectPosition={
                lilcatImage.hotspot &&
                getPositionFromHotspot(lilcatImage.hotspot)
              }
            />
          )}
          <div className="absolute w-1/4 h-1/4">
            {lilcatLogo && (
              <Image
                src={urlFor(lilcatLogo).url()}
                layout="fill"
                objectFit="contain"
                objectPosition={
                  lilcatLogo.hotspot &&
                  getPositionFromHotspot(lilcatLogo.hotspot)
                }
              />
            )}
          </div>
          <div className="absolute bottom-10 transition-transform hover:scale-102">
            <a className="text-white text-2xl underline">{lilcatText}</a>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default LocalizedSectionfour;
