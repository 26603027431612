import { useLocale } from "@lilbit/shared";
import { useContext } from "react";
import { SitenameContext } from "../../context/SitenameContext";

const Signupform = () => {
  const locale = useLocale().urlPrefix;
  const { siteName } = useContext(SitenameContext);
  return (
    <div>
      {siteName === "lildog" ? (
        locale === "no" ? (
          <div className="klaviyo-form-UTAXcx"></div>
        ) : (
          <div className="klaviyo-form-SGGdqB"></div>
        )
      ) : locale === "no" ? (
        <div className="klaviyo-form-UkYqNP"></div>
      ) : (
        <div className="klaviyo-form-UhsiFJ"></div>
      )}
    </div>
  );
};

export default Signupform;
