import Image from "next/image";
import { Image as ImageType } from "../../lib";

interface Props {
  image: ImageType;
  text: string;
  SimpleBlockContent: any;
  urlFor: Function;
}
const LocalizedLogoAndTextUnder = (props: Props) => {
  const { image, text, SimpleBlockContent, urlFor } = props;
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="relative h-44 w-60">
        <Image src={urlFor(image).url()} layout="fill" objectFit="contain" />
      </div>
      <div className="max-w-prose text-center font-objektiv px-4 lg:px-0 lg:text-xl">
        <SimpleBlockContent blocks={text} />
      </div>
    </div>
  );
};

export default LocalizedLogoAndTextUnder;
