import React, { useEffect } from "react";
import { useLocale } from "@lilbit/shared";
import Error from "./Error";
import ExtLinkLogo from "../svgs/extlinklogo";
import Link from "next/link";
import BoldCheckbox from "../svgs/boldCheckbox";

const AcceptTerms = ({ input, acceptedTerms, setAcceptedTerms, siteName }) => {
  const locale = useLocale().urlPrefix;

  return (
    <div className="relative flex items-end mt-4 pt-4">
      <div className="min-w-0 flex-1 text-sm leading-6">
        <label
          htmlFor="comments"
          className="font-medium text-gray-900 flex items-center space-x-4"
        >
          <span>Vilkår og betingelser</span>
          <span>
            <Link
              href={`https://lilbit.no/${locale === "no" ? "no" : "en"}/${
                locale === "no" ? "betingelser" : "conditions"
              }`}
              passHref
            >
              <a target="_blank flex items-center">
                {/* <p className="font-objektiv text-[16px] leading-[20px] text-primary-2">
                {translations.read_terms[locale]}
              </p> */}
                <ExtLinkLogo siteName={siteName} />
              </a>
            </Link>
          </span>
        </label>
        <p id="comments-description" className="text-gray-600">
          {translations.accept_terms[locale]} *
        </p>
      </div>
      <div className="ml-3 flex h-6 items-center">
        <input
          id="conditions"
          aria-describedby="conditions-checkbox"
          name="conditions"
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-primary-mono-2 focus:ring-primary-mono-3"
          onClick={() => {
            if (acceptedTerms) {
              input.acceptTerms = false;
              setAcceptedTerms(false);
            } else {
              input.acceptTerms = true;
              setAcceptedTerms(true);
            }
          }}
        />
      </div>
    </div>
  );
  return (
    <React.Fragment>
      <div className="flex flex-col w-full text-xs font-objektiv font-normal justify-start items-start my-4">
        <div className="flex space-x-4 items-center text-primary-2 w-full">
          <div className="flex items-center">
            <input
              id="subscribe-to-newsletter"
              name="subscribe-to-newsletter"
              type="checkbox"
              onClick={() => {
                if (acceptedTerms) {
                  input.acceptTerms = false;
                  setAcceptedTerms(false);
                } else {
                  input.acceptTerms = true;
                  setAcceptedTerms(true);
                }
              }}
              className="h-4 w-4 rounded border-gray-300 text-primary-2 focus:ring-primary-3"
            />
            <div className="ml-2">
              <label
                htmlFor="subscribe-to-newsletter"
                className="text-sm font-medium text-primary-1 font-objektiv"
              >
                {translations.accept_terms[locale]} *
              </label>
            </div>
          </div>
          {/* <div
            onClick={() => {
              if (acceptedTerms) {
                input.acceptTerms = false;
                setAcceptedTerms(false);
              } else {
                input.acceptTerms = true;
                setAcceptedTerms(true);
              }
            }}
            className="cursor-pointer h-20"
          >
            {acceptedTerms && (
              <div className="flex font-bold space-x-3">
                <BoldCheckbox />
                <p className="flex flex-row">
                  <span className="text-orange-vipps mr-2">*</span>
                  {translations.accept_terms[locale]}
                </p>
              </div>
            )}
            {!acceptedTerms && (
              <div>
                <div className="flex font-normal mb-2 relative items-center">
                  <span className="text-orange-vipps text-lg absolute -left-4">
                    *
                  </span>
                  <div className="border-2 border-gray-300 rounded w-4 h-4 bg-white" />
                  <p className="flex flex-row font-objektiv text-primary-1 pl-3">
                    {translations.accept_terms[locale]}
                  </p>
                </div>
                {!acceptedTerms && (
                  <Error
                    errors={{
                      acceptTerms: translations.accept_terms_error[locale],
                    }}
                    fieldName={"acceptTerms"}
                  />
                )}
              </div>
            )}
          </div> */}
          <div>
            <Link
              href={`https://lilbit.no/${locale === "no" ? "no" : "en"}/${
                locale === "no" ? "betingelser" : "conditions"
              }`}
              passHref
            >
              <a target="_blank flex items-center">
                {/* <p className="font-objektiv text-[16px] leading-[20px] text-primary-2">
                {translations.read_terms[locale]}
              </p> */}
                <ExtLinkLogo siteName={siteName} />
              </a>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AcceptTerms;

const translations = {
  accept_terms: {
    en: "I accept the terms and conditions",
    no: "Jeg aksepterer lilbit sine vilkår og betingelser",
    se: "Jag godkänner villkoren",
    da: "Jeg accepterer vilkår og betingelser",
    fi: "Hyväksyn ehdot",
    de: "Ich akzeptiere die Bedingungen",
    fr: "J'accepte les conditions",
    es: "Acepto los términos",
  },
  accept_terms_error: {
    no: "Vilkår og betingelser må leses og godtas for å legge til bestilling. Merk av boksen hvis du er enig.",
    en: "Terms and conditions needs to be read and accepted to place an order. Please tick the box if you agree.",
    se: "Villkoren måste läsas och godkännas för att lägga till beställning. Markera rutan om du håller med.",
    da: "Terms og betingelser skal læses og accepteres for at placere en ordre. Venligst markér feltet, hvis du accepterer.",
    fi: "Ehtoja ja ehdotuksia on luettava ja hyväksyttävä tilauksen tekemiseksi. Rastita ruutu, jos hyväksyt ne.",
    de: "Die Bedingungen müssen gelesen und akzeptiert werden, um eine Bestellung aufzugeben. Bitte kreuzen Sie das Kästchen an, wenn Sie einverstanden sind.",
    fr: "Les conditions doivent être lues et acceptées pour passer une commande. Veuillez cocher la case si vous êtes d'accord.",
    es: "Los términos deben leerse y aceptarse para realizar un pedido. Marque la casilla si está de acuerdo.",
  },
  read_terms: {
    en: "Read our terms",
    no: "les vilkår og betingelser",
    se: "Läs våra villkor",
    da: "Læs vores vilkår",
    fi: "Lue ehtomme",
    de: "Lesen Sie unsere Bedingungen",
    fr: "Lisez nos conditions",
    es: "Lea nuestros términos",
  },
};
