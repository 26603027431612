import Tag from "../../components/Tag";
import Image from "next/image";

type Props = {
  tags: [];
  buyButtonText: string;
  heading: string;
  SimpleBlockContent: any;
  urlFor: Function;
};

const TagSection = (props: Props) => {
  const { heading, tags = [], SimpleBlockContent, urlFor } = props;
  return (
    <div className="flex flex-col bg-white pt-16 items-center justify-center font-objektiv text-eggplant w-full lg:flex-row lg:items-center lg:bg-iris-pale overflow-hidden">
      <div className="relative h-full hidden lg:block">
        <div className="absolute top-4 left-0 -ml-14 w-full h-full lg:w-px-193 lg:h-px-197">
          <Image
            src="/backgrounds/Circle-pink.svg"
            layout="fill"
            objectFit="fill"
            alt=""
          />
        </div>
        <div className="absolute bottom-0 left-0 w-full h-full lg:w-px-250 lg:h-px-400 lg:-mr-20">
          <Image
            src="/backgrounds/Paw-brown.svg"
            layout="fill"
            objectFit="fill"
            alt=""
          />
        </div>
      </div>
      <div className="relative pl-6 lg:w-2/3 lg:pl-8 lg:h-full">
        <div className="lg:flex lg:h-full lg:w-full lg:items-start lg:justify-start lg:pt-12">
          <h3 className="font-extrabold pb-6">{heading}</h3>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-full rounded-bl-3xl pb-10 pt-6 px-4 max-w-3xl lg:max-w-none lg:grid lg:grid-cols-2 lg:gap-4">
        {tags &&
          tags.map((tag: any) => {
            return (
              <div className="lg:flex lg:flex-col" key={tag._key}>
                <Tag
                  key={tag._key}
                  tag={tag}
                  flexdir="flex-col"
                  desktop={true}
                  urlFor={urlFor}
                />
                <div className="text-p-md leading-p-md font-normal pb-10 text-secondary-9">
                  <SimpleBlockContent blocks={tag.bodies[0]} />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TagSection;
