import Link from "next/link";
import { Footerv2Props } from "../lib";
import Image from "next/image";
import { useLocale } from "../translations";
import { hideNewsAndDogbreedsForTheseLocales } from "./NavBar";

const Footer = (props: Footerv2Props) => {
  const {
    logo,
    sections,
    urlFor,
    cardlogos,
    contactInfo,
    socials,
    socialsHeader,
    SimpleBlockContent,
  } = props;
  const urlPrefix = useLocale().urlPrefix;

  return (
    <div className="bg-primary-4 font-objektiv">
      <div className="w-full max-w-7xl mx-auto py-12 px-4">
        <div className="pb-4">
          <div className="relative h-12 w-24">
            <Image
              src={urlFor(logo).quality(40).url()}
              layout="fill"
              objectFit="contain"
              alt="alt"
            />
          </div>
          <div className="relative h-8 w-36">
            <Image
              src={urlFor(cardlogos).quality(40).url()}
              layout="fill"
              objectFit="contain"
              alt="alt"
            />
          </div>
        </div>
        <div className="grid gap-6 grid-cols-2 lg:grid-cols-5">
          {sections.map((section) => {
            return (
              <div key={section._key} className="flex flex-col space-y-2">
                <h6 className="font-bold text-primary-2 text-[18px] text-lg lg:text-[22px] pb-2">
                  {section.header}
                </h6>
                {section.links.map((l) => {
                  //Hide dogbreeds and news links for these locales
                  if (
                    l._type === "link" &&
                    hideNewsAndDogbreedsForTheseLocales.includes(urlPrefix) &&
                    (l.url.groupid === "news" || l.url.groupid === "dogbreeds")
                  )
                    return null;
                  return (
                    <div key={l._type === "link" ? l.url._id : l._key}>
                      {l._type === "link" ? (
                        <Link
                          href={`/${urlPrefix}/${
                            l.url.groupRoutes.find(
                              (g) => g.language === urlPrefix
                            )?.slug.current
                          }`}
                        >
                          <a className="text-primary-2 lg:text-lg hover:underline">
                            {l.title}
                          </a>
                        </Link>
                      ) : (
                        <Link href={l.externalUrl.link}>
                          <a className="text-primary-2 lg:text-lg hover:underline">
                            {l.title}
                          </a>
                        </Link>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
          <div className="flex flex-col space-y-4 text-primary-2">
            <h6 className="font-bold text-primary-2 text-lg lg:text-[22px] pb-2">
              {contactInfo.header}
            </h6>
            <Link
              href={"/[lang]/support"}
              as={`/${urlPrefix}/support`}
              className="hover:cursor-pointer"
            >
              <p className="lg:text-lg hover:underline cursor-pointer">
                {translations.contactschema[urlPrefix]
                  ? translations.contactschema[urlPrefix]
                  : translations.contactschema.en}
              </p>
            </Link>
            {contactInfo.email && (
              <p className="lg:text-lg">{contactInfo.email}</p>
            )}
            <h6 className="text-primary-2 text-base font-semibold pt-4">
              {contactInfo.subheader}
            </h6>
            <p className="lg:text-lg">{contactInfo.subcontent}</p>
            <h6 className="text-primary-2 text-base font-semibold pt-4">
              {contactInfo.addressHeader}
            </h6>
            <div className="text-base">
              <SimpleBlockContent blocks={contactInfo?.address} />
            </div>
          </div>
          <div>
            <h6 className="font-bold text-primary-2 text-lg lg:text-[22px] pb-4">
              {socialsHeader}
            </h6>
            <div className="flex space-x-4">
              {socials.map((s, i) => {
                return (
                  <Link href={s.url} key={s.url + i}>
                    <a className="relative">
                      <Image
                        src={urlFor(s.icon).quality(30).url()}
                        width={30}
                        height={30}
                        objectFit="cover"
                        style={{ borderRadius: "100%" }}
                        alt={"alt"}
                      />
                    </a>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const translations = {
  contactschema: {
    no: "Kontakt oss",
    en: "Contact us",
    se: "Kontakta oss",
    da: "Kontakt os",
    dk: "Kontakt os",
    fi: "Ota yhteyttä",
    fr: "Contactez-nous",
    es: "Contáctenos",
  },
};
export default Footer;
