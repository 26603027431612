import { Image as ImageType } from "../../lib/types";
import Image from "next/image";

interface Props {
  mainText: string;
  icons: Array<{
    image: ImageType;
    _key: string;
    _type: string;
  }>;
  urlFor: Function;
  SimpleBlockContent: any;
}
const LocalizedPartnersIcons = (props: Props) => {
  const { mainText, icons, urlFor, SimpleBlockContent } = props;
  return (
    <div className="relative w-full bg-primary-mono-3 flex flex-col items-center justify-center py-6">
      {mainText && (
        <h4 className="pb-4 font-p22 text-white font-medium text-center text-base sm:text-xl lg:text-2xl">
          {mainText}
        </h4>
      )}
      <div className="flex flex-col h-full w-full justify-evenly">
        <div className="flex flex-wrap justify-evenly mx-4 sm:mx-16">
          {icons?.map((item) => {
            const { image, _key } = item;
            return (
              <div key={_key} className="relative">
                <div className="flex md:hidden justify-center">
                  <Image
                    src={urlFor(image, true).url()}
                    height={30}
                    width={30}
                    alt={image.alt || ""}
                  />
                </div>
                <div className="hidden md:flex md:justify-center">
                  <Image
                    src={urlFor(image, true).url()}
                    height={60}
                    width={60}
                    alt={image.alt || ""}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LocalizedPartnersIcons;
