import Image from "next/image";
import { Image as ImageType } from "@lilbit/shared";

type Props = {
  image: ImageType;
  bodies: any;
  SimpleBlockContent: any;
  heading: string;
  bgColor: string;
  minorHeading: string;
  urlFor: Function;
};

const GpsTrackingSection = (props: Props) => {
  const {
    image,
    bodies = [],
    SimpleBlockContent,
    heading,
    bgColor,
    minorHeading,
    urlFor,
  } = props;
  return (
    <div
      style={bgColor && { backgroundColor: bgColor }}
      className="flex flex-col lg:w-full items-center justify-center font-objektiv text-eggplant"
    >
      <div className="flex flex-col items-center justify-center w-full rounded-bl-3xl pb-10 pt-12">
        {minorHeading && <h6 className="font-bold">{minorHeading}</h6>}
        <h1 className="font-extrabold text-center px-4 pb-6 text-iris">
          {heading}
        </h1>
        <div className="flex flex-col items-center justify-center w-full px-6 text-p-md leading-p-md text-secondary-9 text-center lg:w-2/3">
          <SimpleBlockContent blocks={bodies[0]} />
        </div>
        <Image
          src={urlFor(image).size(400, 300).url()}
          alt={image.alt}
          width={400}
          height={300}
        />
      </div>
    </div>
  );
};

export default GpsTrackingSection;
