import {
  trimDate,
  translations,
  useLocale,
  LocalizedPostProps,
} from "@lilbit/shared";
import Image from "next/image";
import SocialLinks from "./SocialLinks";
import { getPositionFromHotspot } from "../lib/helpers";

type Props = {
  localizedPosts: LocalizedPostProps;
  SimpleBlockContent: any;
  urlFor: Function;
};

const MobilePost = ({ localizedPosts, urlFor, SimpleBlockContent }: Props) => {
  const {
    title,
    mainImage,
    body = [],
    authorImage,
    authorBio,
    name,
    _updatedAt,
    summary,
    category,
  } = localizedPosts;
  const isDogBreedPost = category === "dogbreeds" || category === "hunderaser";
  const locale = useLocale().urlPrefix;
  const { Blog } = translations;
  let date: string;
  if (_updatedAt) {
    date = trimDate(_updatedAt);
  }
  return (
    <div className="flex flex-col items-center justify-center py-4 px-4 lg:px-24 font-objektiv">
      <div className="flex flex-wrap justify-center items-center w-full">
        <div className="relative h-48 sm:h-80 md:h-96 lg:h-px-500 w-full shadow-2xl rounded-br-3xl rounded-t-3xl">
          <Image
            src={urlFor(mainImage, false)
              .width(1200)
              .height(500)
              .quality(45)
              .url()}
            alt={mainImage?.alt || ""}
            layout="fill"
            objectFit="cover"
            className="rounded-br-3xl rounded-t-3xl"
            objectPosition={
              mainImage?.hotspot && getPositionFromHotspot(mainImage.hotspot)
            }
            quality={100}
          />
        </div>
        <div className="flex justify-start w-full py-4">
          <div className="flex flex-col text-secondary-9 w-1/2">
            <p className="font-bold text-p-sm-desktop">
              {Blog.shareKnowledge.title[locale]}
            </p>
            <p className="font-normal text-p-sm-desktop">
              {Blog.shareKnowledge.subtitle[locale]}
            </p>
          </div>
          <div className="w-1/2">
            <SocialLinks />
          </div>
        </div>
        <div className="flex flex-col justify-start items-start w-full">
          <h2 className="text-primary-mono-1 font-extrabold">{title}</h2>
          {summary && (
            <div className="text-p-lg font-bold text-primary-mono-1">
              <SimpleBlockContent blocks={summary[0]} />
            </div>
          )}
        </div>
      </div>
      <div
        className={`flex items-center w-full ${
          !isDogBreedPost && "border-t-2 border-b-2"
        } border-paleSky my-7`}
      >
        <div className="flex py-2 w-full">
          <div className="flex flex-col justify-center w-full text-secondary-9 font-objektiv text-p-sm pl-4">
            <p>
              {Blog.updated[locale]} {date}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full justify-center max-w-prose">
        <SimpleBlockContent blocks={body} />
      </div>
    </div>
  );
};
export default MobilePost;
