import { useContext, useEffect } from "react";
import { CartContext } from "../context";
type Props = {
  borderColor?: string;
  textColor?: string;
};
const Lildogswitcher = (props: Props) => {
  const {
    borderColor = "border-primary-mono-2",
    textColor = "text-primary-mono-2",
  } = props;
  const { nrOfMainProducts, setNrOfMainProducts } = useContext(CartContext);

  //Functions
  const increaseNrOfMainProducts = () => {
    setNrOfMainProducts(nrOfMainProducts + 1);
  };
  const decreaseNrOfMainProducts = () => {
    nrOfMainProducts > 1 && setNrOfMainProducts(nrOfMainProducts - 1);
  };
  useEffect(() => {
    if (nrOfMainProducts === 0) setNrOfMainProducts(1);
  }, [nrOfMainProducts, setNrOfMainProducts]);

  return (
    <div
      className={`flex ${borderColor} ${textColor} rounded-xl overflow-hidden border-t-2 border-b-2 border-r-1 border-l-1 bg-white font-bold max-w-[110px]`}
    >
      <button
        className="flex items-center justify-center bg-primary-mono-2 overflow-hidden hover:bg-primary-3 w-full focus:outline-none py-1 px-3 cursor-pointer"
        onClick={decreaseNrOfMainProducts}
      >
        <p className="text-2xl text-white">-</p>
      </button>
      <div
        className={`flex items-center justify-center w-full  py-1 px-3 border-${borderColor}`}
      >
        {nrOfMainProducts}
      </div>
      <button
        className="flex items-center focus:outline-none bg-primary-mono-2 hover:bg-primary-3 overflow-hidden justify-center w-full py-1 px-3 cursor-pointer"
        onClick={increaseNrOfMainProducts}
      >
        <p className="text-2xl text-white">+</p>
      </button>
    </div>
  );
};

export default Lildogswitcher;
