import Image from "next/image";
import { Image as ImageType } from "../../lib/types";

interface Props {
  cards: Array<{
    body: string;
    icon: ImageType;
    title: string;
    _key: string;
  }>;
  sr_text: string;
  urlFor: Function;
}

const Sectiontwentyfour = ({ cards, sr_text, urlFor }: Props) => {
  return (
    <div className="py-12 w-full bg-primary-1">
      <div className="mx-auto px-4 sm:px-6 lg:px-8 font-objektiv">
        <h2 className="sr-only">{sr_text || ""}</h2>
        <dl className="space-y-10 lg:space-y-0 lg:grid justify-items-start lg:grid-cols-4 lg:gap-10 px-16">
          {cards?.map((card) => (
            <div
              key={card._key}
              className=" flex flex-col justify-center items-center"
            >
              <dt className="flex flex-col items-center justify-center">
                {card?.icon && (
                  <div className="flex items-start relative w-[30px] h-[35px] bg-primary-1 py-4">
                    <Image
                      src={urlFor(card.icon).url()}
                      layout="fill"
                      className=""
                      objectFit="contain"
                      alt={card.icon.alt || ""}
                    />
                  </div>
                )}
                <p className="mt-5 text-center text-[20px] leading-[22px] text-white font-bold">
                  {card.title || ""}
                </p>
              </dt>
              <dd className="mt-2 grow text-center text-[16px] leading-[24px] text-base text-white">
                {card.body || ""}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default Sectiontwentyfour;
