import { Image as ImageType } from "../../lib/types";
import Image from "next/image";
import { useLocale } from "../../translations";
import { useSitename } from "../../context/SitenameContext";

interface Props {
  mainText: string;
  icons: Array<{
    text: Array<any>;
    image: ImageType & {
      width?: number;
      height?: number;
      mobileWidth?: number;
      mobileHeight?: number;
    };
    _key: string;
    _type: string;
  }>;
  urlFor: Function;
  SimpleBlockContent: any;
}
const LocalizedHeaderAndIconsNoBackground = (props: Props) => {
  const { mainText, icons, urlFor, SimpleBlockContent } = props;
  const locale = useLocale().urlPrefix;
  const { siteName } = useSitename();
  return (
    <div className="relative w-full bg-white flex flex-col items-center justify-center py-4 sm:py-6 md:py-8">
      {mainText && (
        <SimpleBlockContent
          blocks={mainText}
          className="py-2 font-p22 font-bold text-center text-xl lg:text-2xl"
        />
      )}
      <div className="flex flex-col h-full w-full justify-evenly">
        <div className="flex flex-wrap justify-evenly items-center mx-4 sm:mx-16 my-8">
          {icons?.map((item) => {
            const { text, image, _key } = item;
            const { width, height, mobileWidth, mobileHeight } = image;
            return (
              <div key={_key} className="relative">
                <div className="flex md:hidden justify-center">
                  <Image
                    src={urlFor(image, true).url()}
                    height={mobileHeight || 60}
                    width={mobileWidth || 60}
                    alt={image.alt || ""}
                  />
                </div>
                <div className="hidden md:flex md:justify-center">
                  <Image
                    src={urlFor(image, true).url()}
                    height={height || 75}
                    width={width || 75}
                    alt={image.alt || ""}
                  />
                </div>
                <div>
                  <SimpleBlockContent
                    blocks={text}
                    className="pb-2 max-w-[100px] sm:max-w-[140px] px-4 text-xs sm:text-sm md:text-base font-medium text-center font-objektiv"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LocalizedHeaderAndIconsNoBackground;
