const PlainEmail = () => {
  return (
    <svg
      width="24"
      height="19"
      viewBox="0 0 24 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.74808 18.5H20.2584C22.2341 18.5 23.3804 17.3525 23.3804 15.1603V3.83975C23.3804 1.6475 22.2238 0.5 20.0128 0.5H3.5025C1.52672 0.5 0.380432 1.637 0.380432 3.83975V15.1603C0.380432 17.3525 1.53711 18.5 3.74808 18.5ZM10.7289 9.85325L2.53501 1.727C2.67821 1.69625 3.58411 1.676 3.75846 1.676H20.0024C20.1871 1.676 21.0923 1.69625 21.2459 1.7375L13.0631 9.85325C12.6328 10.2732 12.2744 10.4578 11.896 10.4578C11.5169 10.4578 11.1585 10.2635 10.7289 9.85325ZM1.55269 3.233L7.86285 9.464L1.55269 15.7257V3.233ZM15.9284 9.464L22.2082 3.26375V15.7055L15.9284 9.464ZM3.75846 17.3135C3.57372 17.3135 2.65817 17.2933 2.50459 17.2625L9.11153 10.6932L9.66427 11.2467C10.4114 11.9743 11.1281 12.2817 11.896 12.2817C12.6535 12.2817 13.3799 11.9743 14.1174 11.2467L14.6798 10.6932L21.277 17.252C21.1131 17.2933 20.1968 17.3135 20.0024 17.3135H3.75846Z"
        fill="#21401A"
      />
    </svg>
  );
};

export default PlainEmail;
