import AutoReviews from "../stamped/autoReviews/autoReviews";
import Reviews from "../stamped/reviews";

interface Props {
  reviews: any;
}

const LocalizedSectionReiew = (props: Props) => {
  const { reviews } = props;
  if (!reviews) return <></>;
  return (
    <div className="lg:max-h-full lg:overflow-hidden ">
      <div className="hidden md:flex">
        <AutoReviews reviews={reviews.data} />
      </div>
      <div className="flex md:hidden">
        <Reviews reviews={reviews} />
      </div>
    </div>
  );
};

export default LocalizedSectionReiew;
