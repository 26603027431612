import validator from "validator";
import isEmpty from "./validate-isEmpty";
import Translation from "../../translations/translations";
import axios from "axios";

const validateCoupon = async (coupon) => {
  //if (coupon === 's') return true;
  const { data: validated } = await axios.post("/api/payment/validate_coupon", {
    coupon: coupon,
  });
  return validated;
};

const validateAndSanitizeCheckoutForm = (data, language, vipps) => {
  let errors = {};
  let sanitizedData = {
    createAccount: Boolean,
    acceptTerms: Boolean,
    subscribeNews: Boolean,
  };

  /**
   * Set the firstName value equal to an empty string if user has not entered the firstName, otherwise the Validator.isEmpty() wont work down below.
   * Note that the isEmpty() here is our custom function defined in is-empty.js and
   * Validator.isEmpty() down below comes from validator library.
   * Similarly we do it for for the rest of the fields
   */
  data.firstName = !isEmpty(data.firstName) ? data.firstName : "";
  data.coupon = !isEmpty(data.coupon) ? data.coupon : "";
  data.lastName = !isEmpty(data.lastName) ? data.lastName : "";
  // data.company = !isEmpty(data.company) ? data.company : '';
  data.country = !isEmpty(data.country) ? data.country : "";
  data.address1 = !isEmpty(data.address1) ? data.address1 : "";
  data.address2 = !isEmpty(data.address2) ? data.address2 : "";
  data.city = !isEmpty(data.city) ? data.city : "";
  // data.state = !isEmpty(data.state) ? data.state : '';
  data.postcode = !isEmpty(data.postcode) ? data.postcode : "";
  data.phone = !isEmpty(data.phone) ? data.phone.replace(/\s/g, "") : "";
  data.email = !isEmpty(data.email) ? data.email : "";
  data.createAccount = !isEmpty(data.createAccount) ? data.createAccount : "";
  data.orderNotes = !isEmpty(data.orderNotes) ? data.orderNotes : "";
  data.paymentMethod = !isEmpty(data.paymentMethod) ? data.paymentMethod : "";

  /**
   * Checks for error if required is true
   * and adds Error and Sanitized data to the errors and sanitizedData object
   *
   * @param {String} fieldName Field name e.g. First name, last name
   * @param {String} errorContent Error Content to be used in showing error e.g. First Name, Last Name
   * @param {Integer} min Minimum characters required
   * @param {Integer} max Maximum characters required
   * @param {String} type Type e.g. email, phone etc.
   * @param {boolean} required Required if required is passed as false, it will not validate error and just do sanitization.
   */
  const addErrorAndSanitizedData = async (
    fieldName,
    errorContent,
    min,
    max,
    type = "",
    required
  ) => {
    /**
     * Please note that this isEmpty() belongs to validator and not our custom function defined above.
     *
     * Check for error and if there is no error then sanitize data.
     */
    if (required && !validator.isLength(data[fieldName], { min, max })) {
      errors[
        fieldName
      ] = `${errorContent} ${Translation.checkout_errors.must_be[language]} ${min} ${Translation.checkout_errors.to[language]} ${max} ${Translation.checkout_errors.characters[language]}`;
    }

    if (required && "email" === type && !validator.isEmail(data[fieldName])) {
      errors[
        fieldName
      ] = `${errorContent} ${Translation.checkout_errors.is_not_valid[language]}`;
    }

    if (
      required &&
      "phone" === type &&
      !validator.isMobilePhone(data[fieldName])
    ) {
      errors[
        fieldName
      ] = `${errorContent} ${Translation.checkout_errors.is_not_valid[language]}`;
    }

    if (required && validator.isEmpty(data[fieldName])) {
      errors[
        fieldName
      ] = `${Translation.checkout_errors.is_requiered[language]} ${errorContent} `;
    }

    if ("coupon" === type && data[fieldName]?.length > 0) {
      let valid = await validateCoupon(data[fieldName]);
      if (valid === -3) {
        errors[
          fieldName
        ] = `${Translation.checkout_errors.could_not_check_coupon}`;
      } else if (valid === 401) {
        errors[
          fieldName
        ] = `${Translation.checkout_errors.coupon_is_used[language]}`;
      } else if (valid === 400) {
        errors[
          fieldName
        ] = `${Translation.checkout_errors.coupon_does_not_exist[language]}`;
      }
    }
    // If no errors
    if (!errors[fieldName]) {
      sanitizedData[fieldName] = validator.trim(data[fieldName]);
      sanitizedData[fieldName] =
        "email" === type
          ? validator.normalizeEmail(sanitizedData[fieldName])
          : sanitizedData[fieldName];
      sanitizedData[fieldName] = validator.escape(sanitizedData[fieldName]);
    }
  };

  addErrorAndSanitizedData(
    "firstName",
    Translation.checkout_errors.first_name[language],
    2,
    35,
    "string",
    !vipps
  );
  addErrorAndSanitizedData(
    "lastName",
    Translation.checkout_errors.last_name[language],
    2,
    35,
    "string",
    !vipps
  );
  // addErrorAndSanitizedData('company', 'Company Name', 0, 35, 'string', false);
  addErrorAndSanitizedData(
    "country",
    Translation.checkout_errors.country[language],
    2,
    55,
    "string",
    !vipps
  );
  addErrorAndSanitizedData(
    "address1",
    Translation.checkout_errors.address1[language],
    4,
    50,
    "string",
    !vipps
  );
  addErrorAndSanitizedData("address2", "", 0, 254, "string", false);
  addErrorAndSanitizedData(
    "city",
    Translation.checkout_errors.city[language],
    2,
    25,
    "string",
    !vipps
  );
  // addErrorAndSanitizedData('state', 'State/County', 0, 254, 'string', true);
  addErrorAndSanitizedData(
    "postcode",
    Translation.checkout_errors.post_code[language],
    2,
    9,
    "postcode",
    !vipps
  );
  addErrorAndSanitizedData(
    "phone",
    Translation.checkout_errors.phone_number[language],
    8,
    14,
    "phone",
    true
  );
  addErrorAndSanitizedData(
    "email",
    Translation.order_summary.email[language],
    6,
    254,
    "email",
    !vipps
  );
  addErrorAndSanitizedData(
    "coupon",
    Translation.checkout_errors.coupon[language],
    0,
    8,
    "coupon",
    false
  );

  // The data.createAccount is a boolean value.
  sanitizedData.createAccount = data.createAccount;

  sanitizedData.acceptTerms = data.acceptTerms;
  sanitizedData.subscribeNews = data.subscribeNews;

  addErrorAndSanitizedData("orderNotes", "", 0, 254, "string", false);
  addErrorAndSanitizedData(
    "paymentMethod",
    "Payment mode field",
    2,
    50,
    "string",
    !vipps
  );

  // if (data.acceptTerms === false) {
  //   errors[
  //     'acceptTerms'
  //   ] = `${translations.checkout_errors.accept_terms[language]}`;
  // }

  return {
    sanitizedData,
    errors,
    isValid: isEmpty(errors),
  };
};

export default validateAndSanitizeCheckoutForm;
