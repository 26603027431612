import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";

interface Props {
  header: string;
  image: any;
  body: any;
}

const LocalizedFamilySub = (props: Props) => {
  const features = [
    {
      name: "1. Kjøp Familieabonnement",
      description:
        "Fra Appen kan du enkelt kjøpe familieabonnement fra 'Aktiver Lildog' i instillingene.",
      icon: CloudArrowUpIcon,
    },
    {
      name: "2. Legg til ekstra enheter",
      description:
        "Fra Dashboardet inne i appen kan du enkelt legge til flere enheter, dele abonnementet med andre familiemedlemmer og administrere familien din.",
      icon: LockClosedIcon,
    },
    {
      name: "3. Kos dere sammen!",
      description: "Bruk Lildog hele familien",
      icon: ArrowPathIcon,
    },
  ];
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-primary-1 font-default">
            Del ditt abonnement
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-primary-1 sm:text-4xl font-p22">
            Alle familiens hunder og katter, i ett abonnement
          </p>
          <p className="mt-6 text-lg leading-8 text-primary-1 font-default">
            Med familieabonnement fra Lilbit kan du samle alle familiens hunder
            under et abonnement. Et abonnement kan deles av opptil 4 enheter. Du
            velger selv om du vil ha ekstra enheter til deg selv
          </p>
        </div>

        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none font-default">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-primary-mono-1">
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 primary-mono-2">
                  <p className="flex-auto">{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default LocalizedFamilySub;
