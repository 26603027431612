import {
  CountryContext,
  currencySymbols,
  GlobalConfig,
  Image as ImageType,
  Product,
  useLocale,
} from "@lilbit/shared";
import { FaCheckCircle } from "react-icons/fa";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useContext } from "react";
import { subscriptionPrices } from "../../lib/subscriptionPrices";

type Props = {
  cards: {
    text: string;
    _key: string;
    title: string;
    features: string[];
    mostPopular: boolean;
    missingFeatures: string[];
    tier: "basic" | "premium" | "family";
  }[];
  image: ImageType;
  header: string;
  subHeader: string;
  subText: string;
  buyButtonText: string;
  config: GlobalConfig;
  mainProduct: Product;
  urlFor: Function;
};
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const LocalizedSubscriptionInfo = (props: Props) => {
  const { header, cards, subText, subHeader } = props;
  const { country } = useContext(CountryContext);
  const { currency } = country;
  const urlPrefix = useLocale().urlPrefix;

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-primary-mono-2">
            {subHeader}
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            {header}
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          {subText}
        </p>
        <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {cards.map((tier, tierIdx) => (
            <div
              key={tier._key}
              className={classNames(
                tier.mostPopular ? "lg:z-10 lg:rounded-b-none" : "lg:mt-8",
                tierIdx === 0 ? "lg:rounded-r-none" : "",
                tierIdx === cards.length - 1 ? "lg:rounded-l-none" : "",
                "flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10"
              )}
            >
              <div>
                <div className="flex items-center justify-between gap-x-4">
                  <h3
                    className={classNames(
                      tier.mostPopular
                        ? "text-primary-mono-2"
                        : "text-gray-900",
                      "text-lg font-semibold leading-8"
                    )}
                  >
                    {tier.title}
                  </h3>
                  {tier.mostPopular ? (
                    <p className="rounded-full bg-primary-mono-2/10 px-2.5 py-1 text-xs font-semibold leading-5 text-primary-mono-2">
                      {translations.mostPopular[urlPrefix]}
                    </p>
                  ) : null}
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-600">
                  {tier.text}
                </p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    {currency !== "NOK" && currencySymbols[currency]}
                    {subscriptionPrices[tier.tier]["monthly"][currency]}
                    {currency === "NOK" && currencySymbols[currency]}
                  </span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">
                    / {translations.monthly[urlPrefix]}
                  </span>
                </p>
                <p className="text-[11px] text-gray-600">
                  {tier.tier !== "basic" && (
                    <>
                      {translations.billedYearlyWith[urlPrefix]}{" "}
                      {currency !== "NOK" && currencySymbols[currency]}
                      {subscriptionPrices[tier.tier]["yearly"][currency]}
                      {currency === "NOK" && currencySymbols[currency]}
                    </>
                  )}
                </p>
                <ul
                  role="list"
                  className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
                >
                  {tier.features?.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <FaCheckCircle
                        aria-hidden="true"
                        className="h-6 w-5 flex-none text-primary-mono-2"
                      />
                      {feature}
                    </li>
                  ))}
                  {tier.missingFeatures?.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <XMarkIcon
                        aria-hidden="true"
                        className="h-6 w-5 flex-none text-gray-400"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LocalizedSubscriptionInfo;

const translations = {
  monthly: {
    no: "mnd",
    en: "monthly",
    se: "månadsvis",
    dk: "månedlig",
    fi: "kuukausittain",
    de: "monatlich",
    fr: "mensuel",
    es: "mensual",
    nl: "maandelijks",
  },
  mostPopular: {
    no: "Mest populær",
    en: "Most popular",
    se: "Mest populär",
    dk: "Mest populære",
    fi: "Suosituin",
    de: "Am beliebtesten",
    fr: "Le plus populaire",
    es: "Más popular",
    nl: "Meest populair",
  },
  billedYearlyWith: {
    no: "Faktureres årlig med",
    en: "Billed yearly with",
    se: "Faktureras årligen med",
    dk: "Faktureres årligt med",
    fi: "Laskutetaan vuosittain",
    de: "Jährlich abgerechnet mit",
    fr: "Facturé annuellement avec",
    es: "Facturado anualmente con",
    nl: "Jaarlijks gefactureerd met",
  },
};
