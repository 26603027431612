import React, { useRef, useEffect, useState } from "react";
import ReviewCard from "../reviewCard";

import 'swiper/css'
import './custom-swiper.css';

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from 'swiper/modules';


const AutoReviews = ({ reviews }) => {
    const [displayedReviewsCount, setDisplayedReviewsCount] = useState(5);

    useEffect(() => {
        const interval = setInterval(() => {
            if (displayedReviewsCount < reviews?.length) {
                setDisplayedReviewsCount(displayedReviewsCount + 1);
            }
        }, 1500)
        return () => clearInterval(interval);
    }, [displayedReviewsCount, reviews])

    const swiperRef = useRef(null);
    return (
        <section className="w-full h-[440px] -translate-y-8">
            <Swiper
                style={{ minHeight: '500px', maxHeight: '500px'}}
                centeredSlides={true}
                breakpoints={
                    {
                        // Extra_Small Screen
                        0: {
                            slidesPerView: 1.4,
                            spaceBetween: 10
                        },
                

                        380: {
                            slidesPerView: 1.8,
                            spaceBetween: 10
                        },
                        420: {
                            slidesPerView: 2,
                            spaceBetween: 10
                        },
                        
                        520: {
                            slidesPerView: 2.5,
                            spaceBetween: 10
                        },

                        640: {
                            slidesPerView: 3,
                            spaceBetween: 10
                        },
                        //medium Screen
                        800: {
                            slidesPerView: 3.5,
                            spaceBetween: 10
                        },
                        //Large Screen
                        1060: {
                            slidesPerView: 4,
                            spaceBetween: 10
                        },
                        1120: {
                            slidesPerView: 4.5,
                            spaceBetween: 10
                        },
                        //Large Screen
                        1000: {
                            slidesPerView: 4,
                            spaceBetween: 10
                        },
                        1120: {
                            slidesPerView: 4.5,
                            spaceBetween: 10
                        },
                        //Extra Large Screen
                        1280: {
                            slidesPerView: 5,
                            spaceBetween: 20
                        },
                        //Extra Extra Large Screen
                        1400: {
                            slidesPerView: 5.6,
                            spaceBetween: 20
                        },
                        //Extra Extra Extra Large Screen
                        1580: {
                            slidesPerView: 5,
                            spaceBetween: 10
                        },
                        //Extra Extra Large Screen
                        1400: {
                            slidesPerView: 6,
                            spaceBetween: 10
                        },
                        //Extra Extra Extra Large Screen
                        1580: {
                            slidesPerView: 7,
                            spaceBetween: 10
                        },
                        2000: { 
                            slidesPerView: 8,
                            spaceBetween: 10
                        }

                    }
                }
                autoplay={{ delay: 0, disableOnInteraction: false, pauseOnMouseEnter: false }}

                loop={true} 
                speed={4000} 
                modules={[Autoplay, FreeMode]}
                freeMode={true}
                onSwiper={(swiper) => (swiperRef.current = swiper)}
                pagination={{ clickable: true }}
                className={"mySwiper"}>

            


                {
                    reviews?.map((rev, i) => {
                        return (
                            <SwiperSlide key={i} >
                                <ReviewCard review={rev} />
                            </SwiperSlide>
                        );
                    })
                    
                }
            </Swiper>
        </section>
    );
};

export default AutoReviews;