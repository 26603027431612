import React from "react";
import { useLocale } from "../../translations";
import CountdownTimer from "../CountdownTimer";
import SnowFall from "../snowFall";

import { Image as ImageType } from "../../lib";

import { InformationCircleIcon } from "@heroicons/react/24/outline";
interface Props {
  endDateTime: Date;
  mainProductPrices: {
    PRICE: any;
    ORIGINAL_PRICE: any;
  };
  productTaxRate: string;
  locale: {
    defaultCurrency: string;
  };
  header: boolean;
  christmasImage: ImageType;
  christmasImageBig: ImageType;
  urlFor: Function;
}

const LocalizedSectionChristmasSale = (props: Props) => {
  const { endDateTime, mainProductPrices, productTaxRate, locale, header } =
    props;
  const urlPrefix = useLocale().urlPrefix;
  const endDate = new Date(endDateTime.toString());

  const calculatePrice = (price: string, taxRate: number) => {
    const priceNumber = parseFloat(price);
    const priceWithTax = priceNumber * (1 + taxRate / 100);
    return priceWithTax.toFixed(0);
  };
  const originalPrice = calculatePrice(
    mainProductPrices?.ORIGINAL_PRICE?.[locale?.defaultCurrency] ?? null,
    parseFloat(productTaxRate)
  );
  const price = calculatePrice(
    mainProductPrices?.PRICE?.[locale?.defaultCurrency] ?? null,
    parseFloat(productTaxRate)
  );

  const discount = parseFloat(originalPrice) - parseFloat(price);

  {
    /* Making snow css objects  */
  }
  const snowflakes = [];

  for (let i = 0; i < 300; i++) {
    const style = {
      position: "absolute",
      top: `${-10 - Math.random() * 20}%`,
      left: `${Math.random() * 100}%`,
      opacity: Math.random(),
      fontSize: `${Math.random() * 0.75 + 0.25}em`,
      animationDuration: `${Math.random() * 5 + 5}s`,
      animationDelay: `${Math.random() * 5}s`,
    };
    snowflakes.push(<SnowFall key={1} style={style} />);
  }
  const ChristmasSaleHeader = (
    <>
      {mainProductPrices?.ORIGINAL_PRICE && (
        <div className=" bg-christmas-prime h-52 w-full overflow-y-visible relative text-white flex flex-col justify-center text-center ">
          <div className="flex lg:hidden "></div>
          <div className="hidden lg:flex "></div>
          {/* Uncomment to get snowflakes */}
          {/* <div className="z-10">{snowflakes}</div> */}
          <p className=" text-4xl md:text-6xl    font-p22 z-10">
            {translations.christmassSale[urlPrefix]
              ? translations.christmassSale[urlPrefix].toUpperCase()
              : translations.christmassSale.en}
          </p>
          <p className=" text-xl md:text-3xl   font-p22 z-10">
            {translations.christmassIsComing[urlPrefix]
              ? translations.christmassIsComing[urlPrefix]
              : translations.christmassIsComing.en}
          </p>
        </div>
      )}
    </>
  );

  if (header) {
    return <>{ChristmasSaleHeader}</>;
  }
  return (
    <>
      {mainProductPrices?.ORIGINAL_PRICE ? (
        <div className="flex flex-col bg-christmas-prime w-full h-fit mt-4 text-white text-center">
          {/* Uncomment to get snowflakes */}
          {/* <div className="relative -translate-y-4">{snowflakes}</div> */}
          <div className="flex flex-row justify-center text-center z-10">
            <p className="mt-4 col-start-1 col-span-3  md:col-start-2 md:col-span-1 text-3xl md:text-4xl lg:text-5xl  font-p22 ">
              {translations.christmassIsComing[urlPrefix]
                ? translations.christmassIsComing[urlPrefix]
                : translations.christmassIsComing.en}
            </p>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 my-8 justify-center">
            <div className="flex col-span-2 md:col-span-3  flex-col justify-center text-center">
              {discount ? (
                <p className=" text-xl md:text-2xl flex justify-center  font-default">
                  {translations.save[urlPrefix]
                    ? translations.save[urlPrefix]
                    : translations.save.en}{" "}
                  {discount} {translations.curr[locale.defaultCurrency]}
                </p>
              ) : (
                <p className="text-2xl ml-4 sm:ml-8 md:ml-12 lg:ml-16 flex justify-center  font-default">
                  <></>
                </p>
              )}
            </div>
            <div className="hidden flex-col items-center justify-center">
              <p className="text-sm">
                {translations.timer[urlPrefix]
                  ? translations.timer[urlPrefix]
                  : translations.timer.en}
              </p>
              <CountdownTimer targetDate={endDate} />
            </div>
            <div className="flex flex-col items-center text-center mr-4 sm:mr-8 md:mr-12 lg:mr-16 justify-center">
              <p className="text-xs md:text:sm px-10 hidden sm:flex">
                <></>
              </p>
              <div className="w-4/5 hidden  bg-white rounded-2xl items-center text-black text-center  justify-center flex-col md:flex-row ">
                <InformationCircleIcon className="h-8 w-8 fill-black hidden md:flex " />
                <p className="text-xs lg:text-sm text-black md:ml-2 my-1 md:my-2">
                  {translations.lagerstatus[urlPrefix]
                    ? translations.lagerstatus[urlPrefix]
                    : translations.lagerstatus.en}
                  :
                </p>
                <p className="text-sm lg:text-lg text-red-800 md:mx-2">
                  {translations.varer[urlPrefix]
                    ? translations.varer[urlPrefix]
                    : translations.varer.en}
                </p>
              </div>
            </div>
          </div>
          <div className="hidden flex-col  text-center items-center justify-center mb-4">
            <CountdownTimer targetDate={endDate} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const translations = {
  save: {
    no: "Kjøp nå og spar",
    en: "Buy now and save",
    se: "Köp nu och spara",
    dk: "Køb nu og spar",
    fi: "Osta nyt ja säästä",
    de: "Jetzt kaufen und sparen",
    fr: "Achetez maintenant et économisez",
    es: "Compre ahora y ahorre",
  },
  timer: {
    no: "Tilbudet utløper om",
    en: "Offer expires in",
    se: "Erbjudandet löper ut om",
    dk: "Tilbuddet udløber om",
    fi: "Tarjous päättyy",
    de: "Angebot endet in",
    fr: "L'offre expire dans",
    es: "La oferta expira en",
  },
  lagerstatus: {
    no: "Lagerstatus",
    en: "Stock status",
    se: "Lagerstatus",
    dk: "Lagerstatus",
    fi: "Varastotilanne",
    de: "Lagerstatus",
    fr: "Statut des stocks",
    es: "Estado de stock",
  },
  varer: {
    no: "Få varer igjen",
    en: "Few items left",
    se: "Få varor kvar",
    dk: "Få varer tilbage",
    fi: "Vain muutama tuote jäljellä",
    de: "Nur noch wenige Artikel übrig",
    fr: "Plus que quelques articles",
    es: "Quedan pocos artículos",
  },
  curr: {
    NOK: ",-",
    USD: "$",
    EUR: "€",
  },
  christmassIsComing: {
    no: "Kickstart det nye året",
    en: "Kickstart the new year",
    se: "Kickstarta det nya året",
    dk: "Kickstart det nye år",
    fi: "Aloita uusi vuosi",
    de: "Starten Sie das neue Jahr",
    fr: "Démarrez la nouvelle année",
    es: "Comience el nuevo año",
  },
  christmassSale: {
    no: "Januarsalg",
    en: "January Sale",
    se: "Januarirea",
    dk: "Januarsalg",
    fi: "Joulualennus",
    de: "Januarverkauf",
    fr: "Vente de janvier",
    es: "Venta de enero",
  },
};
export default LocalizedSectionChristmasSale;
