import Head from "next/head";
import Header from "./Header";
import React, { ReactNode } from "react";
import { AppProvider } from "../context/AppContext";
import Footer from "./Footer";

import {
  resolveBgColor,
  GlobalConfig,
  BreakpointProvider,
  Product,
  Footerv2Props,
} from "@lilbit/shared";

type Props = {
  user?: any;
  title?: string;
  imageMetadata?: string;
  setMarginX?: boolean;
  config: GlobalConfig;
  children: ReactNode;
  metadata: string;
  siteName: "lildog" | "lilcat";
  urlFor: Function;
  footer: Footerv2Props;
  mainProduct: Product;
};
const Layout = (props: Props) => {
  const {
    config,
    children,
    setMarginX = true,
    metadata = "",
    title = config.title,
    imageMetadata = "",
    urlFor,
    siteName,
    footer,
    mainProduct,
  } = props;
  if (!config) {
    console.error("Missing config");
    return <div>Missing config</div>;
  }
  const bgColor = resolveBgColor();
  return (
    <AppProvider>
      <Head>
        <title>{title}</title>
        {/**image metadata */}
        <meta
          property="og:image"
          content={imageMetadata || "/favicons/favicon-32x32.png"}
        ></meta>
        <meta
          name="viewport"
          content="initial-scale=1.0, width=device-width, viewport-fit=cover"
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicons/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicons/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicons/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <meta name="application-name" content="&nbsp;" />
        <meta name="description" content={metadata ? metadata : ""} />
      </Head>
      <BreakpointProvider>
        <Header
          mainProduct={mainProduct}
          siteName={siteName}
          config={config}
          urlFor={urlFor}
        />
        <div className={`${bgColor} w-full`}>
          <main
            className={`flex-grow w-full lg:mx-auto lg:min-h-screen ${
              setMarginX ? "xl:max-w-5.5xl " : ""
            }`}
          >
            {children}
            {footer && <Footer {...footer} urlFor={urlFor} />}
          </main>
        </div>
      </BreakpointProvider>
    </AppProvider>
  );
};

export default Layout;
