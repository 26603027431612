export const getCountryByCountryCode = (countrycode: string) => {
  let countryName = "";
  let countryCode = "";
  countryCodeConvert.forEach((country) => {
    if (country.countryCode.toLowerCase() === countrycode.toLowerCase()) {
      countryName =
        country.translations[countrycode.toLowerCase()] || country.country;
      countryCode = country.countryCode;
      return [countryName, countryCode];
    }
  });
  return [countryName, countryCode];
};
// Update this is we add new countries to the list.
export const validCountryCodes = [
  "NO",
  "SE",
  "DK",
  "FI",
  "AT",
  "BE",
  "EE",
  "FR",
  "DE",
  "IS",
  "IE",
  "LU",
  "NL",
  "ES",
  "GR",
  "CH",
  "SK",
  "SI",
  "IT",
  "LV",
  "LT",
  "IL",
];

export const countryCodeConvert = [
  {
    country: "Afghanistan",
    countryCode: "AF",
    translations: {
      fi: "Afganistan",
      es: "Afganistán",
    },
  },
  {
    country: "Åland Islands",
    countryCode: "AX",
    translations: {
      no: "Åland",
      se: "Åland",
      da: "Åland",
      de: "Åland",
      fi: "Ahvenanmaa",
      fr: "Åland",
      es: "Islas Åland",
    },
  },
  {
    country: "Albania",
    countryCode: "AL",
    translations: {
      se: "Albanien",
      da: "Albanien",
      de: "Albanien",
      fr: "Albanie",
    },
  },
  {
    country: "Algeria",
    countryCode: "DZ",
    translations: {
      no: "Algerie",
      se: "Algeriet",
      da: "Algeriet",
      de: "Algerien",
      fi: "Algeria",
      fr: "Algérie",
      es: "Argelia",
    },
  },
  {
    country: "American Samoa",
    countryCode: "AS",
    translations: {
      no: "Amerikansk Samoa",
      se: "Amerikanska Samoa",
      da: "Amerikansk Samoa",
      de: "Amerikanisch-Samoa",
      fi: "Amerikan Samoa",
      fr: "Samoa américaines",
      es: "Samoa Americana",
    },
  },
  {
    country: "Andorra",
    countryCode: "AD",
    translations: {},
  },
  {
    country: "Angola",
    countryCode: "AO",
    translations: {},
  },
  {
    country: "Anguilla",
    countryCode: "AI",
    translations: {
      es: "Anguila",
    },
  },
  {
    country: "Antarctica",
    countryCode: "AQ",
    translations: {
      no: "Antarktis",
      se: "Antarktis",
      da: "Antarktis",
      de: "Antarktis",
      fi: "Antarktis",
      fr: "Antarctique",
      es: "Antártida",
    },
  },
  {
    country: "Antigua and Barbuda",
    countryCode: "AG",
    translations: {
      no: "Antigua og Barbuda",
      se: "Antigua och Barbuda",
      da: "Antigua og Barbuda",
      de: "Antigua und Barbuda",
      fi: "Antigua ja Barbuda",
      fr: "Antigua-et-Barbuda",
      es: "Antigua y Barbuda",
    },
  },
  {
    country: "Argentina",
    countryCode: "AR",
    translations: {
      de: "Argentinien",
      fi: "Argentiina",
      fr: "Argentine",
    },
  },
  {
    country: "Armenia",
    countryCode: "AM",
    translations: {
      se: "Armenien",
      da: "Armenien",
      de: "Armenien",
      fr: "Arménie",
    },
  },
  {
    country: "Aruba",
    countryCode: "AW",
    translations: {},
  },
  {
    country: "Australia",
    countryCode: "AU",
    translations: {
      se: "Australien",
      da: "Australien",
      de: "Australien",
      fi: "Australia",
      fr: "Australie",
      es: "Australia",
    },
  },
  {
    country: "Austria",
    countryCode: "AT",
    translations: {
      no: "Østerrike",
      se: "Österrike",
      da: "Østrig",
      de: "Österreich",
      fi: "Itävalta",
      fr: "Autriche",
      es: "Austria",
    },
  },
  {
    country: "Azerbaijan",
    countryCode: "AZ",
    translations: {
      no: "Aserbajdsjan",
      se: "Azerbajdzjan",
      da: "Aserbajdsjan",
      de: "Aserbaidschan",
      fi: "Azerbaidžan",
      fr: "Azerbaïdjan",
      es: "Azerbaiyán",
    },
  },
  {
    country: "Bahamas",
    countryCode: "BS",
    translations: {
      fi: "Bahama",
    },
  },
  {
    country: "Bahrain",
    countryCode: "BH",
    translations: {
      fr: "Bahreïn",
      es: "Baréin",
    },
  },
  {
    country: "Bangladesh",
    countryCode: "BD",
    translations: {
      de: "Bangladesch",
      es: "Bangladés",
    },
  },
  {
    country: "Barbados",
    countryCode: "BB",
    translations: {
      fr: "Barbade",
    },
  },
  {
    country: "Belarus",
    countryCode: "BY",
    translations: {
      en: "Belarus",
      no: "Hviterussland",
      se: "Vitryssland",
      da: "Hviderusland",
      de: "Weißrussland",
      fi: "Valko-Venäjä",
      fr: "Biélorussie",
      es: "Bielorrusia",
    },
  },
  {
    country: "Belgium",
    countryCode: "BE",
    translations: {
      no: "Belgia",
      se: "Belgien",
      da: "Belgien",
      de: "Belgien",
      fi: "Belgia",
      fr: "Belgique",
      es: "Bélgica",
    },
  },
  {
    country: "Belize",
    countryCode: "BZ",
    translations: {},
  },
  {
    country: "Benin",
    countryCode: "BJ",
    translations: {
      fr: "Bénin",
      es: "Benín",
    },
  },
  {
    country: "Bermuda",
    countryCode: "BM",
    translations: {
      fr: "Bermudes",
      es: "Bermudas",
    },
  },
  {
    country: "Bhutan",
    countryCode: "BT",
    translations: {
      fr: "Bhoutan",
      es: "Bután",
    },
  },
  {
    country: "Bolivia",
    countryCode: "BO",
    translations: {
      de: "Bolivien",
    },
  },
  {
    country: "Bonaire, Sint Eustatius and Saba",
    countryCode: "BQ",
    translations: {
      no: "Bonaire, Sint Eustatius og Saba",
      se: "Bonaire, Sint Eustatius och Saba",
      da: "Bonaire, Sint Eustatius og Saba",
      de: "Bonaire, Sint Eustatius und Saba",
      fi: "Bonaire, Sint Eustatius ja Saba",
      fr: "Bonaire, Saint-Eustache et Saba",
      es: "Bonaire, San Eustaquio y Saba",
    },
  },
  {
    country: "Bosnia and Herzegovina",
    countryCode: "BA",
    translations: {
      no: "Bosnia-Hercegovina",
      se: "Bosnien och Hercegovina",
      da: "Bosnien-Hercegovina",
      de: "Bosnien und Herzegowina",
      fi: "Bosnia ja Hertsegovina",
      fr: "Bosnie-Herzégovine",
      es: "Bosnia y Herzegovina",
    },
  },
  {
    country: "Botswana",
    countryCode: "BW",
    translations: {},
  },
  {
    country: "Bouvet Island",
    countryCode: "BV",
    translations: {
      no: "Bouvetøya",
      se: "Bouvetön",
      da: "Bouvetøen",
      de: "Bouvetinsel",
      fi: "Bouvet’nsaari",
      fr: "Île Bouvet",
      es: "Isla Bouvet",
    },
  },
  {
    country: "Brazil",
    countryCode: "BR",
    translations: {
      no: "Brasil",
      se: "Brasilien",
      da: "Brasilien",
      de: "Brasilien",
      fi: "Brasilia",
      fr: "Brésil",
      es: "Brasil",
    },
  },
  {
    country: "British Indian Ocean Territory",
    countryCode: "IO",
    translations: {
      no: "Det britiske territoriet i Indiahavet",
      se: "Brittiska territoriet i Indiska oceanen",
      da: "Det britiske territorium i Det Indiske Ocean",
      de: "Britisches Territorium im Indischen Ozean",
      fi: "Brittiläinen Intian valtameren alue",
      fr: "Territoire britannique de l'océan Indien",
      es: "Territorio Británico del Océano Índico",
    },
  },
  {
    country: "Brunei Darussalam",
    countryCode: "BN",
    translations: {
      no: "Brunei",
      se: "Brunei",
      da: "Brunei",
      fi: "Brunei",
      es: "Brunéi",
    },
  },
  {
    country: "Bulgaria",
    countryCode: "BG",
    translations: {
      se: "Bulgarien",
      da: "Bulgarien",
      de: "Bulgarien",
      fr: "Bulgarie",
    },
  },
  {
    country: "Burkina Faso",
    countryCode: "BF",
    translations: {},
  },
  {
    country: "Burundi",
    countryCode: "BI",
    translations: {},
  },
  {
    country: "Cambodia",
    countryCode: "KH",
    translations: {
      no: "Kambodsja",
      se: "Kambodja",
      da: "Cambodja",
      de: "Kambodscha",
      fi: "Kambodža",
      fr: "Cambodge",
      es: "Camboya",
    },
  },
  {
    country: "Cameroon",
    countryCode: "CM",
    translations: {
      no: "Kamerun",
      se: "Kamerun",
      da: "Cameroun",
      de: "Kamerun",
      fi: "Kamerun",
      fr: "Cameroun",
      es: "Camerún",
    },
  },
  {
    country: "Canada",
    countryCode: "CA",
    translations: {
      se: "Kanada",
      de: "Kanada",
      fi: "Kanada",
      es: "Canadá",
    },
  },
  {
    country: "Cape Verde",
    countryCode: "CV",
    translations: {
      no: "Kapp Verde",
      se: "Kap Verde",
      da: "Kap Verde",
      de: "Kap Verde",
      fi: "Kap Verde",
      fr: "Cap-Vert",
      es: "Cabo Verde",
    },
  },
  {
    country: "Cayman Islands",
    countryCode: "KY",
    translations: {
      no: "Caymanøyene",
      se: "Caymanöarna",
      da: "Caymanøerne",
      de: "Kaimaninseln",
      fi: "Caymansaaret",
      fr: "Îles Caïmans",
      es: "Islas Caimán",
    },
  },
  {
    country: "Central African Republic",
    countryCode: "CF",
    translations: {
      no: "Den sentralafrikanske republikk",
      se: "Centralafrikanska republiken",
      da: "Den Centralafrikanske Republik",
      de: "Zentralafrikanische Republik",
      fi: "Keski-Afrikan tasavalta",
      fr: "République centrafricaine",
      es: "República Centroafricana",
    },
  },
  {
    country: "Chad",
    countryCode: "TD",
    translations: {
      no: "Tsjad",
      se: "Tchad",
      da: "Tchad",
      de: "Tschad",
      fi: "Tšad",
      fr: "Tchad",
    },
  },
  {
    country: "Chile",
    countryCode: "CL",
    translations: {
      fr: "Chili",
    },
  },
  {
    country: "China",
    countryCode: "CN",
    translations: {
      no: "Kina",
      se: "Kina",
      da: "Kina",
      fi: "Kiina",
    },
  },
  {
    country: "Christmas Island",
    countryCode: "CX",
    translations: {
      en: "Christmas Island",
      no: "Christmasøya",
      se: "Julön",
      da: "Juleøen",
      de: "Weihnachtsinsel",
      fi: "Joulusaari",
      fr: "Île Christmas",
      es: "Isla de Navidad",
    },
  },
  {
    country: "Cocos (Keeling) Islands",
    countryCode: "CC",
    translations: {
      no: "Kokosøyene",
      se: "Kokosöarna",
      da: "Kokosøerne",
      de: "Kokosinseln",
      fi: "Kookossaaret",
      fr: "Îles Cocos",
      es: "Islas Cocos",
    },
  },
  {
    country: "Colombia",
    countryCode: "CO",
    translations: {
      de: "Kolumbien",
      fi: "Kolumbia",
      fr: "Colombie",
    },
  },
  {
    country: "Comoros",
    countryCode: "KM",
    translations: {
      no: "Komorene",
      se: "Komorerna",
      da: "Comorerne",
      de: "Komoren",
      fi: "Komorit",
      fr: "Comores",
      es: "Comoras",
    },
  },
  {
    country: "Congo",
    countryCode: "CG",
    translations: {
      no: "Kongo",
      se: "Kongo",
      de: "Kongo",
      fi: "Kongo",
    },
  },
  {
    country: "Congo, Democratic Republic of the",
    countryCode: "CD",
    translations: {
      no: "Den demokratiske republikken Kongo",
      se: "Demokratiska republiken Kongo",
      da: "Den Demokratiske Republik Congo",
      de: "Demokratische Republik Kongo",
      fi: "Kongon demokraattinen tasavalta",
      fr: "Congo, République démocratique du",
      es: "Congo, República Democrática del",
    },
  },
  {
    country: "Cook Islands",
    countryCode: "CK",
    translations: {
      no: "Cookøyene",
      se: "Cooköarna",
      da: "Cookøerne",
      de: "Cookinseln",
      fi: "Cookinsaaret",
      fr: "Îles Cook",
      es: "Islas Cook",
    },
  },
  {
    country: "Costa Rica",
    countryCode: "CR",
    translations: {},
  },
  {
    country: "Côte d'Ivoire",
    countryCode: "CI",
    translations: {
      no: "Elfenbenskysten",
      se: "Elfenbenskusten",
      da: "Elfenbenskysten",
      de: "Elfenbeinküste",
      fi: "Norsunluurannikko",
      es: "Costa de Marfil",
    },
  },
  {
    country: "Croatia",
    countryCode: "HR",
    translations: {
      no: "Kroatia",
      se: "Kroatien",
      da: "Kroatien",
      de: "Kroatien",
      fi: "Kroatia",
      fr: "Croatie",
      es: "Croacia",
    },
  },
  {
    country: "Cuba",
    countryCode: "CU",
    translations: {
      se: "Kuba",
      de: "Kuba",
      fi: "Kuuba",
    },
  },
  {
    country: "Curaçao",
    countryCode: "CW",
    translations: {},
  },
  {
    country: "Cyprus",
    countryCode: "CY",
    translations: {
      no: "Kypros",
      se: "Cypern",
      da: "Cypern",
      de: "Zypern",
      fi: "Kypros",
      fr: "Chypre",
      es: "Chipre",
    },
  },
  {
    country: "Czech Republic",
    countryCode: "CZ",
    translations: {
      no: "Tsjekkia",
      se: "Tjeckien",
      da: "Tjekkiet",
      de: "Tschechische Republik",
      fi: "Tšekki",
      fr: "République tchèque",
      es: "República Checa",
    },
  },
  {
    country: "Denmark",
    countryCode: "DK",
    translations: {
      no: "Danmark",
      se: "Danmark",
      da: "Danmark",
      de: "Dänemark",
      fi: "Tanska",
      fr: "Danemark",
      es: "Dinamarca",
    },
  },
  {
    country: "Djibouti",
    countryCode: "DJ",
    translations: {
      de: "Dschibuti",
      es: "Yibuti",
    },
  },
  {
    country: "Dominica",
    countryCode: "DM",
    translations: {
      fr: "Dominique",
    },
  },
  {
    country: "Dominican Republic",
    countryCode: "DO",
    translations: {
      en: "Dominican Republic",
      no: "Den dominikanske republikk",
      se: "Dominikanska republiken",
      da: "Den Dominikanske Republik",
      de: "Dominikanische Republik",
      fi: "Dominikaaninen tasavalta",
      fr: "République dominicaine",
      es: "República Dominicana",
    },
  },
  {
    country: "Ecuador",
    countryCode: "EC",
    translations: {
      fr: "Équateur",
    },
  },
  {
    country: "Egypt",
    countryCode: "EG",
    translations: {
      se: "Egypten",
      da: "Egypten",
      de: "Ägypten",
      fi: "Egypti",
      fr: "Égypte",
      es: "Egipto",
    },
  },
  {
    country: "El Salvador",
    countryCode: "SV",
    translations: {},
  },
  {
    country: "Equatorial Guinea",
    countryCode: "GQ",
    translations: {
      no: "Ekvatorial-Guinea",
      se: "Ekvatorialguinea",
      da: "Ækvatorialguinea",
      de: "Äquatorialguinea",
      fi: "Päiväntasaajan Guinea",
      fr: "Guinée équatoriale",
      es: "Guinea Ecuatorial",
    },
  },
  {
    country: "Eritrea",
    countryCode: "ER",
    translations: {
      fr: "Érythrée",
    },
  },
  {
    country: "Estonia",
    countryCode: "EE",
    translations: {
      no: "Estland",
      se: "Estland",
      da: "Estland",
      de: "Estland",
      fi: "Viro",
      fr: "Estonie",
    },
  },
  {
    country: "Ethiopia",
    countryCode: "ET",
    translations: {
      no: "Etiopia",
      se: "Etiopien",
      da: "Etiopien",
      de: "Äthiopien",
      fi: "Etiopia",
      fr: "Éthiopie",
      es: "Etiopía",
    },
  },
  {
    country: "Falkland Islands (Malvinas)",
    countryCode: "FK",
    translations: {
      no: "Falklandsøyene",
      se: "Falklandsöarna",
      da: "Falklandsøerne",
      de: "Falklandinseln",
      fi: "Falklandinsaaret",
      fr: "Îles Malouines",
      es: "Islas Malvinas",
    },
  },
  {
    country: "Faroe Islands",
    countryCode: "FO",
    translations: {
      no: "Færøyene",
      se: "Färöarna",
      da: "Færøerne",
      de: "Färöer-Inseln",
      fi: "Färsaaret",
      fr: "Îles Féroé",
      es: "Islas Feroe",
    },
  },
  {
    country: "Fiji",
    countryCode: "FJ",
    translations: {
      de: "Fidschi",
      fi: "Fidži",
      fr: "Fidji",
      es: "Fiyi",
    },
  },
  {
    country: "Finland",
    countryCode: "FI",
    translations: {
      de: "Finnland",
      fi: "Suomi",
      fr: "Finlande",
      es: "Finlandia",
    },
  },
  {
    country: "France",
    countryCode: "FR",
    translations: {
      no: "Frankrike",
      se: "Frankrike",
      da: "Frankrig",
      de: "Frankreich",
      fi: "Ranska",
      fr: "France",
      es: "Francia",
    },
  },
  {
    country: "French Guiana",
    countryCode: "GF",
    translations: {
      no: "Fransk Guyana",
      se: "Franska Guyana",
      da: "Fransk Guyana",
      de: "Französisch-Guayana",
      fi: "Ranskan Guayana",
      fr: "Guyane française",
      es: "Guayana Francesa",
    },
  },
  {
    country: "French Polynesia",
    countryCode: "PF",
    translations: {
      no: "Fransk Polynesia",
      se: "Franska Polynesien",
      da: "Fransk Polynesien",
      de: "Französisch-Polynesien",
      fi: "Ranskan Polynesia",
      fr: "Polynésie française",
      es: "Polinesia Francesa",
    },
  },
  {
    country: "French Southern Territories",
    countryCode: "TF",
    translations: {
      no: "De franske sørterritorier",
      se: "Franska sydterritorierna",
      da: "De franske sydlige territorier",
      de: "Französische Süd- und Antarktisgebiete",
      fi: "Ranskan eteläiset ja antarktiset alueet",
      fr: "Terres australes et antarctiques françaises",
      es: "Territorios Australes Franceses",
    },
  },
  {
    country: "Gabon",
    countryCode: "GA",
    translations: {
      es: "Gabón",
    },
  },
  {
    country: "Gambia",
    countryCode: "GM",
    translations: {},
  },
  {
    country: "Georgia",
    countryCode: "GE",
    translations: {
      se: "Georgien",
      da: "Georgien",
      de: "Georgien",
      fr: "Géorgie",
    },
  },
  {
    country: "Germany",
    countryCode: "DE",
    translations: {
      no: "Tyskland",
      se: "Tyskland",
      da: "Tyskland",
      de: "Deutschland",
      fi: "Saksa",
      fr: "Allemagne",
      es: "Alemania",
    },
  },
  {
    country: "Ghana",
    countryCode: "GH",
    translations: {},
  },
  {
    country: "Gibraltar",
    countryCode: "GI",
    translations: {},
  },
  {
    country: "Greece",
    countryCode: "GR",
    translations: {
      no: "Hellas",
      se: "Grekland",
      da: "Grækenland",
      de: "Griechenland",
      fi: "Kreikka",
      fr: "Grèce",
      es: "Grecia",
    },
  },
  {
    country: "Greenland",
    countryCode: "GL",
    translations: {
      no: "Grønland",
      se: "Grönland",
      da: "Grønland",
      de: "Grönland",
      fi: "Grönlanti",
      fr: "Groenland",
      es: "Groenlandia",
    },
  },
  {
    country: "Grenada",
    countryCode: "GD",
    translations: {},
  },
  {
    country: "Guadeloupe",
    countryCode: "GP",
    translations: {
      es: "Guadalupe",
    },
  },
  {
    country: "Guam",
    countryCode: "GU",
    translations: {},
  },
  {
    country: "Guatemala",
    countryCode: "GT",
    translations: {},
  },
  {
    country: "Guernsey",
    countryCode: "GG",
    translations: {
      fr: "Guernesey",
    },
  },
  {
    country: "Guinea",
    countryCode: "GN",
    translations: {},
  },
  {
    country: "Guinea-Bissau",
    countryCode: "GW",
    translations: {},
  },
  {
    country: "Guyana",
    countryCode: "GY",
    translations: {},
  },
  {
    country: "Haiti",
    countryCode: "HT",
    translations: {},
  },
  {
    country: "Heard Island and McDonald Islands",
    countryCode: "HM",
    translations: {
      no: "Heard- og McDonaldøyene",
      se: "Heard- och McDonaldöarna",
      da: "Heard- og McDonaldøerne",
      de: "Heard und McDonaldinseln",
      fi: "Heard ja McDonaldinsaaret",
      fr: "Îles Heard-et-MacDonald",
      es: "Islas Heard y McDonald",
    },
  },
  {
    country: "Holy See (Vatican City State)",
    countryCode: "VA",
    translations: {
      no: "Vatikanstaten",
      se: "Vatikanstaten",
      da: "Vatikanstaten",
      de: "Vatikanstadt",
      fi: "Vatikaani",
      fr: "Saint-Siège (État de la Cité du Vatican)",
      es: "Ciudad del Vaticano",
    },
  },
  {
    country: "Honduras",
    countryCode: "HN",
    translations: {},
  },
  {
    country: "Hong Kong",
    countryCode: "HK",
    translations: {
      no: "Hongkong",
      se: "Hongkong",
      da: "Hongkong",
      de: "Hongkong",
      fi: "Hongkong",
      fr: "Hong Kong",
      es: "Hong Kong",
    },
  },
  {
    country: "Hungary",
    countryCode: "HU",
    translations: {
      no: "Ungarn",
      se: "Ungern",
      da: "Ungarn",
      de: "Ungarn",
      fi: "Unkari",
      fr: "Hongrie",
      es: "Hungría",
    },
  },
  {
    country: "Iceland",
    countryCode: "IS",
    translations: {
      no: "Island",
      se: "Island",
      da: "Island",
      de: "Island",
      fi: "Islanti",
      fr: "Islande",
      es: "Islandia",
    },
  },
  {
    country: "India",
    countryCode: "IN",
    translations: {
      se: "Indien",
      da: "Indien",
      de: "Indien",
      fi: "Intia",
      fr: "Inde",
    },
  },
  {
    country: "Indonesia",
    countryCode: "ID",
    translations: {
      se: "Indonesien",
      da: "Indonesien",
      de: "Indonesien",
      fr: "Indonésie",
    },
  },
  {
    country: "Iran, Islamic Republic of",
    countryCode: "IR",
    translations: {
      no: "Iran",
      se: "Iran",
      da: "Iran",
      de: "Iran",
      fi: "Iran",
      fr: "Iran",
      es: "Irán",
    },
  },
  {
    country: "Iraq",
    countryCode: "IQ",
    translations: {
      no: "Irak",
      se: "Irak",
      da: "Irak",
      de: "Irak",
      fi: "Irak",
      fr: "Irak",
      es: "Irak",
    },
  },
  {
    country: "Ireland",
    countryCode: "IE",
    translations: {
      no: "Irland",
      se: "Irland",
      da: "Irland",
      de: "Irland",
      fi: "Irlanti",
      fr: "Irlande",
      es: "Irlanda",
    },
  },
  {
    country: "Isle of Mane",
    countryCode: "IM",
    translations: {},
  },
  {
    country: "Israel",
    countryCode: "IL",
    translations: {},
  },
  {
    country: "Italy",
    countryCode: "IT",
    translations: {
      no: "Italia",
      se: "Italien",
      da: "Italien",
      de: "Italien",
      fi: "Italia",
      fr: "Italie",
      es: "Italia",
    },
  },
  {
    country: "Jamaica",
    countryCode: "JM",
    translations: {},
  },
  {
    country: "Japan",
    countryCode: "JP",
    translations: {
      fi: "Japani",
      fr: "Japon",
      es: "Japón",
    },
  },
  {
    country: "Jersey",
    countryCode: "JE",
    translations: {
      fr: "Jersey",
    },
  },
  {
    country: "Jordan",
    countryCode: "JO",
    translations: {
      se: "Jordanien",
      de: "Jordanien",
      fi: "Jordania",
      fr: "Jordanie",
      es: "Jordania",
    },
  },
  {
    country: "Kazakhstan",
    countryCode: "KZ",
    translations: {
      no: "Kasakhstan",
      se: "Kazakstan",
      da: "Kasakhstan",
      de: "Kasachstan",
      fi: "Kazakstan",
      fr: "Kazakhstan",
      es: "Kazajistán",
    },
  },
  {
    country: "Kenya",
    countryCode: "KE",
    translations: {},
  },
  {
    country: "Kiribati",
    countryCode: "KI",
    translations: {},
  },
  {
    country: "Korea, Democratic People's Republic of",
    countryCode: "KP",
    translations: {
      no: "Nord-Korea",
      se: "Nordkorea",
      da: "Nordkorea",
      de: "Nordkorea",
      fi: "Pohjois-Korea",
      fr: "Corée du Nord",
      es: "Corea del Norte",
    },
  },
  {
    country: "Korea, Republic of",
    countryCode: "KR",
    translations: {
      no: "Sør-Korea",
      se: "Sydkorea",
      da: "Sydkorea",
      de: "Südkorea",
      fi: "Etelä-Korea",
      fr: "Corée du Sud",
      es: "Corea del Sur",
    },
  },
  {
    country: "Kuwait",
    countryCode: "KW",
    translations: {
      fr: "Koweït",
    },
  },
  {
    country: "Kyrgyzstan",
    countryCode: "KG",
    translations: {
      no: "Kirgisistan",
      se: "Kirgizistan",
      da: "Kirgisistan",
      de: "Kirgisistan",
      fi: "Kirgisia",
      fr: "Kirghizistan",
      es: "Kirguistán",
    },
  },
  {
    country: "Lao People's Democratic Republic",
    countryCode: "LA",
    translations: {
      no: "Laos",
      se: "Laos",
      da: "Laos",
      de: "Laos",
      fi: "Laos",
      fr: "Laos",
      es: "Laos",
    },
  },
  {
    country: "Latvia",
    countryCode: "LV",
    translations: {
      se: "Lettland",
      da: "Letland",
      de: "Lettland",
      fr: "Lettonie",
      es: "Letonia",
    },
  },
  {
    country: "Lebanon",
    countryCode: "LB",
    translations: {
      no: "Libanon",
      se: "Libanon",
      da: "Libanon",
      de: "Libanon",
      fi: "Libanon",
      fr: "Liban",
      es: "Líbano",
    },
  },
  {
    country: "Lesotho",
    countryCode: "LS",
    translations: {
      es: "Lesoto",
    },
  },
  {
    country: "Liberia",
    countryCode: "LR",
    translations: {},
  },
  {
    country: "Libya",
    countryCode: "LY",
    translations: {
      se: "Libyen",
      da: "Libyen",
      de: "Libyen",
      fr: "Libye",
      es: "Libia",
    },
  },
  {
    country: "Liechtenstein",
    countryCode: "LI",
    translations: {},
  },
  {
    country: "Lithuania",
    countryCode: "LT",
    translations: {
      no: "Litauen",
      se: "Litauen",
      da: "Litauen",
      de: "Litauen",
      fi: "Liettua",
      fr: "Lituanie",
      es: "Lituania",
    },
  },
  {
    country: "Luxembourg",
    countryCode: "LU",
    translations: {
      no: "Luxembourg",
      se: "Luxemburg",
      da: "Luxembourg",
      de: "Luxemburg",
      fi: "Luxemburg",
      fr: "Luxembourg",
      es: "Luxemburgo",
    },
  },
  {
    country: "Macao",
    countryCode: "MO",
    translations: {},
  },
  {
    country: "Macedonia, the Former Yugoslav Republic of",
    countryCode: "MK",
    translations: {
      no: "Makedonia",
      se: "Makedonien",
      da: "Makedonien",
      de: "Mazedonien",
      fi: "Makedonia",
      fr: "Macédoine",
      es: "Macedonia",
    },
  },
  {
    country: "Madagascar",
    countryCode: "MG",
    translations: {
      no: "Madagaskar",
      se: "Madagaskar",
      da: "Madagaskar",
      de: "Madagaskar",
      fi: "Madagaskar",
      fr: "Madagascar",
      es: "Madagascar",
    },
  },
  {
    country: "Malawi",
    countryCode: "MW",
    translations: {
      es: "Malaui",
    },
  },
  {
    country: "Malaysia",
    countryCode: "MY",
    translations: {
      no: "Malaysia",
      se: "Malaysia",
      da: "Malaysia",
      de: "Malaysia",
      fi: "Malesia",
      fr: "Malaisie",
      es: "Malasia",
    },
  },
  {
    country: "Maldives",
    countryCode: "MV",
    translations: {
      no: "Maldivene",
      se: "Maldiverna",
      da: "Maldiverne",
      de: "Malediven",
      fi: "Malediivit",
      fr: "Maldives",
      es: "Maldivas",
    },
  },
  {
    country: "Mali",
    countryCode: "ML",
    translations: {
      no: "Mali",
      se: "Mali",
      da: "Mali",
      de: "Mali",
      fi: "Mali",
      fr: "Mali",
      es: "Mali",
    },
  },
  {
    country: "Malta",
    countryCode: "MT",
    translations: {
      fr: "Malte",
    },
  },
  {
    country: "Marshall Islands",
    countryCode: "MH",
    translations: {
      no: "Marshalløyene",
      se: "Marshallöarna",
      da: "Marshalløerne",
      de: "Marshallinseln",
      fi: "Marshallinsaaret",
      fr: "Îles Marshall",
      es: "Islas Marshall",
    },
  },
  {
    country: "Martinique",
    countryCode: "MQ",
    translations: {
      fr: "Martinique",
    },
  },
  {
    country: "Mauritania",
    countryCode: "MR",
    translations: {
      no: "Mauritania",
      se: "Mauretanien",
      de: "Mauretanien",
      fi: "Mauritania",
      fr: "Mauritanie",
      es: "Mauritania",
    },
  },
  {
    country: "Mauritius",
    countryCode: "MU",
    translations: {
      no: "Mauritius",
      se: "Mauritius",
      da: "Mauritius",
      de: "Mauritius",
      fi: "Mauritius",
      fr: "Maurice",
      es: "Mauricio",
    },
  },
  {
    country: "Mayotte",
    countryCode: "YT",
    translations: {
      fr: "Mayotte",
    },
  },
  {
    country: "Mexico",
    countryCode: "MX",
    translations: {
      no: "Mexico",
      se: "Mexiko",
      da: "Mexico",
      de: "Mexiko",
      fi: "Meksiko",
      fr: "Mexique",
      es: "México",
    },
  },
  {
    country: "Micronesia, Federated States of",
    countryCode: "FM",
    translations: {
      no: "Mikronesiaføderasjonen",
      se: "Mikronesiska federationen",
      da: "Mikronesiens Forenede Stater",
      de: "Mikronesien",
      fi: "Mikronesian liittovaltio",
      fr: "États fédérés de Micronésie",
      es: "Micronesia",
    },
  },
  {
    country: "Moldova, Republic of",
    countryCode: "MD",
    translations: {
      no: "Moldova",
      se: "Moldavien",
      da: "Moldova",
      de: "Moldawien",
      fi: "Moldova",
      fr: "Moldavie",
      es: "Moldavia",
    },
  },
  {
    country: "Monaco",
    countryCode: "MC",
    translations: {
      no: "Monaco",
      se: "Monaco",
      da: "Monaco",
      de: "Monaco",
      fi: "Monaco",
      fr: "Monaco",
      es: "Mónaco",
    },
  },
  {
    country: "Mongolia",
    countryCode: "MN",
    translations: {
      no: "Mongolia",
      se: "Mongoliet",
      da: "Mongoliet",
      de: "Mongolei",
      fi: "Mongolia",
      fr: "Mongolie",
      es: "Mongolia",
    },
  },
  {
    country: "Montenegro",
    countryCode: "ME",
    translations: {
      no: "Montenegro",
      se: "Montenegro",
      da: "Montenegro",
      de: "Montenegro",
      fi: "Montenegro",
      fr: "Monténégro",
      es: "Montenegro",
    },
  },
  {
    country: "Montserrat",
    countryCode: "MS",
    translations: {
      fr: "Montserrat",
    },
  },
  {
    country: "Morocco",
    countryCode: "MA",
    translations: {
      no: "Marokko",
      se: "Marocko",
      da: "Marokko",
      de: "Marokko",
      fi: "Marokko",
      fr: "Maroc",
      es: "Marruecos",
    },
  },
  {
    country: "Mozambique",
    countryCode: "MZ",
    translations: {
      no: "Mosambik",
      se: "Moçambique",
      da: "Mozambique",
      de: "Mosambik",
      fi: "Mosambik",
      fr: "Mozambique",
      es: "Mozambique",
    },
  },
  {
    country: "Myanmar",
    countryCode: "MM",
    translations: {
      no: "Burma",
      se: "Burma",
    },
  },
  {
    country: "Namibia",
    countryCode: "NA",
    translations: {
      fr: "Namibie",
    },
  },
  {
    country: "Nauru",
    countryCode: "NR",
    translations: {},
  },
  {
    country: "Nepal",
    countryCode: "NP",
    translations: {},
  },
  {
    country: "Netherlands",
    countryCode: "NL",
    translations: {
      no: "Nederland",
      se: "Nederländerna",
      da: "Holland",
      de: "Niederlande",
      fi: "Alankomaat",
      fr: "Pays-Bas",
      es: "Países Bajos",
    },
  },
  {
    country: "New Caledonia",
    countryCode: "NC",
    translations: {
      no: "Ny-Caledonia",
      se: "Nya Kaledonien",
      de: "Neukaledonien",
      fi: "Uusi-Kaledonia",
      fr: "Nouvelle-Calédonie",
      es: "Nueva Caledonia",
    },
  },
  {
    country: "New Zealand",
    countryCode: "NZ",
    translations: {
      de: "Neuseeland",
      fi: "Uusi-Seelanti",
      fr: "Nouvelle-Zélande",
      es: "Nueva Zelanda",
    },
  },
  {
    country: "Nicaragua",
    countryCode: "NI",
    translations: {},
  },
  {
    country: "Niger",
    countryCode: "NE",
    translations: {},
  },
  {
    country: "Nigeria",
    countryCode: "NG",
    translations: {
      fr: "Nigéria",
    },
  },
  {
    country: "Niue",
    countryCode: "NU",
    translations: {},
  },
  {
    country: "Norfolk Island",
    countryCode: "NF",
    translations: {
      no: "Norfolkøya",
      se: "Norfolkön",
      da: "Norfolk Island",
      de: "Norfolkinsel",
      fi: "Norfolkinsaari",
      fr: "Île Norfolk",
      es: "Isla Norfolk",
    },
  },
  {
    country: "Northern Mariana Islands",
    countryCode: "MP",
    translations: {
      no: "Nord-Marianene",
      se: "Nordmarianerna",
      da: "Nordmarianerne",
      de: "Nördliche Marianen",
      fi: "Pohjois-Mariaanit",
      fr: "Îles Mariannes du Nord",
      es: "Islas Marianas del Norte",
    },
  },
  {
    country: "Norway",
    countryCode: "NO",
    translations: {
      no: "Norge",
      se: "Norge",
      da: "Norge",
      de: "Norwegen",
      fi: "Norja",
      fr: "Norvège",
      es: "Noruega",
    },
  },
  {
    country: "Oman",
    countryCode: "OM",
    translations: {},
  },
  {
    country: "Pakistan",
    countryCode: "PK",
    translations: {
      es: "Pakistán",
    },
  },
  {
    country: "Palau",
    countryCode: "PW",
    translations: {
      fr: "Palaos",
    },
  },
  {
    country: "Palestine, State of",
    countryCode: "PS",
    translations: {
      no: "Palestina",
      se: "Palestina",
      da: "Palæstina",
      de: "Palästina",
      fi: "Palestiina",
    },
  },
  {
    country: "Panama",
    countryCode: "PA",
    translations: {},
  },
  {
    country: "Papua New Guinea",
    countryCode: "PG",
    translations: {
      no: "Papua Ny-Guinea",
      de: "Papua-Neuguinea",
      da: "Papua Ny Guinea",
      fr: "Papouasie-Nouvelle-Guinée",
      fi: "Papua-Uusi-Guinea",
      es: "Papúa Nueva Guinea",
    },
  },
  {
    country: "Paraguay",
    countryCode: "PY",
    translations: {},
  },
  {
    country: "Peru",
    countryCode: "PE",
    translations: {
      es: "Perú",
    },
  },
  {
    country: "Philippines",
    countryCode: "PH",
    translations: {
      no: "Filippinene",
      se: "Filippinerna",
      da: "Filippinerne",
      de: "Philippinen",
      fi: "Filippiinit",
      fr: "Philippines",
      es: "Filipinas",
    },
  },
  {
    country: "Pitcairn",
    countryCode: "PN",
    translations: {
      no: "Pitcairnøyene",
      se: "Pitcairnöarna",
      da: "Pitcairn",
      de: "Pitcairninseln",
      fi: "Pitcairn",
      fr: "Îles Pitcairn",
      es: "Islas Pitcairn",
    },
  },
  {
    country: "Poland",
    countryCode: "PL",
    translations: {
      no: "Polen",
      se: "Polen",
      da: "Polen",
      de: "Polen",
      fi: "Puola",
      fr: "Pologne",
      es: "Polonia",
    },
  },
  {
    country: "Portugal",
    countryCode: "PT",
    translations: {
      fi: "Portugali",
    },
  },
  {
    country: "Puerto Rico",
    countryCode: "PR",
    translations: {},
  },
  {
    country: "Qatar",
    countryCode: "QA",
    translations: {},
  },
  {
    country: "Réunion",
    countryCode: "RE",
    translations: {
      fr: "Réunion",
    },
  },
  {
    country: "Romania",
    countryCode: "RO",
    translations: {
      se: "Rumänien",
      da: "Rumænien",
      de: "Rumänien",
      fr: "Roumanie",
      es: "Rumania",
    },
  },
  {
    country: "Russian Federation",
    countryCode: "RU",
    translations: {
      no: "Russland",
      se: "Ryssland",
      da: "Rusland",
      de: "Russland",
      fi: "Venäjä",
      fr: "Russie",
      es: "Rusia",
    },
  },
  {
    country: "Rwanda",
    countryCode: "RW",
    translations: {
      de: "Ruanda",
      fi: "Ruanda",
      es: "Ruanda",
    },
  },
  {
    country: "Saint Barthélemy",
    countryCode: "BL",
    translations: {
      fr: "Saint-Barthélemy",
    },
  },
  {
    country: "Saint Helena, Ascension and Tristan da Cunha",
    countryCode: "SH",
    translations: {
      no: "St. Helena",
      se: "Sankta Helena",
      da: "St. Helena",
      de: "St. Helena",
      fi: "Saint Helena",
      fr: "Sainte-Hélène",
      es: "Santa Helena",
    },
  },
  {
    country: "Saint Kitts and Nevis",
    countryCode: "KN",
    translations: {
      no: "Saint Kitts og Nevis",
      se: "Saint Kitts och Nevis",
      da: "Saint Kitts og Nevis",
      de: "St. Kitts und Nevis",
      fi: "Saint Kitts ja Nevis",
      fr: "Saint-Christophe-et-Niévès",
      es: "San Cristóbal y Nieves",
    },
  },
  {
    country: "Saint Lucia",
    countryCode: "LC",
    translations: {
      de: "St. Lucia",
      fi: "Saint Lucia",
      fr: "Sainte-Lucie",
      es: "Santa Lucía",
    },
  },
  {
    country: "Saint Martin (French part)",
    countryCode: "MF",
    translations: {
      no: "Saint-Martin",
      se: "Saint-Martin",
      da: "Saint-Martin",
      de: "Saint-Martin",
      fi: "Saint-Martin",
      fr: "Saint-Martin",
      es: "Saint-Martin",
    },
  },
  {
    country: "Saint Pierre and Miquelon",
    countryCode: "PM",
    translations: {
      no: "Saint-Pierre-et-Miquelon",
      se: "Saint-Pierre och Miquelon",
      da: "Saint-Pierre og Miquelon",
      de: "Saint-Pierre und Miquelon",
      fi: "Saint-Pierre ja Miquelon",
      fr: "Saint-Pierre-et-Miquelon",
      es: "San Pedro y Miquelón",
    },
  },
  {
    country: "Saint Vincent and the Grenadines",
    countryCode: "VC",
    translations: {
      no: "Saint Vincent og Grenadinene",
      se: "Saint Vincent och Grenadinerna",
      da: "Saint Vincent og Grenadinerne",
      de: "St. Vincent und die Grenadinen",
      fi: "Saint Vincent ja Grenadiinit",
      fr: "Saint-Vincent-et-les Grenadines",
      es: "San Vicente y las Granadinas",
    },
  },
  {
    country: "Samoa",
    countryCode: "WS",
    translations: {},
  },
  {
    country: "San Marino",
    countryCode: "SM",
    translations: {
      fr: "Saint-Marin",
    },
  },
  {
    country: "Sao Tome and Principe",
    countryCode: "ST",
    translations: {
      no: "São Tomé og Príncipe",
      se: "São Tomé och Príncipe",
      da: "São Tomé og Príncipe",
      de: "São Tomé und Príncipe",
      fi: "São Tomé ja Príncipe",
      fr: "Sao Tomé-et-Principe",
      es: "Santo Tomé y Príncipe",
    },
  },
  {
    country: "Saudi Arabia",
    countryCode: "SA",
    translations: {
      no: "Saudi-Arabia",
      se: "Saudiarabien",
      da: "Saudi-Arabien",
      de: "Saudi-Arabien",
      fi: "Saudi-Arabia",
      fr: "Arabie saoudite",
      es: "Arabia Saudí",
    },
  },
  {
    country: "Senegal",
    countryCode: "SN",
    translations: {
      fr: "Sénégal",
    },
  },
  {
    country: "Serbia",
    countryCode: "RS",
    translations: {
      se: "Serbien",
      da: "Serbien",
      de: "Serbien",
      fr: "Serbie",
      es: "Serbia",
    },
  },
  {
    country: "Seychelles",
    countryCode: "SC",
    translations: {
      no: "Seychellene",
      se: "Seychellerna",
      da: "Seychellerne",
      de: "Seychellen",
      fi: "Seychellit",
      fr: "Seychelles",
      es: "Seychelles",
    },
  },
  {
    country: "Sierra Leone",
    countryCode: "SL",
    translations: {},
  },
  {
    country: "Singapore",
    countryCode: "SG",
    translations: {
      de: "Singapur",
      fr: "Singapour",
      es: "Singapur",
    },
  },
  {
    country: "Sint Maarten (Dutch part)",
    countryCode: "SX",
    translations: {
      no: "Sint Maarten",
      se: "Sint Maarten",
      da: "Sint Maarten",
      de: "Sint Maarten",
      fi: "Sint Maarten",
      fr: "Saint-Martin",
      es: "Sint Maarten",
    },
  },
  {
    country: "Slovakia",
    countryCode: "SK",
    translations: {
      no: "Slovakia",
      se: "Slovakien",
      da: "Slovakiet",
      de: "Slowakei",
      fi: "Slovakia",
      fr: "Slovaquie",
      es: "Eslovaquia",
    },
  },
  {
    country: "Slovenia",
    countryCode: "SI",
    translations: {
      no: "Slovenia",
      se: "Slovenien",
      da: "Slovenien",
      de: "Slowenien",
      fi: "Slovenia",
      fr: "Slovénie",
      es: "Eslovenia",
    },
  },
  {
    country: "Solomon Islands",
    countryCode: "SB",
    translations: {
      no: "Salomonøyene",
      se: "Salomonöarna",
      da: "Salomonøerne",
      de: "Salomonen",
      fi: "Salomonsaaret",
      fr: "Îles Salomon",
      es: "Islas Salomón",
    },
  },
  {
    country: "Somalia",
    countryCode: "SO",
    translations: {
      fr: "Somalie",
    },
  },
  {
    country: "South Africa",
    countryCode: "ZA",
    translations: {
      no: "Sør-Afrika",
      se: "Sydafrika",
      da: "Sydafrika",
      de: "Südafrika",
      fi: "Etelä-Afrikka",
      fr: "Afrique du Sud",
      es: "Sudáfrica",
    },
  },
  {
    country: "South Georgia and the South Sandwich Islands",
    countryCode: "GS",
    translations: {
      no: "Sør-Georgia og Sør-Sandwichøyene",
      se: "Sydgeorgien och Sydsandwichöarna",
      da: "South Georgia og South Sandwich Islands",
      de: "Südgeorgien und die Südlichen Sandwichinseln",
      fi: "Etelä-Georgia ja Eteläiset Sandwichsaaret",
      fr: "Géorgie du Sud-et-les Îles Sandwich du Sud",
      es: "Islas Georgias del Sur y Sandwich del Sur",
    },
  },
  {
    country: "South Sudan",
    countryCode: "SS",
    translations: {
      no: "Sør-Sudan",
      se: "Sydsudan",
      da: "Sydsudan",
      de: "Südsudan",
      fi: "Etelä-Sudan",
      fr: "Soudan du Sud",
      es: "Sudán del Sur",
    },
  },
  {
    country: "Spain",
    countryCode: "ES",
    translations: {
      no: "Spania",
      se: "Spanien",
      da: "Spanien",
      de: "Spanien",
      fi: "Espanja",
      fr: "Espagne",
      es: "España",
    },
  },
  {
    country: "Sri Lanka",
    countryCode: "LK",
    translations: {},
  },
  {
    country: "Sudan",
    countryCode: "SD",
    translations: {
      fr: "Soudan",
    },
  },
  {
    country: "Suriname",
    countryCode: "SR",
    translations: {
      se: "Surinam",
      no: "Surinam",
      da: "Surinam",
      es: "Surinam",
    },
  },
  {
    country: "Svalbard and Jan Mayen",
    countryCode: "SJ",
    translations: {
      no: "Svalbard og Jan Mayen",
      se: "Svalbard och Jan Mayen",
      da: "Svalbard og Jan Mayen",
      de: "Spitzbergen und Jan Mayen",
      fi: "Huippuvuoret ja Jan Mayen",
      fr: "Svalbard et Jan Mayen",
      es: "Svalbard y Jan Mayen",
    },
  },
  {
    country: "Swaziland",
    countryCode: "SZ",
    translations: {
      fi: "Swazimaa",
      es: "Suazilandia",
    },
  },
  {
    country: "Sweden",
    countryCode: "SE",
    translations: {
      no: "Sverige",
      se: "Sverige",
      da: "Sverige",
      de: "Schweden",
      fi: "Ruotsi",
      fr: "Suède",
      es: "Suecia",
    },
  },
  {
    country: "Switzerland",
    countryCode: "CH",
    translations: {
      no: "Sveits",
      se: "Schweiz",
      da: "Schweiz",
      de: "Schweiz",
      fi: "Sveitsi",
      fr: "Suisse",
      es: "Suiza",
    },
  },
  {
    country: "Syrian Arab Republic",
    countryCode: "SY",
    translations: {
      no: "Syria",
      se: "Syrien",
      da: "Syrien",
      de: "Syrien",
      fi: "Syyria",
      fr: "Syrie",
      es: "Siria",
    },
  },
  {
    country: "Taiwan",
    countryCode: "TW",
    translations: {
      fr: "Taïwan",
      es: "Taiwán",
    },
  },
  {
    country: "Tajikistan",
    countryCode: "TJ",
    translations: {
      no: "Tadsjikistan",
      se: "Tadzjikistan",
      da: "Tadsjikistan",
      de: "Tadschikistan",
      fi: "Tadžikistan",
      fr: "Tadjikistan",
      es: "Tayikistán",
    },
  },
  {
    country: "Tanzania, United Republic of",
    countryCode: "TZ",
    translations: {
      no: "Tanzania",
      se: "Tanzania",
      da: "Tanzania",
      de: "Tansania",
      fi: "Tansania",
      fr: "Tanzanie",
      es: "Tanzania",
    },
  },
  {
    country: "Thailand",
    countryCode: "TH",
    translations: {
      fi: "Thaimaa",
      fr: "Thaïlande",
      es: "Tailandia",
    },
  },
  {
    country: "Timor-Leste",
    countryCode: "TL",
    translations: {
      no: "Øst-Timor",
      se: "Östtimor",
      da: "Østtimor",
      de: "Osttimor",
      fi: "Itä-Timor",
      fr: "Timor oriental",
      es: "Timor Oriental",
    },
  },
  {
    country: "Togo",
    countryCode: "TG",
    translations: {},
  },
  {
    country: "Tokelau",
    countryCode: "TK",
    translations: {
      se: "Tokelauöarna",
    },
  },
  {
    country: "Tonga",
    countryCode: "TO",
    translations: {},
  },
  {
    country: "Trinidad and Tobago",
    countryCode: "TT",
    translations: {
      no: "Trinidad og Tobago",
      se: "Trinidad och Tobago",
      da: "Trinidad og Tobago",
      de: "Trinidad und Tobago",
      fi: "Trinidad ja Tobago",
      fr: "Trinité-et-Tobago",
      es: "Trinidad y Tobago",
    },
  },
  {
    country: "Tunisia",
    countryCode: "TN",
    translations: {
      se: "Tunisien",
      da: "Tunesien",
      de: "Tunesien",
      fr: "Tunisie",
      es: "Túnez",
    },
  },
  {
    country: "Turkey",
    countryCode: "TR",
    translations: {
      no: "Tyrkia",
      se: "Turkiet",
      da: "Tyrkiet",
      de: "Türkei",
      fi: "Turkki",
      fr: "Turquie",
      es: "Turquía",
    },
  },
  {
    country: "Turkmenistan",
    countryCode: "TM",
    translations: {
      fr: "Turkménistan",
      es: "Turkmenistán",
    },
  },
  {
    country: "Turks and Caicos Islands",
    countryCode: "TC",
    translations: {
      no: "Turks- og Caicosøyene",
      se: "Turks- och Caicosöarna",
      da: "Turks- og Caicosøerne",
      de: "Turks- und Caicosinseln",
      fi: "Turks- ja Caicossaaret",
      fr: "Îles Turques-et-Caïques",
      es: "Islas Turcas y Caicos",
    },
  },
  {
    country: "Tuvalu",
    countryCode: "TV",
    translations: {},
  },
  {
    country: "Uganda",
    countryCode: "UG",
    translations: {
      fr: "Ouganda",
    },
  },
  {
    country: "Ukraine",
    countryCode: "UA",
    translations: {
      no: "Ukraina",
      se: "Ukraina",
      da: "Ukraine",
      de: "Ukraine",
      fi: "Ukraina",
      fr: "Ukraine",
      es: "Ucrania",
    },
  },
  {
    country: "United Arab Emirates",
    countryCode: "AE",
    translations: {
      no: "De forente arabiske emirater",
      se: "Förenade Arabemiraten",
      da: "De Forenede Arabiske Emirater",
      de: "Vereinigte Arabische Emirate",
      fi: "Yhdistyneet arabiemiirikunnat",
      fr: "Émirats arabes unis",
      es: "Emiratos Árabes Unidos",
    },
  },
  {
    country: "United Kingdom",
    countryCode: "GB",
    translations: {
      no: "Storbritannia",
      se: "Storbritannien",
      da: "Storbritannien",
      de: "Vereinigtes Königreich",
      fi: "Yhdistynyt kuningaskunta",
      fr: "Royaume-Uni",
      es: "Reino Unido",
    },
  },
  {
    country: "United States",
    countryCode: "US",
    translations: {
      no: "USA",
      se: "USA",
      da: "USA",
      de: "Vereinigte Staaten",
      fi: "Yhdysvallat",
      fr: "États-Unis",
      es: "Estados Unidos",
    },
  },
  {
    country: "United States Minor Outlying Islands",
    countryCode: "UM",
    translations: {
      no: "USAs ytre småøyer",
      se: "USA:s yttre öar",
      da: "USAs ydre småøer",
      de: "Amerikanisch-Ozeanien",
      fi: "Yhdysvaltain erillissaaret",
      fr: "Îles mineures éloignées des États-Unis",
      es: "Islas Ultramarinas Menores de Estados Unidos",
    },
  },
  {
    country: "Uruguay",
    countryCode: "UY",
    translations: {},
  },
  {
    country: "Uzbekistan",
    countryCode: "UZ",
    translations: {
      fr: "Ouzbékistan",
      es: "Uzbekistán",
    },
  },
  {
    country: "Vanuatu",
    countryCode: "VU",
    translations: {},
  },
  {
    country: "Venezuela, Bolivarian Republic of",
    countryCode: "VE",
    translations: {
      no: "Venezuela",
      se: "Venezuela",
      da: "Venezuela",
      de: "Venezuela",
      fi: "Venezuela",
      fr: "Venezuela",
      es: "Venezuela",
    },
  },
  {
    country: "Vietnam",
    countryCode: "VN",
    translations: {
      fr: "Viêt Nam",
      es: "Vietnam",
    },
  },
  {
    country: "Virgin Islands, British",
    countryCode: "VG",
    translations: {
      no: "De britiske jomfruøyene",
      se: "Brittiska Jungfruöarna",
      da: "De Britiske Jomfruøer",
      de: "Britische Jungferninseln",
      fi: "Brittiläiset Neitsytsaaret",
      fr: "Îles Vierges britanniques",
      es: "Islas Vírgenes Británicas",
    },
  },
  {
    country: "Virgin Islands, U.S.",
    countryCode: "VI",
    translations: {
      no: "De amerikanske jomfruøyene",
      se: "Amerikanska Jungfruöarna",
      da: "De Amerikanske Jomfruøer",
      de: "Amerikanische Jungferninseln",
      fi: "Yhdysvaltain Neitsytsaaret",
      fr: "Îles Vierges des États-Unis",
      es: "Islas Vírgenes de los Estados Unidos",
    },
  },
  {
    country: "Wallis and Futuna",
    countryCode: "WF",
    translations: {
      no: "Wallis- og Futunaøyene",
      se: "Wallis- och Futunaöarna",
      da: "Wallis og Futuna",
      de: "Wallis und Futuna",
      fi: "Wallis ja Futuna",
      fr: "Wallis-et-Futuna",
      es: "Wallis y Futuna",
    },
  },
  {
    country: "Western Sahara",
    countryCode: "EH",
    translations: {
      no: "Vest-Sahara",
      se: "Västsahara",
      da: "Vestsahara",
      de: "Westsahara",
      fi: "Länsi-Sahara",
      fr: "Sahara occidental",
      es: "Sahara Occidental",
    },
  },
  {
    country: "Yemen",
    countryCode: "YE",
    translations: {
      no: "Jemen",
      se: "Jemen",
      de: "Jemen",
      fi: "Jemen",
      fr: "Yémen",
    },
  },
  {
    country: "Zambia",
    countryCode: "ZM",
    translations: {
      de: "Sambia",
      fi: "Sambia",
      fr: "Zambie",
    },
  },
  {
    country: "Zimbabwe",
    countryCode: "ZW",
    translations: {
      de: "Simbabwe",
      fi: "Zimbabwe",
      fr: "Zimbabwe",
    },
  },
];
