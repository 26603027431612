import Image from "next/image";
type Props = {
  tag: any;
  flexdir: any;
  desktop: boolean;
  urlFor: Function;
};
const Tag = ({ tag, flexdir, desktop = false, urlFor }: Props) => {
  return (
    <>
      {flexdir ? (
        <div className="flex flex-col w-full items-start justify-center pb-2">
          <Image
            src={
              desktop && tag.iconDesktop
                ? urlFor(tag.iconDesktop).size(48, 48).quality(45).url()
                : urlFor(tag.icon).size(48, 48).quality(45).url()
            }
            width={48}
            height={48}
            alt=""
          />
          <div className="flex pt-5">
            <p className="text-p-lg leading-p-lg font-bold">{tag.tagline}</p>
          </div>
        </div>
      ) : (
        <div className="flex w-full items-center justify-center pb-2 lg:justify-start">
          <Image
            src={
              desktop && tag.iconDesktop
                ? urlFor(tag.iconDesktop).size(18, 18).quality(45).url()
                : urlFor(tag.icon).size(18, 18).quality(45).url()
            }
            width={18}
            height={18}
            quality={45}
            alt=""
          />
          <div className="flex pl-4">
            <p className="text-p-lg font-semibold">{tag.tagline}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Tag;
