import { FiExternalLink, FiPhoneCall } from "react-icons/fi";
import { RiMapPinFill, RiMapPin2Fill } from "react-icons/ri";
import { Store } from "../../lib";
//lat and lng are required props even though they are not used
const Marker = ({
  onClick,
  showTooltip,
  onMouseOver,
  store,
  focusedLocation,
  onMouseOut,
  lat,
  lng,
}: Props) => {
  return (
    <div className="relative">
      <RiMapPin2Fill
        className={`absolute top-[50%] left-[50%] w-[25px] h-[25px] rounded-full select-none -translate-x-1/2 -translate-y-1/2 hover:z-[1] text-primary-1 
        ${onClick ? "cursor-pointer" : "cursor-default"}
        ${showTooltip ? "transition-transform scale-105 z-[0]" : "z-[1]"}`}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      />
      <div
        className={`absolute top-5 -right-32 bg-secondary-3 rounded-t-md rounded-br-md p-4 w-64 ${
          focusedLocation?._id === store._id && "border-2 border-primary-mono-2"
        } ${
          showTooltip ? "opacity-100 z-50" : "opacity-0 z-0"
        } md:w-96 md:-right-48`}
        key={store._id}
      >
        <h4 className="font-bold text-[20px] text-primary-mono-2">
          {store.name}
        </h4>
        <p className="text-[16px] leading-[24px]">{store.address || ""}</p>
        <p className="text-[16px] leading-[24px]">
          {store.postalCode || ""} {store.city || ""}
        </p>
        <p className="text-[16px] leading-[24px]">{store.country || ""}</p>
        {store.url && (
          <a href={store.url} className="underline">
            <span className="flex space-x-2 items-center mt-2">
              <FiExternalLink size={16} />
              <p className="text-[16px] leading-[24px]">Gå til nettsted</p>
            </span>
          </a>
        )}
        {store.phone && (
          <span className="flex space-x-2 items-center mt-2">
            <FiPhoneCall size={16} />
            <a href={`tel:${store.phone}`} className="underline text-[16px]">
              {store.phone}
            </a>
          </span>
        )}
      </div>
    </div>
  );
};

interface Props {
  onClick: () => void;
  lat: number;
  lng: number;
  showTooltip: boolean;
  store: Store;
  focusedLocation: Store | undefined;
  onMouseOver: () => void;
  onMouseOut: () => void;
}

export default Marker;
