import React from "react";
import Image from "next/image";
import { Image as ImageType } from "@lilbit/shared";
type Props = {
  boldText: string;
  titleTag: string;
  smallText: string;
  imageOnLeft: boolean;
  image: ImageType;
  urlFor: Function;
};
const AntiTextAndImage = (props: Props) => {
  const { boldText, smallText, image, imageOnLeft, titleTag, urlFor } = props;
  return (
    <div className="font-objektiv text-primary-2 bg-secondary-5 my-2 py-8">
      {imageOnLeft && (
        <div className="flex flex-col md:flex-row md:justify-start items-center">
          <div className="relative w-full md:w-1/2 h-[470px] sm:h-[550px] ">
            <Image
              layout="fill"
              src={urlFor(image).url()}
              className="rounded-3xl"
              objectFit="cover"
              alt={image.alt || ""}
            />
          </div>
          <div className="md:pl-8 md:w-1/2 pt-4 md:pt-0">
            {titleTag && (
              <div className="inline-block ml-6 mt-4 md:mt-0  mb-4 box-border max-w-xs px-4 py-2 border-2 rounded-xl border-primary-2 border-solid">
                <h2 className="text-xs leading-4 font-semibold">{titleTag}</h2>
              </div>
            )}
            <h3 className="pl-6 md:pl-0 text-center md:text-left font-extrabold font-p22 text-h3 leading-h3 lg:pr-0 pt-2 pb-6 lg:text-h2 lg:leading-h2 ">
              {boldText}
            </h3>
            <p className="mb-6 font-objektiv text-base md:text-[20px] md:leading-[28px] text-primary-1">
              {smallText}
            </p>
          </div>
        </div>
      )}
      {!imageOnLeft && (
        <div className="flex flex-col-reverse md:flex-row md:justify-start items-center">
          <div className="md:w-1/2 pt-4 md:pt-0 md:px-4">
            {titleTag && (
              <div className="inline-block ml-6 mt-4 md:mt-0  mb-4 box-border max-w-xs px-4 py-2 p-4 border-2 rounded-xl border-antiGreen border-solid">
                <h2 className="text-xs leading-4 font-semibold">{titleTag}</h2>
              </div>
            )}
            <h3 className=" font-p22 pl-8 md:pl-0 pr-10 text-center md:text-left font-extrabold text-h3 leading-h3 lg:pr-0 pt-2 pb-6 lg:text-h2 lg:leading-h2 ">
              {boldText}
            </h3>
            <p className="mb-6 font-objektiv text-base md:text-[20px] md:leading-[30px] text-primary-1">
              {smallText}
            </p>
          </div>
          <div className="relative w-full md:w-1/2 h-[470px] sm:h-[550px]">
            <Image
              layout="fill"
              src={urlFor(image).url()}
              className="rounded-3xl"
              objectFit="cover"
              alt={image.alt || ""}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AntiTextAndImage;
