import { locales } from "../lib/app-config";
import { Slug, SanityType } from "../lib/types";

export type Locale = typeof locales[number];
export type SanityLanguageIdentifier =
  typeof locales[number]["sanityLanguageIdentifier"];

export interface Translations {
  [key: string]: string;
}

export type SlugLocales = {
  [key in SanityLanguageIdentifier]: Slug;
};

export type Localization = {
  locale: Locale;
  slugs: SlugLocales;
};

export type LocaleString = SanityType & {
  [key in Locale["sanityLanguageIdentifier"]]: string;
};

const isSupportedAppLanguage = (
  tested: string
): tested is Locale["appLanguage"] => {
  return locales.some((locale) => locale["appLanguage"] === tested);
};

export default isSupportedAppLanguage;
