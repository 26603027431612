import { Image as ImageType } from "../../lib/types";
import Image from "next/image";
import { getPositionFromHotspot } from "../../lib/helpers";

interface Props {
  quoteText: string;
  icons: Array<{
    text: string;
    image: ImageType & {
      width?: number;
      height?: number;
      mobileWidth?: number;
      mobileHeight?: number;
    };
    _key: string;
    _type: string;
  }>;
  mainImage: ImageType;
  urlFor: Function;
  author: string;
}
const LocalizedIconsAndQuote = (props: Props) => {
  const { quoteText, icons, mainImage, urlFor, author } = props;
  return (
    <div className="relative w-full flex flex-col items-center justify-center h-[350px] lg:h-[550px]">
      <Image
        src={urlFor(mainImage, true).url()}
        quality={40}
        layout="fill"
        objectFit="cover"
        priority={true}
        alt={mainImage?.alt || ""}
        objectPosition={
          mainImage?.hotspot && getPositionFromHotspot(mainImage.hotspot)
        }
      />
      <div className="absolute w-full h-[350px] lg:h-[550px] bg-black/40" />
      <div className="absolute">
        <div className="flex flex-col h-full w-full justify-evenly">
          <div className="flex justify-evenly mx-4 sm:mx-12 md:mx-24 lg:mx-32">
            {icons?.map((item) => {
              const { text, image, _key } = item;
              const { width, height, mobileWidth, mobileHeight } = image;
              return (
                <div key={_key} className="relative flex items-center">
                  <div className="block md:hidden">
                    <Image
                      src={urlFor(image, true).quality(20).url()}
                      height={mobileHeight || 60}
                      width={mobileWidth || 75}
                      alt={image.alt || ""}
                      objectFit="contain"
                    />
                  </div>
                  <div className="hidden md:block">
                    <Image
                      src={urlFor(image, true).url()}
                      quality={30}
                      height={height || 65}
                      width={width || 120}
                      alt={image.alt || ""}
                      objectFit="contain"
                    />
                  </div>
                  {text && (
                    <p className="text-white pb-2 text-sm md:text-base text-center font-objektiv">
                      {text}
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {quoteText && (
          <p className="text-white font-p22 max-w-4xl font-semibold md:font-bold text-center text-sm px-4 md:text-2xl md:px-0">
            {quoteText}
          </p>
        )}
        {author && (
          <p className="text-white text-center text-sm italic">{author}</p>
        )}
      </div>
      )
    </div>
  );
};

export default LocalizedIconsAndQuote;
