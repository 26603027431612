const Carticon = ({
  color = "#fff",
  height,
  width,
}: {
  color?: string;
  height: number;
  width: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5h3l.5 2.5m0 0L6 15h10l3.5-7.5h-15ZM16 18a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0H6m0 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm7.5-17 2 2m0 0-2 2m2-2H8M6.5 15l-1.205 1.293c-.63.63-.184 1.707.707 1.707"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Carticon;
