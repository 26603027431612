import React from "react";
import Image from "next/image";
import { Image as ImageType } from "@lilbit/shared";
type Props = {
  boldText: string;
  normalText: string;
  smallText: string;
  imageOnLeft: boolean;
  image: ImageType;
  urlFor: Function;
};
const AntiTextAndVideo = (props: Props) => {
  const { boldText, smallText, image, imageOnLeft, normalText, urlFor } = props;
  return (
    <div className="font-objektiv text-primary-2 bg-secondary-5   m-5 md:mx-4 py-8">
      {imageOnLeft && (
        <div className="flex flex-col md:flex-row md:justify-start ">
          <div className="flex">
            <Image
              width={300}
              height={300}
              src={urlFor(image).url()}
              objectFit="contain"
              alt={image.alt || ""}
            />
          </div>
          <div className="md:w-8/12 md:pl-24  md:mt-20">
            <h3 className="flex  pl-6 md:pl-none justify-center text-center font-extrabold text-h1 leading-h1 lg:pr-0 pt-2 pb-6 lg:text-3xl lg:leading-h2 ">
              {boldText}
            </h3>
            <h2 className="text-xs leading-4 font-semibold flex justify-center items-center text-center">
              {normalText}
            </h2>
            <h4 className="lg:pl-48 w-full lg:w-4/6 leading-5 text-xs md:leading-4 xl:leading-5 font-normal flex text-center">
              {smallText}
            </h4>
          </div>
        </div>
      )}
      {!imageOnLeft && (
        <div className="flex flex-col-reverse md:flex-row md:justify-start">
          <div className="">
            <div className="inline-block ml-6 mt-4 md:mt-0  mb-4 box-border max-w-xs px-4 py-2 p-4 border-2 rounded-xl border-antiGreen border-solid">
              <h2 className="text-xs leading-4 font-semibold">{normalText}</h2>
            </div>
            <h3 className="w-11/12  pl-6 md:pl-none text-center md:text-left font-extrabold text-h1 leading-h1 lg:pr-0 pt-2 pb-6 lg:text-h1-desktop lg:leading-h1-desktop ">
              {boldText}
            </h3>
            <h4 className="w-11/12 md:w-10/12 pl-6 md:pl-none leading-h6-desktop text-h5 md:leading-6 xl:leading-7 font-normal">
              {smallText}
            </h4>
          </div>
          <div>
            <Image
              width={1000}
              height={1000}
              src={urlFor(image).url()}
              objectFit="contain"
              alt={image.alt || ""}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AntiTextAndVideo;
