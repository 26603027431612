import Image from "next/image";
import { Image as ImageType, SanityDocument } from "../../lib/types";
import BlockContent from "@sanity/block-content-to-react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useEffect, useState } from "react";
import { useLocale } from "../../translations/getLocale";
import useWindowSize from "../../hooks/useWindowSize";
import { getPositionFromHotspot, shuffle } from "../../lib/helpers";

interface Props {
  employees: Array<
    SanityDocument & {
      name: string;
      position: string;
      employeeImage: ImageType;
    }
  >;
  header: string;
  body: any;
  title: string;
  urlFor: Function;
}
const LocalizedSectionfive = (props: Props) => {
  const { employees, urlFor, header, body, title } = props;
  const locale = useLocale().urlPrefix;
  const { width } = useWindowSize();
  const [firstEmployee, setFirstEmployee] = useState(employees[0]);
  const [employeeIndex, setEmployeeIndex] = useState(0);
  const [twoRandomEmployees, setTwoRandomEmployees] = useState([]);
  const [mobileArray, _] = useState([...employees]);

  useEffect(() => {
    let employeesArray = employees;
    shuffle(employeesArray);
    const filteredArr = [];
    do {
      filteredArr.push(employeesArray.pop());
    } while (employeesArray.length > 0 && filteredArr.length < 2);
    setTwoRandomEmployees(filteredArr);
    return () => {
      do {
        employeesArray.push(filteredArr.pop());
      } while (filteredArr.length > 0);
    };
  }, [employees]);

  useEffect(() => {
    if (width > 768) {
      setFirstEmployee(employees[employeeIndex]);
    } else {
      setFirstEmployee(mobileArray[employeeIndex]);
    }
  }, [employeeIndex, width, employees, mobileArray]);

  const handleNext = () => {
    if (width > 768) setEmployeeIndex((employeeIndex + 1) % employees.length);
    else setEmployeeIndex((employeeIndex + 1) % mobileArray.length);
  };

  const handlePrev = () => {
    if (width > 768) {
      if (employeeIndex === 0) {
        setEmployeeIndex(employees.length - 1);
      } else {
        setEmployeeIndex(employeeIndex - 1);
      }
    } else {
      if (employeeIndex === 0) {
        setEmployeeIndex(mobileArray.length - 1);
      } else {
        setEmployeeIndex(employeeIndex - 1);
      }
    }
  };

  return (
    <div className="w-full mt-8 font-main transition-all lg:px-12">
      <div className="flex relative justify-between items-center w-full h-[350px] sm:h-[500px] sm:space-x-4 lg:space-x-0 lg:h-[700px]">
        <div className="lg:-left-10 lg:absolute w-10 h-full flex items-center justify-center removeHighlight transform hover:scale-110 hover:cursor-pointer duration-300">
          <FiChevronLeft
            size={30}
            color="#1c3054"
            onClick={() => handlePrev()}
          />
        </div>
        {firstEmployee && (
          <div className="relative h-full w-full md:w-[60%]">
            <Image
              src={urlFor(firstEmployee?.employeeImage).quality(40).url()}
              layout="fill"
              objectFit="cover"
              className={`rounded-b-[50px] rounded-tr-[50px]`}
              alt="lilbit employee"
              objectPosition={
                firstEmployee.employeeImage?.hotspot &&
                getPositionFromHotspot(firstEmployee.employeeImage.hotspot)
              }
            />
            <div className="absolute w-full flex flex-col items-center justify-center bottom-8">
              <h2 className="text-white text-shadow font-semibold font-main text-[22px] leading-[28px]">
                {firstEmployee?.name}
              </h2>
              {firstEmployee?.position[locale] && (
                <h3 className="text-white text-shadow text-[22px] leading-[28px] font-main">
                  {firstEmployee?.position[locale]}
                </h3>
              )}
            </div>
          </div>
        )}
        <div className="hidden flex-col h-full space-y-6 md:flex w-[38%]">
          {twoRandomEmployees?.length > 0 &&
            twoRandomEmployees[0] &&
            twoRandomEmployees.map((employee) => {
              return (
                <div className="relative h-1/2 w-full" key={employee._id}>
                  <Image
                    src={urlFor(employee.employeeImage).quality(40).url()}
                    layout="fill"
                    objectFit="cover"
                    className="rounded-b-[50px] rounded-tr-[50px]"
                    alt="lilbit employee"
                    objectPosition={
                      employee.employeeImage?.hotspot &&
                      getPositionFromHotspot(employee.employeeImage.hotspot)
                    }
                  />
                  <div className="absolute w-full flex flex-col items-center justify-center bottom-8">
                    <h2 className="text-white text-shadow font-semibold text-[22px] leading-[28px]">
                      {employee.name}
                    </h2>
                    {employee.position[locale] && (
                      <h3 className="text-white text-shadow text-[22px] leading-[28px]">
                        {employee.position[locale]}
                      </h3>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="lg:-right-10 lg:absolute sm:w-10 h-full removeHighlight flex items-center justify-center transform hover:scale-110 hover:cursor-pointer duration-300">
          <FiChevronRight
            size={30}
            color="#1c3054"
            onClick={() => handleNext()}
          />
        </div>
      </div>

      <div className="flex flex-col items-center justify-center mt-12 w-full">
        <div className="w-full px-2 lg:w-2/3 flex flex-col items-center justify-center">
          <h2 className="text-xl leading-[50px] font-p22 text-center mb-6 md:text-4xl">
            {header}
          </h2>
          <BlockContent
            blocks={body}
            projectId={process.env.NEXT_PUBLIC_SANITY_PROJECT_ID}
            dataset={process.env.NEXT_PUBLIC_SANITY_DATASET}
            serializers={{
              types: {
                block: (props) => {
                  return (
                    <p className="mb-6 font-objektiv text-base text-lilbit-blue text-center self-center lg:w-full md:px-24 md:text-lg lg:px-0">
                      {props.children}
                    </p>
                  );
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LocalizedSectionfive;
