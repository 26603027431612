import dynamic from "next/dynamic";
import ArrowRight from "../svgs/arrowright";
import { useLocale } from "@lilbit/shared";
interface Props {
  title: string;
  pdf_title: string;
  video_ref: any;
}

const CustomMuxPlayer = dynamic(() => import("../customMuxPlayer"));

const SectionThitry = (props: Props) => {
  const { video_ref, pdf_title, title } = props;
  const locale = useLocale().urlPrefix;
  if (video_ref?.asset?.thumbTime) {;
    video_ref.asset.thumbTime = 0.01;
  }
  return (
    <div className={`w-full mb-8 `}>
      {video_ref?.asset ? (
        <div>
          <h2 className="my-4 font-p22 text-[32px] leading-[42px] text-primary-1 text-center">
            {title}
          </h2>
          <CustomMuxPlayer
            video={video_ref}
            autoload={true}
            autoplay={false}
            showControls={true}
            muted={false}
            loop={false}
          />
          {pdf_title && (
            <div>
              <h2 className="my-4 font-p22 text-[32px] leading-[42px] text-primary-1 text-center mt-6">
                {pdf_title}
              </h2>
              <a
                href="/files/Lildog_bruksanvisning.pdf"
                target="_blank"
                rel="noreferrer"
                className="hover:underline hover:cursor-pointer focus:outline-none"
              >
                <div className="relative flex items-start text-primary-1 font-objetiv">
                  <h4 className="flex-1 text-[30px] text-lilbit-blue font-normal leading-[30px] font-objektiv">
                    {locale == "no" ? "Brukermanual" : "User manual"}
                  </h4>
                  <div className="">
                    <ArrowRight />
                  </div>
                </div>
              </a>
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default SectionThitry;
