import { Image as ImageType } from "../../lib/types";
import Image from "next/image";
import { getPositionFromHotspot } from "../../lib";

interface Props {
  firstText: any;
  secondText: any;
  image: ImageType;
  SimpleBlockContent: any;
  urlFor: Function;
}
const LocalizedImageAndTwoTexts = (props: Props) => {
  const { firstText, secondText, image, urlFor, SimpleBlockContent } = props;

  return (
    <div className="relative w-full flex flex-col items-center justify-center pb-4 sm:pb-6 md:pb-8">
      <div className="flex flex-col h-full w-full justify-evenly">
        <div className="relative mt-4">
          <div className="flex items-center justify-center relative w-full h-[500px] 2xl:h-[650px]">
            {image && (
              <Image
                src={urlFor(image, true).url()}
                objectFit="cover"
                layout="fill"
                alt={image.alt || ""}
                objectPosition={
                  image?.hotspot && getPositionFromHotspot(image.hotspot)
                }
              />
            )}
            <div className="absolute w-full bg-black/20 h-[500px] 2xl:h-[650px]" />
            <div className="absolute text-white flex flex-col items-center justify-center max-w-5xl">
              {firstText && (
                <SimpleBlockContent
                  blocks={firstText}
                  className="py-2 font-p22 font-medium text-center text-xl lg:text-3xl"
                />
              )}
              {secondText && (
                <SimpleBlockContent
                  blocks={secondText}
                  className="py-2 font-objektiv font-medium text-center text-base lg:text-2xl"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalizedImageAndTwoTexts;
