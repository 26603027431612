import React, { useEffect, useRef } from "react";
import MuxPlayer from "@mux/mux-player-react";
import { BiExit } from "react-icons/bi";
interface Props {
  show: boolean;
  playbackId: string;
  autoplay: boolean;
  onClose: () => void;
}
const VideoModal = (props: Props) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const closeOnEscapeKeyDown = (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        props.onClose();
      }
    };
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, [props]);

  const handleOutsideClick = (e: Event) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  if (!props.show) {
    return null;
  }

  return (
    <div className="z-20 fixed inset-0 items-center content-center flex flex-col left-0 top-40 lg:top-20 right-0 bottom-0 w-screen h-screen  ">
      <div className="w-3/4 sm:w-2/3 md:w-1/3 lg:w-1/4 px-4 py-4">
        <button
          type="button"
          className="relative top-8 z-30 left-0 p-2  bg-white fill-primary-4 rounded-br-lg  opacity-70 hover:opacity-100"
          onClick={props.onClose}
        >
          <BiExit className="fill-primary-1" />
        </button>
        <div ref={containerRef}>
          {props.playbackId !== "" ? (
            <MuxPlayer
              streamType="on-demand"
              playbackId={props.playbackId}
              autoPlay={props.autoplay}
              onEnded={props.onClose}
              style={{
                aspectRatio: "9 / 16",
              }}
            />
          ) : (
            <p>Video could not load. Try again</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
