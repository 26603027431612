import Image from "next/image";
import { GlobalConfig, Image as ImageType, Product } from "@lilbit/shared";
import { FaDog } from "react-icons/fa";

type Props = {
  points: { text: string; _key: string; title: string }[];
  image: ImageType;
  header: string;
  subHeader: string;
  subText: string;
  buyButtonText: string;
  config: GlobalConfig;
  mainProduct: Product;
  urlFor: Function;
};

const LocalizedThreePointOverview = (props: Props) => {
  const { image, header, points, subText, urlFor, subHeader } = props;
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-primary-2">
                {header}
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {subHeader}
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">{subText}</p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {points.map((feature) => (
                  <div key={feature._key} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <FaDog className="absolute left-1 top-1 h-5 w-5 text-primary-mono-2" />
                      {feature.title}
                    </dt>{" "}
                    <dd className="inline">{feature.text}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <Image
            src={urlFor(image).url()}
            width={1171}
            height={885}
            objectFit="contain"
          />
        </div>
      </div>
    </div>
  );
};

export default LocalizedThreePointOverview;
