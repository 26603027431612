import Image from "next/image";
interface Props {
  title: string;
  author: string;
  rating: number;
  image: string;
}
const Testimonial = (props: Props) => {
  const { title, author, rating, image } = props;
  if (title === undefined || title === null) return null;

  return (
    <div className=" lg:px-4 md:px-8 px-4 md:py-8 lg:py-4 py-4 items-center bg-primary-3 grid grid-cols-6 rounded-md outline outline-1 outline-slate-300   ">
      {image !== undefined && image !== null && (
        <div className="col-span-2 h-20 w-20 sm:h-28 sm:w-28 md:h-32 md:w-32 md:pt-0">
          <Image
            className="rounded-full"
            src={image}
            alt="dog with lildog outside"
            height={128}
            width={128}
            loading="lazy"
            objectFit="fill"
          />
        </div>
      )}
      <div className="col-span-4 items-left flex flex-col space-y-2 md:space-y-3 lg:space-y-4 pr-16 ">
        <div className="italic font-semibold text-sm md:text-base top-1 text-primary-1">
          {title}
        </div>
        <div className=" text-sm lg:text-base font-normal text-primary-1 left-2 ">
          - {author}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
