import { useEffect, useRef, useState } from "react";
import { BlogPostProps, Content, similarity, useLocale } from "@lilbit/shared";
import Link from "next/link";
import { AiOutlineFileSearch } from "react-icons/ai";
import { useRouter } from "next/router";
type ContentType = Array<Content & { _ref: string; blogPost: BlogPostProps }>;
interface Props {
  content: ContentType;
}
const SearchBar = ({ content }: Props) => {
  const [searchWord, setSearchWord] = useState("");
  const [sortedContent, setSortedContent] = useState<ContentType>([]);
  const [showResults, setShowResults] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [sentFromMouseEvent, setSentFromMouseEvent] = useState(false);
  const urlPrefix = useLocale().urlPrefix;
  const router = useRouter();
  const myRefs = useRef<Array<HTMLAnchorElement>>([]);

  useEffect(() => {
    let temp: ContentType;
    if (searchWord) {
      temp = [...content].filter((ref) =>
        ref.blogPost.title.toLowerCase().includes(searchWord.toLowerCase())
      );
      setSortedContent(temp);
    } else {
      temp = [...content];
      temp.sort((a, b) => a.blogPost?.title?.localeCompare(b.blogPost?.title));
      setSortedContent(temp);
    }
  }, [searchWord, content, similarity]);

  useEffect(() => {
    if (
      myRefs?.current.length > 0 &&
      myRefs.current[selectedIndex] &&
      !sentFromMouseEvent
    ) {
      myRefs.current[selectedIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedIndex, myRefs, sentFromMouseEvent]);

  // Added to prevent render of searchbar when content contains blogposts that have not been translated yet
  if (content.filter((c) => c.blogPost.title === undefined).length > 0)
    return <></>;

  return (
    <div className="flex items-center justify-center mt-10 lg:mt-0">
      <div className="w-4/5 md:w-1/2 lg:w-1/3 relative lg:ml-1 mb-12">
        <div className="flex items-center relative">
          <input
            onChange={(e) => {
              setSearchWord(e.target.value);
              setSelectedIndex(0);
            }}
            placeholder={translations.search_bar_placeholder[urlPrefix]}
            className={`${
              showResults && sortedContent.length > 0 && "rounded-b-none"
            }`}
            onFocus={() => {
              setShowResults(true);
              setSelectedIndex(0);
            }}
            onBlur={() => setShowResults(false)}
            value={searchWord}
            onKeyDown={(e) => {
              if (
                e.code === "ArrowDown" &&
                selectedIndex < sortedContent.length - 1
              ) {
                setSentFromMouseEvent(false);
                setSelectedIndex(selectedIndex + 1);
              } else if (e.code === "ArrowUp" && selectedIndex > 0) {
                setSentFromMouseEvent(false);
                setSelectedIndex(selectedIndex - 1);
              } else if (e.code === "Enter") {
                const currentPost = sortedContent[selectedIndex].blogPost;
                router.push(
                  `/${urlPrefix}/${currentPost.category}/${currentPost.slug.current}`
                );
              }
            }}
          />
          <AiOutlineFileSearch className="absolute right-2" size={20} />
        </div>
        {sortedContent.length > 0 && showResults && (
          <div className="absolute overflow-auto max-h-52 z-50 bg-white flex flex-col divide-y w-full rounded-b border-2 border-t-0 border-primary-1 pt-1">
            {sortedContent.map((a, i) => {
              const {
                blogPost: { title },
              } = a;
              const test = title
                .toLowerCase()
                .indexOf(searchWord.toLowerCase());
              const first = title.slice(0, test);
              const bold = title.slice(test, test + searchWord.length);
              const last = title.slice(test + searchWord.length);
              const isSelected = selectedIndex === i;
              return (
                <Link
                  key={a._key}
                  href={{
                    pathname: "/[lang]/[category]/[slug]",
                  }}
                  as={`/${urlPrefix}/${a.blogPost.category}/${a.blogPost.slug.current}`}
                >
                  <a
                    id={`${i}`}
                    ref={(el) => (myRefs.current[i] = el)}
                    onMouseMove={(e) => {
                      setSentFromMouseEvent(true);
                      setSelectedIndex(i);
                    }}
                    onMouseDown={(e) => e.preventDefault()}
                    className={`py-2 ${
                      isSelected && "bg-primary-3"
                    } flex items-center space-x-4 mx-2 px-2 rounded-sm`}
                  >
                    {test >= 0 ? (
                      <span>
                        {first}
                        <b>{bold}</b>
                        {last}
                      </span>
                    ) : (
                      <span>{title}</span>
                    )}
                  </a>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;

const translations = {
  search_bar_placeholder: {
    en: "Search",
    no: "Søk",
    se: "Sök",
    da: "Søg",
    fi: "Haku",
    de: "Suche",
    fr: "Chercher",
    es: "Buscar",
  },
};
