import { Image as ImageType } from "../../lib";
import Image from "next/image";
import { useLocale } from "../../translations";
import GooglePlay from "../svgs/GooglePlay";
import AppStore from "../svgs/AppStore";
import Link from "next/link";

interface Props {
  appImageOne: ImageType;
  appImageTwo: ImageType;
  googlePlayLink: string;
  appStoreLink: string;
  urlFor: any;
}

const LocalizedLildogHotelAppDownload = (props: Props) => {
  const { appImageOne, appImageTwo, googlePlayLink, appStoreLink, urlFor } =
    props;
  const urlPrefix = useLocale().urlPrefix;
  return (
    <div className="w-screen h-fit bg-whit flex flex-col md:flex-row py-[12px] md:space-x-[48px] md:py-[48px] justify-center items-center text-center">
      <div
        id="Google Play Link and Apple Play Link Div"
        className="flex flex-col justify-center items-center space-y-4"
      >
        <p className=" text-2xl md:text-[30px] md:leading-[48px] font-p22 w-[240px] md:w-[460px]">
          {translations.title[urlPrefix]
            ? translations.title[urlPrefix]
            : translations.title.en}
        </p>
        <div
          id="Big screen App Links"
          className="hidden md:flex flex-row space-x-[4px] md:space-x-[48px]"
        >
          <div className="hover:scale-100 transition-transform duration-300">
            <a href={googlePlayLink}>
              <GooglePlay width={"200px"} height={"200px"} />
            </a>
          </div>
          <div className="hover:scale-100 transition-transform duration-300">
            <a href={appStoreLink}>
              <AppStore width={"200px"} height={"200px"} />
            </a>
          </div>
        </div>
        <div
          id="Small Screen App Links"
          className="flex md:hidden flex-row space-x-[12px] md:space-x-[48px]"
        >
          <div className="hover:scale-100 transition-transform duration-300">
            <a href={googlePlayLink}>
              <GooglePlay width={"140px"} height={"140px"} />
            </a>
          </div>
          <div className="hover:scale-100 transition-transform duration-300">
            <a href={appStoreLink}>
              <AppStore width={"140px"} height={"140px"} />
            </a>
          </div>
        </div>
      </div>
      <div
        id="Images Div Large"
        className="hidden md:flex flex-row justify-center space-x-2 md:space-x-[48px] "
      >
        <div>
          <Image
            src={urlFor(appImageOne).url()}
            alt="Lildog Hotel App Image One"
            width={188}
            height={382}
          />
        </div>
        <div>
          <Image
            src={urlFor(appImageTwo).url()}
            alt="Lildog Hotel App Image One"
            width={188}
            height={382}
          />
        </div>
      </div>
      <div
        id="Images Div Small"
        className="flex md:hidden flex-row justify-center space-x-2 md:space-x-[48px] "
      >
        <div>
          <Image
            src={urlFor(appImageOne).url()}
            alt="Lildog Hotel App Image One"
            width={120}
            height={260}
          />
        </div>
        <div>
          <Image
            src={urlFor(appImageTwo).url()}
            alt="Lildog Hotel App Image One"
            width={120}
            height={260}
          />
        </div>
      </div>
    </div>
  );
};

const translations = {
  title: {
    en: "Download the Lildog Hotel App to get started",
    no: "Last ned Lildog Hotel Appen for å prøve",
    se: "Ladda ner Lildog Hotel Appen för att komma igång",
  },
};

export default LocalizedLildogHotelAppDownload;
