import Image from "next/image";
import { getPositionFromHotspot } from "../../lib";
import { Image as ImageType } from "../../lib";
import Link from "next/link";
import { useLocale } from "../../translations";

interface Props {
  title: string;
  header: string;
  subheader: string;
  backgroundImage: ImageType;
  textImage: ImageType;
  description: any;
  urlFor: any;
  SimpleBlockContent: any;
}

const LocalizedInfluenser = (props: Props) => {
  const {
    title,
    header,
    subheader,
    backgroundImage,
    textImage,
    description,
    urlFor,
    SimpleBlockContent,
  } = props;

  const urlPrefix = useLocale().urlPrefix;

  return (
    <div>
      <div className="absolute w-full h-[500px]">
        {backgroundImage && (
          <Image
            src={urlFor(backgroundImage).quality(45).url()}
            alt="background"
            layout="fill"
            objectFit="cover"
            objectPosition={
              backgroundImage.hotspot &&
              getPositionFromHotspot(backgroundImage.hotspot)
            }
          />
        )}
        <div className="absolute inset-0 bg-gradient-to-t from-primary-mono-1/80 to-transparent" />
      </div>
      <div className="relative flex flex-col h-[500px] mx-auto max-w-7xl text-center justify-center ">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-base font-semibold leading-7 text-white">
            {title}
          </h2>
          <div className="flex flex-col my-0 ">
            <p className="mt-2 text-4xl font-bold tracking-tight font-p22 text-primary-mono-4 sm:text-5xl">
              {header}
            </p>
            <p className="mt-2  font-bold tracking-tight text-white text-3xl sm:text-4xl ">
              {subheader}
            </p>
          </div>
        </div>
      </div>
      <div className="relative flow-root font-default bg-white  ring-1 ring-gray-200  m-8 rounded-2xl h-fit justify-center -translate-y-28">
        <div className="mx-auto w-full text-lg leading-8 text-black">
          <div className="relative flex font-default text-center justify-center">
            <div className="flex-col text-center justify-center items-center max-w-2xl text-lg leading-8 text-black my-8 space-y-4">
              <h1 className="font-p22 text-3xl lg:text-5xl pb-8">
                {translations.howItWorks[urlPrefix]
                  ? translations.howItWorks[urlPrefix]
                  : translations.howItWorks.en}
              </h1>
              <div className="hidden lg:grid grid-cols-3 gap-x-8 max-w-4/5">
                <div className="flex justify-center items-center">
                  <div className="flex justify-center text-center items-center h-12 w-12 rounded-full bg-primary-1">
                    <p className="text-white font-bold">1</p>
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <div className="flex justify-center text-center items-center h-12 w-12 rounded-full bg-primary-1">
                    <p className="text-white font-bold">2</p>
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <div className="flex justify-center text-center items-center h-12 w-12 rounded-full bg-primary-1">
                    <p className=" text-white font-bold">3</p>
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <p className="text-lg font-bold">
                    {translations.register[urlPrefix]
                      ? translations.register[urlPrefix]
                      : translations.register.en}
                  </p>
                </div>
                <div className="flex justify-center items-center">
                  <p className="text-lg font-bold">
                    {translations.advertise[urlPrefix]
                      ? translations.advertise[urlPrefix]
                      : translations.advertise.en}
                  </p>
                </div>
                <div className="flex justify-center items-center">
                  <p className="text-lg font-bold">
                    {translations.earn[urlPrefix]
                      ? translations.earn[urlPrefix]
                      : translations.earn.en}
                  </p>
                </div>
                <div className="flex justify-center items-start text-center">
                  <div className="hover:underline text-sm font-default">
                    <Link
                      href="https://lilbit.targetcircle.com/signup"
                      className="hover:underline"
                    >
                      {translations.registerHere[urlPrefix]
                        ? translations.registerHere[urlPrefix]
                        : translations.registerHere.en}
                    </Link>
                  </div>
                </div>
                <div className="flex justify-center items-start text-center">
                  <p className="text-sm font-default w-2/3">
                    {translations.createEngagement[urlPrefix]
                      ? translations.createEngagement[urlPrefix]
                      : translations.createEngagement.en}
                  </p>
                </div>
                <div className="flex justify-center items-start text-center">
                  <p className="text-sm font-default">
                    {translations.getCommission[urlPrefix]
                      ? translations.getCommission[urlPrefix]
                      : translations.getCommission.en}
                  </p>
                </div>
              </div>
              <div className="lg:hidden flex flex-col justify-center items-center gap-y-1 max-w-4/5">
                <div className="flex justify-center text-center items-center h-10 w-10 rounded-full bg-primary-1">
                  <p className="text-white font-bold">1</p>
                </div>
                <p className="text-base font-bold">
                  {translations.register[urlPrefix]
                    ? translations.register[urlPrefix]
                    : translations.register.en}
                </p>
                <div className="underline text-sm font-default">
                  <Link
                    href="https://lilbit.targetcircle.com/signup"
                    className="hover:underline"
                  >
                    {translations.registerHere[urlPrefix]
                      ? translations.registerHere[urlPrefix]
                      : translations.registerHere.en}
                  </Link>
                </div>
                <div className="flex justify-center text-center items-center h-10 w-10 rounded-full bg-primary-1">
                  <p className="text-white font-bold">2</p>
                </div>
                <p className="text-base font-bold">
                  {translations.advertise[urlPrefix]
                    ? translations.advertise[urlPrefix]
                    : translations.advertise.en}
                </p>
                <p className="text-sm font-default w-2/3">
                  {translations.createEngagement[urlPrefix]
                    ? translations.createEngagement[urlPrefix]
                    : translations.createEngagement.en}
                </p>
                <div className="flex justify-center text-center items-center h-10 w-10 rounded-full bg-primary-1">
                  <p className=" text-white font-bold">3</p>
                </div>
                <p className="text-base font-bold">
                  {translations.earn[urlPrefix]
                    ? translations.earn[urlPrefix]
                    : translations.earn.en}
                </p>
                <p className="text-sm font-default">
                  {translations.getCommission[urlPrefix]
                    ? translations.getCommission[urlPrefix]
                    : translations.getCommission.en}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-screen flex justify-center items-center text-center mb-8 ">
        {textImage ? (
          <div className="relative flex flex-col items-center lg:flex-row font-default  py-8 px-4 w-5/6 lg:space-x-8">
            <div className=" w-full lg:w-1/2  lg:text-left text-lg leading-8 text-black">
              <SimpleBlockContent blocks={description} />
            </div>
            <div className=" w-full lg:w-1/2 h-fit overflow-hidden rounded-xl">
              <div>
                <Image
                  src={urlFor(textImage).quality(40).url()}
                  alt="background"
                  layout="responsive"
                  width={500}
                  height={300}
                  loading="lazy"
                  objectPosition={
                    textImage.hotspot &&
                    getPositionFromHotspot(textImage.hotspot)
                  }
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="relative flex items-center justify-center font-default w-5/6 lg:w-2/3">
            <div className=" w-full lg:w-4/5   text-lg leading-8 text-black">
              <SimpleBlockContent blocks={description} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const translations = {
  register: {
    en: "Register",
    no: "Registrer deg",
    se: "Registrera dig",
    de: "Registrieren",
    dk: "Registrer dig",
    fr: "S'inscrire",
    es: "Registrarse",
  },
  advertise: {
    en: "Advertise",
    no: "Promotere",
    se: "Reklamera",
    de: "Werbung",
    dk: "Reklamer",
    fr: "Faire de la publicité",
    es: "Publicitar",
  },
  earn: {
    en: "Earn",
    no: "Tjen Penger",
    se: "Tjäna",
    de: "Verdienen",
    dk: "Tjen Penger",
    fr: "Gagner",
    es: "Ganar",
  },
  howItWorks: {
    en: "How it works",
    no: "Hvordan fungerer det?",
    se: "Hur fungerar det?",
    de: "Wie es funktioniert",
    dk: "Hvordan fungerer det?",
    fr: "Comment ça marche",
    es: "Cómo funciona",
  },
  createEngagement: {
    en: "Create engagement around Lildog",
    no: "Skap engasjement rundt Lildog",
    se: "Skapa engagemang kring Lildog",
    de: "Erstellen Sie Engagement rund um Lildog",
    dk: "Skap engasjement rundt Lildog",
    fr: "Créer un engagement autour de Lildog",
    es: "Crear compromiso en torno a Lildog",
  },
  getCommission: {
    en: "Get commission for sales generated from your platform",
    no: "Få provisjon for salg generert fra din platform",
    se: "Få provision för försäljning som genereras från din plattform",
    de: "Erhalten Sie eine Provision für Verkäufe, die von Ihrer Plattform generiert werden",
    dk: "Få provisjon for salg generert fra din platform",
    fr: "Obtenez une commission pour les ventes générées depuis votre plateforme",
    es: "Obtenga comisión por las ventas generadas desde su plataforma",
  },
  registerHere: {
    en: "Register here to become an affiliate",
    no: "Registre deg her for å bli en affiliate",
    se: "Registrera dig här för att bli en affiliate",
    de: "Registrieren Sie sich hier, um ein Affiliate zu werden",
    dk: "Registre deg her for å bli en affiliate",
    fr: "Inscrivez-vous ici pour devenir affilié",
    es: "Regístrese aquí para convertirse en afiliado",
  },
};

export default LocalizedInfluenser;
