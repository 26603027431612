import { getPositionFromHotspot } from "../../lib/helpers";
import Image from "next/image";
import { Image as ImageType } from "../../lib";
import { useEffect, useState } from "react";

interface Props {
  title: string;
  header: string;
  subheader: string;
  backgroundImage: ImageType;
  agriaImage: ImageType;
  descriptionOne: any;
  descriptionTwo: any;
  descriptionKnowMore: any;
  urlFor: any;
  SimpleBlockContent: any;
}

const LocalizedAgria = (props: Props) => {
  const {
    title,
    header,
    subheader,
    backgroundImage,
    agriaImage,
    descriptionOne,
    descriptionTwo,
    descriptionKnowMore,
    urlFor,
    SimpleBlockContent,
  } = props;
  const [href, setHref] = useState("");
  const [dogOrCat, setDogOrCat] = useState("");
  const [dogOrCatEng, setDogOrCatEng] = useState("");
  useEffect(() => {
    if (typeof window !== "undefined") {
      setHref(
        window.location.href.includes("lilcat") ||
          window.location.href.includes("localhost:3001")
          ? "https://www.agria.no/katt/lilcat/"
          : "https://www.agria.no/hund/lildog/"
      );
      setDogOrCat(
        window.location.href.includes("lilcat") ||
          window.location.href.includes("localhost:3001")
          ? "katt"
          : "hund"
      );
      setDogOrCatEng(
        window.location.href.includes("lilcat") ||
          window.location.href.includes("localhost:3001")
          ? "cat"
          : "dog"
      );
    }
  }, []);

  return (
    <div>
      <div className="absolute w-full h-[500px]">
        <Image
          src={urlFor(backgroundImage).url()}
          alt="background"
          layout="fill"
          objectFit="cover"
          quality={40}
          objectPosition={
            backgroundImage.hotspot &&
            getPositionFromHotspot(backgroundImage.hotspot)
          }
        />
        <div className="absolute inset-0 bg-gradient-to-t from-primary-mono-1/80 to-transparent" />
      </div>
      <div className="relative flex flex-col h-[500px] mx-auto max-w-7xl text-center justify-center ">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-base font-semibold leading-7 hidden text-white">
            {title}
          </h2>
          <div className="flex flex-col text-center ">
            <p className="mt-2 text-xl sm:text-4xl font-bold tracking-tight font-p22 text-primary-mono-4 ">
              {header}
            </p>
            <p className="mt-2 text-xl sm:text-2xl mx-8 font-bold tracking-tight text-white ">
              {subheader}
            </p>
          </div>
        </div>
      </div>
      <div className="relative flex font-default">
        <div className="mx-2 md:mx-auto justify-center items-center md:items-center text-left max-w-2xl text-lg leading-8 text-black">
          <p className="text-4xl text-primary-1 font-p22 pt-6 text-center lg:text-left">
            Agria Dyreforsikring
          </p>
          <div className="mx-2 lg:mx-0">
            <SimpleBlockContent blocks={descriptionOne} />
          </div>
          <div className="w-full h-fit py-8 rounded-3xl lg:rounded-4xl lg:space-x-4  bg-primary-1 flex flex-col lg:flex-row justify-center items-center text-center lg:text-left lg:px-10 shadow-lg">
            <div className="flex-col text-primary-4 w-5/6 lg:w-7/12 space-y-2">
              <p className="text-xl lg:text-2xl font-p22">
                Få 10% på Agria Dyreforsikring
              </p>
              <p className="text-sm md:text-base lg:text-lg font-default">
                Som Lil{dogOrCatEng} kunde får du 10% rabatt på {dogOrCat}
                eforsikring hos Agria.{" "}
              </p>
              <div className="flex w-full items-center justify-center mt-4 pt-4">
                <div className="w-9/10 lg:w-3/4 py-2 px-2 lg:px-0 flex justify-center text-center rounded-2xl ring-2 ring-primary-3  hover:scale-105 transition-transform text-primary-3 cursor-pointer">
                  <a
                    className="text-xl"
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="font-p22">Skaff deg forsikring her</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="hidden lg:flex w-[300px] h-[200px] overflow-hidden rounded-3xl">
              <Image
                src={urlFor(agriaImage).quality(40).url()}
                alt="background"
                height={280}
                width={300}
                objectFit="cover"
                objectPosition={
                  agriaImage.hotspot &&
                  getPositionFromHotspot(agriaImage.hotspot)
                }
              />
            </div>
          </div>
          <div className="mx-2 lg:mx-2">
            <SimpleBlockContent blocks={descriptionTwo} />
            <p className="font-p22 text-3xl text-primary-1 pt-4">
              Vil du vite mer?
            </p>
            <SimpleBlockContent blocks={descriptionKnowMore} />
          </div>
          <div className="h-8"></div>
        </div>
      </div>
    </div>
  );
};

export default LocalizedAgria;
