import React from "react";
import Image from "next/image";
import Link from "next/link";
import { useLocale } from "@lilbit/shared";

const ErrorPage = ({
  errorMessage,
  headerMessage,
}: {
  errorMessage: string;
  headerMessage: string;
}) => {
  const locale = useLocale().urlPrefix;
  return (
    <div className="flex flex-col text-center justify-start items-center text-primary-mono-1 font-objektiv w-4/5 md:w-1/2 min-h-screen">
      <div className="mb-11">
        <Image
          src="/paymentChoice/checkoutError.svg"
          height="146"
          width="257"
          alt=""
        />
      </div>
      <p className="font-bold text-h5-desktop mb-5">{headerMessage}</p>
      <p className="text-lg mb-5">{errorMessage}</p>
      <Link
        href={{
          pathname: "/[lang]/[category]",
        }}
        as={`/${locale}/checkout`}
        passHref
      >
        <a className="buttonPress w-full rounded-full mb-5 bg-primary-mono-2 text-h6 h-12 text-primary-mono-4 font-medium flex items-center justify-center">
          {translations.try_again[locale]}
        </a>
      </Link>
      <div className="breaklineBorder w-full mb-14"></div>
      <p className="text-lg ">{translations.help[locale]}</p>
      <a href="tel:+4773440070" className="underline text-lg font-semibold">
        {translations.phone[locale]}
      </a>
    </div>
  );
};

export default ErrorPage;

const translations = {
  try_again: {
    en: "Try again",
    no: "Prøv på nytt",
    se: "Försök igen",
    da: "Prøv igen",
    fi: "Yritä uudelleen",
    de: "Versuchen Sie es erneut",
    fr: "Réessayer",
    es: "Inténtalo de nuevo",
  },
  phone: {
    en: "Call (+47) 73 44 00 70",
    no: "Ring (+47) 73 44 00 70",
    se: "Ring (+47) 73 44 00 70",
    da: "Ring (+47) 73 44 00 70",
    fi: "Soita (+47) 73 44 00 70",
    de: "Rufen Sie (+47) 73 44 00 70 an",
    fr: "Appelez (+47) 73 44 00 70",
    es: "Llame al (+47) 73 44 00 70",
  },
  help: {
    en: "Need more help?",
    no: "Trenger du mer hjelp?",
    se: "Behöver du mer hjälp?",
    da: "Brug for mere hjælp?",
    fi: "Tarvitsetko apua?",
    de: "Brauchen Sie mehr Hilfe?",
    fr: "Besoin de plus d'aide?",
    es: "¿Necesitas más ayuda?",
  },
};
