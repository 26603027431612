import Image from "next/image";
import { Image as ImageType } from "../../lib/types";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useState, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useLocale } from "@lilbit/shared";
import { useEffect } from "react";
import Link from "next/link";

interface Props {
  urlFor: Function;
  google_icon: ImageType;
  google_text: string;
  google_url: string;
  apple_icon: ImageType;
  apple_text: string;
  apple_url: string;
  cards: Array<{
    body?: string;
    bold_text?: string;
    norwegian_image: ImageType;
    english_image: ImageType;
    title?: string;
    _key: string;
    show_app_icons: boolean;
    _type: string;
  }>;
}
const SectionTwentySeven = ({
  google_icon,
  google_text,
  apple_icon,
  apple_text,
  google_url,
  apple_url,
  cards,
  urlFor,
}: Props) => {
  const locale = useLocale().urlPrefix;
  const [selected, setSelected] = useState(cards[0]);

  const changeSelected = () => {
    setSelected(cards[0]);
  };
  useEffect(() => {
    changeSelected();
  }, [locale]);

  const handleNext = () => {
    if (cards.indexOf(selected) === cards.length - 1) {
      setSelected(cards[0]);
    } else {
      setSelected(cards[cards.indexOf(selected) + 1]);
    }
  };

  const handlePrev = () => {
    if (cards.indexOf(selected) === 0) {
      setSelected(cards[cards.length - 1]);
    } else {
      setSelected(cards[cards.indexOf(selected) - 1]);
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div className="w-full my-8 shadow-bottom">
      <div className="flex relative justify-center items-center w-full">
        <div className="sm:hidden">
          <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
              <>
                <div className="relative">
                  <div className="inline-flex shadow-sm rounded-md divide-x divide-primary-4">
                    <div className="font-objektiv relative z-0 inline-flex shadow-sm rounded-md bg-primary-1 divide-x divide-primary-4 ">
                      <div className="relative inline-flex items-center  py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white min-w-[160px]">
                        <p className="ml-2.5 text-sm font-medium">
                          {selected?.title || ""}
                        </p>
                      </div>
                      <Listbox.Button
                        aria-label="Expand"
                        className="relative inline-flex items-center p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-1-hover focus:outline-none focus:z-10 focus:ring-1 focus:ring-offset-1 focus:ring-offset-white focus:ring-primary-1-hover"
                      >
                        <ChevronDownIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </Listbox.Button>
                    </div>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="origin-top-right absolute z-10 right-0 mt-2  rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {cards.map((option, i) => {
                        return (
                          <Listbox.Option
                            key={option._key}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-primary-1"
                                  : "text-primary-1",
                                "cursor-pointer select-none relative p-4 text-[16px] leading-[40px]"
                              )
                            }
                            value={option}
                          >
                            {({ selected }) => (
                              <div className="flex flex-col min-w-[165px]">
                                <div className="flex justify-between">
                                  <p
                                    className={
                                      selected ? "font-semibold" : "font-normal"
                                    }
                                  >
                                    {option?.title}
                                  </p>
                                </div>
                              </div>
                            )}
                          </Listbox.Option>
                        );
                      })}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
        <div className="hidden sm:block ">
          <nav
            className="relative z-0 rounded-lg shadow flex divide-x divide-primary-1 "
            aria-label="lildog instructions"
          >
            {cards?.map((card, cardIdx) => (
              <div
                key={card._key}
                className={classNames(
                  card === selected
                    ? "text-white bg-primary-1"
                    : "text-primary-1 hover:text-primary-2 bg-primary-3",
                  cardIdx === 0 ? "rounded-l-lg" : "",
                  cardIdx === cards.length - 1 ? "rounded-r-lg" : "",
                  "font-objektiv group relative min-w-[150px] hover:shadow-2xl md:min-w-[160px] lg:min-w-[200px] flex-auto overflow-hidden py-3 px-6 text-[18px] leading-[30px] text-center focus:z-10 grow hover:cursor-pointer"
                )}
                onClick={() => {
                  setSelected(card);
                }}
              >
                <span>{card.title || ""}</span>
              </div>
            ))}
          </nav>
        </div>
      </div>
      <div
        className="relative z-0 flex items-center w-full justify-between rounded-md "
        aria-label="Pagination"
      >
        <div className="justify-start mt-80 md:mt-0 transform w-10 hover:scale-y-125 hover:cursor-pointer duration-300 z-40">
          <FiChevronLeft
            size={30}
            color="#1c3054"
            onClick={() => handlePrev()}
          />
        </div>
        <div className="flex w-full justify-between flex-col md:flex-row sm:pt-8 sm:px-16 md:px-24 lg:px-32">
          <div className="justify-start md:w-1/2">
            <dl className=" font-objectiv text-primary-1 py-12">
              <dt className=" font-bold text-center md:text-left text-[24px] leading-[35px]">
                {selected?.bold_text || ""}
              </dt>
              <dd className="text[18px] text-center lg:text-left leading-[25px]">
                {selected?.body || ""}
              </dd>
            </dl>
            {selected?.show_app_icons && (
              <div className="flex items-center flex-col lg:flex-row mb-8 md:mb-0 lg:inline-flex">
                <Link href={google_url || "/"}>
                  <a className="flex mt-3 lg:mr-6 w-48 h-14 bg-black text-white rounded-lg items-center justify-center hover:cursor-pointer">
                    {google_icon && (
                      <div className="mr-3 h-[40px] w-[40px] relative py-2 ">
                        <Image
                          src={urlFor(google_icon).url()}
                          layout="fill"
                          className=""
                          objectFit="contain"
                          alt={google_icon.alt || ""}
                        />
                      </div>
                    )}
                    <div>
                      <div className="text-xs">{google_text}</div>
                      <div className="text-xl font-semibold font-sans -mt-1">
                        Google Play
                      </div>
                    </div>
                  </a>
                </Link>
                <Link href={apple_url || "/"}>
                  <a className="flex mt-3 w-48 h-14 bg-black text-white rounded-lg items-center justify-center hover:cursor-pointer">
                    {apple_icon && (
                      <div className="mr-3 relative h-[40px] w-[40px] justify-start">
                        <Image
                          src={urlFor(apple_icon).url()}
                          layout="fill"
                          className=" "
                          objectFit="contain"
                          alt={apple_icon.alt || ""}
                        />
                      </div>
                    )}
                    <div>
                      <div className="text-xs">{apple_text || ""}</div>
                      <div className="text-2xl font-semibold font-sans -mt-1">
                        App Store
                      </div>
                    </div>
                  </a>
                </Link>
              </div>
            )}
          </div>

          {(selected?.norwegian_image || selected?.english_image) && (
            <div className="md:w-1/2 -ml-12 md:-ml-0 relative justify-end">
              <div className="relative h-[450px] px-6">
                <div className="h-full w-full absolute z-10">
                  <Image
                    src={
                      locale === "en"
                        ? urlFor(selected?.english_image).url()
                        : urlFor(selected?.norwegian_image).url()
                    }
                    layout="fill"
                    className=" "
                    objectFit="contain"
                    alt={
                      locale === "en" && selected?.english_image?.alt
                        ? selected?.english_image.alt
                        : selected?.norwegian_image?.alt
                        ? selected?.norwegian_image?.alt
                        : ""
                    }
                    quality={40}
                  />
                </div>
                {/* <div className="h-[95%] ml-2 mt-3 md:mx-2 md:my-3 w-[96%] absolute z-30">
                  <Image
                    src={
                      locale === "en"
                        ? urlFor(selected.english_image).url()
                        : urlFor(selected.norwegian_image).url()
                    }
                    layout="fill"
                    className=""
                    objectFit="contain"
                    alt=""
                  />
                </div> */}
              </div>
            </div>
          )}
        </div>
        <div className="justify-end w-10 mt-80 md:mt-0 transform hover:scale-y-125 hover:cursor-pointer duration-300 z-40">
          <FiChevronRight
            size={30}
            color="#1c3054"
            onClick={() => handleNext()}
          />
        </div>
      </div>
    </div>
  );
};

export default SectionTwentySeven;
