import { useEffect, useState } from "react";
import { useLocale } from "../../translations";
import GoogleMap from "./googlemap";
import SearchBar from "./searchbar";
import { FiExternalLink, FiMap, FiPhoneCall } from "react-icons/fi";
import { Store } from "../../lib";

export type Geopoint = {
  lat: number;
  lng: number;
  alt?: number;
  _type: string;
};

const Storemapper = ({ stores }: { stores: Store[] }) => {
  const [center, setCenter] = useState(
    stores?.length ? stores[0]?.geopoint : { lat: 0, lng: 0, _type: "geopoint" }
  );
  const [focusedLocation, setFocusedLocation] = useState<Store | undefined>(
    undefined
  );
  const [sortedLocations, setSortedLocations] = useState<Store[]>(stores);
  const [userLocation, setUserLocation] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const handleLocationClick = (store: Store) => {
    setCenter(store.geopoint);
    setFocusedLocation(store);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const urlPrefix = useLocale().urlPrefix;

  const scrollToCard = (store: Store) => {
    const element = document.getElementById(store._id);
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const requestUserLocation = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userLocation) {
      setLoading(false);
      //setcenter to location with shortest distance to userLocation
      const distances = stores.map((store) => {
        return {
          store: store,
          distance: Math.sqrt(
            Math.pow(store.geopoint.lat - userLocation.lat, 2) +
              Math.pow(store.geopoint.lng - userLocation.lng, 2)
          ),
        };
      });
      distances.sort((a, b) => a.distance - b.distance);
      setCenter(distances[0].store.geopoint);
      setFocusedLocation(distances[0].store);
    }
  }, [userLocation]);

  if (!stores) return <></>;
  return (
    <div className="font-objektiv text-primary-mono-1 my-12 px-2">
      <GoogleMap
        zoomLevel={7}
        center={center}
        focusedLocation={focusedLocation}
        setFocusedLocation={setFocusedLocation}
        scrollToCard={scrollToCard}
        stores={stores}
      />
      <div>
        <h2 className="text-3xl text-primary-mono-2 font-bold text-center md:text-left">
          {translations.title[urlPrefix]}
        </h2>
        <div>
          <SearchBar
            content={stores}
            onClick={setCenter}
            setSortedLocations={setSortedLocations}
            requestUserLocation={requestUserLocation}
            userLocation={userLocation}
            loading={loading}
          />
        </div>
        <div className="grid grid-cols-1 gap-5 mb-5 md:grid-cols-2 lg:grid-cols-3">
          {sortedLocations?.map((store, _) => {
            return (
              <div
                id={store._id}
                className={`bg-secondary-3 rounded-t-md rounded-br-md p-4 flex flex-col ${
                  focusedLocation?._id === store._id &&
                  "border-2 border-primary-mono-2"
                }`}
                key={store._id}
              >
                <h4 className="font-bold text-[20px]">{store.name}</h4>
                <p className="text-[16px] leading-[24px]">
                  {store.address || ""}
                </p>
                <p className="text-[16px] leading-[24px]">
                  {store.postalCode} {store.city}
                </p>
                <p className="text-[16px] leading-[24px]">{store.country}</p>
                {store.phone && (
                  <span className="flex space-x-2 items-center mt-2">
                    <FiPhoneCall />
                    <a href={`tel:${store.phone}`} className="underline">
                      {store.phone}
                    </a>
                  </span>
                )}
                {store.url && (
                  <a href={store.url} className="underline">
                    <span className="flex space-x-2 items-center mt-2">
                      <FiExternalLink />
                      <p>{translations.goToWebsite[urlPrefix]}</p>
                    </span>
                  </a>
                )}
                <button
                  className="mt-2"
                  onClick={() => handleLocationClick(store)}
                >
                  <span className="flex space-x-2 items-center">
                    <FiMap />
                    <p className="underline">
                      {translations.showInMap[urlPrefix]}
                    </p>
                  </span>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const translations = {
  title: {
    en: "Find a store",
    no: "Finn utsalgssted",
    se: "Hitta en butik",
    da: "Find en butik",
    fi: "Etsi myymälä",
    de: "Finde ein Geschäft",
    fr: "Trouver un magasin",
    es: "Encontrar una tienda",
  },
  showInMap: {
    en: "Show in map",
    no: "Vis i kart",
    se: "Visa på kartan",
    da: "Vis på kort",
    fi: "Näytä kartalla",
    de: "Auf Karte anzeigen",
    fr: "Afficher sur la carte",
    es: "Mostrar en el mapa",
  },
  goToWebsite: {
    en: "Go to website",
    no: "Gå til nettsted",
    se: "Gå till webbplatsen",
    da: "Gå til hjemmeside",
    fi: "Siirry sivustolle",
    de: "Zur Website gehen",
    fr: "Aller sur le site",
    es: "Ir al sitio web",
  },
};

export default Storemapper;
