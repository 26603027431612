import React, { useState, useRef } from "react";
import Image from "next/image";

import VideoModal from "../../VideoModal";

import { BsFillPlayCircleFill } from "react-icons/bs";

import "swiper/css";
import "swiper/css/navigation";
import "./custom-swiper.css";

interface Props {
  reels: any;
  header: string;
}

const LocalizedSectionReels = (props: Props) => {
  const reels = props.reels;
  const header = props.header;

  const [showModal, setShowModal] = useState(false);
  const [playbackId, setPlaybackId] = useState("");

  const handleOnClick = (playbackId) => {
    setPlaybackId(playbackId);
    setShowModal(true);
  };

  if (!reels || reels.length < 2) {
    return null;
  }
  return (
    <div>
      <div className="h-auto w-full relative flex flex-col justify-center items-center bg-primary-4 overflow-x-scroll ">
        {header !== undefined && header !== "" && header !== null && (
          <p className="font-normal font-p22 text-xl md:text-2xl lg:text-4xl text-primary-1 justify-center text-center mt-3">
            {header}
          </p>
        )}
        <VideoModal
          show={showModal}
          onClose={() => setShowModal(false)}
          playbackId={playbackId}
          autoplay={true}
        />
        <div className="pt-12 pb-12 px-4 flex flex-row space-x-8 overflow-x-scroll">
          {reels?.map((reel, _) => (
            <div
              className="relative  bg-cover bg-no-repeat"
              key={reel.asset.playbackId}
            >
              <Image
                src={`https://image.mux.com/${reel.asset.playbackId}/thumbnail.png?time=0&height=200&width=200&fit_mode=smartcrop`}
                className="rounded-full"
                height={200}
                width={200}
                quality={50}
              />
              <div className="flex justify-center items-center absolute inset-0 ">
                <BsFillPlayCircleFill className="text-white w-8 h-8 md:w-10 md:h-10" />
              </div>
              <a onClick={() => handleOnClick(reel.asset.playbackId)}>
                <div className="absolute hover:cursor-pointer bottom-0 left-0 right-0 top-0 h-full w-full rounded-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)]  opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default LocalizedSectionReels;
