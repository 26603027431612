import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useRouter } from "next/router";
import { useLocale } from "../../translations";

interface Props {
  currentStep: number;
  setCurrentStep: (step: number) => void;
}

const Breadcrumbs = ({ currentStep, setCurrentStep }: Props) => {
  const urlPrefix = useLocale().urlPrefix;

  const pages = [
    { name: translations.information[urlPrefix], step: 0 },
    { name: translations.payment[urlPrefix], step: 1 },
  ];

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li key={pages[0].name}>
          <div className="flex items-center">
            <button
              onClick={() => setCurrentStep(0)}
              disabled={pages[0].step === currentStep}
              className={`text-sm font-medium hover:text-gray-700 ${
                pages[0].step === currentStep
                  ? "text-gray-900"
                  : "text-gray-500"
              }`}
              aria-current={pages[0].step === currentStep ? "page" : undefined}
            >
              {pages[0].name}
            </button>
          </div>
        </li>
        {pages.slice(1).map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRightIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <button
                disabled={page.step === currentStep || currentStep < page.step}
                onClick={() => setCurrentStep(page.step)}
                className={`ml-4 text-sm font-medium hover:text-gray-700 ${
                  page.step === currentStep ? "text-gray-900" : "text-gray-500"
                }`}
                aria-current={page.step === currentStep ? "page" : undefined}
              >
                {page.name}
              </button>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;

const translations = {
  information: {
    en: "Information",
    no: "Informasjon",
    se: "Information",
    da: "Information",
    fi: "Tieto",
    de: "Information",
    fr: "Information",
    es: "Información",
  },
  payment: {
    en: "Payment",
    no: "Betaling",
    se: "Betalning",
    da: "Betaling",
    fi: "Maksu",
    de: "Zahlung",
    fr: "Paiement",
    es: "Pago",
  },
};
