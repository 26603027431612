import Image from "next/image";
import { Content, Image as ImageType } from "../../lib/types";
import Link from "next/link";
import { useState } from "react";

interface Props {
  items: Array<
    Content & { header: string; icon: ImageType; text: string; href: string }
  >;
  urlFor: Function;
  SimpleBlockContent: any;
}
const SectionTwentyNine = ({ items, urlFor, SimpleBlockContent }: Props) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="font-objektiv bg-whtie grid auto-rows-auto grid-cols-1 gap-4 px-4 content-center my-6 max-w-6xl mx-auto sm:grid-cols-2 sm:gap-8 lg:gap-12 lg:grid-cols-3">
      {items.map((item) => {
        const { header, icon, text, href, _key } = item;
        if (href) {
          return (
            <Link href={href} key={_key}>
              <a
                className={`flex flex-col items-stretch xs:mx-10 sm:mx-0 text-primary-mono-1 bg-primary-mono-3 rounded-t-[50px] rounded-br-[50px] p-4 hover:scale-105 ${
                  loading ? "hover:cursor-wait" : "cursor-pointer"
                } `}
                onClick={() => {
                  setLoading(true);
                }}
              >
                <div className="h-[70px] w-[70px] self-center rounded-full bg-white p-3">
                  {icon && (
                    <Image
                      src={urlFor(icon).url()}
                      layout="responsive"
                      width={60}
                      height={60}
                      className=""
                      alt={header || ""}
                    />
                  )}
                </div>
                <h2 className="py-2 text-[20px] leading-[26px] sm:text-[26px] sm:leading-[32px] text-center font-bold">
                  {header}
                </h2>
                <div className="text-[16px] leading-[20px] sm:text-[18px] sm:leading-[25px] text-center">
                  {text}
                </div>
              </a>
            </Link>
          );
        } else
          return (
            <div
              className="flex flex-col items-stretch xs:mx-10 sm:mx-0 text-primary-mono-1 bg-primary-mono-3 rounded-t-[50px] rounded-br-[50px] p-4"
              key={_key}
            >
              <div className="h-[70px] w-[70px] self-center rounded-full bg-white p-3">
                <Image
                  src={urlFor(icon).url()}
                  layout="responsive"
                  width={60}
                  height={60}
                  className=""
                  alt={header || ""}
                />
              </div>
              <h2 className="py-2 text-[20px] leading-[26px] sm:text-[26px] sm:leading-[32px] text-center font-bold">
                {header}
              </h2>
              <div className="text-[16px] leading-[20px] sm:text-[18px] sm:leading-[25px] text-center">
                {text}
              </div>
            </div>
          );
      })}
    </div>
  );
};

export default SectionTwentyNine;
