import Image from "next/image";
import { BsStar, BsStarFill } from "react-icons/bs";

const ReviewCard = ({ review }) => {
  const userPhotoUrl = "" + review?.image;

  return (
    <div className="slide w-full max-w-[20rem]  h-[400px] shrink-0  shadow-md overflow-hidden rounded-sm mx-auto">
      <div className="relative w-full h-[200px]">
        <Image
          src={userPhotoUrl}
          layout="fill"
          sizes="20vh"
          alt="author"
          loading="lazy"
        />
      </div>

      <div className="relative items-center justify-center flex">
        <div className="flex space-x-1 bg-white shadow-md w-min py-3 px-4 rounded-md absolute -top-4">
          {[...Array(5)].map((_, i) =>
            i < review?.rating ? (
              <BsStarFill key={i} color="#b5cf8f" />
            ) : (
              <BsStar key={i} />
            )
          )}
        </div>
        <div className="p-2 mt-12 text-center space-y-1">
          <h6 className="font-semibold">{review?.author}</h6>
          <p className="text-[12px]">{review?.title}</p>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
