import { useSitename } from "../../context/SitenameContext";
import { useLocale } from "../../translations";
const LocalizedKlaviyoEmbedded = ({}) => {
  const siteName = useSitename().siteName;
  const locale = useLocale().urlPrefix;
  return (
    <div className="py-4">
      {siteName === "lildog" ? (
        locale === "no" ? (
          <div className="klaviyo-form-UTAXcx"></div>
        ) : (
          <div className="klaviyo-form-SGGdqB"></div>
        )
      ) : locale === "no" ? (
        <div className="klaviyo-form-UkYqNP"></div>
      ) : (
        <div className="klaviyo-form-UhsiFJ"></div>
      )}
    </div>
  );
};

export default LocalizedKlaviyoEmbedded;
