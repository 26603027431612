import Link from "next/link";
import Image from "next/image";
import { useLocale, BlogPostProps } from "@lilbit/shared";
import { getPositionFromHotspot } from "../../lib/helpers";

type Props = {
  blogPost: BlogPostProps;
  SimpleBlockContent: any;
  urlFor: Function;
};
const BlogPost = (props: Props) => {
  const { blogPost, urlFor, SimpleBlockContent } = props;
  const { title, mainImage, slug, _id, category, name, publishedAt, summary } =
    blogPost;
  const urlPrefix = useLocale().urlPrefix;
  //A hacky way of changing _type to summary without interfering with the sanity schema
  if (summary) {
    try {
      summary[0][0]["_type"] = "summary";
    } catch {}
  }
  //This is to prevent trying to render posts that has not been translated, which causes error. Can be removed when all posts have been translated.
  if (!slug || !title) return <></>;
  return (
    <section className="relative flex flex-col rounded-lg hover:scale-105 hover:shadow-lg shadow overflow-hidden w-4/5 my-4 md:w-1/3 md:mx-1 md:my-4 lg:w-30pc">
      <Link
        key={_id}
        href={{
          pathname: "/[lang]/[category]/[slug]",
        }}
        as={`/${urlPrefix}/${category}/${slug.current}`}
      >
        <a>
          <div className="relative h-48 w-full object-cover">
            <Image
              src={urlFor(mainImage, false).url()}
              alt={mainImage.alt}
              layout="fill"
              objectFit="cover"
              objectPosition={
                mainImage?.hotspot && getPositionFromHotspot(mainImage.hotspot)
              }
            />
          </div>
          <div className="flex-1 bg-white p-6 flex flex-col justify-between">
            <div className="flex-1 cursor-pointer">
              <p className="text-sm font-medium text-primary-mono-2">
                {urlPrefix === "en" ? "Article" : "Artikkel"}
              </p>
              <div className="block mt-2 max-h-52 overflow-ellipsis">
                <p className="text-xl font-semibold text-primary-mono-1 mb-2">
                  {title}
                </p>
                {summary?.length > 0 && (
                  <SimpleBlockContent blocks={summary[0]} />
                )}
              </div>
            </div>
            <div className="mt-6 flex items-center">
              <div className="flex-shrink-0">
                <div>
                  <span className="sr-only">{name}</span>
                </div>
                <div className="ml-3">
                  <div className="flex space-x-1 text-sm text-gray-500 absolute bottom-4 left-4 bg-white w-full">
                    <time dateTime={publishedAt}>
                      {new Date(publishedAt)?.toLocaleDateString()}
                    </time>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </Link>
    </section>
  );
};

export default BlogPost;
