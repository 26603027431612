import React from "react";
import { useLocale } from "../../translations";

interface Props {
  mainHeaderBlackFriday: string;
  subHeaderBlackFriday: string;
  mainProductPrices: {
    PRICE: any;
    ORIGINAL_PRICE: any;
  };
  productTaxRate: 25;
  locale: {
    defaultCurrency: string;
  };
}

const LocalizedSectionBlackFridayHeader = (props: Props) => {
  const heading: string = props.mainHeaderBlackFriday;
  const subheading: string = props.subHeaderBlackFriday;
  const mainProductPrices = props.mainProductPrices;
  const locale = props.locale;

  const urlPrefix = useLocale().urlPrefix;

  const calculatePrice = (price: string, taxRate: number) => {
    const priceNumber = parseFloat(price);
    const priceWithTax = priceNumber * (1 + taxRate / 100);
    return parseFloat(priceWithTax.toFixed(0));
  };
  const originalPrice = calculatePrice(
    mainProductPrices?.ORIGINAL_PRICE?.[locale?.defaultCurrency] ?? null,
    props.productTaxRate
  );

  const price = calculatePrice(
    mainProductPrices?.PRICE?.[locale?.defaultCurrency] ?? null,
    props.productTaxRate
  );

  const handleOnClick = () => {
    const targetComponent = document.getElementById(
      "scroll-to-purchase-section"
    );
    if (targetComponent) {
      const targetPosition = targetComponent.getBoundingClientRect().top;
      const offset = 50;

      window.scrollTo({
        top: window.scrollY + targetPosition - offset,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {!isNaN(originalPrice) && !isNaN(price) && (
        <div
          onClick={handleOnClick}
          className="h-fit py-10 md:py-8 w-full bg-black flex justify-center items-center flex-col hover:cursor-pointer"
        >
          <div className="flex flex-row">
            <h1 className="text-white text-4xl font-bold text-center underline">
              {heading}
            </h1>
          </div>
          <h1 className="text-white text-3xl font-normal text-center">
            {subheading ? subheading : ""}
          </h1>
          {!isNaN(originalPrice) && !isNaN(price) && (
            <div className="flex flex-row mt-4">
              <p className="text-white text-3xl line-through mr-4">
                {translations.before[urlPrefix]} {originalPrice}{" "}
                {translations.curr[locale.defaultCurrency]}
              </p>
              <p className="text-red-600 font-bold text-3xl">
                {translations.now[urlPrefix]} {price}{" "}
                {translations.curr[locale.defaultCurrency]}
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const translations = {
  before: {
    no: "FØR",
    en: "BEFORE",
    se: "FÖRE",
    da: "FØR",
    dk: "FØR",
    fr: "",
    es: "",
  },
  now: {
    no: "NÅ",
    en: "NOW",
    se: "NU",
    da: "NU",
    dk: "NU",
    fr: "",
    es: "",
  },
  curr: {
    NOK: ",-",
    USD: "$",
    EUR: "€",
  },
};
export default LocalizedSectionBlackFridayHeader;
