const XIconCircle = ({ height, width }: { height: number; width: number }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="27.5" cy="27.5" r="27.5" fill="#A39C9C" />
      <line
        x1="18.8614"
        y1="19.0815"
        x2="37.183"
        y2="37.4031"
        stroke="white"
        strokeWidth="7"
        strokeLinecap="round"
      />
      <line
        x1="36.7407"
        y1="19.4195"
        x2="18.4191"
        y2="37.7411"
        stroke="white"
        strokeWidth="7"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default XIconCircle;
