import Image from "next/image";
import AddToCart from "../Button/addToCartBtn";
import { GlobalConfig, Product } from "@lilbit/shared";

import { Image as ImageType } from "@lilbit/shared";

type Props = {
  image: ImageType;
  bodies: any;
  mainProduct: Product;
  SimpleBlockContent: any;
  heading: string;
  urlFor: Function;
  setVippsModalOpen: Function;
  config: GlobalConfig;
};

const FullImageSection = (props: Props) => {
  const {
    bodies,
    heading,
    image,
    mainProduct,
    SimpleBlockContent,
    urlFor,
    config,
  } = props;

  return (
    <div className="flex flex-col w-full items-center justify-center font-objektiv text-secondary-5">
      <div className="flex w-full items-center justify-center relative h-96 lg:h-px-702">
        <Image
          src={urlFor(image).url()}
          alt={image.alt}
          layout="fill"
          objectFit="cover"
        />
        <div className="absolute items-center justify-center w-full lg:w-full lg:px-18">
          <div className="flex flex-col justify-center items-center lg:justify-center lg:items-start">
            <h2 className="font-extrabold pb-6 text-center">{heading}</h2>
            {bodies && (
              <div className="flex flex-col items-center justify-center font-normal text-p-sm w-full px-6 text-center lg:text-p-lg lg:font-normal lg:max-w-2xl">
                <SimpleBlockContent blocks={bodies[0]} />
              </div>
            )}
            <div className="hidden lg:flex w-full justify-around items-center mt-13 lg:justify-start lg:space-x-3">
              <div className="flex items-center justify-center lg:w-px-200">
                <AddToCart
                  config={config}
                  textColor="text-secondary-5"
                  borderColor="border-secondary-5"
                  mainProduct={mainProduct}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullImageSection;
