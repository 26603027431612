import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { BsStar, BsStarFill, BsArrowLeft, BsArrowRight } from "react-icons/bs";

const Reviews = ({ reviews }) => {
  const [hasOverflow, setHasOverflow] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const container = ref.current;

    const checkOverflow = () => {
      if (container) {
        const hasHorizontalOverflow =
          container.scrollWidth > container.clientWidth;
        setHasOverflow(hasHorizontalOverflow);
      }
    };

    const handleResize = () => {
      checkOverflow();
    };

    window.addEventListener("resize", handleResize);
    checkOverflow();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollRight = () => {
    ref.current.scrollLeft += 256 * 3;
  };

  const scrollLeft = () => {
    ref.current.scrollLeft -= 256 * 3;
  };
  return (
    <div>
      <div className="w-screen h-[480px] pt-2 flex flex-col " ref={ref}>
        <div className="flex space-x-2 font-objektiv relative w-full overflow-y-clip overflow-x-scroll">
          {reviews?.data?.map((review, i) => {
            const userPhotoUrl = "" + review.image;
            return (
              <div
                key={i}
                className="w-64 h-[450px] shrink-0 shadow-small rounded-sm "
              >
                <div className="relative w-full h-[240px]">
                  <Image src={userPhotoUrl} layout="fill" objectFit="cover" />
                </div>
                <div className="relative items-center justify-center flex">
                  <div className="flex space-x-1 bg-white shadow-md w-min py-3 px-4 rounded-md absolute -top-4">
                    {[...Array(5)].map((_, i) =>
                      i < review.rating ? (
                        <BsStarFill key={i} color="#b5cf8f" />
                      ) : (
                        <BsStar key={i} />
                      )
                    )}
                  </div>
                  <div className="p-2 mt-12 text-center">
                    <h6 className="font-semibold">{review.author}</h6>
                    {/* <h6 className="font-semibold">{review.author}</h6> */}
                    <p className="text-[13px]">{review.title}</p>
                    {/* <p className="text-sm">{review.reviewMessage}</p> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {hasOverflow && (
        <div className="w-full justify-between mb-4 px-2 hidden sm:flex">
          <BsArrowLeft
            className="text-gray-900 w-8 h-8 hover:scale-102 cursor-pointer "
            onClick={scrollLeft}
          />
          <BsArrowRight
            className="text-gray-900 w-8 h-8 hover:scale-102 cursor-pointer "
            onClick={scrollRight}
          />
        </div>
      )}
    </div>
  );
};

export default Reviews;
