import { Fragment } from "react";
import { Transition, Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { classNames, GlobalConfig, siteSpecificLocales } from "../lib";
import { useLocale } from "../translations";

interface Props {
  handleLocaleChange: (e: any) => void;
  config: GlobalConfig;
  siteName: "lildog" | "lilcat";
}
const LocaleSwitcher = ({ handleLocaleChange, config, siteName }: Props) => {
  const urlPrefix = useLocale().urlPrefix;
  const slugsForAllLocales = config.slugsForAllLocales;
  const slugsForAllCategories = config.slugsForAllCategories;
  const locales = siteSpecificLocales(siteName);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left font-objektiv">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="flex justify-center items-center w-full rounded-sm bg-secondary-5 font-semibold text-[12px] text-primary-mono-2 lg:font-bold">
                {urlPrefix.toUpperCase()}
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="z-50 origin-top-left absolute left-0 mt-2 w-24 rounded-md shadow-lg bg-secondary-5 ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div className="py-1">
                  {locales.map((locale) => {
                    /* Prevent locale switcher from showing locales that don't have any posts */
                    if (
                      !slugsForAllLocales ||
                      !slugsForAllCategories ||
                      !slugsForAllCategories[locale.urlPrefix] ||
                      !slugsForAllLocales[locale.urlPrefix]
                    ) {
                      return null;
                    }
                    return (
                      <Menu.Item key={locale.urlPrefix}>
                        {({ active }) => (
                          <button
                            onClick={() => {
                              handleLocaleChange(locale);
                            }}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm w-full text-left"
                            )}
                          >
                            {locale.urlPrefix.toUpperCase()}
                          </button>
                        )}
                      </Menu.Item>
                    );
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};

export default LocaleSwitcher;
