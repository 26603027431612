import { useState } from "react";
import * as Sentry from "@sentry/browser";

const useLocalStorage = (key: string, initialValue: any = "") => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      Sentry.captureException(
        new Error(
          `Error logged from line 28 in src/hooks/useLocaleStorage.ts" (POST): ${error}`
        )
      );
    }
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
