import Image from "next/image";
import { Image as ImageType } from "../../lib/types";

interface Props {
  cards: Array<{
    body: string;
    icon: ImageType;
    width: number;
    height: number;
    _key: string;
  }>;
  sr_text: string;
  urlFor: Function;
  image: ImageType;
}

const Sectiontwentysix = ({ cards, sr_text, urlFor, image }: Props) => {
  return (
    <div className="flex flex-col justify-center items-center w-full bg-white font-objektiv text-center mb-12">
      <h2 className="sr-only">{sr_text || ""}</h2>
      {image && (
        <div className="flex items-center justify-center relative w-full h-[300px] sm:h-[300px] md:h-[300px] sm:px-2">
          <Image
            src={urlFor(image).url()}
            layout="fill"
            className=""
            objectFit="contain"
            quality={40}
            alt={image.alt || ""}
          />
        </div>
      )}
      <div className="sm:px-6 lg:px-32">
        <dl className="space-y-6 lg:space-y-0 lg:grid content-center lg:grid-cols-3 lg:gap-1">
          {cards?.map((card) => (
            <div
              key={card._key}
              className=" flex flex-col justify-start items-center"
            >
              <dt>
                {card?.icon && (
                  <div className="flex items-center justify-center relative rounded-[15px] overflow-hidden">
                    <Image
                      src={urlFor(card.icon).url()}
                      width={card.width ? card.width : 250}
                      height={card.height ? card.height : 170}
                      layout="fixed"
                      quality={40}
                      objectFit="fill"
                      alt={card.icon.alt || ""}
                    />
                  </div>
                )}
              </dt>
              <dd className="mt-2 w-9/12 text-center text-[18px] leading-[25px] text-primary-1">
                {card.body || ""}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default Sectiontwentysix;
