import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { CountryContext, useLocale } from "@lilbit/shared";
import { useContext, useEffect } from "react";
import {
  basicMonthlyPricesOnly,
  basicTwoYearPrices,
  basicYearlyPrices,
  premiumFiveYearPrices,
  premiumTwoYearPrices,
  premiumYearlyPrices,
  familyYearlyPrices,
  familyTwoYearPrices,
  familyFiveYearPrices,
} from "../lib/subscriptionPrices";
import Link from "next/link";

const features = [
  {
    id: process.env.NEXT_PUBLIC_STRIPE_BASIC_PRODUCT_ID,
    features: [],
    missingFeatures: [
      {
        no: "Intil en ny enhet årlig ved tap/skade",
        en: "Up to one new device annually in case of loss/damage",
        se: "Upp till en ny enhet årligen vid förlust/stöld/skada",
        da: "Op til en ny enhed årligt i tilfælde af tab/skade",
        fi: "Enintään yksi uusi laite vuosittain tapauksessa vahinkoja/varastettu",
        de: "Bis zu einem neuen Gerät jährlich bei Verlust/Diebstahl/Schaden",
        fr: "Jusqu'à un nouvel appareil par an en cas de perte/vol/dommage",
        es: "Hasta un nuevo dispositivo anual en caso de pérdida/robo/daño",
      },
      {
        no: "Inntill 4 enheter på et abonnement",
        en: "Up to 4 devices on one subscription",
        se: "Upp till 4 enheter på en prenumeration",
        da: "Op til 4 enheder på et abonnement",
        fi: "Enintään 4 laitetta yhdellä tilauksella",
        de: "Bis zu 4 Geräte auf einem Abonnement",
        fr: "Jusqu'à 4 appareils sur un abonnement",
        es: "Hasta 4 dispositivos en una suscripción",
      },
    ],
  },
  {
    id: process.env.NEXT_PUBLIC_STRIPE_PREMIUM_PRODUCT_ID,
    features: [
      {
        no: "Intil en ny enhet årlig ved tap/skade",
        en: "Up to one new device annually in case of loss/damage",
        se: "Upp till en ny enhet årligen vid förlust/stöld/skada",
        da: "Op til en ny enhed årligt i tilfælde af tab/skade",
        fi: "Enintään yksi uusi laite vuosittain tapauksessa vahinkoja/varastettu",
        de: "Bis zu einem neuen Gerät jährlich bei Verlust/Diebstahl/Schaden",
        fr: "Jusqu'à un nouvel appareil par an en cas de perte/vol/dommage",
        es: "Hasta un nuevo dispositivo anual en caso de pérdida/robo/daño",
      },
    ],
    missingFeatures: [
      {
        no: "Inntill 4 enheter på et abonnement",
        en: "Up to 4 devices on one subscription",
        se: "Upp till 4 enheter på en prenumeration",
        da: "Op til 4 enheder på et abonnement",
        fi: "Enintään 4 laitetta yhdellä tilauksella",
        de: "Bis zu 4 Geräte auf einem Abonnement",
        fr: "Jusqu'à 4 appareils sur un abonnement",
        es: "Hasta 4 dispositivos en una suscripción",
      },
    ],
  },
  {
    id: process.env.NEXT_PUBLIC_STRIPE_FAMILY_PRODUCT_ID,
    features: [
      {
        no: "Intil en ny enhet årlig ved tap/skade",
        en: "Up to one new device annually in case of loss/damage",
        se: "Upp till en ny enhet årligen vid förlust/stöld/skada",
        da: "Op til en ny enhed årligt i tilfælde af tab/skade",
        fi: "Enintään yksi uusi laite vuosittain tapauksessa vahinkoja/varastettu",
        de: "Bis zu einem neuen Gerät jährlich bei Verlust/Diebstahl/Schaden",
        fr: "Jusqu'à un nouvel appareil par an en cas de perte/vol/dommage",
        es: "Hasta un nuevo dispositivo anual en caso de pérdida/robo/daño",
      },
      {
        no: "Inntill 4 enheter på et abonnement",
        en: "Up to 4 devices on one subscription",
        se: "Upp till 4 enheter på en prenumeration",
        da: "Op til 4 enheder på et abonnement",
        fi: "Enintään 4 laitetta yhdellä tilauksella",
        de: "Bis zu 4 Geräte auf einem Abonnement",
        fr: "Jusqu'à 4 appareils sur un abonnement",
        es: "Hasta 4 dispositivos en una suscripción",
      },
    ],
    missingFeatures: [],
  },
];

const PricingTable = () => {
  const urlPrefix = useLocale().urlPrefix;
  const { country } = useContext(CountryContext);

  const basicMonthlyPrices = {
    id: process.env.NEXT_PUBLIC_STRIPE_BASIC_PRODUCT_ID,
    header: translations.basic,
    firstInterval: {
      label: {
        en: "Monthly",
        no: "Månedlig",
        se: "Månadsvis",
        da: "Månedligt",
        fi: "Kuukausittain",
        de: "Monatlich",
        fr: "Mensuel",
        es: "Mensual",
      },
      description: {
        en: `Billed monthly with ${basicMonthlyPricesOnly[country.currency]}${
          country.currency === "NOK" ? ",-" : " " + country.currency
        }`,
        no: `Faktureres månedlig med ${
          basicMonthlyPricesOnly[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        se: `Faktureras månadsvis med ${
          basicMonthlyPricesOnly[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        da: `Faktureres månedligt med ${
          basicMonthlyPricesOnly[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        fi: `Laskutetaan kuukausittain ${
          basicMonthlyPricesOnly[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        de: `Monatlich mit ${basicMonthlyPricesOnly[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        fr: `Facturé mensuellement avec ${
          basicMonthlyPricesOnly[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        es: `Facturado mensualmente con ${
          basicMonthlyPricesOnly[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
      },
      NOK: 149,
      EUR: 12.71,
      USD: 13.86,
      SEK: 149,
      DKK: 97,
    },
    secondInterval: {
      label: {
        en: "1 year",
        no: "1 år",
        se: "1 år",
        da: "1 år",
        fi: "1 vuosi",
        de: "1 Jahr",
        fr: "1 an",
        es: "1 año",
      },
      description: {
        en: `Billed yearly with ${basicYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        no: `Faktureres årlig med ${basicYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        se: `Faktureras årligen med ${basicYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        da: `Faktureres årligt med ${basicYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        fi: `Laskutetaan vuosittain ${basicYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        de: `Jährlich mit ${basicYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        fr: `Facturé annuellement avec ${basicYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        es: `Facturado anualmente con ${basicYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
      },
      NOK: basicYearlyPrices.NOK / 12,
      EUR: (basicYearlyPrices.EUR / 12).toFixed(2),
      USD: (basicYearlyPrices.USD / 12).toFixed(2),
      SEK: basicYearlyPrices.SEK / 12,
      DKK: (basicYearlyPrices.DKK / 12).toFixed(2),
    },
    thirdInterval: {
      label: {
        en: "2 years",
        no: "2 år",
        se: "2 år",
        da: "2 år",
        fi: "2 vuotta",
        de: "2 Jahre",
        fr: "2 ans",
        es: "2 años",
      },
      description: {
        en: `Billed every 2 years with ${basicTwoYearPrices[country.currency]}${
          country.currency === "NOK" ? ",-" : " " + country.currency
        }`,
        no: `Faktureres hvert 2. år med ${
          basicTwoYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        se: `Faktureras var 2: a år med ${
          basicTwoYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        da: `Faktureres hvert 2. år med ${
          basicTwoYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        fi: `Laskutetaan joka 2. vuosi ${basicTwoYearPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        de: `Alle 2 Jahre mit ${basicTwoYearPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        fr: `Facturé tous les 2 ans avec ${
          basicTwoYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        es: `Facturado cada 2 años con ${basicTwoYearPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
      },
      NOK: basicTwoYearPrices.NOK / 24,
      EUR: (basicTwoYearPrices.EUR / 24).toFixed(2),
      USD: (basicTwoYearPrices.USD / 24).toFixed(2),
      SEK: basicTwoYearPrices.SEK / 24,
      DKK: (basicTwoYearPrices.DKK / 24).toFixed(2),
    },
  };
  const premiumMonthlyPrices = {
    id: process.env.NEXT_PUBLIC_STRIPE_PREMIUM_PRODUCT_ID,
    header: translations.premium,
    firstInterval: {
      label: {
        en: "1 year",
        no: "1 år",
        se: "1 år",
        da: "1 år",
        fi: "1 vuosi",
        de: "1 Jahr",
        fr: "1 an",
        es: "1 año",
      },
      description: {
        en: `Billed yearly with ${premiumYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        no: `Faktureres årlig med ${premiumYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        se: `Faktureras årligen med ${premiumYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        da: `Faktureres årligt med ${premiumYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        fi: `Laskutetaan vuosittain ${premiumYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        de: `Jährlich mit ${premiumYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        fr: `Facturé annuellement avec ${
          premiumYearlyPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        es: `Facturado anualmente con ${premiumYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
      },
      NOK: premiumYearlyPrices.NOK / 12,
      EUR: (premiumYearlyPrices.EUR / 12).toFixed(2),
      USD: (premiumYearlyPrices.USD / 12).toFixed(2),
      SEK: premiumYearlyPrices.SEK / 12,
      DKK: (premiumYearlyPrices.DKK / 12).toFixed(2),
    },
    secondInterval: {
      label: {
        en: "2 years",
        no: "2 år",
        se: "2 år",
        da: "2 år",
        fi: "2 vuotta",
        de: "2 Jahre",
        fr: "2 ans",
        es: "2 años",
      },
      description: {
        en: `Billed every 2 years with ${
          premiumTwoYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        no: `Faktureres hvert 2. år med ${
          premiumTwoYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        se: `Faktureras var 2: e år med ${
          premiumTwoYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        da: `Faktureres hvert 2. år med ${
          premiumTwoYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        fi: `Laskutetaan joka 2. vuosi ${
          premiumTwoYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        de: `Alle 2 Jahre mit ${premiumTwoYearPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        fr: `Facturé tous les 2 ans avec ${
          premiumTwoYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        es: `Facturado cada 2 años con ${
          premiumTwoYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
      },
      NOK: premiumTwoYearPrices.NOK / 24,
      EUR: (premiumTwoYearPrices.EUR / 24).toFixed(2),
      USD: (premiumTwoYearPrices.USD / 24).toFixed(2),
      SEK: premiumTwoYearPrices.SEK / 24,
      DKK: (premiumTwoYearPrices.DKK / 24).toFixed(2),
    },
    thirdInterval: {
      label: {
        en: "5 years",
        no: "5 år",
        se: "5 år",
        da: "5 år",
        fi: "5 vuotta",
        de: "5 Jahre",
        fr: "5 ans",
        es: "5 años",
      },
      description: {
        en: `Billed every 5 years with ${
          premiumFiveYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        no: `Faktureres hvert 5. år med ${
          premiumFiveYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        se: `Faktureras var 5: e år med ${
          premiumFiveYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        da: `Faktureres hvert 5. år med ${
          premiumFiveYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        fi: `Laskutetaan joka 5. vuosi ${
          premiumFiveYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        de: `Alle 5 Jahre mit ${premiumFiveYearPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        fr: `Facturé tous les 5 ans avec ${
          premiumFiveYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        es: `Facturado cada 5 años con ${
          premiumFiveYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
      },
      NOK: premiumFiveYearPrices.NOK / 60,
      EUR: (premiumFiveYearPrices.EUR / 60).toFixed(2),
      USD: (premiumFiveYearPrices.USD / 60).toFixed(2),
      SEK: premiumFiveYearPrices.SEK / 60,
      DKK: (premiumFiveYearPrices.DKK / 60).toFixed(2),
    },
  };
  const familyMonthlyPrices = {
    id: process.env.NEXT_PUBLIC_STRIPE_FAMILY_PRODUCT_ID,
    header: translations.family,
    firstInterval: {
      label: {
        en: "1 year",
        no: "1 år",
        se: "1 år",
        da: "1 år",
        fi: "1 vuosi",
        de: "1 Jahr",
        fr: "1 an",
        es: "1 año",
      },
      description: {
        en: `Billed yearly with ${familyYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        no: `Faktureres årlig med ${familyYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        se: `Faktureras årligen med ${familyYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        da: `Faktureres årligt med ${familyYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        fi: `Laskutetaan vuosittain ${familyYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        de: `Jährlich mit ${familyYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        fr: `Facturé annuellement avec ${familyYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        es: `Facturado anualmente con ${familyYearlyPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
      },
      NOK: familyYearlyPrices.NOK / 12,
      EUR: (familyYearlyPrices.EUR / 12).toFixed(2),
      USD: (familyYearlyPrices.USD / 12).toFixed(2),
      SEK: familyYearlyPrices.SEK / 12,
      DKK: (familyYearlyPrices.DKK / 12).toFixed(2),
    },
    secondInterval: {
      label: {
        en: "2 years",
        no: "2 år",
        se: "2 år",
        da: "2 år",
        fi: "2 vuotta",
        de: "2 Jahre",
        fr: "2 ans",
        es: "2 años",
      },
      description: {
        en: `Billed every 2 years with ${
          familyTwoYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        no: `Faktureres hvert 2. år med ${
          familyTwoYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        se: `Faktureras var 2: e år med ${
          familyTwoYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        da: `Faktureres hvert 2. år med ${
          familyTwoYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        fi: `Laskutetaan joka 2. vuosi ${
          familyTwoYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        de: `Alle 2 Jahre mit ${familyTwoYearPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        fr: `Facturé tous les 2 ans avec ${
          familyTwoYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        es: `Facturado cada 2 años con ${
          familyTwoYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
      },
      NOK: familyTwoYearPrices.NOK / 24,
      EUR: (familyTwoYearPrices.EUR / 24).toFixed(2),
      USD: (familyTwoYearPrices.USD / 24).toFixed(2),
      SEK: familyTwoYearPrices.SEK / 24,
      DKK: (familyTwoYearPrices.DKK / 24).toFixed(2),
    },
    thirdInterval: {
      label: {
        en: "5 years",
        no: "5 år",
        se: "5 år",
        da: "5 år",
        fi: "5 vuotta",
        de: "5 Jahre",
        fr: "5 ans",
        es: "5 años",
      },
      description: {
        en: `Billed every 5 years with ${
          familyFiveYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        no: `Faktureres hvert 5. år med ${
          familyFiveYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        se: `Faktureras var 5: e år med ${
          familyFiveYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        da: `Faktureres hvert 5. år med ${
          familyFiveYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        fi: `Laskutetaan joka 5. vuosi ${
          familyFiveYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        de: `Alle 5 Jahre mit ${familyFiveYearPrices[country.currency]}${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        fr: `Facturé tous les 5 ans avec ${
          familyFiveYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
        es: `Facturado cada 5 años con ${
          familyFiveYearPrices[country.currency]
        }${
          country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
        }`,
      },
      NOK: familyFiveYearPrices.NOK / 60,
      EUR: (familyFiveYearPrices.EUR / 60).toFixed(2),
      USD: (familyFiveYearPrices.USD / 60).toFixed(2),
      SEK: familyFiveYearPrices.SEK / 60,
      DKK: (familyFiveYearPrices.DKK / 60).toFixed(2),
    },
  };
  return (
    <div className="space-y-4">
      {[premiumMonthlyPrices, basicMonthlyPrices, familyMonthlyPrices].map(
        (price) => {
          return (
            <div
              className="bg-white w-full relative rounded-3xl"
              key={price.id}
            >
              <div className="rounded-3xl ring-1 ring-gray-200">
                <div className="p-8">
                  <h3 className="text-2xl font-bold tracking-tight text-gray-900">
                    {price.header[urlPrefix]}
                  </h3>
                  <div className="grid lg:grid-cols-3 lg:gap-4">
                    <div className="flex flex-col border-b-[0.5px] border-gray-200 pb-4 relative mt-4 lg:border-b-0 lg:pb-0">
                      <span className="absolute inline-flex items-center rounded-md bg-primary-mono-2/10 px-2 py-1 -top-2 text-xs font-medium text-primary-mono-2 ring-1 ring-inset ring-primary-mono-3/20">
                        {price.firstInterval.label[urlPrefix]}
                      </span>
                      <div className="mt-6 flex items-baseline gap-x-2">
                        <div className="flex flex-col items-start">
                          <div className="flex items-center lg:items-start lg:flex-col">
                            <span className="text-lg font-bold tracking-tight text-gray-900">
                              {price.firstInterval[country.currency]}
                              {country.currency === "NOK"
                                ? ",-"
                                : " " + country.currency}
                            </span>
                            <span className="text-xs font-semibold leading-6 tracking-wide text-gray-600">
                              <span className="lg:hidden">&nbsp;</span>
                              {translations.priceSuffix[urlPrefix]}
                            </span>
                          </div>
                        </div>
                      </div>
                      <p className="text-[11px] text-gray-600">
                        {price.firstInterval.description[urlPrefix]}
                      </p>
                    </div>
                    <div className="flex flex-col border-b-[0.5px] border-gray-200 pb-4 relative mt-4 lg:border-b-0 lg:pb-0">
                      <span className="absolute inline-flex items-center rounded-md bg-primary-mono-2/10 px-2 py-1 -top-2 text-xs font-medium text-primary-mono-2 ring-1 ring-inset ring-primary-mono-3/20">
                        {price.secondInterval.label[urlPrefix]}
                      </span>
                      <div className="mt-6 flex items-baseline gap-x-2">
                        <div className="flex flex-col items-start">
                          <div className="flex items-center lg:items-start lg:flex-col">
                            <span className="text-lg font-bold tracking-tight text-gray-900">
                              {price.secondInterval[country.currency]}
                              {country.currency === "NOK"
                                ? ",-"
                                : " " + country.currency}
                            </span>
                            <span className="text-xs font-semibold leading-6 tracking-wide text-gray-600">
                              <span className="lg:hidden">&nbsp;</span>
                              {translations.priceSuffix[urlPrefix]}
                            </span>
                          </div>
                        </div>
                      </div>
                      <p className="text-[11px] text-gray-600">
                        {price.secondInterval.description[urlPrefix]}
                      </p>
                    </div>
                    <div className="flex flex-col relative mt-4">
                      <span className="absolute inline-flex items-center rounded-md bg-primary-mono-2/10 px-2 py-1 -top-2 text-xs font-medium text-primary-mono-2 ring-1 ring-inset ring-primary-mono-3/20">
                        {price.thirdInterval.label[urlPrefix]}
                      </span>
                      <div className="mt-6 flex items-baseline gap-x-2">
                        <div className="flex flex-col items-start">
                          <div className="flex items-center lg:items-start lg:flex-col">
                            <span className="text-lg font-bold tracking-tight text-gray-900">
                              {price.thirdInterval[country.currency]}
                              {country.currency === "NOK"
                                ? ",-"
                                : " " + country.currency}
                            </span>
                            <span className="text-xs font-semibold leading-6 tracking-wide text-gray-600">
                              <span className="lg:hidden">&nbsp;</span>
                              {translations.priceSuffix[urlPrefix]}
                            </span>
                          </div>
                        </div>
                      </div>
                      <p className="text-[11px] text-gray-600">
                        {price.thirdInterval.description[urlPrefix]}
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 flex items-center gap-x-2">
                    <h4 className="flex-none text-xs font-semibold text-primary-mono-2">
                      {translations.whatsIncluded[urlPrefix]}
                    </h4>
                    <div className="h-px flex-auto bg-gray-100" />
                  </div>
                  <ul
                    role="list"
                    className="mt-2 grid grid-cols-1 gap-2 text-xs leading-6 text-gray-600"
                  >
                    {features
                      .find((f) => f.id === price.id)
                      .features.map((feature) => (
                        <li
                          key={feature[urlPrefix]}
                          className="flex gap-x-3 items-center"
                        >
                          <CheckIcon
                            className="h-4 w-4 flex-none text-primary-mono-2"
                            aria-hidden="true"
                          />
                          {feature[urlPrefix]}
                        </li>
                      ))}
                    {features
                      .find((f) => f.id === price.id)
                      .missingFeatures.map((feature) => (
                        <li
                          key={feature[urlPrefix]}
                          className="flex gap-x-3 line-through items-center"
                        >
                          <XMarkIcon
                            className="h-4 w-4 flex-none text-primary-mono-2"
                            aria-hidden="true"
                          />
                          {feature[urlPrefix]}
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          );
        }
      )}
      <div className="flex w-full justify-center items-center font-default text-base md:text-lg pt-2">
        <Link href={translations.subscription[urlPrefix]}>
          <a target="_blank" rel="noopener noreferrer">
            <div className="flex text-center items-center justify-center bg-primary-4 rounded-xl h-12 w-full px-8 hover:scale-110 transition-transform">
              <p className="text-primary-1 ">
                {translations.readMore[urlPrefix]
                  ? translations.readMore[urlPrefix]
                  : translations.readMore.en}
              </p>
            </div>
          </a>
        </Link>
      </div>
    </div>
  );
};

const translations = {
  family: {
    en: "Family",
    no: "Familie",
    se: "Familj",
    da: "Familie",
    fi: "Perhe",
    de: "Familie",
    fr: "Famille",
    es: "Familia",
  },
  premium: {
    en: "Premium",
    no: "Premium",
    se: "Premium",
    da: "Premium",
    fi: "Premium",
    de: "Premium",
    fr: "Premium",
    es: "Premium",
  },
  basic: {
    en: "Basic",
    no: "Basic",
    se: "Basic",
    da: "Basic",
    fi: "Basic",
    de: "Basic",
    fr: "Basic",
    es: "Basic",
  },
  whatsIncluded: {
    en: "What’s included",
    no: "Hva er inkludert",
    se: "Vad ingår",
    da: "Hvad er inkluderet",
    fi: "Mitä sisältyy",
    de: "Was ist enthalten",
    fr: "Ce qui est inclus",
    es: "Qué está incluido",
  },
  priceFrom: {
    en: "from",
    no: "fra",
    se: "från",
    da: "fra",
    fi: "alkaen",
    de: "von",
    fr: "de",
    es: "de",
  },
  priceSuffix: {
    en: "/month",
    no: "/mnd",
    se: "/månad",
    da: "/måned",
    fi: "/kuukausi",
    de: "/Monat",
    fr: "/mois",
    es: "/mes",
  },
  readMore: {
    en: "Read more",
    no: "Les mer",
    se: "Läs mer",
    da: "Læs mere",
    fi: "Lue lisää",
    de: "Weiterlesen",
    fr: "Lire la suite",
    es: "Lee mas",
  },
  subscription: {
    no: "/no/abonnement",
    en: "/en/subscriptions",
    se: "/se/abonnemang",
    da: "/da/abonnementer",
    fi: "/fi/tilaukset",
    de: "/de/abonnements",
    fr: "/fr/abonnements",
    es: "/es/suscripciones",
  },
};

export default PricingTable;
