import Image from "next/image";
import { Image as ImageType } from "../../lib";
import { getPositionFromHotspot } from "../../lib";

interface Props {
  title: string;
  subtitle: string;
  header: string;
  text: string;
  image: ImageType;
  phoneImage: ImageType;
  urlFor: any;
}

const LocalizedBigImageBigTextSmallText = (props: Props) => {
  const { title, subtitle, header, text, image, urlFor, phoneImage } = props;
  return (
    <div className="w-screen flex flex-col  ">
      <div className="w-11/12 lg:w-9/10 px-10 h-[450px] lg:h-[650px] relative flex mx-auto mt-4 sm:mt-0">
        <div className="hidden md:flex">
          <Image
            src={urlFor(image).url()}
            layout="fill"
            objectFit="cover"
            className="rounded-3xl"
            priority={true}
            alt={image.alt}
            objectPosition={
              image?.hotspot && getPositionFromHotspot(image.hotspot)
            }
          />
        </div>
        <div className="flex md:hidden">
          <Image
            src={urlFor(phoneImage).url()}
            layout="fill"
            objectFit="cover"
            className="rounded-3xl"
            priority={true}
            alt={image.alt}
          />
        </div>

        {/* <div className="bg-black absolute top-0 left-0 w-full h-full bg-opacity-20 rounded-3xl" /> */}
        <div className="flex items-center justify-center w-full h-full">
          <h1 className="absolute text-white  text-center w-4/5 leading-tight text-3xl md:text-4xl md:w-1/2 lg:text-5xl xl:text-6xl space-y-4">
            <p className="font-p22 text-xl md:text-3xl lg:text-4xl">
              {title || ""}
            </p>
            <p />
            <p className="font-p22 text-2xl md:text-4xl lg:text-5xl lg:translate-y-10">
              {subtitle || ""}
            </p>
          </h1>
        </div>
      </div>
      <div className=" w-11/12 lg:w-9/10 px-10 mx-auto flex flex-col justify-between items-center   leading-snug mt-16 text-primary-1 lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/5">
          <h2 className="text-2xl text-center  font-p22 sm:text-3xl md:leading-[50px] md:text-[35px] lg:leading-[60px] lg:text-[45px] lg:text-left">
            {header}
          </h2>
        </div>
        <div className="mt-8 lg:mt-0 lg:w-3/5 xl:w-1/2 ">
          <p className="mb-6 font-objektiv text-base md:text-[26px] md:leading-[34px]">
            {text}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LocalizedBigImageBigTextSmallText;
