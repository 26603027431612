/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { CountryContext } from "@lilbit/shared";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const currencyElements = [
  {
    isDefault: false,
    displayName: "USA",
    countryCode: "US",
    sanityCountryIdentifier: "US",
    currency: "USD",
  },
  {
    isDefault: true,
    displayName: "Norge",
    countryCode: "NO",
    sanityCountryIdentifier: "NO",
    currency: "NOK",
  },
  {
    isDefault: false,
    displayName: "Germany",
    countryCode: "DE",
    sanityCountryIdentifier: "US",
    currency: "EUR",
  },
];

const CurrencySwitcher = () => {
  const { country, setCountry } = useContext(CountryContext);
  return (
    <>
      {CountryContext && (
        <Menu
          as="div"
          className="relative inline-block text-left font-objektiv"
        >
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="flex justify-center items-center w-full rounded-sm bg-secondary-5 font-semibold text-[12px] text-primary-mono-2 lg:font-bold">
                  {country?.currency}
                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="z-50 origin-top-right absolute right-0 mt-2 w-24 rounded-md shadow-lg bg-secondary-5 ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="py-1">
                    {currencyElements &&
                      currencyElements.map((element, index) => {
                        return (
                          <Menu.Item key={`${element} ${index}`}>
                            {({ active }) => (
                              <button
                                onClick={() => {
                                  setCountry(element);
                                }}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm w-full text-left"
                                )}
                              >
                                {element?.currency}
                              </button>
                            )}
                          </Menu.Item>
                        );
                      })}
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      )}
    </>
  );
};
export default CurrencySwitcher;
