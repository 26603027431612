import React, { useState, useEffect, createContext, ReactNode } from "react";

export const defaultValue = {};

export const BreakpointContext = createContext(defaultValue);

export const breakPointValues = {
  sm: "640",
  md: "768",
  lg: "1024",
  xl: "1280",
};

export const breakPointQueries = {
  ...Object.entries(breakPointValues).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: `(min-width: ${value}px)`,
    }),
    {}
  ),
};

const BreakpointProvider = ({ children }: { children: ReactNode }) => {
  const [queryMatch, setQueryMatch] = useState({});

  useEffect(() => {
    const mediaQueryLists = {};
    const keys = Object.keys(breakPointQueries);
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc, media) => {
        acc[media] = !!(
          mediaQueryLists[media] && mediaQueryLists[media].matches
        );
        return acc;
      }, {});
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches = {};
      keys.forEach((media) => {
        if (typeof breakPointQueries[media] === "string") {
          mediaQueryLists[media] = window.matchMedia(breakPointQueries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach((media) => {
        if (typeof breakPointQueries[media] === "string") {
          mediaQueryLists[media].addListener(handleQueryListener);
        }
      });
    }

    return () => {
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof breakPointQueries[media] === "string") {
            mediaQueryLists[media].removeListener(handleQueryListener);
          }
        });
      }
    };
  }, [breakPointQueries]);

  return (
    <BreakpointContext.Provider value={queryMatch}>
      {children}
    </BreakpointContext.Provider>
  );
};

export default BreakpointProvider;
