import * as React from "react";
import { svg } from "../../lib";
const PaypalLogo = ({ width, height }: svg) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      aria-hidden="true"
      className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--paypal-svg Icon-color-svg Icon-color--gray600-svg"
    >
      <path fill="#F5F6F8" d="M0 0h32v32H0z" />
      <path
        fill="#253B80"
        d="m12.473 25.358.357-2.273-.797-.018H8.227l2.645-16.772a.216.216 0 0 1 .215-.183h6.418c2.13 0 3.6.443 4.368 1.318.36.41.59.84.7 1.312.116.495.119 1.087.005 1.809l-.008.053v.462l.36.204c.274.14.521.327.728.556.308.35.507.797.591 1.325.087.544.059 1.192-.084 1.924-.164.843-.43 1.578-.788 2.178a4.48 4.48 0 0 1-1.249 1.369 5.065 5.065 0 0 1-1.681.758 8.385 8.385 0 0 1-2.102.243h-.5c-.357 0-.704.129-.976.36a1.512 1.512 0 0 0-.509.908l-.038.205-.632 4.006-.028.147c-.008.046-.021.07-.04.085a.106.106 0 0 1-.066.024h-3.083Z"
      />
      <path
        fill="#179BD7"
        d="M23.27 10.657a9.59 9.59 0 0 1-.065.376c-.846 4.346-3.742 5.847-7.44 5.847h-1.883a.914.914 0 0 0-.904.774l-.964 6.114-.273 1.733a.482.482 0 0 0 .476.557h3.34a.804.804 0 0 0 .793-.677l.033-.17.629-3.99.04-.22a.803.803 0 0 1 .794-.678h.5c3.235 0 5.767-1.313 6.508-5.115.309-1.588.149-2.914-.67-3.846a3.194 3.194 0 0 0-.913-.705Z"
      />
      <path
        fill="#222D65"
        d="M22.385 10.304a6.677 6.677 0 0 0-.823-.183 10.458 10.458 0 0 0-1.66-.121h-5.03a.802.802 0 0 0-.793.679l-1.07 6.777-.031.198a.914.914 0 0 1 .904-.774h1.883c3.698 0 6.593-1.502 7.44-5.847.025-.128.046-.254.065-.376a4.51 4.51 0 0 0-.885-.353Z"
      />
      <path
        fill="#253B80"
        d="M14.079 10.679a.8.8 0 0 1 .793-.678h5.03c.596 0 1.152.039 1.66.12a6.67 6.67 0 0 1 1.013.242c.25.083.482.18.696.294.252-1.606-.002-2.7-.87-3.69-.958-1.09-2.685-1.556-4.896-1.556h-6.418a.917.917 0 0 0-.906.775L7.508 23.13a.551.551 0 0 0 .544.638h3.962l.995-6.312 1.07-6.777Z"
      />
    </svg>
  );
};
export default PaypalLogo;
