import Image from "next/image";
import { Image as ImageType } from "@lilbit/shared";
import { getPositionFromHotspot } from "../../lib/helpers";
type Props = {
  boldText: string;
  smallText: string;
  image: ImageType;
  urlFor: Function;
};
const AntiSquaredImageAndText = (props: Props) => {
  const { boldText, smallText, image, urlFor } = props; //flex justify-items-stretch

  return (
    <div className="relative text-primary-mono-1 w-full">
      <div className="flex flex-col items-center bg-primary-mono-3 overflow-hidden lg:flex-row lg:justify-start">
        <div className="lg:w-1/2 w-full relative h-[450px] lg:h-[550px] ">
          {image && (
            <Image
              layout="fill"
              objectFit="cover"
              src={urlFor(image).url()}
              objectPosition={
                image?.hotspot && getPositionFromHotspot(image.hotspot)
              }
              alt={image.alt ? image.alt : ""}
            />
          )}
        </div>
        <div className="lg:w-1/2 pb-8 px-6 lg:px-16">
          <h3 className="pt-6 pb-4 text-center font-p22 font-bold text-lg lg:text-3xl">
            {boldText}
          </h3>
          <h4 className="font-objektiv text-center px-2 text-sm lg:leading-9 lg:text-lg">
            {smallText}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default AntiSquaredImageAndText;
