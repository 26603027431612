import React from "react";
import AddToCart from "../Button/addToCartBtn";
import { GlobalConfig, Product } from "@lilbit/shared";

type Props = {
  heading: string;
  mainProduct: Product;
  setVippsModalOpen: Function;
  config: GlobalConfig;
};
const BannerWithBtn = (props: Props) => {
  const { heading, mainProduct, setVippsModalOpen, config } = props;

  return (
    <div className="flex flex-col items-center justify-center bg-primary-2 h-72 w-full lg:flex-row lg:h-px-328">
      <div className="flex items-center justify-start">
        <h4 className="font-bold text-center text-secondary-5 px-4">
          {heading}
        </h4>
      </div>
      <div className="flex flex-col space-y-6 pt-3 justify-around items-center lg:justify-start lg:flex-row lg:space-y-0 lg:pt-0 lg:space-x-3">
        <div className="flex items-center justify-center w-px-193">
          <AddToCart
            config={config}
            textColor="text-secondary-5"
            borderColor="border-secondary-5"
            mainProduct={mainProduct}
          />
        </div>
      </div>
    </div>
  );
};

export default BannerWithBtn;
