import { Product } from "../lib/types";
import { GlobalConfig } from "@lilbit/shared";
import { useRouter } from "next/router";
import NavBar from "./NavBar";
type Props = {
  config: GlobalConfig;
  urlFor: Function;
  siteName: "lildog" | "lilcat";
  mainProduct: Product;
};
const Header = ({ config, urlFor, siteName, mainProduct }: Props) => {
  const router = useRouter();
  const inCheckoutOrOrder =
    router.query.category === "checkout" || router.query.orderId;
  return (
    <div className={`${!inCheckoutOrOrder && "sticky top-0 z-50"}`}>
      <NavBar
        mainProduct={mainProduct}
        siteName={siteName}
        config={config}
        urlFor={urlFor}
      />
    </div>
  );
};

export default Header;
