import { Image as ImageType, getPositionFromHotspot } from "../../lib";
import Image from "next/image";
interface Props {
  SimpleBlockContent: any;
  body: any;
  image: ImageType;
  urlFor: any;
  header: string;
}
const LocalizedSectionten = (props: Props) => {
  const { image, SimpleBlockContent, body, urlFor, header } = props;
  return (
    <div className="w-full px-4 my-4 mx-auto bg-[#A1CFE8] 2xl:px-0">
      <div className="relative w-full h-[240px] sm:h-[300px] md:h-[400px] lg:h-[600px] flex flex-col justify-center items-center">
        <div>
          <Image
            src={urlFor(image).url()}
            layout="fill"
            objectFit="contain"
            objectPosition={
              image.hotspot && getPositionFromHotspot(image.hotspot)
            }
          />
        </div>
        <div className="absolute w-1/3 right-20 font-p22 space-y-4 hidden lg:block">
          <div className="text-2xl">{header}</div>
          <div>
            <SimpleBlockContent blocks={body} />
          </div>
        </div>
      </div>
      <div className="bg-[#A1CFE8] w-full rounded-b-[50px] pt-6 justify-center flex lg:hidden">
        <div className="font-p22 pb-12 max-w-prose text-center">
          <div className="text-2xl">{header}</div>
          <div>
            <SimpleBlockContent blocks={body} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalizedSectionten;
