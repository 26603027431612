import React from "react";
import Image from "next/image";
import { Specification } from "../../lib/types";
type Props = {
  boldTextFirst: string;
  boldTextsecond: string;
  specificationsFirst: Array<Specification>;
  specificationsSecond: Array<Specification>;
  urlFor: Function;
};

const AntiProductInFuture = ({
  boldTextFirst,
  specificationsFirst,
  boldTextsecond,
  specificationsSecond,
  urlFor,
}: Props) => {
  return (
    <div className="flex flex-col items-center justify-center font-objektiv text-secondary-5 bg-secondary-12 w-full py-12 rounded-3xl mt-5">
      <div className="flex flex-col w-full rounded-bl-3xl lg:flex-row">
        <div className="pl-4 pr-8 pb-12 lg:pb-0 lg:ml-8 lg:w-1/2">
          <div className="lg:h-40">
            <h3 className="flex text-center font-extrabold text-base lg:text-h3 lg:leading-h3 lg:px-16 pb-6 lg:pb-12">
              {boldTextFirst}
            </h3>
          </div>
          {specificationsFirst.map((specification, index) => {
            return (
              <div
                key={index}
                className="flex space-x-2 pl-6 lg:pl-12 flex-row pb-4 relative"
              >
                <div className="absolute left-10">
                  <Image
                    src={urlFor(specification.icon).url()}
                    width={30}
                    height={30}
                    quality={10}
                    objectFit="contain"
                    alt=""
                  />
                </div>
                <p className="pl-12 lg:pl-10 text-sm">
                  {specification.tagline}
                </p>
              </div>
            );
          })}
        </div>
        <div className="px-4 lg:pl-2 lg:w-1/2 lg:ml-8">
          <div className="lg:h-40">
            <h3 className="text-center font-extrabold text-base lg:text-h3 lg:leading-h3 lg:px-16 pb-6 lg:pb-12">
              {boldTextsecond}
            </h3>
          </div>

          {specificationsSecond.map((specification, index) => {
            return (
              <div
                key={index}
                className="flex space-x-4 pl-6 lg:pl-0 flex-row pb-4 relative"
              >
                <div className="absolute left-10 lg:left-0">
                  <Image
                    src={urlFor(specification.icon).url()}
                    width={30}
                    height={30}
                    quality={10}
                    objectFit="contain"
                    alt={specification.icon.alt || ""}
                  />
                </div>
                <p className="pl-10 text-sm">{specification.tagline}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default AntiProductInFuture;
