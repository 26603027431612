import Image from "next/image";
import { Image as ImageType } from "../../lib/types";

interface Props {
  cards: Array<{
    body: string;
    icon: ImageType;
    title: string;
    _key: string;
  }>;
  sr_text: string;
  urlFor: Function;
  header: string;
  small_text: string;
}

const Sectiontwentyfive = ({
  cards,
  sr_text,
  urlFor,
  header,
  small_text,
}: Props) => {
  return (
    <div className="py-12 w-full bg-primary-4 font-objektiv px-8 text-center pt-12 rounded-b-xl mb-8 lg:px-52">
      <h2 className="sr-only">{sr_text || ""}</h2>
      <h3 className="mt-2 text-[28px] leading-[38px] font-bold text-primary-1 md:text-[40px] md:leading-[50px]">
        {header || ""}
      </h3>
      <p className="mx-auto mt-5 max-w-prose text-[18px] leading-[28px] text-primary-1 pb-8 px-8">
        {small_text || ""}
      </p>
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <dl className="space-y-10 lg:space-y-0 lg:grid content-center lg:grid-cols-3 lg:gap-8">
          {cards?.map((card) => (
            <div
              key={card._key}
              className=" flex flex-col justify-start items-center"
            >
              <dt className="flex items-center justify-start flex-col">
                {card?.icon && (
                  <div className="relative w-[30px] h-[35px] bg-primary-4 px-20">
                    <Image
                      src={urlFor(card.icon).url()}
                      layout="fill"
                      className=""
                      objectFit="contain"
                      alt={card.icon.alt || ""}
                    />
                  </div>
                )}
                <p className="mt-5 text-center text-[22px] leading-[32px]  text-primary-1 font-bold">
                  {card.title || ""}
                </p>
              </dt>
              <dd className="mt-2 text-center text-[18px] leading-[25px] text-primary-1">
                {card.body || ""}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default Sectiontwentyfive;
