import Image from "next/image";
import { useState } from "react";
import { Image as ImageType } from "../../lib/types";
import { getPositionFromHotspot } from "../../lib/helpers";
import dynamic from "next/dynamic";
import PlayButton from "../svgs/playButton";

interface Props {
  image: ImageType;
  urlFor: Function;
  quote: string;
  auther: string;
  autherDescription: string;
  buttonText: string;
  video_ref: any;
}

const CustomMuxPlayer = dynamic(() => import("../customMuxPlayer"));

const SectionTwentyThree = (props: Props) => {
  const {
    image,
    urlFor,
    quote,
    auther,
    autherDescription,
    buttonText,
    video_ref,
  } = props;
  const [play, setPlay] = useState(false);
  return (
    <div
      className={`w-full my-8  ${
        play ? "h-[400px]" : "h-[650px]"
      } lg:h-[750px] relative flex`}
    >
      {video_ref?.asset && play ? (
        <div>
          <CustomMuxPlayer
            video={video_ref}
            autoload={true}
            autoplay={true}
            showControls={true}
            muted={false}
            loop={false}
          />
        </div>
      ) : (
        <div>
          {image && (
            <>
              <Image
                src={urlFor(image).url()}
                layout="fill"
                objectFit="cover"
                className="rounded-3xl"
                objectPosition={
                  image?.hotspot && getPositionFromHotspot(image.hotspot)
                }
                alt={image?.alt ? image.alt : ""}
                title={image?.alt ? image.alt : ""}
              />
              <div className="bg-black absolute w-full h-full bg-opacity-40 lg:bg-opacity-10 rounded-3xl" />
            </>
          )}
          <div className="absolute w-full md:w-4/6 lg:w-5/12 text-white  pt-40 px-12 lg:pl-12 h-full">
            <h2 className="  font-p22 font-bold text-center leading-[30px] lg:leading-[45px]  text-[25px] lg:text-[35px]">
              {quote || ""}
            </h2>
            <p className="font-objektiv text-center text-[20px] lg:text-[30px] leading-[25px] lg:leading-[37px] px-4 pt-12 font-bold">
              {auther || ""}
            </p>
            <p className="font-objektiv text-center text-[10px] lg:text-[12px] leading-[18px] px-16 sm:px-24 lg:px-12 pt-6 font-bold">
              {autherDescription || ""}
            </p>
            {buttonText && (
              <button
                onClick={() => setPlay(true)}
                className="flex flex-row justify-around  font-objektiv text-center  text-[32px] leading-[40px] px-4 pt-12 font-bold"
              >
                <PlayButton />
                <p className="lg:ml-2">{buttonText}</p>
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionTwentyThree;
