import { Image as ImageType } from "../../lib/types";
import Image from "next/image";
import { Product } from "../../lib";
import AddToCart from "../Button/addToCartBtn";

interface Props {
  mainText: string;
  secondaryText: string;
  buttonText: string;
  icons: Array<{
    text: Array<any>;
    image: ImageType;
    _key: string;
    _type: string;
  }>;
  urlFor: Function;
  SimpleBlockContent: any;
  mainProduct: Product;
  config;
}
const LocalizedIconsAndText = (props: Props) => {
  const {
    mainText,
    buttonText,
    icons,
    secondaryText,
    urlFor,
    SimpleBlockContent,
    mainProduct,
    config,
  } = props;

  return (
    <div className="relative w-full flex flex-col items-center justify-center py-4 sm:py-6 md:py-8">
      {mainText && (
        <SimpleBlockContent
          blocks={mainText}
          className="py-2 font-p22 font-semibold sm:font-bold text-center text-lg sm:text-xl lg:text-2xl"
        />
      )}
      <div className="flex flex-col h-full w-full justify-evenly">
        <div className="flex justify-evenly mx-4 sm:mx-12 md:mx-24 lg:mx-32 mt-4">
          {icons?.map((item) => {
            const { text, image, _key } = item;
            if (!image) return null; // TODO remove this when adding image in Sanity
            return (
              <div key={_key} className="relative">
                <div className="flex md:hidden justify-center">
                  <Image
                    src={urlFor(image, true).url()}
                    height={75}
                    width={75}
                    alt={image.alt || ""}
                    objectFit="contain"
                  />
                </div>
                <div className="hidden md:flex md:justify-center">
                  <Image
                    src={urlFor(image, true).url()}
                    height={150}
                    width={150}
                    alt={image.alt || ""}
                    objectFit="contain"
                  />
                </div>
                <div>
                  <SimpleBlockContent
                    blocks={text}
                    className="pb-2 max-w-[270px] md:px-4 text-sm md:text-lg text-center font-objektiv"
                  />
                </div>
              </div>
            );
          })}
        </div>
        {secondaryText && (
          <div className="flex flex-col items-center text-primary-1">
            <SimpleBlockContent
              blocks={secondaryText}
              className="py-2 max-w-[480px] text-primary-mono-2 font-objektiv font-bold self-center text-center text-sm sm:text-base md:py-8 md:text-lg lg:text-xl"
            />
          </div>
        )}
        <div className="mx-2 md:mx-0 w-full px-4 md:px-0 md:w-max self-center py-4">
          <AddToCart
            config={config}
            mainProduct={mainProduct}
            buyBtnText={buttonText}
            backgroundColor="bg-buy-btn"
            textColor="text-white"
            borderWidth={0}
          />
        </div>
      </div>
    </div>
  );
};

export default LocalizedIconsAndText;
