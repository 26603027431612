import React, { useState, useContext, createContext, ReactNode } from "react";

type SiteName = "lildog" | "lilcat";

type SiteNameContextType = {
  siteName: SiteName;
  cookieConsentGiven: boolean;
};

export const SitenameContext = createContext({} as SiteNameContextType);

export const useSitename = () => useContext(SitenameContext);

const SitenameProvider = ({
  name,
  children,
  cookieConsentGiven,
}: {
  name: SiteName;
  children: ReactNode;
  cookieConsentGiven: boolean;
}) => {
  const [siteName, _] = useState(name);

  return (
    <SitenameContext.Provider value={{ siteName, cookieConsentGiven }}>
      {children}
    </SitenameContext.Provider>
  );
};
export default SitenameProvider;
