import React, { useState, useEffect } from "react";
export const AppContext = React.createContext([{}, () => {}]);

type Props = {
  children: any;
};
export const AppProvider = (props: Props) => {
  const [cart, setCart] = useState(null);

  useEffect(() => {
    //@ts-ignore
    if (process.browser) {
      let cartData = localStorage.getItem("woo-next-cart");
      cartData = null !== cartData ? JSON.parse(cartData) : "";
      setCart(cartData);
    }
  }, []);

  return (
    <AppContext.Provider value={[cart, setCart]}>
      {props.children}
    </AppContext.Provider>
  );
};
