import { Image as ImageType } from "../../lib/types";
import Image from "next/image";
import { getPositionFromHotspot } from "../../lib/helpers";

interface Props {
  bubbleText: string;
  header: string;
  leftAppImage: ImageType;
  rightAppImage: ImageType;
  middleText: string;
  subText: string;
  urlFor: Function;
}
const LocalizedHotelEcosystem = (props: Props) => {
  const {
    bubbleText,
    header,
    leftAppImage,
    middleText,
    subText,
    urlFor,
    rightAppImage,
  } = props;
  return (
    <div className="w-full bg-primary-mono-3 relative lg:pt-24 lg:mb-24">
      <div className="max-w-7xl mx-auto flex flex-col items-center justify-start font-p22 p-4 min-h-[850px] relative">
        <div className="w-full z-20 flex flex-col items-center space-y-4 lg:space-y-12">
          <div className="bg-primary-mono-4 max-w-lg p-4 rounded-t-xl rounded-br-xl">
            <h3 className="text-3xl text-primary-mono-1">{header}</h3>
            <p className="text-primary-mono-1 lg:text-lg">{subText}</p>
          </div>
          <h4 className="text-primary-mono-1 font-bold lg:text-xl">
            {middleText}
          </h4>
        </div>
        <div className="flex flex-col w-full">
          <div className="relative lg:absolute w-full lg:w-2/3 bottom-0 right-0 h-[300px] lg:h-[500px] z-10 lg:right-24">
            <Image
              src="/backgrounds/Rendered_cages.svg"
              layout="fill"
              objectFit="contain"
            />
            <div className="bg-primary-mono-4/90 w-44 h-44 rounded-full flex items-center justify-center px-2 lg:mx-2 absolute top-0 -right-2 z-0 lg:hidden">
              <div className="relative flex items-center justify-center">
                <h4 className="text-lg text-primary-mono-1 font-bold text-center">
                  {bubbleText}
                </h4>
              </div>
            </div>
          </div>
          <div className="relative w-5/6 h-[600px] z-10 rotate-6 self-center lg:hidden">
            <Image
              src={urlFor(rightAppImage).url()}
              layout="fill"
              objectFit="contain"
            />
          </div>
        </div>
        <div className="bg-primary-mono-4/90 w-44 h-44 rounded-full items-center justify-center px-2 lg:mx-2 absolute top-10 left-0 z-0 hidden lg:flex">
          <div className="relative flex items-center justify-center">
            <h4 className="text-lg text-primary-mono-1 font-bold text-center">
              {bubbleText}
            </h4>
          </div>
        </div>
        <div className="relative hidden lg:block lg:absolute -bottom-10 left-14 w-full lg:w-[290px] h-[600px] z-10 rotate-12">
          <Image
            src={urlFor(leftAppImage).url()}
            layout="fill"
            objectFit="contain"
          />
        </div>
        <div className="relative hidden lg:block lg:absolute -bottom-20 right-14 w-full lg:w-[290px] h-[600px] z-10 -rotate-6">
          <Image
            src={urlFor(rightAppImage).url()}
            layout="fill"
            objectFit="contain"
          />
        </div>
        <div className="bg-primary-mono-4/60 w-44 h-44 rounded-full absolute -top-20 left-44 z-0 hidden lg:block" />
        <div className="bg-primary-mono-4/60 w-32 h-32 rounded-full absolute top-64 left-10 z-0 hidden lg:block" />
        <div className="bg-primary-mono-4/60 w-44 h-44 rounded-full absolute top-36 right-72 z-0 hidden lg:block" />
        <div className="bg-primary-mono-4/60 w-44 h-44 rounded-full absolute top-10 right-20 z-0 hidden lg:block" />
      </div>
      <div className="h-24 bg-white w-full absolute -bottom-24 z-30 hidden lg:block" />
    </div>
  );
};

export default LocalizedHotelEcosystem;
