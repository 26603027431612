import { locales, defaultLocale } from "../lib/app-config";
import { useRouter, NextRouter } from "next/router";
import { NextPageContext } from "next";

const validLocale = (urlPrefix) => {
  return locales.find((l) => l.urlPrefix === urlPrefix) || defaultLocale;
};

const getLocaleFromRouter = (router: NextRouter) => {
  if (router.query?.locale) {
    return validLocale(router.query.locale);
  }
  // Fallback to using the first part of the asPath
  return validLocale(
    router.asPath?.split("/").filter(Boolean)[0]?.split("?")[0]
  );
};

// Get the current locale
export const useLocale = () => {
  const router = useRouter();

  return getLocaleFromRouter(router);
};

export const getValidLocale = (locale: string) => {
  return validLocale(locale);
};

export const getLocaleFromContext = ({ query, asPath }: NextPageContext) => {
  if (query?.lang) {
    return validLocale(query.lang);
  }

  // Fallback to using the first part of the asPath
  return validLocale(asPath?.split("/").filter(Boolean)[0]);
};

export const getCountryParamFromContext = (ctx: NextPageContext) => {
  const urlString = ctx.asPath;
  const baseUrl = "http://example.com"; // Just for parsing purposes
  const url = new URL(urlString, baseUrl);
  return url.searchParams.get("country");
};
