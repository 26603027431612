export default {
  not_found: {
    back_home: {
      en: "Go back home",
      no: "Tilbake til hjemmeside",
    },
    page_not_found: {
      en: "Page not found",
      no: "Siden finnes ikke",
    },
    check_address: {
      en: "Please check the URL in the address bar and try again.",
      no: "Vennligst sjekk URLen i adressefeltet og prøv på nytt.",
    },
    contact_support: {
      en: "Contact support",
      no: "Kontakt kundeservice",
    },
  },
  checkout_page: {
    update_order: {
      en: "Update order",
      no: "Oppdater bestilling",
    },
    pay: {
      en: "Pay",
      no: "Betal",
    },
    vipps: {
      en: "Pay",
      no: "Hurtigkasse",
    },
    backBtn: {
      en: "Back",
      no: "Tilbake",
    },
    loading_payment_text: {
      no: "Betaling tar noen sekunder. Vennligst ikke forlat denne siden",
      en: "Payment takes a few seconds. Please do not leave this page",
    },
    price: {
      no: "Pris",
      en: "Price",
    },
    pay_with_card: {
      no: "Betal med kort",
      en: "Pay with credit card",
    },
    pay_with_vipps: {
      no: "Betal med Vipps",
      en: "Pay with Vipps",
    },
    pay_with_vipps_quick: {
      no: "Betal med Vipps Hurtigkasse",
      en: "Pay with Vipps Express",
    },
    finish_with_vipps: {
      no: "Fullfør med vipps",
      en: "Finish with vipps",
    },
    choose_payment_method: {
      no: "Velg betalingsmetode",
      en: "Choose payment method",
    },
    show_order: {
      en: "Show details",
      no: "Vis detaljer",
    },
    hide_order: {
      en: "Hide order",
      no: "Skjul ordre",
    },
    sub_info: {
      en: "This is the price of the lildog monthly subscription",
      no: "Dette er prisen på lildog månedlig abonnementet",
    },
    ship_info: {
      en: "Shipping costs",
      no: "Frakt kostnader",
    },
    sub_totla_info: {
      en: "The total sum excluding taxes",
      no: "Total ekskludert skatter",
    },
    tax_info: {
      en: "Taxes and VAT",
      no: "Skatter og moms",
    },
    vipps_express_info: {
      en: "You can use this payment method if you want us to get your address from your Vipps account",
      no: "Du kan bruke denne betalingsmåten hvis du ønsker å spare tid, så henter vi adressen fra Vipps-kontoen din",
    },
    vipps_info: {
      en: "This payment method can be used if you want to fill the address yourself and pay with Vipps Application",
      no: "Denne betalingsmåten kan brukes hvis du vil fylle ut adressen selv og betale med Vipps-applikasjonen",
    },
    edit: { en: "Edit order", no: "Rediger bestilling" },
    cancel: { en: "Cancel", no: "Avbryt" },
    discount: { en: "Discount", no: "Rabatt" },
    add_code: { en: "Add Code", no: "Legg til rabattkode" },
    subscription: {
      en: "10 years unlimeted use",
      no: "10 år ubegrenset bruk",
    },
    included: { en: "Included", no: "Inkludert" },
    shipping: { en: "Shipping", no: "Frakt" },
    taxes: { en: "VAT", no: "MVA" },
    subtotal: { en: "Subtotal", no: "Delsum" },
    pc: { en: "pc.", no: "stk." },
    update: { en: "Save", no: "Lagre" },
    enter_code: { en: "Enter discount code", no: "Skriv inn rabattkoden" },
    your_details: { en: "Your details", no: "Detaljene dine" },
    shipping_address: { en: "Shipping", no: "Frakt" },
    next: { en: "Next", no: "Neste" },
    discounts: { en: "Discounts", no: "Rabatter" },
    payment: { en: "Payment", no: "Betaling" },
    accept_terms: {
      en: "I accept the terms and conditions",
      no: "Jeg aksepterer lilbit sine vilkår og betingelser",
    },
    read_terms: {
      en: "Read our terms",
      no: "les vilkår og betingelser",
    },
    news_letter: {
      en: "Subscribe to newsletter",
      no: "Meld deg på nyhetsbrev",
    },
    vipps_express: {
      en: "Vipps",
      no: "Vipps",
    },
    vipps_normal: { en: "Vipps payment", no: "Vipps betaling" },
    credit_card_payment: {
      en: "Credit card",
      no: "Kredittkort",
    },
    klarna_payment: {
      en: "Klarna",
      no: "Klarna",
    },
    req_field: {
      en: "= required field",
      no: "= Obligatorisk felt",
    },
    support_help_call: {
      en: "Call",
      no: "Ring",
    },
    support_help_mail: {
      en: "or send an email to",
      no: "eller send epost til",
    },
    add_desc: {
      no: "Legg til ekstra fraktbeskrivelse",
      en: "Add extra shipping details",
    },
    add_comment: {
      no: "Ekstra fraktbeskrivelse",
      en: "Extra shipping details",
    },
  },
  front_page: {
    delivery_statement: {
      en: "Delivered to your home",
      no: "Leveres på døra",
    },
    vat_included: {
      en: "VAT included",
      no: "MVA inkludert",
    },
    max_one_unit: {
      en: "Maximum 1 unit per customer",
      no: "Maks 1 enhet pr. kunde",
    },
    cookies: {
      en: "We use essential cookies for basic functions and 3rd party marketing cookies for an enhanced experience.",
      no: "Vi bruker nødvendige cookies for grunnleggende funksjoner og 3-parts markedsføringscookies for bedre brukeropplevelse.",
      se: "Vi använder nödvändiga cookies för grundläggande funktioner och 3-parts marknadsföringscookies för bättre användarupplevelse.",
      da: "Vi bruger nødvendige cookies til grundlæggende funktioner og 3-parts markedsføringscookies til en bedre brugeroplevelse.",
      fi: "Käytämme välttämättömiä evästeitä perustoimintoihin ja kolmannen osapuolen markkinointievästeitä paremman käyttökokemuksen saamiseksi.",
      fr: "Nous utilisons des cookies essentiels pour les fonctions de base et des cookies marketing de tiers pour une expérience améliorée.",
      es: "Utilizamos cookies esenciales para funciones básicas y cookies de marketing de terceros para una experiencia mejorada.",
      de: "Wir verwenden wesentliche Cookies für grundlegende Funktionen und Marketing-Cookies von Drittanbietern für ein verbessertes Erlebnis.",
    },
    cookiesDecline: {
      en: "Accept Necessary",
      no: "Godta Nødvendige",
      se: "Acceptera Nödvändiga",
      da: "Accepter Nødvendige",
      fi: "Hyväksy Välttämättömät",
      fr: "Accepter Nécessaire",
      es: "Aceptar Necesario",
      de: "Akzeptieren Notwendig",
    },
    cookiesButton: {
      en: "Accept",
      no: "Godta",
      se: "Acceptera",
      da: "Accepter",
      fi: "Hyväksy",
      fr: "Accepter",
      es: "Aceptar",
      de: "Akzeptieren",
    },
  },
  order_summary: {
    Thanks_for_ordering: {
      no: "Takk for din bestilling ",
      en: "Thank you for giving dogs a better life! You're awesome 🐕",
    },
    order_failed: {
      en: "Something went wrong, your order was not paid.",
      no: "Noe gikk galt, orderen ble ikke betalt.",
    },
    your_order_has_been_received: {
      no: "Din bestilling er mottatt. Vi vil behandle den og sende ut så snart vi kan. En ordrebekreftelse er sendt til deg på",
      en: "Your order has been received. We will process it and send it out as soon as we can. An order confirmation has been sent to you at",
    },
    delivery_details: {
      no: "Leveringsdetaljer",
      en: "Delivery details",
    },
    order_notes: {
      en: "Customer provided note:",
      no: "Notater:",
    },
    track_order: {
      no: "Spor din ordre",
      en: "Track your order",
    },
    track_url: {
      no: "Lenke til sporing:",
      en: "Tracking Url:",
    },
    summary: {
      no: "Bestillingsinfo",
      en: "Order Summary",
    },
    manage_order: {
      no: "Endre ordren",
      en: "Manage order",
    },
    your_info: {
      no: "Din informasjon",
      en: "Your information",
    },
    order_number: {
      no: "Ordrenummer:",
      en: "Order number:",
    },
    order_date: {
      no: "Ordredato:",
      en: "Order date:",
    },
    pay_type: {
      no: "Betalingstype:",
      en: "Payment type:",
    },
    include: {
      no: "Inkludert",
      en: "Included",
    },
    subscription: {
      en: "10 years unilimeted use",
      no: "10 år ubegrenset bruk",
    },
    tax: { en: "VAT", no: "MVA" },
    shipping: {
      no: "Frakt:",
      en: "Shipping:",
    },
    subtotal: {
      no: "Delsum:",
      en: "Subtotal:",
    },
    discounts: {
      en: "Discounts:",
      no: "Rabatter:",
    },
    total: {
      no: "Total",
      en: "Total",
    },
    regret: {
      no: "Angre på kjøpet ditt?",
      en: "Regret your purchase?",
    },
    cancel: {
      no: "Avbestill/returner ordre",
      en: "Cancel order/return order",
    },
    wrong: {
      no: "Noe galt?",
      en: "Something wrong?",
    },
    contact: {
      no: "Kontakt support",
      en: "Contact support",
    },
    help: {
      no: "Trenger du mer hjelp?",
      en: "Need more help?",
    },
    phone: {
      no: "Ring (+47) 73 44 00 70",
      en: "Call (+47) 73 44 00 70",
    },
    no_access: {
      en: "You don't have access to this order. Use the same device which you used to purchase the order or contact support",
      no: "Du har ikke tilgang til denne siden. Bruk samme enhet som du brukte for å bestille orderen eller ta kontakt med kundeservice",
    },
    something_wrong: {
      en: "Something went wrong!",
      no: "Noe gikk galt!",
    },
    header_payment_cancelled: {
      en: "Your order is cancelled!",
      no: "Bestillingen din er kansellert!",
    },
    header_payment_refunded: {
      en: "Your order is refunded!",
      no: "Bestillingen din er refundert!",
    },
    try_again: {
      en: "Try again",
      no: "Prøv på nytt",
    },
    back_to_payment: {
      en: "Back to payment page",
      no: "Tilbake til betalingsside",
    },
    payment_failed: {
      en: "Your transaction has failed, Please try again or call our customer support",
      no: "Betaling mislyktes, prøv på nytt eller kontakt kundeservice",
    },
    payment_cancelled: {
      en: "Your transaction has been cancelled, Please try again or call our customer support",
      no: "Transaksjonen din er kansellert. Prøv igjen eller ring vår kundestøtte",
    },
    payment_refunded: {
      en: "Your transaction has been refunded. Please contact our customer support if you need more information",
      no: "Transaksjonen din er refundert. Vennligst kontakt kundeservice hvis du trenger mer informasjon",
    },
    cancel_info: {
      no: "Du kan kansellere en bestilling innen omtrent en time etter at du har lagt den ved å besøke denne siden. Etter at den timen har gått, kan det være at vi ikke kan kansellere bestillingen din. Vi tilbyr imidlertid alltid 30 dagers gratis retur på bestillingen.",
      en: "You may cancel an order within approximately one hour after placing it by visiting this page. After that hour has passed, we might not be able to cancel your order. However, we always offer 30 day free return on your order.",
    },
    if_you_have_any_questions: {
      no: "Hvis du har noen spørsmål om din ordre, ikke nøl med å kontakte vår kundeservice eller ring oss på  +47 73 02 11 99.",
      en: "If you have any questions about your order, do not hesitate to contact our customer service or call us on +47 73 02 11 99.",
    },

    product: {
      no: "Produkt",
      en: "Product",
    },
    number: {
      no: "Antall produkter",
      en: "Number of products",
    },
    total_order: {
      no: "Totalsum",
      en: "Total",
    },
    name: {
      no: "Navn",
      en: "Name",
    },

    email: {
      no: "E-postadresse",
      en: "Email",
    },
    delivery_address: {
      no: "Leveringsadresse",
      en: "Delivery address",
    },
  },
  tooltips: {
    required_fields: {
      no: "Fyll ut alle obligatoriske felt",
      en: "Please fill out all required fields",
    },
    accept_terms: {
      no: "Godta vilkårene først",
      en: "Please accept terms and conditions first",
    },
  },
  checkout_errors: {
    accept_terms: {
      no: "Vilkår og betingelser må leses og godtas for å legge til bestilling. Merk av boksen hvis du er enig.",
      en: "Terms and conditions needs to be read and accepted to place an order. Please tick the box if you agree.",
    },
    must_be: {
      no: "må være",
      en: "must be",
    },
    to: {
      no: "til",
      en: "to",
    },
    characters: {
      no: "tegn",
      en: "characters",
    },
    is_not_valid: {
      no: "er ikke gyldig",
      en: "is not valid",
    },
    is_requiered: {
      no: "Vennligst legg til",
      en: "Please add",
    },
    first_name: {
      no: "Fornavn",
      en: "First name",
    },
    last_name: {
      no: "Etternavn",
      en: "Last name",
    },
    country: {
      no: "Land",
      en: "Country",
    },
    address1: {
      no: "Gateadresse",
      en: "Street address",
    },
    city: {
      no: "By",
      en: "City field",
    },
    post_code: {
      no: "Postnummer",
      en: "Post code",
    },
    phone_number: {
      no: "Telefonnummeret",
      en: "Phone number",
    },
    coupon: {
      no: "Kupong",
      en: "Coupon",
    },
    terms_and_conditions: {
      no: "Please read and accept terms and conditions first",
      en: "Les og aksepter kjøpsvilkår først",
    },
    could_not_check_coupon: {
      no: "Kunne ikke sjekke kupongen, kontakt kundeservice",
      en: "Could not check you coupon, please contact the support team",
    },
    coupon_is_used: {
      no: "Denne kupongen er brukt, og kan ikke gjenbrukes",
      en: `The coupon is used once before, and can't be used again`,
    },
    coupon_does_not_exist: {
      no: "Kupongen eksisterer ikke",
      en: "The coupon does not exist",
    },
  },
  Signin: {
    name: {
      en: "Name",
      no: "Navn",
    },
    no_user: {
      no: "Har du ikke en bruker? Registrer deg her",
      en: "Don't have a user? Register here",
    },
    have_user: {
      no: "Har du allerede en bruker? trykk her for å logg inn",
      en: "Already have a user? Click here to sign in",
    },
    repeat_password: {
      no: "Skriv passordet på nytt",
      en: "Repeat password",
    },
    password: {
      no: "Passord",
      en: "Password",
    },
    email: {
      no: "E-postadresse",
      en: "Email",
    },
    no_match: {
      no: "Skriv samme passord i begge feltene",
      en: "The passwords does not match!",
    },
    reset_password: {
      no: "Tilbakestill passord",
      en: "Reset password",
    },
    signout: {
      en: "Sign out",
      no: "Logg ut",
    },
  },

  Dashboard: {
    account_header: {
      en: "My account",
      no: "Min bruker",
    },
    product_header: {
      en: "My products",
      no: "Mine produkter",
    },
  },
  Footer: {
    AboutAi: {
      en: "About AI technology",
      no: "Om kunstig intelligens",
    },
    AboutCompany: {
      en: "About the company",
      no: "Om selskapet",
    },
    Account: {
      en: "Account",
      no: "Bruker",
    },
    Buy: {
      en: "Buy lildog",
      no: "Kjøp lildog",
    },
    Cookies: {
      en: "Cookies",
      no: "Informasjonskapsler",
    },
    Contact: {
      en: "Contact us",
      no: "Kontakt oss",
    },
    Company: {
      en: "Company",
      no: "Selskapet",
    },
    Currency: {
      en: "Currency",
      no: "Valuta",
    },
    Dogbreeds: {
      en: { title: "Dogbreeds", url: "dogbreeds" },
      no: { title: "Hunderaser", url: "hunderaser" },
    },
    Feedback: {
      en: "Feedback",
      no: "Tilbakemeldinger",
    },
    ForDevelopers: {
      en: "For developers",
      no: "For utviklere",
    },
    Faq: {
      en: "Frequently asked questions",
      no: "Ofte stilte spørsmål",
    },
    GetApp: {
      en: "Get the app",
      no: "Skaff deg appen",
    },
    Legal: {
      en: "Legal",
      no: "Juridisk",
    },
    Mission: {
      en: "Our mission",
      no: "Vårt mål",
    },
    More: {
      en: "More",
      no: "Mer",
    },
    Marketing: {
      en: "Marketing",
      no: "Markedsføring",
    },
    News: {
      en: { title: "News", url: "news" },
      no: { title: "Nyheter", url: "nyheter" },
    },
    Norway: {
      en: "Norway",
      no: "Norge",
    },
    PrivacyPolicy: {
      en: "Privacy",
      no: "Personvern",
    },
    PrivacyPolicyUrl: {
      en: "privacy",
      no: "personvern",
    },
    Product: {
      en: "Product",
      no: "Produkt",
    },
    Support: {
      en: "Support",
      no: "Kundeservice",
    },
    Terms: {
      en: "Terms & conditions",
      no: "Vilkår og betingelser",
    },
    TermsUrl: {
      en: "conditions",
      no: "betingelser",
    },
    WorkHere: {
      en: "Work at lildog",
      no: "Jobb hos oss",
    },
  },
  AddToCart: {
    en: "Buy now",
    no: "Kjøp nå",
  },
  Blog: {
    readMore: {
      en: "Read more",
      no: "Les mer",
    },
    shareKnowledge: {
      title: {
        en: "",
        no: "",
      },
      subtitle: {
        en: "Feel free to share",
        no: "Del gjerne",
      },
    },
    published: {
      no: "Publisert",
      en: "Published at",
    },
    updated: {
      en: "Last updated",
      no: "Sist oppdatert",
    },
    facts: {
      en: "Facts",
      no: "Fakta",
    },
  },
  ProductTranslations: {
    AddToCart: {
      en: "Add to cart",
      no: "Legg i handlevogn",
    },
    Remove: {
      en: "Remove",
      no: "Fjern",
    },
    InStock: {
      en: "In stock",
      no: "På lager",
    },
  },
  Currency: {
    no: "kr",
    en: "€",
  },
  navbar: {
    menu_1: {
      en: "Hide menu",
      no: "Skjul meny",
    },
    menu_2: {
      en: "Menu",
      no: "Meny",
    },
  },
  Banner: {
    header: {
      no: "Forhåndssalg starter snart!",
      en: "Presale starting soon!",
    },
  },
  BuyProductSection: {
    Subheaders: {
      AboutTheUnit: {
        en: "About the unit",
        no: "Om enheten",
      },
      IncludedInPurchase: {
        en: "Included in the purchase",
        no: "Inkludert i kjøpet",
      },
      Highlights: {
        en: "Highlights",
        no: "Høydepunkter",
      },
    },
    Vipps_express: {
      en: "Preorder",
      no: "Forhåndskjøp",
    },
  },
  Vippsmodalbutton: {
    Terms: {
      en: "Terms and conditions",
      no: "Kjøpsvilkår",
    },
  },
};
