import { Image as ImageType } from "../../lib/types";
import Image from "next/image";

interface Props {
  mainText: string;
  icons: Array<{
    text: Array<any>;
    image: ImageType;
    _key: string;
    _type: string;
  }>;
  urlFor: Function;
  SimpleBlockContent: any;
}
const LocalizedHeaderAndIcons = (props: Props) => {
  const { mainText, icons, urlFor, SimpleBlockContent } = props;
  const noOfIcons = icons?.length;
  return (
    <div className="relative w-full bg-primary-4 flex flex-col items-center justify-center py-4 sm:py-6 md:py-8">
      {mainText && (
        <SimpleBlockContent
          blocks={mainText}
          className="py-2 text-primary-2 font-p22 font-medium text-center text-lg sm:text-xl lg:text-2xl"
        />
      )}
      <div className="flex flex-col h-full w-full justify-evenly">
        <div
          className={`grid ${
            noOfIcons === 3 ? "grid-cols-3" : "grid-cols-2"
          } md:flex md:justify-around w-full`}
        >
          {icons?.map((item) => {
            const { text, image, _key } = item;
            return (
              <div key={_key} className="relative flex flex-col items-center">
                <div className="flex md:hidden justify-center">
                  <Image
                    src={urlFor(image, true).url()}
                    height={60}
                    width={60}
                    alt={image.alt || ""}
                  />
                </div>
                <div className="hidden md:flex md:justify-center">
                  <Image
                    src={urlFor(image, true).url()}
                    height={80}
                    width={80}
                    alt={image.alt || ""}
                  />
                </div>
                <div>
                  <SimpleBlockContent
                    blocks={text}
                    className="pb-2 max-w-[160px] px-4 text-xs text-primary-2 font-medium text-center font-objektiv sm:max-w-[200px] sm:text-sm md:text-base"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LocalizedHeaderAndIcons;
