import { svg } from "../../lib";
const KlarnaLogo = ({ width, height }: svg) => {
  return (
    <svg
      height={height}
      viewBox="4.413 5.76850966 37.5527723 11.47054642"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <rect fill="#ffb3c7" height="25" rx="4.321" width="45" />
        <path
          d="m40.794 14.646a1.07 1.07 0 0 0 -1.066 1.076 1.07 1.07 0 0 0 1.066 1.076c.588 0 1.066-.482 1.066-1.076a1.07 1.07 0 0 0 -1.066-1.076zm-3.508-.831c0-.814-.689-1.473-1.539-1.473s-1.539.66-1.539 1.473.69 1.472 1.54 1.472 1.538-.659 1.538-1.472zm.006-2.863h1.698v5.725h-1.698v-.366a2.96 2.96 0 0 1 -1.684.524c-1.653 0-2.993-1.352-2.993-3.02s1.34-3.02 2.993-3.02c.625 0 1.204.193 1.684.524zm-13.592.746v-.745h-1.739v5.724h1.743v-2.673c0-.902.968-1.386 1.64-1.386h.02v-1.665c-.69 0-1.323.298-1.664.745zm-4.332 2.117c0-.814-.689-1.473-1.539-1.473s-1.539.66-1.539 1.473.69 1.472 1.54 1.472 1.538-.659 1.538-1.472zm.006-2.863h1.699v5.725h-1.699v-.366c-.48.33-1.059.524-1.684.524-1.653 0-2.993-1.352-2.993-3.02s1.34-3.02 2.993-3.02c.625 0 1.204.193 1.684.524zm10.223-.153c-.678 0-1.32.212-1.75.798v-.644h-1.691v5.724h1.712v-3.008c0-.87.578-1.297 1.275-1.297.746 0 1.176.45 1.176 1.285v3.02h1.696v-3.64c0-1.332-1.05-2.238-2.418-2.238zm-17.374 5.878h1.778v-8.275h-1.778zm-7.81.002h1.883v-8.279h-1.882zm6.586-8.279c0 1.792-.692 3.46-1.926 4.699l2.602 3.58h-2.325l-2.827-3.89.73-.552a4.768 4.768 0 0 0 1.902-3.837h1.842z"
          fill="#0a0b09"
        />
      </g>
    </svg>
  );
};
export default KlarnaLogo;
