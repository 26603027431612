import React from "react";
import { useLocale } from "../translations";
interface Props {
  page: string;
}
const ThirtyDayFreeTrial = (props: Props) => {
  const urlPrefix = useLocale().urlPrefix;
  const page = props.page.toLowerCase();
  return (
    <div className="bg-primary-3 w-full h-40 flex flex-col justify-center items-center text-center">
      <p className="text-primary-1 font-semibold text-xl ">
        {translations.moneyBack[urlPrefix] ?? translations.moneyBack.en}
      </p>
      {page === "lildog" && (
        <p className="text-primary-1 font-normal text-xl ">
          {translations.tryNowLildog[urlPrefix] ?? translations.tryNowLildog.en}
        </p>
      )}
      {page === "lilcat" && (
        <p className="text-primary-1 font-normal text-xl ">
          {translations.tryNowLilcat[urlPrefix] ?? translations.tryNowLilcat.en}
        </p>
      )}
    </div>
  );
};

const translations = {
  tryNowLildog: {
    no: "Prøv både Lildog og abonnement gratis i 30 dager",
    en: "Try Lildog and subscription for free for 30 days",
    se: "Prova Lildog och prenumeration gratis i 30 dagar",
    da: "Prøv både Lildog og abonnement gratis i 30 dager",
    dk: "Prøv både Lildog og abonnement gratis i 30 dager",
    fi: "Kokeile Lildogia ja tilausta ilmaiseksi 30 päivän ajan",
    de: "Probieren Sie Lildog und Abonnement 30 Tage lang kostenlos aus",
    fr: "Essayez Lildog et l'abonnement gratuitement pendant 30 jours",
    es: "Prueba Lildog y la suscripción gratis durante 30 días",
  },
  tryNowLilcat: {
    no: "Prøv både Lilcat og abonnement gratis i 30 dager",
    en: "Try Lilcat and subscription for free for 30 days",
    se: "Prova Lilcat och prenumeration gratis i 30 dagar",
    da: "Prøv både Lilcat og abonnement gratis i 30 dager",
    dk: "Prøv både Lilcat og abonnement gratis i 30 dager",
    fi: "Kokeile Lilcatia ja tilausta ilmaiseksi 30 päivän ajan",
    de: "Probieren Sie Lilcat und Abonnement 30 Tage lang kostenlos aus",
    fr: "Essayez Lilcat et l'abonnement gratuitement pendant 30 jours",
    es: "Prueba Lilcat y la suscripción gratis durante 30 días",
  },
  moneyBack: {
    no: "Penger tilbake garanti",
    en: "Money back guarantee",
    se: "Pengarna tillbaka-garanti",
    da: "Penger tilbake garanti",
    dk: "Penger tilbake garanti",
    fi: "Rahat takaisin -takuu",
    de: "Geld-zurück-Garantie",
    fr: "Garantie de remboursement",
    es: "Garantía de devolución de dinero",
  },
};

export default ThirtyDayFreeTrial;
