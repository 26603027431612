import React from "react";

const SnowFall = ({ style }) => {
  return (
    <>
      <div style={style} className="snowflake">
        •
      </div>
    </>
  );
};

export default SnowFall;
