import { useState } from "react";
import { useLocale } from "../../translations";
import axios from "axios";
import { BiLoaderCircle } from "react-icons/bi";
import Link from "next/link";
interface FormState {
  name: string;
  email: string;
  phone: string;
  company: string;
  product: string;
}

interface Props {
  title: string;
  content: string;
}

const LocalizedDistributer = (props: Props) => {
  const { title, content } = props;
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const urlPrefix = useLocale().urlPrefix;
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    product: "",
  });

  const inputFields = [
    {
      id: "name",
      placeholder: translations.name[urlPrefix],
      value: formState.name,
      type: "text",
      onchange: (e: any) => handleChange("name", e.target.value),
    },
    {
      id: "email",
      placeholder: translations.mail[urlPrefix],
      value: formState.email,
      type: "email",
      onchange: (e: any) => handleChange("email", e.target.value),
    },
    {
      id: "phone",
      placeholder: translations.phone[urlPrefix],
      value: formState.phone,
      type: "tel",
      onchange: (e: any) => handleChange("phone", e.target.value),
    },
    {
      id: "company",
      placeholder: "Company",
      value: formState.company,
      type: "text",
      onchange: (e: any) => handleChange("company", e.target.value),
    },
  ];

  const handleChange = (field: keyof FormState, value: string) => {
    setFormState({
      ...formState,
      [field]: value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const subject = translations.subjeckt[urlPrefix];
    const body = `Someone wants to sell us!\nName: ${formState.name} \nEmail: ${formState.email} \nPhone: ${formState.phone}\nCompany: ${formState.company}\nProduct Interest: ${formState.product}`;
    await axios
      .post("/api/send-email", {
        subject: subject,
        text: body,
      })
      .then(() => {
        setEmailSent(true);
        setLoading(false);
      });
  };

  const handleResend = () => {
    setEmailSent(false);
    for (let key in formState) {
      formState[key] = "";
    }
  };

  const selectOptions = [
    urlPrefix == "no" ? "Produkt" : "Product",
    "Lildog Tracker + Lildog Hotel",
    "Lildog Tracker",
    "Lildog Hotel",
  ];

  return (
    <div className="flex flex-col h-screen w-screen justify-start items-center">
      {!emailSent ? (
        <div className="w-4/5 md:w-3/5 lg:w-2/5 pt-12 space-y-4">
          <p className="font-p22 text-xl">{title && title}</p>
          <p className="font-default text-base">{content && content}</p>
          {inputFields.map((field) => (
            <input
              className="block w-full rounded-md shadow-sm focus:border-mono-2 focus:ring-primary-mono-2 sm:text-sm"
              id={`distributer_input_${field.id}`}
              key={field.id}
              type={field.type}
              placeholder={field.placeholder}
              value={field.value}
              onChange={field.onchange}
            />
          ))}
          <select
            onChange={(e) => handleChange("product", e.target.value)}
            defaultValue={""}
            id="product-select"
            name="product"
            className={`block w-full rounded-md shadow-sm focus:border-primary-mono-2 focus:ring-primary-mono-2 sm:text-sm `}
          >
            {selectOptions.map((option, i) => (
              <option key={i} id={`distributer_option_${i}`} value={option}>
                {option}
              </option>
            ))}
          </select>
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="disabled:opacity-50 disabled:cursor-not-allowed px-4 py-2 w-full  bg-primary-1 text-white rounded-md"
          >
            {!loading ? (
              "Send"
            ) : (
              <BiLoaderCircle className="animate-spin inline-block" />
            )}
          </button>
        </div>
      ) : (
        <div className="w-fit h-fit justify-between pt-12 items-center text-center font-default text-base space-y-10">
          <p>{translations.succes[urlPrefix]}</p>
          <div className="border-2 text-left p-8">
            <p className="font-bold border-b-2">
              {translations.checkInfo[urlPrefix]}:
            </p>
            {inputFields.map((field, i) => (
              <p key={`distribute_summary_${i}`}>
                {field.id}: {formState[field.id]}
              </p>
            ))}
            <p>Product Interest: {formState.product}</p>
          </div>
          <div className="space-y-2">
            <button
              onClick={handleResend}
              className="disabled:opacity-50 disabled:cursor-not-allowed px-4 py-2 w-full  bg-primary-1 text-white rounded-md"
            >
              {translations.resend[urlPrefix]}
            </button>
            <Link href={`/${urlPrefix}`}>
              <div className="disabled:opacity-50 cursor-pointer disabled:cursor-not-allowed px-4 py-2 w-full  bg-primary-1 text-white rounded-md">
                {translations.goToHome[urlPrefix]}
              </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

const translations = {
  name: {
    no: "Navn",
    en: "Name",
    se: "Namn",
    dk: "Navn",
    de: "Name",
    fr: "Nom",
    fi: "Nimi",
    es: "Nombre",
  },
  mail: {
    no: "E-post",
    en: "Email",
    se: "E-post",
    dk: "E-mail",
    de: "E-Mail",
    fr: "E-mail",
    fi: "Sähköposti",
    es: "Correo electrónico",
  },
  phone: {
    no: "Telefon",
    en: "Phone",
    se: "Telefon",
    dk: "Telefon",
    de: "Telefon",
    fr: "Téléphone",
    fi: "Puhelin",
    es: "Teléfono",
  },
  subjeckt: {
    no: "Interesse for salg av Lildog produkter i butikk",
    en: "Interest in selling Lildog products in store",
    se: "Intresse för att sälja Lildog-produkter i butik",
    dk: "Interesse for at sælge Lildog produkter i butik",
    de: "Interesse an Lildog-Produkten im Laden zu verkaufen",
    fr: "Intérêt à vendre des produits Lildog en magasin",
    fi: "Kiinnostus myydä Lildog-tuotteita kaupassa",
    es: "Interés en vender productos Lildog en la tienda",
  },
  succes: {
    no: "Hennvendelsen ble sendt. Vi kommer tilbake til deg så fort som mulig",
    en: "The email was sent. We will get back to you as soon as possible",
    se: "E-postmeddelandet skickades. Vi återkommer till dig så snart som möjligt",
    dk: "E-mailen blev sendt. Vi vender tilbage til dig så hurtigt som muligt",
    de: "Die E-Mail wurde gesendet. Wir werden uns so schnell wie möglich bei Ihnen melden",
    fr: "L'e-mail a été envoyé. Nous reviendrons vers vous dès que possible",
    fi: "Sähköposti lähetettiin. Palaamme sinulle mahdollisimman pian",
    es: "El correo electrónico fue enviado. Nos pondremos en contacto contigo lo antes posible",
  },
  checkInfo: {
    no: "Sjekk at all informasjon er riktig",
    en: "Check that all information is correct",
    se: "Kontrollera att all information är korrekt",
    dk: "Kontroller, at alle oplysninger er korrekte",
    de: "Überprüfen Sie, ob alle Informationen korrekt sind",
    fr: "Vérifiez que toutes les informations sont correctes",
    fi: "Tarkista, että kaikki tiedot ovat oikein",
    es: "Compruebe que toda la información sea correcta",
  },
  resend: {
    no: "Send på nytt",
    en: "Resend",
    se: "Skicka igen",
    dk: "Send igen",
    de: "Erneut senden",
    fr: "Renvoyer",
    fi: "Lähetä uudelleen",
    es: "Reenviar",
  },
  goToHome: {
    no: "Gå til forsiden",
    en: "Go to homepage",
    se: "Gå till startsidan",
    dk: "Gå til hjemmesiden",
    de: "Zur Startseite",
    fr: "Aller à la page d'accueil",
    fi: "Mene etusivulle",
    es: "Ir a la página de inicio",
  },
};

export default LocalizedDistributer;
