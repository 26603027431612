import React, { useState, forwardRef } from "react";
import Image from "next/image";
import { Image as ImageType } from "@lilbit/shared";
import { getPositionFromHotspot } from "../../lib/helpers";
import CircleSmall from "../svgs/circleSmall";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Transition } from "@headlessui/react";
import Link from "next/link";

type Props = {
  backgroundColor: string;
  textColor: string;
  items: Array<{
    boldText: string;
    smallText: string;
    image: ImageType;
    agriaUrl: boolean;
    _key: string;
  }>;

  urlFor: Function;
};
const LocalizedImageAndTextBeside = (props: Props) => {
  const { items, urlFor } = props; //flex justify-items-stretch
  const backgroundColor = props.backgroundColor
    ? props.backgroundColor
    : "quaternary";
  const textColor = props.textColor ? props.textColor : "primary";
  const [selectedSlide, setSelectedSlide] = useState(0);
  // Create a wrapper component to handle ref forwarding
  const TransitionWrapper = forwardRef<HTMLDivElement, any>((props, ref) => (
    <Transition {...props} ref={ref} />
  ));
  TransitionWrapper.displayName = "TransitionWrapper";

  const colors = {
    white: "white",
    black: "black",
    primary: "primary-mono-1",
    secondary: "primary-mono-2",
    tertiary: "primary-mono-3",
    quaternary: "primary-mono-4",
  };

  if (!items) return <></>;
  return (
    <div className="flex w-full lg:flex-row lg:items-center">
      <div className="flex flex-col w-full">
        {items.map((item, index) => {
          const { boldText, smallText, image, _key } = item;

          if (selectedSlide === index)
            return (
              <TransitionWrapper
                key={_key}
                show={selectedSlide === index}
                enter="transition duration-1000 ease-in-out"
                enterFrom="opacity-0 transform scale-95"
                enterTo="opacity-100 transform scale-100"
                leave="transition duration-1000 ease-in-out"
                leaveFrom="opacity-100 transform scale-100"
                leaveTo="opacity-0 transform scale-95"
              >
                <div className="relative bg-white  w-full flex items-center justify-center mt-4">
                  <div
                    className={`flex justify-evenly lg:p-12 rounded-r-3xl rounded-tl-3xl lg:rounded-r-[100px] lg:rounded-tl-[100px] lg:rounded-bl-[20px] flex-col lg:flex-row items-center text-${colors[textColor]} bg-${colors[backgroundColor]} rounded-b-3xl overflow-hidden lg:w-11/12`}
                  >
                    <div className="w-full lg:w-1/2 pb-8 px-6 lg:px-16 py-8 sm:py-12 lg:py-0">
                      <h3 className="py-4 text-center font-p22 font-medium text-xl md:text-2xl lg:text-3xl">
                        {boldText || ""}
                      </h3>
                      {item.agriaUrl ? (
                        <div className="flex w-full items-center justify-center mt-4 pt-4">
                          <Link
                            className="text-xl"
                            href={"/no/kundefordeler"}
                            target="_blank"
                          >
                            <div className="w-9/10 lg:w-3/4 xl:w-1/4 py-2 px-2 lg:px-0 flex justify-center text-center rounded-2xl ring-2 ring-primary-1  hover:scale-110 hover:shadow-md hover:ring-3 transition-transform cursor-pointer">
                              <p className="font-p22">Les mer</p>
                            </div>
                          </Link>
                        </div>
                      ) : (
                        <p className="font-objektiv text-center font-normal text-sm lg:text-lg px-8 sm:px-12 md:px-24 lg:px-0">
                          {smallText || ""}
                        </p>
                      )}
                    </div>
                    <div
                      className={`relative w-full lg:w-1/2  lg:rounded-r-[60px] lg:rounded-tl-[60px] lg:rounded-bl-[10px] bg-primary-4  lg:overflow-hidden`}
                    >
                      <div className="w-full h-[270px] xs:h-[300px] sm:h-[350px] lg:h-[400px] ">
                        {image && (
                          <Image
                            layout="fill"
                            objectFit="cover"
                            src={urlFor(image, true).url()}
                            objectPosition={
                              image?.hotspot &&
                              getPositionFromHotspot(image.hotspot)
                            }
                            alt={image.alt ? image.alt : ""}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </TransitionWrapper>
            );
        })}
        {items.length > 1 ? (
          <div className="flex">
            <div className="flex flex-1 flex-row justify-center items-center my-4 lg:my-8">
              {items.map((item, index) => {
                if (index < 4) {
                  return (
                    <div className="mx-2" key={item._key}>
                      <CircleSmall width={18} height={18} />
                    </div>
                  );
                }
              })}
            </div>
            <div
              className="block flex-none lg:hidden right-1 my-4"
              onClick={() =>
                setSelectedSlide(
                  selectedSlide + 1 < items.length ? selectedSlide + 1 : 0
                )
              }
            >
              <ChevronRightIcon
                className={`text-opacity-70 ml-2 h-8 w-8 text-primary-1 cursor-pointer font-bold hover:scale-105`}
                aria-hidden="true"
              />
            </div>
          </div>
        ) : (
          <div className="h-4 py-6 bg-white"></div>
        )}
      </div>
      {items.length > 1 && (
        <div
          className="hidden lg:block"
          onClick={() =>
            setSelectedSlide(
              selectedSlide + 1 < items.length ? selectedSlide + 1 : 0
            )
          }
        >
          <ChevronRightIcon
            className={`text-opacity-70 ml-2 h-8 w-8 text-primary-1 cursor-pointer font-bold hover:scale-105`}
            aria-hidden="true"
          />
        </div>
      )}
    </div>
  );
};

export default LocalizedImageAndTextBeside;
