type Props = {
  cards: [];
  title: string;
  summary: any;
};
const PreSaleSection = (props: Props) => {
  const { cards, title, summary } = props;
  return (
    <div className="bg-frontPageGrey font-objektiv">
      {/* Header */}
      <div className="relative pb-32 bg-secondary-9">
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-secondary-5 md:text-5xl lg:text-6xl">
            {title || ""}
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-gray-300">
            {summary || ""}
          </p>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
          {cards.length &&
            cards?.map((card: any, index) => {
              return (
                <div
                  key={card._key}
                  className="flex flex-col bg-secondary-5 rounded-2xl shadow-xl"
                >
                  <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                    <div
                      className={`${
                        index === 2 ? "bg-tangerine" : "bg-iris"
                      } absolute top-0 p-5 inline-block rounded-xl shadow-lg transform -translate-y-1/2 font-bold text-secondary-5`}
                    >
                      {card.tag || ""}
                    </div>
                    <h3 className="text-xl text-secondary-9 font-bold">
                      {card.title || ""}
                    </h3>
                    <p className="mt-4 text-p-md text-secondary-9">
                      {card.body || ""}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </section>
    </div>
  );
};

export default PreSaleSection;
