import { defaultLocale } from "../lib/app-config";
import { locales } from "../lib/app-config";
import isSupportedAppLanguage, { Locale } from "./types";

export const getInitialLocale = (): Locale => {
  if (typeof window !== "undefined") {
    const localSetting = JSON.parse(localStorage.getItem("appLanguage"));

    if (localSetting && isSupportedAppLanguage(localSetting)) {
      return locales.find((locale) => locale.appLanguage === localSetting);
    }

    if (isSupportedAppLanguage(navigator.language)) {
      return locales.find(
        (locale) => locale.appLanguage === navigator.language
      );
    }
  }

  return defaultLocale;
};

export const getGeoCountry = async () => {
  let geo = "";
  await fetch("https://extreme-ip-lookup.com/json/?key=yNKlOv3uy9sLpsr6ojaQ")
    .then((res) => res.json())
    .then((response) => {
      geo = response.countryCode;
    });
  return geo;
};
