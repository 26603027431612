import Image from "next/image";
import { Image as ImageType } from "@lilbit/shared";
import { getPositionFromHotspot } from "../../lib/helpers";

interface Props {
  headerText: any;
  text: any;
  mainImage: ImageType;
  verticalImages: Array<ImageType & { _key: string }>;
  horizentalImages: Array<ImageType & { _key: string }>;
  button: { buttonText: string; url: string };
  urlFor: Function;
  SimpleBlockContent: any;
}
const LocalizedMainImageAndImagesAround = (props: Props) => {
  const {
    headerText,
    text,
    verticalImages,
    horizentalImages,
    button,
    mainImage,
    urlFor,
    SimpleBlockContent,
  } = props;
  return (
    <div className="flex flex-col justify-center items-center">
      <SimpleBlockContent
        blocks={headerText}
        className="font-bold text-center lg:px-42 sm:px-16 px-2 text-primary-mono-1 font-p22 lg:text-2xl text-lg"
      />
      <SimpleBlockContent
        blocks={text}
        className="font-light text-center max-w-prose px-4 text-primary-mono-1 font-objektiv text-xs sm:px-20 lg:text-base"
      />

      <div className="flex-row grid-cols-4 hidden lg:flex">
        <div className="col-span-3 relative self-start w-[700px] h-[570px] rounded-r-2xl rounded-tl-2xl rounded-bl-md overflow-hidden bg-white">
          <Image
            layout="fill"
            objectFit="contain"
            src={urlFor(mainImage, true).url()}
            objectPosition={
              mainImage?.hotspot && getPositionFromHotspot(mainImage.hotspot)
            }
            alt={mainImage.alt ? mainImage.alt : ""}
          />
        </div>
        <div className="ml-6 mr-2 col-span-1">
          {verticalImages.map((image, index) => {
            return (
              <div
                key={image._key}
                className={`mb-4 relative w-full ${
                  index % 2 === 0
                    ? "rounded-r-2xl rounded-tl-2xl"
                    : "rounded-l-2xl rounded-br-2xl"
                }  overflow-hidden bg-white`}
              >
                <div className="w-[180px] h-[180px] ">
                  <Image
                    layout="fill"
                    objectFit="cover"
                    src={urlFor(image, true).url()}
                    alt={image.alt ? image.alt : ""}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/**Mobile */}
      <div className="flex flex-col px-1 lg:hidden">
        <div className="h-full w-full relative rounded-r-2xl rounded-tl-2xl rounded-bl-md overflow-hidden bg-white">
          <div className="h-[300px] w-[200px]">
            <Image
              layout="fill"
              objectFit="contain"
              src={urlFor(mainImage, true).url()}
              objectPosition={
                mainImage?.hotspot && getPositionFromHotspot(mainImage.hotspot)
              }
              alt={mainImage.alt ? mainImage.alt : ""}
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2 h-[160px]">
          {verticalImages.map((image, index) => {
            return (
              <div
                key={image._key}
                className={`my-4 relative w-full h-full ${
                  index % 2 === 0
                    ? "rounded-r-2xl rounded-tl-2xl"
                    : "rounded-l-2xl rounded-br-2xl"
                }  overflow-hidden bg-white`}
              >
                <div className="w-[220px] h-[110px]">
                  <Image
                    layout="fill"
                    objectFit="contain"
                    src={urlFor(image, true).url()}
                    alt={image.alt ? image.alt : ""}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex lg:flex-row flex-col my-6">
        {horizentalImages.map((image, index) => {
          return (
            <div
              key={image._key}
              className={`lg:mr-4 mt-6 lg:mt-0 relative w-full ${
                index % 2 === 0
                  ? "rounded-r-2xl rounded-tl-2xl"
                  : "rounded-l-2xl rounded-br-2xl"
              }  overflow-hidden bg-white`}
            >
              <div className="w-[280px] h-[180px]">
                <Image
                  layout="fill"
                  objectFit="cover"
                  src={urlFor(image, true).url()}
                  alt={image.alt ? image.alt : ""}
                />
              </div>
            </div>
          );
        })}
      </div>
      {button?.buttonText && (
        <a
          target="_blank"
          href={button?.url ? button.url : "/"}
          rel="noopener noreferrer"
        >
          <button
            className={`flex mb-4 lg:mb-8 bg-primary-mono-1 justify-center items-center font-objektiv cursor-pointer transform transition-colors button-shadow lg:mx-0 rounded-xl h-[48px] w-full focus:outline-none focus:ring-2`}
          >
            <div className="flex items-center justify-center w-full space-x-4 px-2 md:px-4">
              <p
                className={`text-sm leading-6 font-semibold text-center text-white sm:text-lg`}
              >
                {button.buttonText || ""}
              </p>
            </div>
          </button>
        </a>
      )}
    </div>
  );
};

export default LocalizedMainImageAndImagesAround;
