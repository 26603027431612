import Image from "next/image";
import Tag from "../../components/Tag";

const FrontPageSection = (props) => {
  const {
    bodies = [],
    heading,
    minorHeading,
    image,
    tags = [],
    bgColor,
    SimpleBlockContent,
    urlFor,
  } = props;
  return (
    <div
      style={bgColor && { backgroundColor: bgColor }}
      className="flex flex-col items-center justify-center font-objektiv text-eggplant"
    >
      <div className="flex flex-col  items-center justify-center w-full rounded-bl-3xl pb-10 pt-12 lg:flex-row">
        <div className="flex flex-col items-center">
          {minorHeading && <h6 className="font-bold">{minorHeading}</h6>}
          <h1 className="font-extrabold text-h1 leading-h1 text-center px-4 pb-6 lg:text-h2-desktop lg:leading-h2-desktop">
            {heading}
          </h1>
        </div>
        <div className="relative w-full h-80 lg:h-px-574">
          <Image src={urlFor(image).url()} alt={image.alt} layout="fill" />
        </div>
        {tags &&
          tags.map((tag) => {
            return (
              <Tag
                key={tag._key}
                tag={tag}
                flexdir="flex-col"
                desktop={false}
                urlFor={urlFor}
              />
            );
          })}
      </div>
      <div className="flex flex-col items-center justify-center w-full px-6">
        <SimpleBlockContent blocks={bodies[0]} />
      </div>
    </div>
  );
};

export default FrontPageSection;
