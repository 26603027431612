import { useEffect, useRef, useState } from "react";
import { similarity, Store, useLocale } from "@lilbit/shared";
import {
  AiOutlineEnvironment,
  AiOutlineFileSearch,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { Geopoint } from "./storemapper";

type ContentType = Store[];

interface Props {
  content: ContentType;
  onClick: (geopoint: Geopoint) => void;
  setSortedLocations: (stores: ContentType) => void;
  requestUserLocation: () => void;
  userLocation: { lng: number; lat: number };
  loading: boolean;
}
const SearchBar = ({
  content,
  onClick,
  setSortedLocations,
  requestUserLocation,
  userLocation,
  loading,
}: Props) => {
  const [searchWord, setSearchWord] = useState("");
  const [sortedContent, setSortedContent] = useState<ContentType>([]);
  const [showResults, setShowResults] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [sentFromMouseEvent, setSentFromMouseEvent] = useState(false);
  const urlPrefix = useLocale().urlPrefix;
  const myRefs = useRef<Array<HTMLButtonElement>>([]);
  useEffect(() => {
    let temp: ContentType;
    if (searchWord) {
      temp = [...content].filter(
        (ref) =>
          ref.city.toLowerCase().includes(searchWord.toLowerCase()) ||
          ref.postalCode.includes(searchWord)
      );
      setSortedContent(temp);
    } else {
      temp = [...content];
      temp.sort((a, b) => a.name.localeCompare(b.city));
      setSortedContent(temp);
    }
  }, [searchWord, content, similarity]);

  useEffect(() => {
    if (
      myRefs?.current.length > 0 &&
      myRefs.current[selectedIndex] &&
      !sentFromMouseEvent
    ) {
      myRefs.current[selectedIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedIndex, myRefs, sentFromMouseEvent]);

  useEffect(() => {
    setSortedLocations(sortedContent);
  }, [setSortedLocations, sortedContent]);

  useEffect(() => {
    if (userLocation) {
      const sorted = [...content].sort((a, b) => {
        const aDistance = Math.sqrt(
          Math.pow(a.geopoint.lat - userLocation.lat, 2) +
            Math.pow(a.geopoint.lng - userLocation.lng, 2)
        );
        const bDistance = Math.sqrt(
          Math.pow(b.geopoint.lat - userLocation.lat, 2) +
            Math.pow(b.geopoint.lng - userLocation.lng, 2)
        );
        return aDistance - bDistance;
      });
      setSortedContent(sorted);
    }
  }, [userLocation]);

  return (
    <div className="flex flex-col items-center justify-start md:flex-row md:items-start">
      <div className="w-full relative max-w-md md:mb-12 md:w-1/2 lg:w-1/3 lg:ml-1">
        <div className="flex items-center relative mt-10">
          <input
            onChange={(e) => {
              setSearchWord(e.target.value);
              setSelectedIndex(0);
            }}
            placeholder={translations.placeholder[urlPrefix]}
            className={`p-4 w-full border rounded-md border-primary-mono-2 focus:ring-primary-mono-2 ${
              showResults && sortedContent.length > 0 && "rounded-b-none"
            }`}
            onFocus={() => {
              setShowResults(true);
              setSelectedIndex(0);
            }}
            onBlur={() => setShowResults(false)}
            value={searchWord}
            onKeyDown={(e) => {
              if (
                e.code === "ArrowDown" &&
                selectedIndex < sortedContent.length - 1
              ) {
                setSentFromMouseEvent(false);
                setSelectedIndex(selectedIndex + 1);
              } else if (e.code === "ArrowUp" && selectedIndex > 0) {
                setSentFromMouseEvent(false);
                setSelectedIndex(selectedIndex - 1);
              } else if (e.code === "Enter") {
                if (sortedContent.length > 0) {
                  onClick(sortedContent[selectedIndex].geopoint);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }
              }
            }}
          />
          <AiOutlineFileSearch className="absolute right-2" size={20} />
        </div>
      </div>
      <button
        className="flex items-center ml-3 my-4 md:my-0 md:mt-13"
        onClick={requestUserLocation}
      >
        <AiOutlineEnvironment className="mr-2" size={20} />
        <p>{translations.useMyPosition[urlPrefix]}</p>
        {loading && (
          <AiOutlineLoading3Quarters className="ml-2 animate-spin" size={16} />
        )}
      </button>
    </div>
  );
};

const translations = {
  useMyPosition: {
    en: "Use my position",
    no: "Bruk min plassering",
    se: "Använd min position",
    da: "Brug min placering",
    fi: "Käytä sijaintiani",
    de: "Meinen Standort verwenden",
    fr: "Utiliser ma position",
    es: "Usar mi posición",
  },
  placeholder: {
    en: "City or postal code",
    no: "Søk etter by eller postnummer",
    se: "Sök efter stad eller postnummer",
    da: "By eller postnummer",
    fi: "Kaupunki tai postinumero",
    de: "Stadt oder Postleitzahl",
    fr: "Ville ou code postal",
    es: "Ciudad o código postal",
  },
};

export default SearchBar;
