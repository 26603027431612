import Image from "next/image";
import { CameraIcon } from "@heroicons/react/24/solid";

type Props = {
  heading: string;
  body: any;
  summary: any;
  mainImage: any;
  urlFor: Function;
  SimpleBlockContent: any;
  isApp?: boolean;
};
const Privacy = (props: Props) => {
  const {
    body,
    heading,
    summary,
    mainImage,
    urlFor,
    SimpleBlockContent,
    isApp,
  } = props;
  const { caption, alt } = mainImage || { caption: "", alt: "" };
  if (isApp) {
    return (
      <div className="flex flex-col w-full lg:px-46 pb-20 max-w-8xl mx-auto">
        <div className="flex flex-col text-center lg:mx-auto mt-12">
          <h1 className="font-bold font-p22 text-h2 leading-h2 lg:text-h1-desktop lg:leading-h1-desktop text-primary-1 mt-1 pb-4">
            {heading || ""}
          </h1>
          <p className="font-normal font-objektiv pb-12 text-p-md lg:text-h6-desktop text-primary-1 mx-auto max-w-4xl">
            {summary || ""}
          </p>
        </div>
        <div>
          {caption && (
            <div className="flex space-x-2 items-center">
              <CameraIcon className="w-5 h-5 text-primary-1" />
              <p className="text-primary-1 font-objektiv text-p-md lg:text-p-sm-desktop">
                {caption}
              </p>
            </div>
          )}
        </div>
        <div className="text-primary-1 font-objektiv">
          <SimpleBlockContent blocks={body} isApp={isApp} />
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col w-full px-5 lg:px-46 pb-20 max-w-8xl mx-auto">
      <div className="flex flex-col text-center lg:mx-auto mt-12">
        <h1 className="font-bold font-p22 text-h2 leading-h2 lg:text-h1-desktop lg:leading-h1-desktop text-primary-1 mt-1 pb-4">
          {heading || ""}
        </h1>
        <p className="font-normal font-objektiv pb-26 text-p-md lg:text-h6-desktop text-primary-1 mx-auto max-w-4xl">
          {summary || ""}
        </p>
      </div>
      <div className="mb-12">
        {mainImage && (
          <div className="relative h-px-480">
            <Image
              src={urlFor(mainImage).url()}
              layout="fill"
              objectFit="cover"
              alt={alt && alt}
            />
          </div>
        )}
        {caption && (
          <div className="flex space-x-2 items-center">
            <CameraIcon className="w-5 h-5 text-primary-1 font-objektiv" />
            <p className="text-black italic text-p-md lg:text-p-sm-desktop">
              {caption}
            </p>
          </div>
        )}
      </div>
      <div className="text-black font-objektiv">
        <SimpleBlockContent blocks={body} />
      </div>
    </div>
  );
};

export default Privacy;
