import Image from "next/image";
import { useRouter } from "next/router";
const SocialLinks = () => {
  const router = useRouter();
  const { asPath } = router;
  return (
    <>
      <div className="lg:hidden flex w-full justify-end space-x-5">
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=https://lildog.com/${asPath}`}
        >
          <Image
            width={16}
            height={18}
            src="/social/Icon-facebook-orange.svg"
            alt="Facebook logo"
          />
        </a>
      </div>
      <div className="hidden lg:flex w-full justify-end space-x-5">
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=https://lildog.com/${asPath}`}
        >
          <Image
            width={26}
            height={30}
            src="/social/Icon-facebook-orange.svg"
            alt="Facebook logo"
          />
        </a>
      </div>
    </>
  );
};
export default SocialLinks;
