import Image from "next/image";
import { Image as ImageType } from "../../lib/types";
import CheckMarkCircle from "../svgs/checkMarkCircle";
import XIconCircle from "../svgs/xIconCircle";

interface Props {
  productsToCompare: Array<{
    name: string;
    image?: ImageType;
    values: Array<{ value: boolean }>;
    _key: string;
  }>;
  comparisonFields: Array<{ comparisonField: string; _key: string }>;
  urlFor: Function;
  siteName: "lildog" | "lilcat";
}
const LocalizedCompare = (props: Props) => {
  const { productsToCompare, comparisonFields, urlFor, siteName } = props;
  return (
    <table className="w-full table-auto px-1 md:px-2 my-6 bg-white border-collapse max-w-6xl mx-auto">
      <thead>
        <tr className="py-2 align-middle">
          <th className=""></th>
          {productsToCompare.map((product, index) => {
            if (product.image) {
              return (
                <th
                  className={`p-1 md:p-4 flex items-center justify-center`}
                  key={index}
                >
                  <div className="w-[60px] sm:w[80px] relative md:w-[150px] h-[20px] md:h-[40px]">
                    <Image
                      src={urlFor(product.image, true).url()}
                      className=" self-center"
                      layout="fill"
                      objectFit="contain"
                      alt={product.image.alt || ""}
                    />
                  </div>
                </th>
              );
            }
            return (
              <th
                className={`md:py-6 text-primary-mono-1 text-center font-p22 font-medium text-xs md:text-lg`}
                key={index}
              >
                {product.name}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {comparisonFields.map((comparisonField, index) => {
          return (
            <tr
              key={index}
              className={`${
                index % 2 === 0 ? "bg-primary-mono-4" : "bg-white"
              } rounded-3xl`}
            >
              <td
                className={`p-2 md:p-4 text-primary-mono-1 rounded-l-lg md:rounded-l-xl text-xs font-objektiv font-normal md:text-lg md:font-bold text-center w-[40%]`}
              >
                {comparisonField}
              </td>
              {productsToCompare.map((product, newIndex) => {
                return (
                  <td
                    className={`box-content ${
                      newIndex === productsToCompare.length - 1
                        ? "md:rounded-r-lg rounded-r-xl"
                        : ""
                    }`}
                    key={newIndex}
                  >
                    <div className="flex items-center py-2 md:py-6 md:hidden justify-center">
                      {product.values[index] ? (
                        <CheckMarkCircle
                          siteName={siteName}
                          height={16}
                          width={16}
                        />
                      ) : (
                        <XIconCircle height={16} width={16} />
                      )}
                    </div>
                    <div className="hidden md:flex items-center py-2 md:py-6 justify-center">
                      {product.values[index] ? (
                        <CheckMarkCircle
                          siteName={siteName}
                          height={24}
                          width={24}
                        />
                      ) : (
                        <XIconCircle height={24} width={24} />
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default LocalizedCompare;

{
  /* <table className="w-full table-auto md:px-12 px-1 md:px-2 my-6 bg-white border-collapse ">
      <thead>
        <tr className="py-2 ">
          <th className=""></th>
          {productsToCompare.map((product, index) => {
            if (product.image) {
              return (
                <th
                  className={`flex items-center justify-center ${
                    index === 0
                      ? "border-x-2 border-t-2 md:border-x-4 md:border-t-4 md:py-8 py-4 px-4 md:px-8 rounded-t-xl border-primary-3 md:rounded-t-3xl"
                      : ""
                  }`}
                  key={index}
                >
                  <div className="w-[80px] relative flex md:w-[150px] h-[20px] md:h-[40px]">
                    <Image
                      src={urlFor(product.image, true).url()}
                      className=""
                      layout="fill"
                      objectFit="contain"
                      alt={product.image.alt || ""}
                    />
                  </div>
                </th>
              );
            }
            return (
              <th
                className={`md:py-6 text-primary-1 text-center font-p22 font-medium text-xs md:text-lg  ${
                  index === 0
                    ? "border-x-2 border-t-2 md:border-x-4 md:border-t-4 md:py-8 py-4 px-4 md:px-8 rounded-t-xl border-primary-3 md:rounded-t-3xl"
                    : ""
                }`}
                key={index}
              >
                {product.name}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {comparisonFields.map((comparisonField, index) => {
          return (
            <tr
              key={index}
              className={`${
                index % 2 === 0 ? "bg-primary-4" : "bg-white"
              } rounded-3xl`}
            >
              <td
                className={`p-2 md:p-4 text-primary-1 rounded-l-lg md:rounded-l-xl text-xs font-objektiv font-normal md:text-lg md:font-bold text-center w-[40%]`}
              >
                {comparisonField}
              </td>
              {productsToCompare.map((product, newIndex) => {
                return (
                  <td
                    className={`box-content ${
                      newIndex === productsToCompare.length - 1
                        ? "md:rounded-r-lg rounded-r-xl"
                        : ""
                    } ${
                      newIndex === 0
                        ? " border-x-2 md:border-x-4 border-primary-3 "
                        : ""
                    }`}
                    key={newIndex}
                  >
                    <div className="flex items-center py-2 md:py-6 md:hidden justify-center">
                      {product.values[index] ? (
                        <CheckMarkCircle
                          siteName={siteName}
                          height={20}
                          width={20}
                        />
                      ) : (
                        <XIconCircle height={20} width={20} />
                      )}
                    </div>
                    <div className="hidden md:flex items-center py-2 md:py-6 justify-center">
                      {product.values[index] ? (
                        <CheckMarkCircle
                          siteName={siteName}
                          height={40}
                          width={40}
                        />
                      ) : (
                        <XIconCircle height={40} width={40} />
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table> */
}
