import { memo } from "react";
const Vippslogo = () => {
  return (
    <svg
      width="63"
      height="16"
      viewBox="0 0 63 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.61554 0.519493L6.26374 7.87147L8.86002 0.519493H11.9236L7.35418 11.4241H5.06945L0.5 0.519493H3.61554Z"
        fill="white"
      ></path>
      <path
        d="M18.8288 9.69751C20.7501 9.69751 21.8405 8.80935 22.879 7.52646C23.4502 6.83567 24.1771 6.68764 24.6964 7.08238C25.2157 7.47712 25.2676 8.21725 24.6964 8.90804C23.1906 10.783 21.2693 11.9179 18.8288 11.9179C16.1806 11.9179 13.8439 10.5363 12.2343 8.11856C11.7669 7.47712 11.8708 6.78633 12.39 6.44093C12.9093 6.09554 13.6882 6.24356 14.1555 6.93435C15.2979 8.56264 16.8556 9.69751 18.8288 9.69751ZM22.4117 3.62843C22.4117 4.51659 21.6847 5.10869 20.8539 5.10869C20.0231 5.10869 19.2961 4.51659 19.2961 3.62843C19.2961 2.74027 20.0231 2.14817 20.8539 2.14817C21.6847 2.14817 22.4117 2.78961 22.4117 3.62843Z"
        fill="white"
      ></path>
      <path
        d="M29.7345 0.519686V1.99995C30.5134 0.963765 31.7077 0.223633 33.4732 0.223633C35.706 0.223633 38.3023 1.99995 38.3023 5.79929C38.3023 9.796 35.8098 11.7203 33.2135 11.7203C31.8635 11.7203 30.6173 11.2269 29.6826 9.99337V15.2236H26.8786V0.519686H29.7345ZM29.7345 5.94732C29.7345 8.16771 31.0846 9.35192 32.5904 9.35192C34.0444 9.35192 35.4983 8.2664 35.4983 5.94732C35.4983 3.67758 34.0444 2.59205 32.5904 2.59205C31.1365 2.59205 29.7345 3.62824 29.7345 5.94732Z"
        fill="white"
      ></path>
      <path
        d="M43.2352 0.519686V1.99995C44.0141 0.963765 45.2084 0.223633 46.9738 0.223633C49.2066 0.223633 51.8029 1.99995 51.8029 5.79929C51.8029 9.796 49.3105 11.7203 46.7142 11.7203C45.3641 11.7203 44.1179 11.2269 43.1833 9.99337V15.2236H40.3793V0.519686H43.2352ZM43.2352 5.94732C43.2352 8.16771 44.5853 9.35192 46.0911 9.35192C47.545 9.35192 48.9989 8.2664 48.9989 5.94732C48.9989 3.67758 47.545 2.59205 46.0911 2.59205C44.5853 2.59205 43.2352 3.62824 43.2352 5.94732Z"
        fill="white"
      ></path>
      <path
        d="M57.7748 0.223633C60.1114 0.223633 61.773 1.25982 62.5 3.82561L59.9557 4.22034C59.9037 2.93745 59.0729 2.49337 57.8267 2.49337C56.892 2.49337 56.1651 2.88811 56.1651 3.52955C56.1651 4.02298 56.5286 4.5164 57.619 4.71376L59.5402 5.05916C61.4096 5.40455 62.4481 6.58876 62.4481 8.16771C62.4481 10.5361 60.2153 11.7203 58.0863 11.7203C55.8535 11.7203 53.3611 10.6348 52.9976 7.97034L55.542 7.57561C55.6978 8.95718 56.5805 9.45061 58.0344 9.45061C59.1248 9.45061 59.8518 9.05587 59.8518 8.41442C59.8518 7.82232 59.4883 7.37824 58.294 7.18087L56.5286 6.88482C54.6592 6.53942 53.5169 5.30587 53.5169 3.72692C53.5688 1.25982 55.9055 0.223633 57.7748 0.223633Z"
        fill="white"
      ></path>
    </svg>
  );
};

export default memo(Vippslogo);
