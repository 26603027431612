import Image from "next/image";
import {
  Product,
  Image as ImageType,
  getPositionFromHotspot,
} from "@lilbit/shared";

type Props = {
  image: ImageType;
  mainProduct: Product;
  heading: string;
  urlFor: Function;
};
const ImageWithHeaderSection = (props: Props) => {
  const { heading, image, mainProduct, urlFor } = props;
  return (
    <>
      <div className="flex flex-col items-center justify-center my-12 font-objektiv text-secondary-5 w-full max-w-6xl mx-auto">
        <div className="relative text-center w-full h-[400px] lg:min-h-[650px]">
          {image && (
            <Image
              className="rounded-t-3xl rounded-br-3xl"
              src={urlFor(image).url()}
              alt={image.alt || ""}
              layout="fill"
              objectFit="cover"
              objectPosition={
                image?.hotspot && getPositionFromHotspot(image.hotspot)
              }
            />
          )}
          {heading && (
            <div className="absolute top-0">
              <h3 className="font-extrabold text-center px-9 pt-10">
                {heading}
              </h3>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ImageWithHeaderSection;
