import React, { useContext, useEffect } from "react";
import Image from "next/image";
import { CartContext, Product, Image as ImageType } from "@lilbit/shared";

type Props = {
  heading: string;
  inverted: boolean;
  image: ImageType;
  mainProductPrices: any;
  flexDir: any;
  tags: Array<any>;
  mainProduct: Product;
  urlFor: Function;
  setVippsModalOpen: Function;
  textBesideIcons: string;
  partnerIcons: Array<ImageType & { _key: string }>;
};
const MainProduct = (props: Props) => {
  const { heading, image, urlFor, textBesideIcons, partnerIcons } = props;

  //Context
  const { nrOfMainProducts, setNrOfMainProducts } = useContext(CartContext);
  useEffect(() => {
    if (nrOfMainProducts === 0) setNrOfMainProducts(1);
  }, [nrOfMainProducts, setNrOfMainProducts]);
  return (
    <div className="flex flex-col w-full items-center bg-secondary-5 justify-center font-objektiv text-secondary-5">
      <div className="text-secondary-5 flex w-full">
        <div className="relative w-full flex items-center justify-center rounded-3xl h-px-450 lg:h-px-500">
          <Image
            src={urlFor(image).url()}
            alt={image.alt || ""}
            layout="fill"
            objectFit="cover"
            className="lg:rounded-t-3xl"
            quality={40}
          />
          <div className="absolute">
            <div className="text-center max-w-xs lg:max-w-lg">
              <h1 className="font-bold text-2xl leading-9 lg:text-4xl lg:leading-10">
                {heading}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full lg:h-36 bg-antiDarkGreen-dark">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 lg:grid-cols-6 ">
            <div className="col-span-2 flex justify-center ">
              <span className="text-2xl font-normal  mt-6 pt-8">
                {textBesideIcons}
              </span>
            </div>
            {partnerIcons?.map((partner, index) => {
              return (
                <div
                  className={`col-span-1 flex justify-center md:col-span-${
                    index == 0 || index == 1 ? "2" : "3"
                  } lg:col-span-1`}
                  key={index}
                >
                  <Image
                    src={urlFor(partner).url()}
                    alt={"lildog partner"}
                    width={200}
                    height={150}
                    quality={40}
                    objectFit="contain"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainProduct;
