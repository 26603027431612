const ExtLinkLogo = ({ siteName }: { siteName: "lildog" | "lilcat" }) => {
  // 094E18
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.3335 3.00016H3.00016C2.07969 3.00016 1.3335 3.74635 1.3335 4.66683V13.0002C1.3335 13.9206 2.07969 14.6668 3.00016 14.6668H11.3335C12.254 14.6668 13.0002 13.9206 13.0002 13.0002V9.66683M9.66683 1.3335H14.6668M14.6668 1.3335V6.3335M14.6668 1.3335L6.3335 9.66683"
        stroke={siteName === "lildog" ? "#094E18" : "#7a4005"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExtLinkLogo;
