import { useContext } from "react";
import { defaultValue, BreakpointContext } from "../context";

const useBreakpoint = () => {
  const context = useContext(BreakpointContext);
  if (context === defaultValue) {
    throw new Error("useBreakpoint must be used within BreakpointProvider");
  }
  return context;
};

export default useBreakpoint;
