import Image from "next/image";
import { useState } from "react";
import {
  ProductPrices,
  Card,
  Highlights as HighlightsProps,
  useLocale,
  Product,
} from "@lilbit/shared";
import Toast from "../toast";

type Props = {
  title: string;
  summary: string;
  normalText: string;
  boldText: string;
  emailHeader: string;
  cards: Array<Card>;
  mainProduct: Product;
  highlightsList: Array<HighlightsProps>;
  includedInPurchaseList: Array<HighlightsProps>;
  mainProductPrices: ProductPrices;
  urlFor: Function;
  setVippsModalOpen: Function;
};

const BuyProductSection = (props: Props) => {
  const { title, cards, highlightsList, mainProduct, urlFor, emailHeader } =
    props;
  const { images } = mainProduct;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState("");
  const [header, setHeader] = useState("");
  //TODO
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    setShowToast(false);
    let response;
    try {
      response = await fetch("/api/add-email", {
        method: "POST",
        body: JSON.stringify(email),
        // type: 'application/json'
      });
      setIsSubmitting(false);
      setHasSubmitted(true);
    } catch (err) {}
    if (response.status === 400) {
      if (locale === "en") {
        setHeader("Email is already registered");
        setMessage(
          "We will send a message to this email when the product becomes available"
        );
      } else {
        setHeader("Denne e-postadressen er allerede registrert");
        setMessage(
          "Vi sender en mail til denne e-postadressen når produktet er tilgjengelig"
        );
      }
    } else if (response.status === 200) {
      if (locale === "en") {
        setHeader("Email was succesfully registered");
        setMessage(
          "We will send you a message when the product becomes available"
        );
      } else {
        setHeader("E-posten ble registrert");
        setMessage("Vi sender deg en mail når produktet er tilgjengelig");
      }
    }
    setShowToast(true);
  };

  const locale = useLocale().urlPrefix;
  const productImage = images ? images[0] : "";
  return (
    <>
      <div className="bg-frontPageGrey font-objektiv">
        <div className="pt-6 pb-16 sm:pb-24">
          <div className="mt-8 mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-12 lg:auto-rows-min lg:gap-x-8">
              <div className="lg:col-start-7 lg:col-span-8">
                <div className="w-full">
                  <h1 className="text-h3 leading-h3 text-left w-full lg:text-h3-desktop lg:leading-h3-desktop font-extrabold text-primary-2">
                    {title}
                  </h1>
                </div>
              </div>
              {/* Image gallery */}
              <div className="mt-8 lg:mt-0 lg:col-start-1 lg:col-span-6 lg:row-start-1 lg:row-span-2">
                <div className="flex flex-col bg-secondary-5 h-full rounded-t-3xl rounded-br-3xl items-center pb-8 space-y-4">
                  <div className="flex justify-center items-center">
                    <div className="bg-orange-dark rounded-3xl m-4">
                      {productImage && (
                        <Image
                          src={productImage.src}
                          alt={productImage.alt || ""}
                          width={600}
                          height={400}
                          quality={100}
                          objectFit="contain"
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full flex flex-col px-4 font-objektiv space-y-8">
                    <label className="font-semibold text-xl text-primary-2 px-4">
                      {emailHeader && emailHeader}
                    </label>
                    <form
                      className="flex flex-row"
                      onSubmit={(event) => onSubmit(event)}
                    >
                      <input
                        className="shadow appearance-none border rounded"
                        placeholder={
                          locale === "en" ? "Email Address" : "E-postadresse"
                        }
                        onChange={(event) => setEmail(event.target.value)}
                        type="email"
                      />
                      <button
                        type="submit"
                        className="pl-2 pr-2 rounded text-white w-1/2 space-x-3 font-bold bg-primary-2 flex items-center justify-center"
                      >
                        {locale === "en" ? "Submit" : "Send"}
                        {isSubmitting && (
                          <div className="flex items-center justify-center pl-3">
                            <Image
                              src={`/carticons/Icon_Loading.png`}
                              className="animate-spin"
                              height={20}
                              width={20}
                              alt="Loading icon"
                            />
                          </div>
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </div>

              <div className="mt-4 lg:col-span-6">
                <div className="mt-4 prose prose-sm text-primary-2 text-p-md">
                  <ul role="list" className="list-inside list-disc">
                    {highlightsList?.length &&
                      highlightsList.map((item) => (
                        <li key={item._key}>{item[locale]}</li>
                      ))}
                  </ul>
                </div>

                <section aria-labelledby="policies-heading" className="mt-10">
                  <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-2">
                    {cards?.length &&
                      cards.map((card) => (
                        <div
                          key={card._key}
                          className="bg-secondary-6 border border-gray-6.1 rounded-lg p-4 text-center"
                        >
                          <dt className="flex flex-col">
                            <Image
                              src={urlFor(card.icon).url()}
                              width={20}
                              height={20}
                              alt="icon"
                            />
                            <span className="mt-4 text-p-md font-bold text-primary-2">
                              {card.title}
                            </span>
                          </dt>
                          <dd
                            className="mt-1 text-sm text-primary-2
                        "
                          >
                            {card.body}
                          </dd>
                        </div>
                      ))}
                  </dl>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showToast && <Toast message={message} header={header} />}
    </>
  );
};

export default BuyProductSection;
