import { LockClosedIcon } from "@heroicons/react/24/solid";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { useLocale } from "../translations";
import { useRouter } from "next/router";
import Image from "next/image";
import ErrorToast from "./errortoast";
const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signInUser, authLoading, authError, user } = useContext(AuthContext);
  const urlPrefix = useLocale().urlPrefix;
  const router = useRouter();
  const fallback = router.query.fallback;

  useEffect(() => {
    //TODO fix this
    if (user) {
      //refresh current page, keep all query params
      router.replace({
        pathname: router.pathname,
        query: {
          app: router.query.app || "",
          imei: router.query.imei || "",
        },
      });
    }
  }, [user]);

  return (
    <div className="min-h-screen flex items-start justify-center py-12 px-4 sm:px-6 lg:px-8">
      <ErrorToast
        header={"Auth"}
        message={authError}
        show={Boolean(authError)}
        setShow={() => {}}
      />
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {urlPrefix === "en" ? "Sign in to your account" : "Logg inn"}
          </h2>
        </div>
        <input type="hidden" name="remember" defaultValue="true" />
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="email-address" className="sr-only">
              {urlPrefix === "en" ? "Email address" : "E-post adresse"}
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-2 focus:border-primary-2 focus:z-10 sm:text-sm"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="password" className="sr-only">
              {urlPrefix === "en" ? "Password" : "Passord"}
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary-2 focus:border-primary-2 focus:z-10 sm:text-sm"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div>
          <button
            onClick={() => {
              signInUser(email, password);
              if (fallback)
                router.push({
                  pathname: `/${urlPrefix}/${fallback}`,
                  query: {
                    fallback: router.query.category,
                    app: router.query.app || "",
                    imei: router.query.imei || "",
                  },
                });
            }}
            className="group relative w-full flex justify-between py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-2 hover:bg-primary-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-2"
          >
            <span className="flex items-center">
              <LockClosedIcon
                className="h-5 w-5 text-primary-3 group-hover:text-primary-4"
                aria-hidden="true"
              />
            </span>
            <span>{urlPrefix === "en" ? "Sign in" : "Logg inn"}</span>
            <div>
              {authLoading && (
                <Image
                  src={`/carticons/Icon_Loading.png`}
                  className="animate-spin"
                  height={20}
                  width={20}
                  quality={45}
                  alt="loading icon"
                />
              )}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Signup;
