import React, {
  useState,
  useContext,
  createContext,
  ReactNode,
  useEffect,
} from "react";
import { Country } from "../lib/types";
import appConfig from "../lib/app-config";

type CountryContextType = {
  country: Country;
  setCountry: Function;
};

export const CountryContext = createContext({} as CountryContextType);

export const useSettings = () => useContext(CountryContext);

const CountryProvider = ({
  country: c,
  children,
}: {
  country: Country;
  children: ReactNode;
}) => {
  const [country, setCountry] = useState(c);

  useEffect(() => {
    setCountry(c);
  }, [c, setCountry]);

  return (
    <CountryContext.Provider value={{ country, setCountry }}>
      {children}
    </CountryContext.Provider>
  );
};
export default CountryProvider;
