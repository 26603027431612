interface Props {
  first_number: string;
  first_string: string;
  second_number: string;
  second_string: string;
  third_number: string;
  third_string: string;
  fourth_number: string;
  fourth_string: string;
  fifth_number: string;
  fifth_string: string;
}
const LocalizedSectionsixteen = (props: Props) => {
  const {
    fifth_number,
    fifth_string,
    first_number,
    first_string,
    fourth_number,
    fourth_string,
    second_number,
    second_string,
    third_number,
    third_string,
  } = props;
  const array = [
    {
      number: first_number,
      string: first_string,
    },
    {
      number: second_number,
      string: second_string,
    },
    {
      number: third_number,
      string: third_string,
    },
    {
      number: fourth_number,
      string: fourth_string,
    },
    {
      number: fifth_number,
      string: fifth_string,
    },
  ];
  return (
    <div className="flex flex-wrap items-center justify-center px-3 mb-8 text-primary-mono-1 lg:grid lg:px-6 lg:grid-cols-5 lg:gap-6 lg:space-x-0">
      {array.map((item, index) => {
        return (
          <div
            key={index}
            className="flex flex-col items-center justify-center text-center bg-primary-mono-3 p-4 rounded-3xl w-[40%] h-[103px] mb-4 mx-2 lg:mx-0 lg:mb-0 lg:w-full lg:h-[155px]"
          >
            <div className="text-2xl lg:text-4xl font-bold">{item.number}</div>
            <div className="text-sm lg:text-lg">{item.string}</div>
          </div>
        );
      })}
    </div>
  );
};

export default LocalizedSectionsixteen;
