import GoogleMapReact from "google-map-react";
import { useState } from "react";
import { Store } from "../../lib";
import "./googlemap.css";
import Marker from "./marker";
import { Geopoint } from "./storemapper";

const GoogleMap = ({
  zoomLevel,
  center,
  focusedLocation,
  setFocusedLocation,
  scrollToCard,
  stores,
}: Props) => {
  const [currentStore, setCurrentStore] = useState<Store | undefined>(
    undefined
  );
  const [clickedStoreId, setClickedStoreId] = useState("");
  return (
    <div
      id="google-map"
      className="google-map mb-12 rounded-t-2xl rounded-br-2xl overflow-hidden"
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
        }}
        defaultCenter={stores[0]}
        defaultZoom={zoomLevel}
        center={center}
      >
        {stores?.map((store, i) => (
          <Marker
            showTooltip={
              currentStore?._id === store._id ||
              focusedLocation?._id === store._id
            }
            key={store.address + i}
            lat={store.geopoint.lat}
            lng={store.geopoint.lng}
            onClick={() => {
              if (clickedStoreId === store._id) {
                setClickedStoreId("");
                setFocusedLocation(undefined);
                return;
              }
              setFocusedLocation(store);
              setClickedStoreId(store._id);
              // scrollToCard(store);
            }}
            onMouseOver={() => {
              if (clickedStoreId !== store._id) {
                setClickedStoreId("");
                setFocusedLocation(undefined);
              }
              setCurrentStore(store);
            }}
            onMouseOut={() => {
              if (clickedStoreId !== store._id) setCurrentStore(undefined);
            }}
            store={store}
            focusedLocation={focusedLocation}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

interface Props {
  zoomLevel: number;
  center: Geopoint;
  focusedLocation: Store;
  setFocusedLocation: (location: Store) => void;
  scrollToCard: (location: Store) => void;
  stores: Store[];
}

export default GoogleMap;
