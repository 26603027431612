import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

type Props = {
  item: { question: string; answer: string };
  SimpleBlockContent: any;
};
const FaqDropdown = ({ item, SimpleBlockContent }: Props) => {
  return (
    <Disclosure
      as="div"
      key={item.question}
      className="pt-6 text-primary-mono-1"
    >
      {({ open }) => (
        <>
          <dt>
            <Disclosure.Button className="text-left w-full flex justify-between items-start">
              <span className=" text-[12px] md:text-[20px] leading-[26px] font-objektiv font-medium  text-primary-mono-1">
                {item.question}
              </span>
              <span className="ml-6 items-center">
                <ChevronDownIcon
                  className={classNames(
                    open ? "-rotate-180" : "rotate-0",
                    "h-6 w-6 transform"
                  )}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="mt-2 pr-12">
            <div className=" text-[10px] leading-[18px] md:text-[16px] md:leading-[22px] font-objektiv font-normal">
              <SimpleBlockContent blocks={item.answer} />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
export default FaqDropdown;
