import React, { useState, useEffect } from "react";
import { setCookie, parseCookies } from "nookies";
import { BiXCircle } from "react-icons/bi";
import { useLocale } from "../translations/getLocale";

const CookiesConsent = ({ onStateChange }) => {
  const [showConsent, setShowConsent] = useState(false);
  const [acceptMarketing, setAcceptMarketing] = useState(true);
  const [animateOut, setAnimateOut] = useState(false);
  const [showBigCookie, setShowBigCookie] = useState(false);
  const [smallCookieVisible, setSmallCookieVisible] = useState(true);
  const urlPrefix = useLocale().urlPrefix;

  useEffect(() => {
    const cookies = parseCookies();
    if (!cookies["cookie-consent"]) {
      setShowConsent(true);
    }
  }, []);

  const updateParentState = () => {
    onStateChange(acceptMarketing);
  };
  const handleClose = () => {
    setAnimateOut(true);
    setTimeout(() => {
      setShowBigCookie(false);
      setSmallCookieVisible(true);
    }, 200);
  };

  const handleAccept = () => {
    setAnimateOut(true);
    setCookie(null, "cookie-consent", "accepted", {
      maxAge: 30 * 24 * 60 * 60, // 30 days
      path: "/",
    });
    if (acceptMarketing) {
      setCookie(null, "cookie-consent-marketing", "accepted", {
        maxAge: 30 * 24 * 60 * 60, // 30 days
        path: "/",
      });
    } else {
      setCookie(null, "cookie-consent-marketing", "declined", {
        maxAge: 30 * 24 * 60 * 60, // 30 days
        path: "/",
      });
    }
    setTimeout(() => {
      updateParentState();
      setShowConsent(false);
    }, 1500);
  };

  const handlesmallAccept = () => {
    setSmallCookieVisible(false);
    setCookie(null, "cookie-consent-marketing", "accepted", {
      maxAge: 30 * 24 * 60 * 60, // 30 days
      path: "/",
    });
    setCookie(null, "cookie-consent", "accepted", {
      maxAge: 30 * 24 * 60 * 60, // 30 days
      path: "/",
    });
    setTimeout(() => {
      updateParentState();
      setShowConsent(false);
    }, 1500);
  };

  const handleChangePreferences = () => {
    setAnimateOut(false);
    setSmallCookieVisible(false);
    setTimeout(() => {
      setShowBigCookie(true);
    }, 300);
  };

  const animationClass = animateOut ? "slide-out" : "slide-in";

  const handleToggleClick = () => {
    setAcceptMarketing(!acceptMarketing); // Toggle the state
  };

  if (!showConsent) {
    return null;
  }

  const smallCookie = (
    <div
      className={`fixed space-y-2 text-sm inset-x-0 bottom-10 m-auto h-fit max-h-1/5 w-10/12 md:w-4/5 bg-primary-mono-1 text-primary-mono-4  rounded-lg  p-4 z-50 font-p22 flex border-2 border-primary-1 shadow-lg ${
        !smallCookieVisible ? "slide-out-small" : "slide-in-small"
      }`}
    >
      <div className="flex flex-col md:flex-row justify-center md:justify-between items-center space-y-4 md:space-y-0 md:space-x-20">
        <div className="flex flex-col">
          <h1 className="font-p22 text-xl">
            {translations.yourDataYourChoise[urlPrefix]
              ? translations.yourDataYourChoise[urlPrefix]
              : translations.yourDataYourChoise["en"]}
          </h1>
          <p className="border-b" />
          <p className="text-lg font-default">
            {translations.smallCookie[urlPrefix]
              ? translations.smallCookie[urlPrefix]
              : translations.smallCookie["en"]}
          </p>
        </div>
        <div className="flex flex-col space-y-2 md:space-y-4 font-default">
          <button
            className="bg-primary-mono-4 w-60 md:w-40 h-12 rounded-lg text-base font-medium button-shadow"
            onClick={handlesmallAccept}
          >
            <p className="hover:cursor-pointer text-primary-mono-1">
              {translations.understand[urlPrefix]
                ? translations.understand[urlPrefix]
                : translations.understand["en"]}
            </p>
          </button>
          <button
            className="bg-primary-mono-4 w-60 md:w-40 h-12 rounded-lg text-base font-medium button-shadow"
            onClick={handleChangePreferences}
          >
            <p className="text-primary-mono-1">
              {translations.changePreferences[urlPrefix]
                ? translations.changePreferences[urlPrefix]
                : translations.changePreferences["en"]}
            </p>
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {showBigCookie ? (
        <div
          className={`fixed space-y-2 text-sm  bottom-0 lg:top:50 lg:right-0  h-fit max-h-4/5 lg:fit lg:w-1/3  bg-primary-mono-1 text-primary-mono-4 border-2 border-primary-1 rounded-t-2xl lg:rounded-tr-none lg:rounded-l-2xl  shadow-md p-4 z-50 overflow-y-scroll font-p22 ${animationClass} `}
        >
          {/* Your cookie consent message and styling here */}
          <div className="flex flex-row justify-between items-center">
            <h1 className="font-p22  text-base  lg:text-xl">
              {translations.useCookies[urlPrefix]
                ? translations.useCookies[urlPrefix]
                : translations.useCookies["en"]}
            </h1>
            <BiXCircle
              className=" opacity-70 hover:opacity-100 hover:cursor-pointer rounded-full h-6 w-6 md:h-8 md:w-8 right-2 top-2 absolute fill-primary-mono-4"
              onClick={handleClose}
            />
          </div>
          <p className="font-default text-base">
            {translations.weUseCookies[urlPrefix]
              ? translations.weUseCookies[urlPrefix]
              : translations.weUseCookies["en"]}
          </p>
          <div className="border-t flex flex-row justify-between items-center">
            <h2 className="font-p22 text-base lg:text-lg">
              {translations.coreCookiesHeader[urlPrefix]
                ? translations.coreCookiesHeader[urlPrefix]
                : translations.coreCookiesHeader["en"]}
            </h2>
            <div className="bg-primary-mono-4 rounded-xl">
              <p className="text-primary-mono-1 mx-2 text-xs font-default ">
                Always accepted
              </p>
            </div>
          </div>
          <p className="font-default text-base">
            {translations.coreCookies[urlPrefix]
              ? translations.coreCookies[urlPrefix]
              : translations.coreCookies["en"]}
          </p>
          <div className="border-t" />
          <div className="flex flex-row justify-between items-center">
            <h2 className="font-p22 text-base lg:text-lg">
              {translations.marketingCookiesHeader[urlPrefix]
                ? translations.marketingCookiesHeader[urlPrefix]
                : translations.marketingCookiesHeader["en"]}
            </h2>
            <label className="flex cursor-pointer items-center">
              <div className="relative">
                <input
                  type="checkbox"
                  id="toggle"
                  className="sr-only"
                  checked={acceptMarketing}
                  onChange={handleToggleClick}
                />
                <div className="block bg-gray-600 w-10 h-6 rounded-full"></div>
                <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
              </div>
              <div className="ml-3 w-12 text-primary-mono-4 font-medium">
                {acceptMarketing
                  ? translations.accept[urlPrefix]
                    ? translations.accept[urlPrefix]
                    : translations.accept["en"]
                  : translations.decline[urlPrefix]
                  ? translations.decline[urlPrefix]
                  : translations.decline["en"]}
              </div>
            </label>
          </div>
          <p className="font-default text-base">
            {translations.marketingCookies[urlPrefix]
              ? translations.marketingCookies[urlPrefix]
              : translations.marketingCookies["en"]}
          </p>
          <div className="border-t py-2" />
          <div className="flex justify-center lg:justify-start">
            <button
              onClick={handleAccept}
              className="bg-primary-mono-4 cursor-pointer button-shadow  px-4 py-2 rounded-lg border-primary-1"
            >
              <p className="text-primary-mono-1">
                {translations.update[urlPrefix]
                  ? translations.update[urlPrefix]
                  : translations.update["en"]}
              </p>
            </button>
          </div>
        </div>
      ) : (
        smallCookie
      )}
    </>
  );
};

const translations = {
  accept: {
    no: "Godta",
    en: "Accept",
    se: "Acceptera",
    dk: "Accepter",
    de: "Akzeptieren",
    es: "Aceptar",
    fr: "Accepter",
    fi: "Hyväksyä",
  },
  decline: {
    no: "Avslå",
    en: "Decline",
    se: "Avvisa",
    dk: "Nedgang",
    de: "Ablehnen",
    es: "Disminución",
    fr: "Déclin",
    fi: "Kieltäytyä",
  },
  yourDataYourChoise: {
    no: "Dine data, ditt valg",
    en: "Your data, your choice",
    se: "Dina data, ditt val",
    dk: "Dine data, dit valg",
    de: "Ihre Daten, Ihre Wahl",
    es: "Tus datos, tu elección",
    fr: "Vos données, votre choix",
    fi: "Tietosi, valintasi",
  },
  webanalysisHeader: {
    no: "Webanalyse",
    en: "Webanalysis",
    se: "Webbanalys",
    dk: "Webanalyse",
    de: "Webanalyse",
    es: "Webanalysis",
    fr: "Webanalyse",
    fi: "Webanalyysi",
  },
  webanalysis: {
    no: "Vi bruker google analytics for å analysere trafikk på våre nettsider. Disse langrer ikke personlig data og er anonymisert. Du kan lese mer om hvordan google analytics behandler data her: https://policies.google.com/technologies/partner-sites",
    en: "We use google analytics to analyze traffic on our websites. These do not store personal data and are anonymized. You can read more about how google analytics processes data here: https://policies.google.com/technologies/partner-sites",
    se: "Vi använder google analytics för att analysera trafiken på våra webbplatser. Dessa lagrar inte personuppgifter och är anonymiserade. Du kan läsa mer om hur google analytics behandlar data här: https://policies.google.com/technologies/partner-sites",
    dk: "Vi bruger google analytics til at analysere trafikken på vores websteder. Disse gemmer ikke personlige data og er anonymiseret. Du kan læse mere om, hvordan google analytics behandler data her: https://policies.google.com/technologies/partner-sites",
    de: "Wir verwenden Google Analytics, um den Datenverkehr auf unseren Websites zu analysieren. Diese speichern keine personenbezogenen Daten und sind anonymisiert. Weitere Informationen zur Verarbeitung von Daten durch Google Analytics finden Sie hier: https://policies.google.com/technologies/partner-sites",
    es: "Utilizamos Google Analytics para analizar el tráfico en nuestros sitios web. Estos no almacenan datos personales y están anonimizados. Puede leer más sobre cómo Google Analytics procesa los datos aquí: https://policies.google.com/technologies/partner-sites",
    fr: "Nous utilisons Google Analytics pour analyser le trafic sur nos sites Web. Ceux-ci ne stockent pas de données personnelles et sont anonymisés. Vous pouvez en savoir plus sur la façon dont Google Analytics traite les données ici: https://policies.google.com/technologies/partner-sites",
    fi: "Käytämme Google Analyticsia verkkosivustojemme liikenteen analysointiin. Nämä eivät tallenna henkilökohtaisia tietoja ja ne anonymisoidaan. Voit lukea lisää siitä, miten Google Analytics käsittelee tietoja täältä: https://policies.google.com/technologies/partner-sites",
  },
  smallCookie: {
    no: "Vi bruker cookies og dine data til esensielle tjenster, markedsføring og statistikk. Du kan kontrollere hvilke data vi får behandle, og lese mer i vår personværnserklæring. ",
    en: "We use cookies and your data for essential services, marketing and statistics. You can control which data we get to process, and read more in our privacy policy.",
    se: "Vi använder cookies och dina data för väsentliga tjänster, marknadsföring och statistik. Du kan kontrollera vilka data vi får bearbeta och läsa mer i vår sekretesspolicy.",
    dk: "Vi bruger cookies og dine data til essentielle tjenester, markedsføring og statistik. Du kan kontrollere, hvilke data vi får til at behandle, og læse mere i vores privatlivspolitik.",
    de: "Wir verwenden Cookies und Ihre Daten für wesentliche Dienste, Marketing und Statistiken. Sie können kontrollieren, welche Daten wir verarbeiten dürfen, und in unserer Datenschutzrichtlinie mehr lesen.",
    es: "Utilizamos cookies y sus datos para servicios esenciales, marketing y estadísticas. Puede controlar qué datos podemos procesar y leer más en nuestra política de privacidad.",
    fr: "Nous utilisons des cookies et vos données pour des services essentiels, du marketing et des statistiques. Vous pouvez contrôler les données que nous pouvons traiter et en savoir plus dans notre politique de confidentialité.",
    fi: "Käytämme evästeitä ja tietojasi olennaisiin palveluihin, markkinointiin ja tilastoihin. Voit hallita, mitä tietoja saamme käsitellä, ja lukea lisää tietosuojakäytännöstämme.",
  },
  understand: {
    no: "Jeg forstår",
    en: "I understand",
    se: "Jag förstår",
    dk: "Jeg forstår",
    de: "Ich verstehe",
    es: "Entiendo",
    fr: "Je comprends",
    fi: "Ymmärrän",
  },
  changePreferences: {
    no: "Endre preferanser",
    en: "Change preferences",
    se: "Ändra preferenser",
    dk: "Skift præferencer",
    de: "Präferenzen ändern",
    es: "Cambiar preferencias",
    fr: "Modifier les préférences",
    fi: "Muuta asetuksia",
  },
  update: {
    en: "Update my choises",
    no: "Lagre mine valg",
    se: "Spara mina val",
    dk: "Gem mine valg",
    de: "Meine Auswahl speichern",
    es: "Guardar mis elecciones",
    fr: "Enregistrer mes choix",
    fi: "Tallenna valintani",
  },
  useCookies: {
    no: "Vi bruker informasjonskapsler (cookies)",
    en: "We use cookies",
    se: "Vi använder cookies",
    dk: "Vi bruger cookies",
    de: "Wir verwenden Cookies",
    es: "Utilizamos cookies",
    fr: "Nous utilisons des cookies",
    fi: "Käytämme evästeitä",
  },
  weUseCookies: {
    no: "Våre nettsteder bruker informasjonskapsler (cookies) til sikkerhet og analyse. I tillegg bruker vi informasjonskapsler (cookies) til å personalisere innhold og annonser. Les mer om hvordan vi bruker (cookies) i vår cookie policy.",
    en: "Our websites use cookies to ensure security and analysis. In addition, we use cookies to personalize content and ads. Read more about how we use cookies in our cookie policy.",
    se: "Våra webbplatser använder cookies för säkerhet och analys. Dessutom använder vi cookies för att anpassa innehåll och annonser. Läs mer om hur vi använder cookies i vår cookie policy.",
    dk: "Vores websteder bruger cookies til sikkerhed og analyse. Derudover bruger vi cookies til at tilpasse indhold og annoncer. Læs mere om, hvordan vi bruger cookies i vores cookiepolitik.",
    de: "Unsere Websites verwenden Cookies, um Sicherheit und Analyse zu gewährleisten. Darüber hinaus verwenden wir Cookies, um Inhalte und Anzeigen zu personalisieren. Lesen Sie mehr darüber, wie wir Cookies in unserer Cookie-Richtlinie verwenden.",
    es: "Nuestros sitios web utilizan cookies para garantizar la seguridad y el análisis. Además, utilizamos cookies para personalizar el contenido y los anuncios. Lea más sobre cómo utilizamos las cookies en nuestra política de cookies.",
    fr: "Nos sites Web utilisent des cookies pour assurer la sécurité et l'analyse. De plus, nous utilisons des cookies pour personnaliser le contenu et les annonces. En savoir plus sur la façon dont nous utilisons les cookies dans notre politique en matière de cookies.",
    fi: "Verkkosivustomme käyttävät evästeitä varmistaakseen turvallisuuden ja analyysin. Lisäksi käytämme evästeitä sisällön ja mainosten personointiin. Lue lisää evästeiden käytöstämme evästekäytännössämme.",
  },
  coreCookiesHeader: {
    no: "Nødvendige cookies",
    en: "Essential cookies",
    se: "Nödvändiga cookies",
    dk: "Nødvendige cookies",
    de: "Essentielle Cookies",
    es: "Cookies esenciales",
    fr: "Cookies essentiels",
    fi: "Välttämättömät evästeet",
  },
  coreCookies: {
    no: "Disse støtter opp under kjernefunksjonalitet på nettsiden. Vi har vurdert disse som nødvendige, og lagres derfor uten samtykke.  ",
    en: "These support core functionality for the webpage. We have considered these necessary, and are therefore stored without consent.",
    se: "Dessa stöder kärnfunktionalitet för nettstedet. Vi har ansett dessa som nödvändiga och lagras därför utan samtycke.",
    dk: "Disse understøtter kernefunktionalitet for webstedet. Vi har betragtet disse som nødvendige og gemmes derfor uden samtykke.",
    de: "Diese unterstützen die Kernfunktionalität für die Webseite. Wir haben diese als notwendig erachtet und werden daher ohne Zustimmung gespeichert.",
    es: "Estos admiten la funcionalidad básica de la página web. Hemos considerado que estos son necesarios y, por lo tanto, se almacenan sin consentimiento.",
    fr: "Ces cookies prennent en charge les fonctionnalités de base de la page Web. Nous les avons considérés comme nécessaires et sont donc stockés sans consentement.",
    fi: "Nämä tukevat ydintoimintoja verkkosivulla. Olemme pitäneet näitä tarpeellisina, ja ne tallennetaan siksi ilman suostumusta.",
  },
  marketingCookies: {
    no: "Vi bruker informasjonskapsler (cookies) til å personalisere innhold og annonser, og samle inn statistikk over trafikken på nettsiden. Disse er ikke essensielle. Valget du tar her gjelder i inntil 30 dager.",
    en: "We use cookies to personalize content and ads, and collect statistics on traffic to the website. These are not essential. The choice you make here applies for up to 30 days.",
    se: "Vi använder cookies för att anpassa innehåll och annonser och samla in statistik om trafiken till webbplatsen. Dessa är inte väsentliga. Det val du gör här gäller i upp till 30 dagar.",
    dk: "Vi bruger cookies til at tilpasse indhold og annoncer og indsamle statistik over trafikken til webstedet. Disse er ikke væsentlige. Det valg, du træffer her, gælder i op til 30 dage.",
    de: "Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren und Statistiken über den Datenverkehr auf der Website zu sammeln. Diese sind nicht wesentlich. Die von Ihnen hier getroffene Wahl gilt für bis zu 30 Tage.",
    es: "Utilizamos cookies para personalizar contenido y anuncios y recopilar estadísticas sobre el tráfico del sitio web. Estos no son esenciales. La elección que haga aquí se aplica hasta por 30 días.",
    fr: "Nous utilisons des cookies pour personnaliser le contenu et les annonces et collecter des statistiques sur le trafic vers le site Web. Ceux-ci ne sont pas essentiels. Le choix que vous faites ici s'applique jusqu'à 30 jours.",
    fi: "Käytämme evästeitä sisällön ja mainosten personointiin ja verkkosivuston liikenteen tilastointiin. Nämä eivät ole välttämättömiä. Täällä tekemäsi valinta koskee enintään 30 päivää.",
  },
  marketingCookiesHeader: {
    no: "Markedsføring",
    en: "Marketing",
    se: "Marknadsföring",
    dk: "Markedsføring",
    de: "Marketing",
    es: "Marketing",
    fr: "Marketing",
    fi: "Markkinointi",
  },
};

export default CookiesConsent;
