const PlainPhone = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8195 22.5C18.8097 22.5 20.1515 21.946 21.3682 20.6502C21.4596 20.5614 21.5392 20.4731 21.6189 20.373C22.2897 19.6419 22.6196 18.8775 22.6196 18.1801C22.6196 17.3489 22.1647 16.5958 21.141 15.9091L17.7636 13.6162C16.6945 12.8958 15.466 12.8406 14.5108 13.7709L13.6581 14.5908C13.3625 14.8788 13.1006 14.9007 12.7595 14.6792C12.1453 14.2697 10.9858 13.2726 10.1781 12.497C9.35917 11.7107 8.54073 10.8024 8.09705 10.1265C7.86985 9.80531 7.88108 9.5393 8.1767 9.25135L9.02934 8.43189C9.98461 7.50113 9.91671 6.29364 9.17741 5.26383L6.77775 1.9181C6.09564 0.965385 5.29967 0.511492 4.44651 0.50026C3.71896 0.489538 2.94545 0.810683 2.19492 1.45297C2.10404 1.53058 2.01265 1.60819 1.92177 1.68579C0.580002 2.89328 0 4.17786 0 6.19408C0 9.45098 1.94474 13.3057 5.72037 16.9614C9.48426 20.6058 13.3962 22.5 16.8195 22.5ZM16.8195 21.1715C13.9195 21.2159 9.78141 18.6784 6.64245 15.6431C3.49227 12.6078 1.3433 8.95267 1.39997 6.12771C1.4112 4.93145 2.35422 3.39057 3.21809 2.64821C3.30948 2.58183 3.37739 2.52669 3.45704 2.46032C3.78686 2.19431 4.12792 2.05034 4.44651 2.05034C4.77634 2.05034 5.58252 2.69365 5.79849 3.02603L7.94797 6.14966C8.20938 6.52646 7.71056 7.45824 7.32355 7.83453L6.40249 8.72087C5.54984 9.5296 6.12831 9.98247 6.68585 10.7361C7.29955 11.578 8.44832 12.8406 9.40359 13.7601C10.416 14.7348 11.8257 15.9643 12.6559 16.5514C13.4294 17.0941 13.8971 16.6499 14.7385 15.8193L15.6483 14.9222C16.0236 14.5342 17.4879 15.0672 17.8749 15.322L20.5145 17.3601C20.8668 17.5817 20.9919 17.8585 20.9919 18.1908C20.9919 18.49 20.8443 18.8336 20.5712 19.1435C20.5028 19.2211 20.4461 19.2988 20.3777 19.3764C19.6271 20.2183 18.048 21.1496 16.8195 21.1715Z"
        fill="#21401A"
      />
    </svg>
  );
};

export default PlainPhone;
