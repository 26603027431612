import { useEffect, useState, createContext } from "react";
import { getApp, getApps, initializeApp } from "firebase/app";
import {
  onAuthStateChanged,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  onIdTokenChanged,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import {
  getFirestore,
  Firestore,
  getDoc,
  setDoc,
  doc,
} from "firebase/firestore";
import nookies from "nookies";
import { useRouter } from "next/router";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState<UserData | null>(null);
  const [authLoading, setAuthLoading] = useState(false);
  const [authError, setAuthError] = useState("");
  const router = useRouter();
  const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
  const auth = getAuth(app);
  const db = getFirestore(app);

  useEffect(() => {
    setAuthLoading(true);
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      setUser(firebaseUser);
    });
    setAuthLoading(false);
    return unsubscribe;
  }, []);

  useEffect(() => {
    return onIdTokenChanged(auth, async (user) => {
      setAuthLoading(true);
      const token = router.query.idToken as string;
      //Add check for idToken in url?
      if (!user && !token) {
        setUser(null);
        nookies.set(undefined, "token", "", { path: "/" });
      } else if (token) {
        nookies.set(undefined, "token", token, { path: "/" });
      } else {
        const token = await user.getIdToken();
        setUser(user);
        nookies.set(undefined, "token", token, { path: "/" });
      }
      setAuthLoading(false);
    });
  }, []);

  const signUpWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    try {
      setAuthLoading(true);
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (!userDoc.exists()) {
        setDoc(doc(db, "users", user.uid), {});
      }
    } catch (error) {
      console.error(error);
      setAuthError(resolveErrorMessage(error.message));
    } finally {
      setAuthLoading(false);
    }
  };

  const signInUser = async (email: string, password: string) => {
    try {
      setAuthLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error(error);
      setAuthError(resolveErrorMessage(error.message));
    } finally {
      setAuthLoading(false);
    }
  };

  const signOutUser = async () => {
    await signOut(auth);
  };

  const value = {
    signOutUser,
    signInUser,
    signUpWithEmailAndPassword,
    user,
    db,
    authLoading,
    authError,
    setUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export const AuthContext = createContext<AuthContext | null>(null);

export default AuthContextProvider;

export type UserData = {
  email: string;
  uid: string;
  accessToken?: string;
  displayName?: string;
};

export type SubscriptionData = {
  subscriptionId: string;
  subscriptionPeriodStart: number;
  subscriptionPeriodEnd: number;
  subscriptionDaysUntilDue: number | null;
  subscriptionStatus:
    | "incomplete"
    | "incomplete_expired"
    | "trialing"
    | "active"
    | "past_due"
    | "canceled"
    | "unpaid";
};

type AuthContext = {
  user: UserData;
  signInUser: (email: string, password: string) => void;
  signOutUser: () => void;
  signUpWithEmailAndPassword: (email: string, password: string) => void;
  db: Firestore;
  authLoading: boolean;
  authError: string;
  setUser: (user: UserData) => void;
};

const resolveErrorMessage = (error: string) => {
  switch (error) {
    case "Firebase: Error (auth/user-not-found).":
      return "User not found";
    case "Firebase: Error (auth/wrong-password).":
      return "Wrong password";
    case "Firebase: Error (auth/email-already-in-use).":
      return "Email already in use";
    case "Firebase: Error (auth/weak-password).":
      return "Password is too weak";
    default:
      return "Something went wrong";
  }
};
