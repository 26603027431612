import Image from "next/image";
import { Image as ImageType } from "@lilbit/shared";
type Props = {
  image: ImageType;
  bodies: any;
  heading: string;
  SimpleBlockContent: any;
  urlFor: Function;
};

const ChargingSection = (props: Props) => {
  const { heading, image, urlFor, bodies = [], SimpleBlockContent } = props;
  return (
    <div className="hidden relative lg:flex items-center justify-center bg-tangerine w-full lg:h-px-664 overflow-hidden">
      <div className="absolute -left-60 top-10 w-full h-full lg:w-px-650 lg:h-px-579">
        <Image
          src="/backgrounds/Chargin-section-background-orange-2.svg"
          layout="fill"
          objectFit="fill"
          alt=""
        />
      </div>
      <div className="flex flex-col items-center justify-center w-full h-full lg:px-42">
        <div className="w-full h-full flex flex-col items-center justify-center">
          <div className="relative w-px-200 h-36 lg:h-52">
            <Image src={urlFor(image).url()} layout="fill" alt="" />
          </div>
          <div>
            <h2 className="font-bold text-center text-secondary-5 pb-6">
              {heading}
            </h2>
          </div>
          <div className="text-center text-secondary-5">
            <SimpleBlockContent blocks={bodies[0]} />
          </div>
        </div>
      </div>
      <div className="absolute -right-60 bottom-7 w-full h-full lg:w-px-650 lg:h-px-579">
        <Image
          src="/backgrounds/Chargin-section-background-orange.svg"
          layout="fill"
          objectFit="fill"
          alt=""
        />
      </div>
    </div>
  );
};

export default ChargingSection;
