const localize = (value, languages) => {
  if (Array.isArray(value)) {
    return value.map((v) => localize(v, languages));
  } else if (typeof value == "object" && value) {
    // I added value to avoid stopping when value is null
    if (/^locale[A-Z]/.test(value._type)) {
      const language = languages.find((lang) => value[lang]);
      return value[language];
    }

    return Object.entries(value).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: localize(value, languages),
      }),
      {}
    );
  }
  return value;
};

export default localize;
