import { useLocale } from "../../translations";

import PhoneLogo from "../svgs/phonelogo";
import PlainEmail from "../svgs/plainEmail";
import PlainPhone from "../svgs/plainPhone";
import Link from "next/link";

interface Props {
  text: string;
}

const LocalizedLildogHotelContact: React.FC = (props: Props) => {
  const { text } = props;
  const urlPrefix = useLocale().urlPrefix;
  return (
    <div className="flex flex-col justify-center items-center text-center w-screen bg-primary-mono-4 py-[48px] space-y-[24px]">
      <div className="text-[92px] rounded-full p-2 bg-white flex justify-center items-center text-center">
        <PhoneLogo />
      </div>
      <h2 className="font-p22 text-2xl ">{translations.title[urlPrefix]}</h2>
      <p className="font-objektiv text-lg px-8">{text}</p>
      <div className="flex flex-row justify-center space-x-[24px]">
        <Link href={`mailto:${emailAddress}`}>
          <div className="cursor-pointer bg-white p-2 space-x-2 rounded-[12px] border-[2px] h-[48px] w-[140px] flex flex-row justify-center items-center opacity-on-touch">
            <PlainEmail />
            <p className="font-objektiv">{translations.emailUs[urlPrefix]}</p>
          </div>
        </Link>
        <Link href={`tel:${supportPhone}`}>
          <div className="cursor-pointer bg-white p-2 space-x-2 rounded-[12px] border-[2px] h-[48px] w-[140px] flex flex-row justify-center items-center opacity-on-touch">
            <PlainPhone />
            <p className="font-objektiv">{translations.callUs[urlPrefix]}</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

const translations = {
  title: {
    en: "Contact",
    se: "Kontakt",
    no: "Kontakt",
    de: "Kontakt",
    dk: "Kontakt",
    fr: "Contact",
    es: "Contacto",
    fi: "Yhteystiedot",
  },
  emailUs: {
    en: "Email us",
    se: "Maila oss",
    no: "Mail oss",
    de: "Skriv til os",
    dk: "Skriv til os",
    fr: "E-mail",
    es: "correo electrónico",
    fi: "Lähetä meille sähköpostia",
  },
  callUs: {
    en: "Call us",
    se: "Ring oss",
    no: "Ring oss",
    de: "Rufen Sie uns an",
    dk: "Ring til os",
    fr: "Appelez-nous",
    es: "Llámanos",
    fi: "Soita meille",
  },
};

const emailAddress = "support.hotel@lildog.com";
const supportPhone = "+4773440070";

export default LocalizedLildogHotelContact;
