import Dropdown from "../FaqDropdown";

type Props = {
  faqs: [];
  heading: string;
  SimpleBlockContent: any;
};

const FaqSection = (props: Props) => {
  const { faqs = [], heading = "", SimpleBlockContent } = props;
  return (
    <div className="bg-primary-white w-full">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:pt-24 lg:pb-32">
        <div className="max-w-3xl mx-auto divide-y-2 divide-primary-mono-1">
          <h4 className="text-center font-p22 font-medium text-[20px] leading-[30px] md:text-[30px] md:leading-[22px]  text-primary-mono-1">
            {heading}
          </h4>
          <dl className="mt-6 space-y-6 divide-y divide-primary-mono-1">
            {faqs &&
              faqs.map((faq: any) => (
                <Dropdown
                  SimpleBlockContent={SimpleBlockContent}
                  item={faq}
                  key={faq._key}
                />
              ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
