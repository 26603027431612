import React, { useEffect, useState } from 'react';
import '../../../shared/styles/index.css';
import '../../../shared/styles/cookieconsent.css';
import CookiesConsent from '@lilbit/shared/src/components/cookiesConsent';
import * as Sentry from '@sentry/browser';
import {
  CartContextProvider,
  getDefaultCountryFromLocale,
  CountryProvider,
  getLocaleFromContext,
  I18nextProvider,
  AuthContextProvider,
  resolveStripeLocale,
  SitenameProvider,
  Country,
  getCountryParamFromContext,
} from '@lilbit/shared';
import Script from 'next/script';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { parseCookies } from 'nookies';
import {
  botSonic,
  snapchatPixelScript,
  tiktokPixelScript,
  circlewiseScript,
  klaviyoScript,
  googleAnalyticsScript,
  googleTagManagerScript,
  rakutenScript,
  metaPixel as metaPixelScript,
} from '../components/appScripts';
import { NextPageContext } from 'next';
import appConfig from '@lilbit/shared/src/lib/app-config';

const fonts = [
  {
    cssSrc:
      'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600',
  },
];
function MyApp({ Component, pageProps, err, commonData }) {
  const { locale, localeResource, country, isApp, mainProductPrices } =
    commonData;
  const [scriptsLoaded, setScriptsLoaded] = useState(false);
  const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY, {
    locale: resolveStripeLocale(locale),
  });

  const [marketingCookieConsentGiven, setMarketingCookieConsentGiven] =
    useState(
      parseCookies()['cookie-consent-marketing'] === 'accepted'
        ? true
        : parseCookies()['cookie-consent-marketing'] === undefined
        ? undefined
        : false
    );
  const [cookieConsentGiven, setCookieConsentGiven] = useState(
    parseCookies()['cookie-consent'] === 'accepted'
      ? true
      : parseCookies()['cookie-consent'] === undefined
      ? undefined
      : false
  );
  const [pageLoaded, setPageLoaded] = useState(false);

  const handleStateChange = () => {
    setCookieConsentGiven(
      parseCookies()['cookie-consent'] === 'accepted' ? true : false
    );
    setMarketingCookieConsentGiven(
      parseCookies()['cookie-consent-marketing'] === 'accepted' ? true : false
    );
  };

  const router = useRouter();
  useEffect(() => {
    window.onload = () => {
      setPageLoaded(true);
    };
  });
  /* Converge page load tracking */
  useEffect(() => {
    if (window.cvg !== 'function') return;
    const handleRouteChange = (url) => {
      {
        //@ts-ignore. cvg is defined through the converge script
        cvg({ method: 'track', eventName: '$page_load' });
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (marketingCookieConsentGiven && !isApp) {
      const tiktokPixel = document.createElement('script');
      tiktokPixel.textContent = tiktokPixelScript;
      tiktokPixel.defer = true;
      document.head.appendChild(tiktokPixel);

      const snapchatPixel = document.createElement('script');
      snapchatPixel.textContent = snapchatPixelScript;
      snapchatPixel.defer = true;
      document.head.appendChild(snapchatPixel);

      const circlewise = document.createElement('script');
      circlewise.textContent = circlewiseScript;
      circlewise.defer = true;
      document.head.appendChild(circlewise);

      const klaviyo = document.createElement('script');
      klaviyo.src =
        'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=X89nD7';
      klaviyo.defer = true;
      document.head.appendChild(klaviyo);

      const googleAnalytics = document.createElement('script');
      googleAnalytics.textContent = googleAnalyticsScript;
      googleAnalytics.defer = true;
      document.head.appendChild(googleAnalytics);

      const googleTagManager = document.createElement('script');
      googleTagManager.textContent = googleTagManagerScript;
      googleTagManager.defer = true;
      document.head.appendChild(googleTagManager);

      const rakuten = document.createElement('script');
      rakuten.textContent = rakutenScript;
      rakuten.defer = true;
      document.head.appendChild(rakuten);

      const metaPixel = document.createElement('script');
      metaPixel.textContent = metaPixelScript;
      metaPixel.defer = true;
      document.head.appendChild(metaPixel);

      setScriptsLoaded(true);
      return () => {
        document.head.removeChild(tiktokPixel);
        document.head.removeChild(snapchatPixel);
        document.head.removeChild(circlewise);
        document.head.removeChild(klaviyo);
        document.head.removeChild(googleAnalytics);
        document.head.removeChild(googleTagManager);
        document.head.removeChild(rakuten);
        document.head.removeChild(metaPixel);
      };
    }
  }, [marketingCookieConsentGiven, scriptsLoaded, isApp]);

  return (
    <SitenameProvider name="lildog" cookieConsentGiven={cookieConsentGiven}>
      <AuthContextProvider>
        <CartContextProvider>
          <CountryProvider country={country}>
            {/* TODO remove this -> Elements */}
            <Elements stripe={stripePromise} options={{ fonts: fonts }}>
              <Head>
                {/* Kavlyo init */}
                {!isApp && marketingCookieConsentGiven && (
                  <Script
                    type="text/javascript"
                    id="klaviyo-track"
                    dangerouslySetInnerHTML={{ __html: klaviyoScript }}
                    defer
                  />
                )}
              </Head>
              {!isApp && cookieConsentGiven && (
                <Script
                  id="botsonic"
                  type="text/javascript"
                  dangerouslySetInnerHTML={{ __html: botSonic }}
                  defer
                />
              )}
              {pageLoaded && !isApp && cookieConsentGiven === undefined && (
                <CookiesConsent onStateChange={handleStateChange} />
              )}
              <Component {...pageProps} err={err} />
            </Elements>
          </CountryProvider>
        </CartContextProvider>
      </AuthContextProvider>
    </SitenameProvider>
  );
}

MyApp.getInitialProps = async function ({ ctx }: { ctx: NextPageContext }) {
  try {
    const isApp =
      ctx.asPath.split('?').filter((x) => x.toLowerCase().includes('app'))
        .length > 0;
    const locale = getLocaleFromContext(ctx);

    /* Get country from urlparams */
    let country: Country;
    const countryParam = getCountryParamFromContext(ctx);
    if (!countryParam) {
      country = getDefaultCountryFromLocale(locale);
    } else {
      country = appConfig.countries.find((c) => c.cca2 === countryParam);
    }

    // const localeResource = await import(
    //   `../translations/locales/${locale.appLanguage}`
    // );

    /**
     * Get shared data for all pages
     */

    return {
      commonData: {
        locale,
        // localeResource: localeResource.default,
        country,
        isApp,
      },
    };
  } catch (error) {
    Sentry.captureException(
      new Error(`Error in src/page/_app.js page: (POST) ${error}`)
    );
    console.error(error);
    console.warn('Could not fetch common page data');

    // Fallback values
    return {
      commonData: {
        mainNavigation: [],
        tenant: {
          defaults: {
            currency: 'usd',
          },
        },
      },
    };
  }
};

export default MyApp;

//test
