export const botSonic = `
(function (w, d, s, o, f, js, fjs) {
  w["botsonic_widget"] = o;
  w[o] = w[o] || function () {
    (w[o].q = w[o].q || []).push(arguments);
  };
  (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
  js.id = o;
  js.src = f;
  js.async = 1;
  fjs.parentNode.insertBefore(js, fjs);
})(window, document, "script", "Botsonic", "https://widget.writesonic.com/CDN/botsonic.min.js");

Botsonic("init", {
  serviceBaseUrl: "https://api.botsonic.ai",
  token: "e3a83918-1b60-4910-9848-aebacf0f4d81",
});
`;

export const convergeScript = `window.cvg||(c=window.cvg=function(){c.process?c.process.apply(c,arguments):c.queue.push(arguments)},c.queue=[]);cvg({method:"track",eventName:"$page_load"});`

export const snapchatPixelScript = `
(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
{a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
r.src=n;var u=t.getElementsByTagName(s)[0];
u.parentNode.insertBefore(r,u);})(window,document,
'https://sc-static.net/scevent.min.js');

snaptr('init', '113da1e0-4b21-4511-bfc2-2aa8d272cdb7', {
'user_email': '__INSERT_USER_EMAIL__'
});
snaptr('track', 'PAGE_VIEW');
`;

export const tiktokPixelScript = `
!function (w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

  ttq.load('CK24SO3C77U25LTFPVLG');
  ttq.page();
}(window, document, 'ttq');`;

export const klaviyoScript = `
!function(){if(!window.klaviyo){window._klOnsite=window._klOnsite||[];try{window.klaviyo=new Proxy({},{get:function(n,i){return"push"===i?function(){var n;(n=window._klOnsite).push.apply(n,arguments)}:function(){for(var n=arguments.length,o=new Array(n),w=0;w<n;w++)o[w]=arguments[w];var t="function"==typeof o[o.length-1]?o.pop():void 0,e=new Promise((function(n){window._klOnsite.push([i].concat(o,[function(i){t&&t(i),n(i)}]))}));return e}}})}catch(n){window.klaviyo=window.klaviyo||[],window.klaviyo.push=function(){var n;(n=window._klOnsite).push.apply(n,arguments)}}}}();
`

export const circlewiseScript = `window.tmt_offer_sid = 'e0a6gp';
(function() {
    var timestamp = +new Date;
    var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true;
    po.src = '//cdn.tmtarget.com/tracking/s/entrypoint_v2.min.js?t=' + timestamp;
    var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);
})();`

export const googleAnalyticsScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-MZRZ8M2');`

export const googleTagManagerScript = `
  (function() {
    var script = document.createElement('script');
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-16625326364";
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'AW-16625326364');
  })();
`;

export const rakutenScript = `(function (url) {
    window.rakutenDataLayer = false; // Set custom DataLayer name if 'DataLayer' conflicts
    var dln = window.rakutenDataLayer || 'DataLayer';
    if(!window[dln]){
      window[dln] = {};
    }
    if(!window[dln].events){
      window[dln].events = {};
    }
    window[dln].events.SPIVersion = window[dln].events.SPIVersion || "v3.4.1";
    
    var loc, ct = document.createElement("script");
    ct.type = "text/javascript";
    ct.async = true; ct.src = url; loc = document.getElementsByTagName('script')[0];
    loc.parentNode.insertBefore(ct, loc);
  }(document.location.protocol + "//tag.rmp.rakuten.com/125757.ct.js"));`


export const metaPixel = `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '751144399242473');
fbq('track', 'PageView');
`