import React, { createContext, useEffect, useReducer, useState } from "react";
import { CartReducer, sumItems } from "./ShoppingCartReducer";
import { ProductPrices } from "../lib";

export interface CartContextProps {
  increase: Function;
  decrease: Function;
  clearCart: Function;
  addProduct: Function;
  removeProduct: Function;
  handleCheckout: Function;
  setNrOfMainProducts: Function;
  cartItems: any;
  nrOfMainProducts: number;
  mainProductPrices: ProductPrices | undefined;
}

export const CartContext = createContext<CartContextProps | null>(null);

//Check if next is running clientside
const IS_SERVER = typeof window === "undefined";
let storage;
let initialState;
if (!IS_SERVER) {
  // if (localStorage.getItem("cart")) {
  //   storage = JSON.parse(localStorage.getItem("cart"));
  // } else {
  storage = [];
  // }
  initialState = {
    cartItems: storage,
    ...sumItems(storage),
    checkout: false,
  };
}

const CartContextProvider = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    const fetchMainProductPrices = async () => {
      const res = await fetch("/api/woocommerce/get/mainProductPrices");
      const data = await res.json();
      setMainProductPrices(data);
    };
    fetchMainProductPrices();
  }, []);

  const [state, dispatch] = useReducer(CartReducer, initialState);
  const [nrOfMainProducts, setNrOfMainProducts] = useState(1);
  const [mainProductPrices, setMainProductPrices] = useState<
    undefined | ProductPrices
  >(undefined);
  const increase = (payload) => {
    payload && dispatch({ type: "INCREASE", payload });
  };

  const decrease = (payload) => {
    payload && dispatch({ type: "DECREASE", payload });
  };

  const addProduct = (payload) => {
    payload && dispatch({ type: "ADD_ITEM", payload });
  };

  const removeProduct = (payload) => {
    payload && dispatch({ type: "REMOVE_ITEM", payload });
  };

  const clearCart = () => {
    dispatch({ type: "CLEAR" });
  };

  const handleCheckout = () => {
    dispatch({ type: "CHECKOUT" });
  };

  const contextValues = {
    removeProduct,
    addProduct,
    increase,
    decrease,
    clearCart,
    handleCheckout,
    nrOfMainProducts,
    setNrOfMainProducts,
    mainProductPrices,
    ...state,
  };

  return (
    <CartContext.Provider value={contextValues}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
