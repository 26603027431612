import { trimDate, translations, LocalizedPostProps } from "@lilbit/shared";
import Image from "next/image";
import SocialLinks from "./SocialLinks";
import { useLocale } from "../translations/getLocale";
import { getPositionFromHotspot } from "../lib/helpers";

type Props = {
  localizedPosts: LocalizedPostProps;
  SimpleBlockContent: any;
  urlFor: Function;
};
const DesktopPost = ({ localizedPosts, urlFor, SimpleBlockContent }: Props) => {
  const {
    _updatedAt,
    title,
    mainImage,
    authorImage,
    authorBio,
    name,
    photoCredits,
    imageTag,
    category,
    summary,
    body = [],
  } = localizedPosts;
  const isDogBreedPost = category === "dogbreeds" || category === "hunderaser";
  const locale = useLocale().urlPrefix;
  const { Blog } = translations;
  let date: string;
  const splitImageTag = (imageTag) => {
    if (imageTag) {
      return imageTag[0].split(":");
    }
    return "";
  };
  if (_updatedAt) {
    date = trimDate(_updatedAt);
  }
  return (
    <div className="flex flex-col items-center justify-center py-4 px-4 lg:px-24 font-objektiv w-full">
      <div className="flex flex-wrap justify-center items-center w-full">
        <div className="flex flex-col justify-start items-start w-full">
          <h6 className="text-primary-mono-3 font-bold bg-primary-mono-1 rounded-xl px-4">
            {category.toUpperCase()}
          </h6>
          <h1 className="text-primary-mono-2 heading-2">{title}</h1>
          {summary && (
            <div className="text-p-lg-desktop font-bold text-primary-mono-2">
              <SimpleBlockContent blocks={summary[0]} />
            </div>
          )}
        </div>
        <div className="relative h-48 sm:h-80 md:h-96 lg:h-px-500 w-full shadow-lg rounded-br-3xl rounded-t-3xl">
          <Image
            src={urlFor(mainImage, false)
              .width(1200)
              .height(600)
              .quality(50)
              .url()}
            alt={mainImage?.alt || ""}
            layout="fill"
            objectFit="cover"
            loading="lazy"
            className="rounded-br-3xl rounded-t-3xl"
            objectPosition={
              mainImage?.hotspot && getPositionFromHotspot(mainImage.hotspot)
            }
            priority
          />
        </div>
        <div className="flex w-full text-gray-700">
          {imageTag && imageTag.length > 0 && (
            <div className="flex">
              {(imageTag[0].includes(":") && (
                <p>
                  <b>{splitImageTag(imageTag)[0]}:</b>
                  {splitImageTag(imageTag)[1]}
                </p>
              )) || <p>{imageTag}</p>}
            </div>
          )}
          <p>&nbsp;{photoCredits && `Foto: ${photoCredits}`}</p>
        </div>
        <div className="flex flex-row-reverse w-full pt-9 border-b-2 border-paleSky">
          <div className="flex justify-between w-full">
            <div className="flex justify-end items-center w-full py-4 space-x-8">
              <div className="flex flex-col text-secondary-9">
                <p className="font-bold text-p-sm-desktop">
                  {Blog.shareKnowledge.title[locale]}
                </p>
                <p className="font-normal text-p-sm-desktop">
                  {Blog.shareKnowledge.subtitle[locale]}
                </p>
              </div>
              <div>
                <SocialLinks />
              </div>
            </div>
          </div>
          <div className="flex items-center w-full">
            <div className="flex w-full items-center space-x-8">
              <div className="flex flex-col w-full text-secondary-9 font-objektiv text-p-sm-desktop">
                <p>
                  {Blog.updated[locale]} {date}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-center pt-14 max-w-prose">
        <SimpleBlockContent blocks={body} />
      </div>
    </div>
  );
};
export default DesktopPost;
