import React from "react";
import Image from "next/image";
import Lildogswitcher from "../lildogswitcher";
import AddToCart from "../Button/addToCartBtn";
import { GlobalConfig, Product, Specification } from "../../lib/types";

type Props = {
  textBesideButton: string;
  specifications: Array<Specification>;
  mainProduct: Product;
  urlFor: Function;
  siteName: "lildog" | "lilcat";
  config: GlobalConfig;
};

const AntiProductSpecifications = (props: Props) => {
  const { specifications, textBesideButton, mainProduct, urlFor, config } =
    props;
  return (
    <>
      <div className="py-20 bg-primary-2 text-secondary-5 px-6 w-full">
        <div className="max-w-xl  px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-6">
            {specifications.map((specification, index) => (
              <div key={index}>
                <dt>
                  <div className="flex justify-center lg:mb-14">
                    <Image
                      src={urlFor(specification.image).url()}
                      alt={specification.alt}
                      width={180}
                      height={180}
                      quality={100}
                      objectFit="contain"
                    />
                  </div>
                  <p className="flex mt-5 font-extrabold text-2xl justify-center lg:h-20 lg:mb-10 text-center">
                    {specification.boldText}
                  </p>
                </dt>
                <dd className="flex justify-center mt-2 font-normal text-base mb-8 text-center">
                  {specification.smallText}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
      <div className="pb-8 pt-6 w-full text-secondary-5  bg-secondary-10 rounded-b-3xl flex flex-col items-center justify-start space-y-4 px-6 md:flex-row">
        <div className="xs:flex font-objektiv font-bold space-x-4 xs:justify-center  xs:w-3/4 md:w-2/4">
          <Lildogswitcher borderColor="white" textColor="white" />
          <div className="flex items-center justify-center w-full lg:w-px-272 ">
            <AddToCart
              config={config}
              textColor="text-secondary-5"
              borderColor="border-white"
              mainProduct={mainProduct}
            />
          </div>
        </div>
        <div className="lg:ml-40 md:ml-16">
          <p>{textBesideButton}</p>
        </div>
      </div>
    </>
  );
};

export default AntiProductSpecifications;
