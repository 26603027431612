const CircleSmall = ({ height, width }: { height: number; width: number }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.5" cy="7" r="6.5" fill="#9F9F9F" fillOpacity="0.3" />
    </svg>
  );
};

export default CircleSmall;
