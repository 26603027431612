import Image from "next/image";
import React, { useState } from "react";
import { useLocale, Product } from "@lilbit/shared";
import axios from "axios";
import { useContext } from "react";
import { CartContext } from "../context";
import Vippslogo from "./svgs/vippslogo";
import { CountryContext } from "../context";

type Props = {
  productID: number;
  coupon: string;
  lgScreenWidth: string;
  smScreenWidth: string;
  input: any;
  getTotalPrice: Function;
  mainProduct: Product;
};
const Vippsbutton = ({
  productID,
  coupon,
  lgScreenWidth,
  smScreenWidth,
  input,
  getTotalPrice,
  mainProduct,
}: Props) => {
  const locale = useLocale().urlPrefix;
  const countryContext = useContext(CountryContext);
  const [loading, setLoading] = useState(false);
  const [showAcceptTermsMessage, setShowAcceptTermsMessage] = useState(false);
  const { nrOfMainProducts, mainProductPrices } = useContext(CartContext);
  const { acceptTerms, subscribeNews } = input;

  const completeVippsExpressPayment = (res: any) => {
    if (res.status === 200) {
      if (
        typeof window !== "undefined" &&
        window.ttq &&
        typeof window.ttq.track === "function"
      ) {
        const formData = {
          value: getTotalPrice(),
          currency: countryContext.country.currency,
          description: "vipps",
          contents: [
            {
              content_id: mainProduct.id,
              content_name: mainProduct.name,
            },
          ],
        };
        window.ttq.track("CompletePayment", formData);
      }
    }
  };

  if (mainProduct?.stock_status === "outofstock") {
    return <></>;
  }
  return (
    <div
      className="flex flex-col w-full items-center justify-center cursor-pointer lg:rounded-3xl lg:justify-start"
      onMouseOver={() => {
        !acceptTerms ? setShowAcceptTermsMessage(true) : "";
      }}
      onMouseOut={() => {
        setShowAcceptTermsMessage(false);
      }}
    >
      <button
        className={`buttonPress relative flex items-center justify-center active:bg-primary-3 sm:${smScreenWidth} lg:${lgScreenWidth} mx-0 lg:mx-0 w-full h-[48px] sm:max-w-full lg:max-w-full rounded-[40px] bg-orange-vipps disabled:cursor-not-allowed disabled:bg-peach font-objektiv`}
        onClick={async () => {
          setLoading(true);
          // Tik Tok Pixel
          if (
            typeof window !== "undefined" &&
            window.ttq &&
            typeof window.ttq.track === "function"
          ) {
            const formData = {
              value: getTotalPrice(),
              currency: countryContext.country.currency,
              description: "vipps",
              contents: [
                {
                  content_id: mainProduct.id,
                  content_name: mainProduct.name,
                },
              ],
            };
            window.ttq.track("AddPaymentInfo", formData);
          }
          if (
            typeof window !== "undefined" &&
            window.snaptr &&
            typeof window.snaptr === "function"
          ) {
            const formData = {
              price: getTotalPrice(),
              currency: countryContext.country.currency,
              item_ids: mainProduct.id,
              description: "vipps",
              number_item: nrOfMainProducts,
            };
            window.snaptr("track", "ADD_BILLING", formData);
          }
          /* Converge Added Payment Info event */
          if (window.cvg === "function") {
            //@ts-ignore to ignore the error that cvg is not defined
            cvg({
              method: "track",
              eventName: "Added Payment Info",
              properties: {
                total_price: getTotalPrice(),
                currency: countryContext.country.currency,
                items: [
                  {
                    product_id: mainProduct.id, // Woocommerce product ID
                    sku: mainProduct.sku,
                    name: mainProduct.name,
                    price:
                      mainProductPrices?.PRICE[countryContext.country.currency],
                    currency: countryContext.country.currency,
                    quantity: nrOfMainProducts,
                  },
                ],
              },
            });
          }
          const { data: created_order } = await axios.post(
            "/api/payment/checkout_order",
            {
              order: null,
              products: [
                {
                  id: productID,
                  quantity: nrOfMainProducts,
                },
              ],
              coupon: coupon,
              payment_method: "vipps_express",
              payment_method_title: "Vipps eComm Express Payment",
              shipping_method_id:
                countryContext.country.countryCode === "NO"
                  ? "Post_nord"
                  : "DHL",
              shipping_method_title: countryContext.country.countryCode
                ? "Post Nord"
                : "DHL",
              cvg_uid: document?.cookie?.match(/__cvg_uid=([^;]+)/)?.[1] || "",
            }
          );
          await axios
            .post("/api/payment/vippsExpress", {
              quantity: nrOfMainProducts,
              created_order: created_order.id,
              language: locale,
              subscribe_to_newsletter: subscribeNews,
              subscribe_to_newsletter_email: input.email,
            })
            .then(async (res) => {
              setLoading(false);
              completeVippsExpressPayment(res.data);
              window.location.assign(res.data);
            });
          setLoading(false);
        }}
        disabled={loading || !acceptTerms}
      >
        {/* {showAcceptTermsMessage && !acceptTerms ? (
          <div className="flex items-center justify-center absolute -top-20 min-w-min">
            <Tooltip
              className="flex-col w-auto border-2 absolute -top-12 right-0 bg-white text-primary-2 border-primary-2 rounded-lg leading-4 font-objektiv font-semibold text-xs flex items-center justify-center"
              tooltip={translations.tooltips.accept_terms[locale]}
            />
          </div>
        ) : (
          ""
        )} */}
        {!loading && (
          <div className="flex items-center justify-center space-x-2">
            <div className="flex text-center">
              <p className="text-p-md leading-8 font-semibold text-secondary-5">
                {translations.payWithVipps[locale] ||
                  translations.payWithVipps["en"]}
              </p>
            </div>
            <div className="flex items-center pt-1 md:pt-[6px]">
              {/* <Image src="/Vipps/vipps_semi_logo.svg" width={19} height={14} /> */}
              <Vippslogo />
            </div>
          </div>
        )}

        {loading && (
          <div>
            <Image
              src={`/carticons/Icon_Loading.png`}
              className="animate-spin"
              height={20}
              width={20}
              quality={50}
              alt=""
            />
          </div>
        )}
      </button>
    </div>
  );
};
const translations = {
  payWithVipps: {
    no: "Betal med ",
    en: "Pay with ",
  },
};
export default Vippsbutton;
