import { getPositionFromHotspot } from "../../lib";
import Image from "next/image";
import { Image as ImageType } from "../../lib";

interface Props {
  title: string;
  header: string;
  backgroundImage: ImageType;
  smallImage: ImageType;
  descriptionOne: any;
  descriptionTwo: any;
  urlFor: any;
  SimpleBlockContent: any;
}

const LocalizedFromAgria = (props: Props) => {
  const {
    title,
    backgroundImage,
    smallImage,
    descriptionOne,
    urlFor,
    SimpleBlockContent,
  } = props;
  return (
    <div>
      <div className="relative w-full h-[600px] lg:h-[800px] mt-8 mb-8 flex items-center justify-center ">
        <div className=" w-11/12 lg:w-9/10 overflow-hidden  rounded-3xl h-full  bg-primary-4 flex flex-col lg:flex-row justify-center items-center text-center ">
          <div className="hidden relative lg:flex justify-center items-center h-full w-1/2">
            <div className="h-full w-full">
              <Image
                src={urlFor(backgroundImage).quality(40).url()}
                alt="background"
                layout="fill"
                objectFit="cover"
                objectPosition={
                  backgroundImage.hotspot &&
                  getPositionFromHotspot(backgroundImage.hotspot)
                }
              />
            </div>
          </div>
          <div className="flex-col text-primary-1  w-5/6 lg:w-1/2 space-y-4">
            <p className="text-xl lg:text-2xl font-p22 px-16">{title}</p>
            <p className="text-sm md:text-base lg:text-lg font-default text-center sm:px-4 md:px-10 lg:px-20  ">
              <SimpleBlockContent blocks={descriptionOne} />
            </p>
          </div>
          <div className="relative lg:hidden sm:flex justify-center items-center w-3/4 h-[300px] rounded-3xl overflow-hidden">
            <div className="w-full h-[160px] xs:h-[220px] sm:h-[280px]">
              <Image
                src={urlFor(smallImage).quality(40).url()}
                alt="background"
                layout="fill"
                objectFit="cover"
                objectPosition={
                  backgroundImage.hotspot &&
                  getPositionFromHotspot(backgroundImage.hotspot)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="h-8"></div>
    </div>
  );
};

export default LocalizedFromAgria;
