import React, { useRef, useEffect, useState } from "react";
import { BiXCircle, BiCheckCircle, BiX } from "react-icons/bi";

import Image from "next/image";
import { useLocale } from "../translations";
import {
  countryCodeConvert,
  validCountryCodes,
} from "../lib/countryNameAndCode";

interface Props {
  backgroundImgUrl: string;
  textContent: string;
  show: boolean;
  onClose: () => void;
}

const CountryAvailabilityModal = (props: Props) => {
  const containerRef = useRef(null);
  const selectRef = useRef(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [selectedCountryName, setSelectedCountryName] = useState(null);
  const urlPrefix = useLocale().urlPrefix;
  const [countries, setCountries] = useState([]);

  const handleSelectClick = () => {
    if (selectRef.current) {
      selectRef.current.focus();
    }
  };

  useEffect(() => {
    const countryCodeAndNames = [];
    countryCodeConvert.forEach((country) => {
      countryCodeAndNames.push({
        countryCode: country.countryCode,
        countryName: country.translations[urlPrefix] || country.country,
      });
    });
    countryCodeAndNames.sort((a, b) => {
      const nameA = a.countryName.toUpperCase();
      const nameB = b.countryName.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setCountries(countryCodeAndNames);
  }, [urlPrefix]);

  if (!props.show) {
    return null;
  }
  const checkValidConutry = () => {
    if (validCountryCodes?.includes(selectedCountryCode)) {
      return (
        <div className="flex flex-col justify-center items-center">
          <BiCheckCircle className="fill-green-500 h-8 w-8 md:h-10 md:w-10" />{" "}
          <p className="text-sm md:text-base text-black text-center">
            {translations.worksIn[urlPrefix]}
          </p>
          <p className="text-base md:text-lg lg:text-xl  text-black ubpixel-antialiased text-center">
            {selectedCountryName}
          </p>{" "}
        </div>
      );
    }
    return (
      <div className="flex flex-col justify-center items-center">
        <BiX className="fill-red-600 h-8 w-8 md:h-10 md:w-10" />{" "}
        <p className="text-sm md:text-base text-black text-center">
          {translations.doesNotWorkIn[urlPrefix]}
        </p>
        <p className="text-base md:text-lg lg:text-xl  text-black ubpixel-antialiased text-center">
          {selectedCountryName}
        </p>{" "}
      </div>
    );
  };

  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };
  // Close modal on escape key press.  Uncomment to add this feature
  // useEffect(() => {
  //   document.body.addEventListener("keydown", closeOnEscapeKeyDown);
  //   return function cleanup() {
  //     document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
  //   };
  // }, [closeOnEscapeKeyDown]);

  const handleOutsideClick = (e: Event) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      props.onClose();
    }
  };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleOutsideClick);
  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // });

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    const selectedText = e.target.options[e.target.selectedIndex].text;
    setSelectedCountryCode(selectedValue);
    setSelectedCountryName(selectedText);
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-900 z-10 bg-opacity-30 ">
      <div
        className=" bg-white fixed items-center content-center flex flex-col gap-y-4 top-40 w-11/12 md:w-2/3 lg:w-1/2  h-auto shadow-xl rounded-2xl "
        ref={containerRef}
      >
        <div className="w-4/5 flex pt-8 md:pt-10 lg:pt-12 h-auto">
          <h2 className="text-lg md:text-xl lg:text-2xl text-black w-fit">
            {translations.headingText[urlPrefix]}
          </h2>
          <BiXCircle
            className=" opacity-70 hover:opacity-100 rounded-full h-6 w-6 sm:h-8 sm:w-8 md:h-12 md:w-12 right-2 top-2 absolute fill-primary-mono-1"
            onClick={props.onClose}
          />
        </div>
        <div className="relative w-4/5 text-black text-sm md:text-base space-y-2 h-auto">
          {props.textContent !== undefined ? props.textContent : null}
        </div>
        <div className="inline-block relative w-4/5 ">
          <select
            id="countries"
            ref={selectRef}
            onChange={handleSelectChange}
            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-600 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline  "
          >
            <option selected>{translations.chooseCountry[urlPrefix]}</option>
            {countries?.map((country) => {
              return (
                <option key={country.countryCode} value={country.countryCode}>
                  {country.countryName}
                </option>
              );
            })}
          </select>
        </div>

        <div className="relative w-4/5 h-auto flex justify-center items-center mb-2 md:mb-4">
          {props.backgroundImgUrl !== null ? (
            <>
              <Image
                className="rounded-xl"
                width={1200}
                height={600}
                quality={40}
                src={props.backgroundImgUrl}
                alt={"dog running"}
              />
              <div
                className="absolute p-2 bg-white opacity-90 w-5/6 sm:w-4/5 md:w-3/5 h-4/5 sm:h-1/2 z-20 rounded-lg justify-center items-center flex"
                onClick={handleSelectClick}
              >
                {selectedCountryName ? (
                  <p className="items-center text-black">
                    {checkValidConutry()}
                  </p>
                ) : (
                  <p>{translations.chooseCountry[urlPrefix]}</p>
                )}
              </div>
            </>
          ) : (
            <div className="bg-white w-full h-40 flex items-center justify-center">
              <div className="absolute px-2 py-4 bg-white opacity-90 w-4/5  md:w-3/5 h-2/3 z-20 rounded-lg justify-center items-center flex outline-dashed">
                {selectedCountryName ? (
                  <p
                    className=" text-black"
                    onClick={() => handleSelectClick()}
                  >
                    {checkValidConutry()}
                  </p>
                ) : (
                  <p onClick={() => handleSelectClick()}>
                    {translations.chooseCountry[urlPrefix]}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const translations = {
  headingText: {
    en: "Check available countries",
    no: "Sjekk tilgjengelige land",
    se: "Kolla tillgängliga länder",
    da: "Tjek tilgængelige lande",
    de: "Verfügbare Länder überprüfen",
    fi: "Tarkista saatavilla olevat maat",
    fr: "Vérifier les pays disponibles",
    es: "Verificar países disponibles",
  },
  selectCountry: {
    en: "Select a country",
    no: "Velg et land",
    se: "Välj ett land",
    da: "Vælg et land",
    de: "Wählen Sie ein Land",
    fi: "Valitse maa",
    fr: "Choisissez un pays",
    es: "Elige un país",
  },
  chooseCountry: {
    en: "Choose a country",
    no: "Velg et land",
    se: "Välj ett land",
    da: "Vælg et land",
    de: "Wählen Sie ein Land",
    fi: "Valitse maa",
    fr: "Choisissez un pays",
    es: "Elige un país",
  },
  worksIn: {
    en: "Works in",
    no: "Fungerer i",
    se: "Fungerar i",
    da: "Fungerer i",
    fi: "Toimii",
    de: "Funktioniert in",
    fr: "Fonctionne en",
    es: "Funciona en",
  },
  doesNotWorkIn: {
    en: "Does not work in",
    no: "Fungerer ikke i",
    se: "Fungerar inte i",
    da: "Fungerer ikke i",
    fi: "Ei toimi",
    de: "Funktioniert nicht in",
    fr: "Ne fonctionne pas en",
    es: "No funciona en",
  },
};

export default CountryAvailabilityModal;
