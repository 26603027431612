import Image from "next/image";
import { Image as ImageType } from "../../lib";
import { useLocale } from "../../translations";

interface Props {
  hotelImage: ImageType;
  hotelImageTwo: ImageType;
  lildogLogo: ImageType;
  hotelDescription: any;
  urlFor: any;
  SimpleBlockContent: any;
}

const LocalizedLildoghotel = (props: Props) => {
  const urlPrefix = useLocale().urlPrefix;
  const { hotelImage, hotelImageTwo, lildogLogo, urlFor } = props;
  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="relative w-full hidden md:flex h-[500px] bg-white">
        <Image
          src={urlFor(hotelImage).url()}
          alt="Lildog Hotel HHeader Photo"
          layout="fill"
          objectFit="cover"
          quality={40}
        />
        <div className="absolute md:inset-20 lg:inset-40 hidden md:flex flex-col items-center justify-center rounded-[48px] rounded-bl-none bg-primary-mono-1 md:w-[545px] md:h-[316px] bg-opacity-90 p-[48x] pr-[96px] space-y-[24px]">
          <Image
            src={urlFor(lildogLogo).url()}
            alt="Lildog Logo"
            width={292}
            height={100}
            objectFit="contain"
            quality={40}
          />
          <p className="text-white font-default text-[32px] leading-[48px] pl-[75px]">
            {translations.lildogMoto[urlPrefix]
              ? translations.lildogMoto[urlPrefix]
              : translations.lildogMoto.en}
          </p>
        </div>
      </div>
      {hotelImageTwo && (
        <div className="relative flex md:hidden w-full h-[440px]">
          <Image
            src={urlFor(hotelImageTwo).url()}
            alt="Lildog Hotel Header Photo"
            layout="fill"
            objectFit="cover"
            quality={40}
          />
          <div className="absolute top-[220px] md:hidden flex flex-col items-center justify-center rounded-3xl rounded-bl-none bg-primary-mono-1 w-[229px] h-[179px] bg-opacity-90 p-2 pr-4 space-y-2">
            <Image
              src={urlFor(lildogLogo).url()}
              alt="Lildog Logo"
              width={240}
              height={160}
              objectFit="contain"
              quality={40}
            />
            <p className="text-white font-default text-lg leading-lg w-[220px] pl-[6px]">
              {translations.lildogMoto[urlPrefix]
                ? translations.lildogMoto[urlPrefix]
                : translations.lildogMoto.en}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const translations = {
  lildogMoto: {
    no: "Ta vare på hunden din, slik den alltid tar vare på deg",
    se: "Ta hand om din hund, som den alltid tar hand om dig",
    en: "Care for your dog, like it always cares for you",
  },
};

export default LocalizedLildoghotel;
