import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { useLocalStorage } from "@lilbit/shared";

const I18NextContext = createContext();

export const useT = () => {
  const c = useContext(I18NextContext);

  return (val, options) => c.t(val, options);
};

const I18nextProvider = ({ locale, localeResource, children }) => {
  const lng = locale.appLanguage;
  const [getStoredAppLanguage, setStoredAppLanguage] =
    useLocalStorage("appLanguage");

  React.useEffect(() => {
    if (lng !== getStoredAppLanguage) {
      setStoredAppLanguage(lng);
    }
  }, [lng]);

  i18n.use(initReactI18next).init({
    resources: {
      [lng]: localeResource,
    },
    lng,

    interpolation: {
      escapeValue: false, // react already safe from xss
      format: function (value, format) {
        if (format === "uppercase") {
          return value.toUpperCase();
        }
        return value;
      },
    },
  });

  return (
    <I18NextContext.Provider value={i18n}>{children}</I18NextContext.Provider>
  );
};

I18nextProvider.propTypes = {
  locale: PropTypes.object,
  localeResource: PropTypes.object,
  children: PropTypes.object,
};

export default I18nextProvider;
