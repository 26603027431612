import { memo } from "react";
import { svg } from "../../lib";
const Vippslogocard = ({ width, height }: svg) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M26.8854 0.333496H2.11458C0.946731 0.333496 0 1.28023 0 2.44808V17.5522C0 18.7201 0.946731 19.6668 2.11458 19.6668H26.8854C28.0533 19.6668 29 18.7201 29 17.5522V2.44808C29 1.28023 28.0533 0.333496 26.8854 0.333496Z"
          fill="#FF5B24"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8336 7.74143C17.6656 7.74143 18.3786 7.10984 18.3786 6.20201H18.3787C18.3787 5.29398 17.6656 4.6626 16.8336 4.6626C16.0019 4.6626 15.2891 5.29398 15.2891 6.20201C15.2891 7.10984 16.0019 7.74143 16.8336 7.74143ZM18.8538 10.2287C17.8237 11.5706 16.7344 12.4983 14.8132 12.4983H14.8134C12.8533 12.4983 11.3279 11.314 10.1397 9.57725C9.66424 8.86657 8.93145 8.70876 8.39664 9.08377C7.90158 9.43914 7.78305 10.1892 8.23829 10.8406C9.88198 13.3473 12.1594 14.8076 14.8132 14.8076C17.2495 14.8076 19.1509 13.6236 20.636 11.6497C21.1904 10.9195 21.1707 10.1695 20.636 9.75494C20.1407 9.35981 19.408 9.49859 18.8538 10.2287Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="29"
            height="19.3333"
            fill="white"
            transform="translate(0 0.333496)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(Vippslogocard);
