import { useBreakpoint } from "@lilbit/shared";
import { breakPointValues } from "../context";
import { countries } from "@lilbit/shared";
import { Locale } from "../translations/types";
import { useRouter } from "next/router";

export const resolveStripeLocale = (locale: Locale) => {
  switch (locale.urlPrefix) {
    case "en":
    case "fi":
    case "da":
      return locale.urlPrefix;
    case "no":
      return "nb";
    case "se":
      return "sv";
    case "de":
      return "de";
    default:
      return "en";
  }
};

export const resolveZendeskLocale = (locale: Locale) => {
  switch (locale.urlPrefix) {
    case "en":
    case "no":
      locale.urlPrefix;
    case "se":
      return "sv";
    default:
      return "en";
  }
};

export const gtmVirtualPageView = (rest) => {
  window.dataLayer?.push({
    event: "VirtualPageView",
    ...rest,
  });
};

export const formatWpText = (text) => {
  const stringsToRemove = ["<p>", "</p>"];
  if (text) {
    stringsToRemove.map((str) => {
      text = text.replace(str, "");
    });
    return text;
  }
  return "";
};

export const getResponsiveBackgroundImageWidth = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const breakpoints: any = useBreakpoint();
  if (breakpoints.sm) {
    return breakPointValues.md;
  }
  if (breakpoints.md) {
    return breakPointValues.lg;
  }
  if (breakpoints.lg) {
    return breakPointValues.xl;
  }
  if (breakpoints.xl) {
    return 2000;
  }
  return breakPointValues.sm;
};

export const getDefaultCountryFromLocale = (locale: Locale) => {
  return countries.find(
    (country) => country.countryCode === locale.defaultCountry
  );
};

export const imageHotspotUsed = (mainImage) => {
  return mainImage.hotspot !== undefined;
};

export const trimDate = (date) => {
  return date.split("T")[0];
};

export const splitByColon = (str) => {
  if (str) {
    return str.split(":");
  }
  return "";
};
export const getPositionFromHotspot = (hotspot) => {
  if (!hotspot || !hotspot.x || !hotspot.y) return "center";
  return `${hotspot.x * 100}% ${hotspot.y * 100}%`;
};

export const resolveBgColor = () => {
  // Add pages to the list to make the background span the entire screen
  // Remember to add translations
  const pagesWithBgPrimary4 = ["order"];
  const pagesWithBgSecondary3 = ["checkout"];
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter();
  const cat = router?.query?.category?.toString() || "";
  const bgPrimary4 =
    pagesWithBgPrimary4.includes(cat) ||
    router?.query?.hasOwnProperty("orderId") ||
    false;
  const bgSecondary3 = pagesWithBgSecondary3.includes(cat) || false;
  if (bgPrimary4) return "bg-primary-mono-4";
  else if (bgSecondary3) return "bg-secondary-mono-3";
  else if (router.asPath.split("/")[2] === "tailit") return "bg-secondary-3";
  return "";
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const similarity = (s1: string, s2: string) => {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  let longerLength = longer.length;
  if (longerLength == 0) {
    return 1.0;
  }
  return (
    (longerLength - editDistance(longer, shorter)) /
    parseFloat(longerLength.toFixed(1))
  );
};

const editDistance = (s1: string, s2: string) => {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  let costs = new Array();
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i;
    for (let j = 0; j <= s2.length; j++) {
      if (i == 0) costs[j] = j;
      else {
        if (j > 0) {
          let newValue = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
};

export const createCheckoutData = (
  order,
  products,
  coupon,
  payment_method,
  payment_method_title,
  shipping_method_id,
  shipping_method_title,
  cvg_uid,
  sitename
) => {
  let newProducts = [];
  products.map((product, index) => {
    newProducts[index] = {
      product_id: product.id,
      //TODO endre quantity når butikken skal selge mer enn 1 produkt.
      quantity: product.quantity,
    };
  });
  const checkoutData = {
    billing: order
      ? {
          first_name: order?.firstName ? order.firstName : "",
          last_name: order?.lastName ? order.lastName : "",
          address_1: order?.address1 ? order.address1 : "",
          address_2: order?.address2 ? order.address2 : "",
          city: order?.city ? order.city : "",
          country: order?.country ? order.country : "",
          postcode: order?.postcode ? order.postcode : "",
          // email: order?.email ? order.email : "",
          phone: order?.phone ? order.phone : "",
        }
      : "",
    shipping: order
      ? {
          first_name: order?.firstName ? order.firstName : "",
          last_name: order?.lastName ? order.lastName : "",
          address_1: order?.address1 ? order.address1 : "",
          address_2: order?.address2 ? order.address2 : "",
          city: order?.city ? order.city : "",
          country: order?.country ? order.country : "",
          postcode: order?.postcode ? order.postcode : "",
          // email: order?.email ? order.email : "",
          phone: order?.phone ? order.phone : "",
        }
      : "",
    customer_note: order && order?.orderNotes ? order.orderNotes : "",
    line_items: newProducts,
    shipping_lines: [
      {
        method_id: shipping_method_id,
        method_title: shipping_method_title,
        total: "0.00",
      },
    ],
    coupon_lines: coupon
      ? [
          {
            code: coupon,
          },
        ]
      : [],
    payment_method: payment_method,
    payment_method_title: payment_method_title,
    meta_data: [
      {
        key: "_subscribe_news",
        value: order && order?.subscribeNews ? order.subscribeNews : false,
      },
      cvg_uid && {
        key: "cvg_uid",
        value: cvg_uid,
      },
      {
        key: "shop_name",
        value: sitename,
      },
    ],
  };
  if (order && order?.email) {
    checkoutData.billing["email"] = order.email;
    checkoutData.shipping["email"] = order.email;
  }

  return checkoutData;
};

export const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const isValidImei = (imei: string) => {
  imei = imei.replace(/\D/g, "");
  if (imei.length !== 15) {
    return false;
  }

  const digits = imei.split("").map(Number);
  for (let i = digits.length - 2; i >= 0; i -= 2) {
    digits[i] *= 2;
    if (digits[i] > 9) {
      digits[i] -= 9;
    }
  }
  const sum = digits.reduce((acc, curr) => acc + curr, 0);
  return sum % 10 === 0;
};
