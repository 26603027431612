import React, { useEffect, useState, useRef } from "react";

import Klarna from "./checkout/klarna";
import { useLocale, Product } from "@lilbit/shared";
import countryList from "./checkout/country-list";
import { BiExit } from "react-icons/bi";
type Props = {
  productID: number;
  coupon: string;
  lgScreenWidth: string;
  smScreenWidth: string;
  input: any;
  getTotalPrice: Function;
  mainProduct: Product;
  productTaxRate: any;
  country: any;
};

const KlarnaExpress = (props: Props) => {
  const { coupon, getTotalPrice, mainProduct, productTaxRate, country } = props;
  const [showKlarnaModal, setShowKlarnaModal] = useState(false);
  const [showCountryChooser, setShowCountryChooser] = useState(true);
  const [disabledButton, setDisabledButton] = useState(true);
  const [klarnaInput, setKlarnaInput] = useState({
    country: "",
    createAccount: false,
    paymentMethod: "klarna",
    acceptTerms: true,
    subscribeNews: false,
    errors: null,
    coupon: "",
  });
  const urlPrefix = useLocale().urlPrefix;

  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      setShowKlarnaModal(false);
      setShowCountryChooser(true);
      setDisabledButton(true);
    }
  };

  const pressOnEnterKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  useEffect(() => {
    document.body.addEventListener("keydown", pressOnEnterKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", pressOnEnterKeyDown);
    };
  }, []);

  const handleExit = () => {
    setShowKlarnaModal(false);
    setShowCountryChooser(true);
    setDisabledButton(true);
  };

  const handleOnChange = (event: any) => {
    setKlarnaInput({
      ...klarnaInput,
      country: event.target.value,
    });
    if (event.target.value !== "") {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  return (
    <div className="flex flex-col w-full  cursor-pointer lg:rounded-3xl lg:justify-start z-50">
      <button
        className={`buttonPress bg-pink-200 relative flex items-center justify-center  mx-0 lg:mx-0 w-full h-[48px] sm:max-w-full lg:max-w-full rounded-[40px] disabled:cursor-not-allowed font-objektiv`}
        onClick={() => setShowKlarnaModal(true)}
      >
        <div className="flex flex-row justify-center items-center px-2 space-x-2">
          <p className="text-black font-bold text-center text-sm">Klarna.</p>
          <p className="text-black font-normal text-xs text-center">
            Express Checkout
          </p>
        </div>
      </button>
      {showKlarnaModal && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-900 z-10 bg-opacity-30 cursor-pointer ">
          <div className="bg-white cursor-default fixed inset-0 items-center content-center flex flex-col justify-center gap-y-4  overflow-y-scroll">
            <BiExit
              className="z-40 top-4 left-4 h-8 w-8 absolute cursor-pointer transform transition-transform hover:scale-125"
              onClick={handleExit}
            />
            {showCountryChooser ? (
              <>
                <div className=" items-center space-y-4">
                  <label
                    htmlFor="country"
                    className="block text-lg font-normal text-gray-700"
                  >
                    {translations.chooseCountry[urlPrefix] ??
                      translations.chooseCountry.en}
                  </label>
                  <div className="mt-1">
                    <select
                      required
                      onChange={handleOnChange}
                      defaultValue={country.displayName}
                      id="country-select"
                      name="country"
                      autoComplete="country-name"
                      className="block w-full rounded-md shadow-sm focus:border-primary-mono-2 focus:ring-primary-mono-2 sm:text-sm"
                    >
                      <option />
                      {countryList.map((country) => (
                        <option
                          key={country.countryCode}
                          value={country.countryCode}
                        >
                          {country.countryName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <button
                    id="checkout-button-fast-klarna"
                    disabled={disabledButton}
                    className={`buttonPress bg-primary-mono-2 relative flex items-center justify-center  mx-0 lg:mx-0 w-full h-[48px] sm:max-w-full lg:max-w-full rounded-xl disabled:cursor-not-allowed font-objektiv`}
                    onClick={() => setShowCountryChooser(false)}
                  >
                    <div className="flex flex-row justify-center items-center px-2 space-x-2">
                      <p className="text-white font-semibold text-center text-xs">
                        Go To Checkout
                      </p>
                    </div>
                  </button>
                </div>
              </>
            ) : (
              <div className="h-[600px] md:h-[800px] w-[260px] sm:w-[300px] md:w-[460px]  lg:w-[600px]">
                <Klarna
                  product={mainProduct}
                  productTaxRate={productTaxRate}
                  input={klarnaInput}
                  coupon={coupon}
                  getTotalPrice={getTotalPrice}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const translations = {
  chooseCountry: {
    en: "Choose delivery country",
    no: "Velg leveringsland",
    se: "Välj leveransland",
    da: "Vælg leveringsland",
    dk: "Vælg leveringsland",
    fi: "Valitse toimitusmaa",
    de: "Wähle Lieferland",
    fr: "Choisissez le pays de livraison",
    es: "Elija el país de entrega",
  },
};
export default KlarnaExpress;
