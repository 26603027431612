import Image from "next/image";
import { Image as ImageType } from "../../lib/types";
import { useRouter } from "next/router";

interface Props {
  urlFor: Function;
  google_icon: ImageType;
  google_text: string;
  google_url: string;
  apple_icon: ImageType;
  apple_text: string;
  apple_url: string;
  header_text: string;
  first_text: string;
  second_text: string;
  SimpleBlockContent: any;
}
const DeviceIMEI = ({
  google_icon,
  google_text,
  apple_icon,
  apple_text,
  google_url,
  apple_url,
  header_text,
  first_text,
  second_text,
  urlFor,
  SimpleBlockContent,
}: Props) => {
  const router = useRouter();
  const imei = router.query.imei
    ? router.query.imei
    : router.query.IMEI
    ? router.query.IMEI
    : "";
  return (
    <div className="flex flex-col justify-center min-h-[400px] font-objektiv px-12">
      {imei && (
        <p className="pt-12 text-[24px] leading-[32px] text-center font-objektiv text-primary-1">
          {header_text} <span className=" font-semibold">{imei}</span>
        </p>
      )}
      <SimpleBlockContent
        blocks={first_text}
        className="pt-12 font-objektiv text-center text-[18px] leading-[25px] lg:text-[20px] lg:leading-[36px] text-primary-1"
      />
      <div className="block items-center self-center jusify-between sm:flex sm:flex-row ">
        <a
          className="flex mt-3 lg:mr-6 w-48 h-14 bg-black text-white rounded-lg items-center justify-center hover:cursor-pointer mr-6"
          href={google_url}
          target="_blank"
          rel="noreferrer"
        >
          {google_icon && (
            <div className="mr-3 h-[40px] w-[40px] relative py-2 ">
              <Image
                src={urlFor(google_icon).url()}
                layout="fill"
                className=""
                objectFit="contain"
                alt={google_icon.alt || ""}
              />
            </div>
          )}
          <div>
            <div className="text-xs">{google_text}</div>
            <div className="text-xl font-semibold font-sans -mt-1">
              Google Play
            </div>
          </div>
        </a>
        <a
          className="flex mt-3 w-48 h-14 bg-black text-white rounded-lg items-center justify-center hover:cursor-pointer"
          href={apple_url}
          target="_blank"
          rel="noreferrer"
        >
          {apple_icon && (
            <div className="mr-3 relative h-[40px] w-[40px] justify-start">
              <Image
                src={urlFor(apple_icon).url()}
                layout="fill"
                className=" "
                objectFit="contain"
                alt={apple_icon.alt || ""}
              />
            </div>
          )}
          <div>
            <div className="text-xs">{apple_text || ""}</div>
            <div className="text-2xl font-semibold font-sans -mt-1">
              App Store
            </div>
          </div>
        </a>
      </div>
      <div>
        <SimpleBlockContent
          blocks={second_text}
          className="font-objektiv text-center text-[18px] leading-[25px] lg:text-[20px] lg:leading-[36px] text-primary-1 my-6 md:my-12"
        />
      </div>
    </div>
  );
};

export default DeviceIMEI;
