interface Props {
  body: string;
  SimpleBlockContent: any;
}

const SimpleBlockText = ({
  body,
  SimpleBlockContent
}: Props) => {
  return (
        <SimpleBlockContent blocks={body} />
  );
};

export default SimpleBlockText;
